import { useFdrDeleteBookmarkGroupMutation } from 'schemas/query/bookmark/fdr-delete-bookmark-group.generated';

export function useDeleteBookmarkGroup() {
  const [mutation, { data, loading }] = useFdrDeleteBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['FdrBookmarkGroupList'],
  });

  async function deleteBookmarkGroup(groupId: string) {
    return mutation({
      variables: {
        input: {
          id: groupId,
        },
      },
    });
  }

  return {
    deleteBookmarkGroup,
    data,
    loading,
  };
}
