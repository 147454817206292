import { IMetaDataContext } from '@hotelplan/fdr.lib.meta.with-meta';

export function tryMobileShare(
  meta: IMetaDataContext,
  mobile: boolean,
  url: string,
  share: boolean
): boolean {
  const windowNavigator = window.navigator as any;
  if (share && windowNavigator?.share && meta && mobile) {
    windowNavigator.share({
      title: meta.title,
      text: meta.description,
      url: url || window.location.href,
    });

    return false;
  }

  return true;
}
