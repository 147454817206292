import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrStaticBreadcrumbsDocument,
  FdrStaticBreadcrumbsQuery,
  FdrStaticBreadcrumbsQueryVariables,
} from 'schemas/query/static/fdr-static-breadcrumbs.generated';
import { createBreadcrumb, getHomeBreadcrumb } from './utils.common';

export async function getFdrStaticBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrStaticBreadcrumbsQuery,
    FdrStaticBreadcrumbsQueryVariables
  >({
    query: FdrStaticBreadcrumbsDocument,
    variables: { id: pageId },
  });

  const homeBreadcrumb = getHomeBreadcrumb(data, lang);

  return [
    ...homeBreadcrumb, // home
    createBreadcrumb(null, data?.fdrStaticPage.title, true), // current static
  ];
}
