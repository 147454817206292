import styled from 'styled-components';

export const FdrInfoNotificationContainer = styled.div(
  ({ theme: { colors, space, media } }) => ({
    borderRadius: '0px',
    borderBottom: '1px solid',
    borderColor: colors.borderColor,
    paddingTop: space[1],
    paddingBottom: space[1],
    width: '100%',
    minHeight: '38px',
    backgroundColor: 'white',
    display: 'flex',
    'p, a': {
      fontSize: '16px',
      lineHeight: '22px',
    },
    img: {
      width: '20px',
      height: '20px',
    },
    [media.mobile]: {
      '> div': {
        padding: `0 ${space[3]}`,
      },
    },
  })
);
