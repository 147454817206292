import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FlaglineText } from 'theme/headings';
import { useFdrExactSearchContext } from './fdr-exact-search.context';
import {
  FdrExactSearchOptions,
  FdrExactSearchSection,
  FdrOptionButton,
} from './fdr-exact-search.styles';

interface IIconOptions {
  checkmarkIcon: string;
  checkmarkBg: string;
  closeIcon: string;
  closeBg: string;
}

const iconOptions = {
  checked: {
    checkmarkIcon: `checkmark-white`,
    checkmarkBg: 'redWeb',
    closeIcon: `close-orange`,
    closeBg: 'transparent',
  },
  unChecked: {
    checkmarkIcon: `checkmark-orange`,
    checkmarkBg: 'transparent',
    closeIcon: `close-white`,
    closeBg: 'redWeb',
  },
};

function checkIcon(isExactSearch: boolean): IIconOptions {
  if (isExactSearch) {
    return iconOptions.checked;
  }

  return iconOptions.unChecked;
}

export function FdrExactSearch(): ReactElement {
  const [t] = useTranslation('search');
  const exactSearchState = useFdrExactSearchContext();
  const [isExactSearch, setExactSearch] = exactSearchState || [];

  const { checkmarkIcon, checkmarkBg, closeIcon, closeBg } =
    checkIcon(isExactSearch);

  function offExactSearch() {
    setExactSearch(false);
  }

  function onExactSearch() {
    setExactSearch(true);
  }

  return (
    <FdrExactSearchSection className="exact-search">
      <FlaglineText>{t('search:exact.search.title')}</FlaglineText>
      <FdrExactSearchOptions>
        <FdrOptionButton
          variant="iconBtn"
          bg={closeBg}
          icon={{ name: closeIcon }}
          onClick={offExactSearch}
          isCloseIcon
        />
        <FdrOptionButton
          variant="iconBtn"
          bg={checkmarkBg}
          icon={{ name: checkmarkIcon }}
          onClick={onExactSearch}
        />
      </FdrExactSearchOptions>
    </FdrExactSearchSection>
  );
}
