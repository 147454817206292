import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrUspBoxesWrapper = styled(Container)<{ count?: number }>(
  ({ theme: { colors, media }, count = 4 }) => {
    const boxWidth = count === 1 ? '100%' : `calc(${100 / count}%)`;

    return sx2CssThemeFn({
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: '20px',

      [media.mobile]: {
        overflowX: 'auto',
        scrollbarWidth: 'none',
        p: 0,
      },
      '> div': {
        img: {
          maxWidth: '150px',
          maxHeight: '121px',
          mt: '40px',
          mb: '30px',
          objectFit: 'contain',
        },
      },
      '.with-link': {
        '&:hover': {
          borderColor: colors.primary,
        },
      },
      h2: {
        width: '100%',
        borderBottom: `1px solid ${colors.darkGrey}`,
        mb:'8px',
        pb: '20px',
      },
    });
  }
);
