import React, { ReactElement } from 'react';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import { FdrMarketingMediaSwitch } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-marketing-media-switch';
import { getResourceLink } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-media.util';
import { FdrSingleMarketingItem } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-single-marketing-item';
import { FdrTeaserCard } from 'components/domain/fdr-teaser-card';
import {
  ListContainer,
  RecommendationsGrid,
} from 'components/domain/recommendations/Recommendations.common';
import { RecommendationSliderSection } from 'components/domain/recommendations/RecommendationSliderSection';
import { FdrMarketingRecommenderFragment } from 'schemas/fragment/regular/fdrMarketingRecommender.generated';

const ITEMS_PER_PAGE = 3;

interface IFdrMarketingRecommendationsProps {
  component: FdrMarketingRecommenderFragment;
}

enum EDisplayType {
  SWIPE = 'SWIPE',
  BLOCK = 'BLOCK',
}

function renderImageCard(item, lazy) {
  const media = item.media;
  const resourceLink = getResourceLink(item.media);

  if (media.__typename === 'FdrImage') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { resizedPreview, resizedImage, ...other } = media;
    const image = { resized: resizedPreview, ...other };

    return <FdrImg image={image} lazy={lazy} />;
  }

  return (
    <FdrMarketingMediaSwitch
      typename={media.__typename}
      resourceLink={resourceLink}
      lazy={lazy}
    />
  );
}

export function FdrMarketingRecommender({
  component,
}: IFdrMarketingRecommendationsProps): ReactElement {
  const {
    marketingItems: items,
    bgColor,
    fgColor,
    title,
    titleFgColor,
    displayType = EDisplayType.SWIPE,
  } = component;
  const count = items.length;

  const itemsResult = items.map((item, index) => {
    const lazy = displayType === EDisplayType.SWIPE && index > 2;

    return (
      <FdrTeaserCard
        key={index}
        cfg={{
          subTitle: item.subtitle,
          title: item.title,
          lazy,
          link: item.link,
        }}
        img={renderImageCard(item, lazy)}
        data-id="marketing-card"
        className="marketing-item"
      />
    );
  });

  return (
    <div
      data-id="marketing-recommendations-section"
      style={{ color: fgColor, backgroundColor: bgColor }}
    >
      {count === 1 ? (
        <FdrSingleMarketingItem item={items[0]} />
      ) : (
        <ListContainer data-display={displayType}>
          {displayType !== EDisplayType.SWIPE ? (
            <CardsSection
              title={title}
              page={1}
              totalPages={1}
              headerStyle={{ color: titleFgColor, marginRight: '24px' }}
            >
              <RecommendationsGrid style={{ borderColor: titleFgColor }}>
                {itemsResult}
              </RecommendationsGrid>
            </CardsSection>
          ) : (
            <RecommendationSliderSection
              title={title}
              count={count}
              titleColor={titleFgColor || fgColor}
              itemsPerPage={ITEMS_PER_PAGE}
            >
              {itemsResult}
            </RecommendationSliderSection>
          )}
        </ListContainer>
      )}
    </div>
  );
}
