import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const Tile = styled.div<{ isFullHeight: boolean }>(
  ({ theme: { colors }, isFullHeight }) =>
    sx2CssThemeFn({
      background: 'white',
      marginBottom: '10px',
      borderRadius: '5px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: [isFullHeight ? 'column' : 'row', 'column'],
      '&:last-child': {
        marginBottom: '0',
      },
      a: {
        padding: '10px 24px',
        borderRadius: '5px',
      },
      '&:hover': {
        background: colors.redHover,
        color: colors.white,
        cursor: 'pointer',

        '&.old-roundtrip': {
          background: [colors.white, colors.oldRoundtripsPinHover],
        },
      },
      '&.active': {
        background: [colors.white, colors.redActive],
        color: [colors.black, colors.white],
        '&:hover': { cursor: 'default' },

        a: {
          background: [colors.redWeb, colors.white],
          color: [colors.white, colors.primary],
        },

        '&.old-roundtrip': {
          background: [colors.white, colors.blueOcean],
        },
      },
      '&.inverse.active': {
        a: {
          background: [colors.redActive, colors.white],
          color: [colors.white, colors.primary],
        },
      },
      '&.inverse': {
        '&:hover': {
          a: {
            background: [colors.redActive, colors.white],
            color: [colors.white, colors.redActive],
          },
        },
        a: {
          background: colors.redWeb,
          color: colors.white,
        },
      },
    })
);

export const TripButton = styled.a({
  maxWidth: '100%',
  marginTop: '5px',
});

export const ImageWrapper = styled.div(
  sx2CssThemeFn(({ theme: { colors } }) => ({
    overflow: 'hidden',
    height: ['auto', '100px'],
    maxHeight: ['86px', '100px'],
    width: ['91px', 'auto'],
    flexShrink: 0,
    background: colors.lightGrey,
    position: 'relative',
    img: {
      width: '100%',
      height: '100%',
    },
  }))
);

export const Ellipse = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
});

export const Description = styled.span({
  marginBottom: '10px',
  display: 'block',
  fontWeight: '700',
});

export const Details = styled.span({
  display: 'block',
});

export const TileText = styled.div({
  padding: '10px',
  fontSize: '16px',
  lineHeight: '22px',
  overflow: 'hidden',
});

export const ListSubtitle = styled.div(({ theme: { colors } }) => ({
  fontSize: '15px',
  fontWeight: '400',
  textTransform: 'uppercase',
  padding: '10px 0',
  '.number': {
    color: colors.primary,
    paddingLeft: '6px',
  },
}));

export const Info = styled.div(
  sx2CssThemeFn({
    fontSize: 1,
    fontWeight: 'normal',
    lineHeight: '22px',
    display: 'flex',
    marginBottom: '6px',
    marginTop: '2px',

    svg: {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      flexShrink: 0,
      color: 'black',
    },
  })
);
