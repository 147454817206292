import isEqual from 'lodash/fp/isEqual';
import uniqWith from 'lodash/fp/uniqWith';
import React, { useState } from 'react';
import {
  ProductPinType,
  TInputProductWithRoute,
  IAdventureTravelRouteProduct,
  TResultProductWithRoute,
} from './fdr-routes.types';

type TSaveNumber = React.Dispatch<React.SetStateAction<number>>;

type TContext = {
  active: number | undefined;
  hovered: number | undefined;
  click: TSaveNumber;
  hover: TSaveNumber;
  isRouteMode: boolean;
  products: TResultProductWithRoute[];
  pinType: ProductPinType;
  showNearBy: boolean;
  hasCurrent: boolean;
};

export const FdrRouteContext = React.createContext<TContext>({
  active: undefined,
  hovered: undefined,
  click: () => void 0,
  hover: () => void 0,
  isRouteMode: false,
  products: [],
  pinType: ProductPinType.PinIcon,
  showNearBy: false,
  hasCurrent: false,
});

type TProps = {
  productsWithRoutes?: Array<IAdventureTravelRouteProduct>;
  currentProduct?: TInputProductWithRoute;
  pinType: ProductPinType;
  showNearBy: boolean;
};

const toProductWithRoute = ({
  link,
  name,
  image,
  routePoints,
}: IAdventureTravelRouteProduct): TResultProductWithRoute => ({
  link,
  name,
  images: [],
  image,
  routePoints: routePoints || [],
});

export const FdrRoutes: React.FC<React.PropsWithChildren<TProps>> = ({
  children,
  productsWithRoutes,
  currentProduct,
  pinType,
  showNearBy,
}: React.PropsWithChildren<TProps>) => {
  const [active, setActive] = useState<number>(undefined);
  const [hovered, setHovered] = useState<number>(undefined);

  const isRouteMode = pinType === ProductPinType.RouteIcon;

  const nearByRoutes: TResultProductWithRoute[] = showNearBy
    ? (productsWithRoutes || []).map(toProductWithRoute)
    : [];

  const isNearbyWithoutCurrent = isRouteMode && !currentProduct;
  const isNearbyWithCurrent =
    isRouteMode && currentProduct?.routePoints?.length > 0;

  const products =
    (!isRouteMode && [currentProduct]) ||
    (isNearbyWithoutCurrent && nearByRoutes) ||
    (isNearbyWithCurrent && [currentProduct, ...nearByRoutes]) ||
    [];

  const uniqueProducts = uniqWith(isEqual, products);

  return (
    <FdrRouteContext.Provider
      value={{
        active,
        hovered,
        click: setActive,
        hover: setHovered,
        isRouteMode,
        products: uniqueProducts,
        hasCurrent: !!currentProduct,
        pinType,
        showNearBy,
      }}
    >
      {children}
    </FdrRouteContext.Provider>
  );
};
