import md5 from 'blueimp-md5';
import { uniqWith } from 'lodash/fp';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { ICoordinates } from '@hotelplan/components.common.map-pin/Map.types';
import {
  FdrAdventureTravelRoutePoint,
  FdrCoordinates,
  FdrImage,
  FdrLink,
  ProductInfoB2B,
} from '@hotelplan/supergraph-api';
import type { IAdventureTravelRouteProduct } from 'components/domain/fdr-adventure-travel-routes';
import { TPinType } from 'components/domain/fdr-geo/trips-list/fdr-geo-trips.types';

export type TProductPin = {
  type: TPinType;
  tripId: string;
  name: string;
  pointName: string;
  image: FdrImage;
  coordinates?: FdrCoordinates;
  link?: FdrLink;
  routePoints?: FdrAdventureTravelRoutePoint[];
  productInfoB2B?: ProductInfoB2B;
};

export type RelatedRoundtripItem = {
  image?: FdrImage;
  link: FdrLink;
  name: string;
  productInfoB2B?: ProductInfoB2B;
};

export type TRoundtripItem = RelatedRoundtripItem & {
  type: TPinType;
  tripId: string;
  relatedId: string;
};

export type RelatedRoundtrips = {
  coordinates?: FdrCoordinates;
  roundtrips?: RelatedRoundtripItem[];
};

export type TRelatedPin = RelatedRoundtrips & {
  type: TPinType;
  tripId?: string;
  roundtrips?: TRoundtripItem[];
};

const uniqueId = (prefix: string, element: any) => {
  return `${prefix}_${md5(JSON.stringify(element))}`;
};

export const isEqualByTripId = <T extends { tripId?: string }>(
  prev: T,
  next: T
): boolean => next.tripId && prev.tripId === next.tripId;

const isEqualPin = (prev, next) => {
  return (
    prev.coordinates.latitude === next.coordinates.latitude &&
    prev.coordinates.longitude === next.coordinates.longitude
  );
};

const fillWithIds = <T, D extends { [prop: string]: string }>(
  prefix: string,
  items: T[],
  extend: D = {} as D
): Array<T & { tripId: string } & D> =>
  items.map(i => ({ ...i, tripId: uniqueId(prefix, i), ...extend }));

const fillWithIdsAndMap = <T>(prefix: string, items: T[]): Map<string, T> =>
  new Map(items.map(i => [uniqueId(prefix, i), i]));

export const enrichRelatedPinWithIds = (
  related: RelatedRoundtrips[]
): Map<string, TRelatedPin> => {
  const pins: Array<[string, TRelatedPin]> = related.map((p, i) => {
    const relatedId = uniqueId(`related`, i);
    const roundtrips = fillWithIds(`trip_${i}`, p.roundtrips, {
      relatedId,
      type: TPinType.RELATED_ROUTES,
    });

    return [relatedId, { ...p, roundtrips, type: TPinType.RELATED_ROUTES }];
  });

  return new Map(pins);
};

const enrichProductRoutePin = (
  product: IAdventureTravelRouteProduct
): TProductPin[] => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { routePoints, name, image, link, productInfoB2B } = product;
  const tripId = uniqueId(`trip`, product);

  return [
    ...routePoints.map(({ coordinates, locationName: pointName }) => ({
      type: TPinType.PRODUCTS,
      coordinates,
      productInfoB2B,
      image,
      name,
      pointName,
      link,
      routePoints,
      tripId,
    })),
  ];
};

export const findUniqProductRoutePins = (
  items: Omit<IAdventureTravelRouteProduct, '__typename'>[]
): Map<string, TProductPin> => {
  return fillWithIdsAndMap(
    `product`,
    uniqWith(isEqualPin, items.flatMap(enrichProductRoutePin))
  );
};

export const fitBounds = (
  map: google.maps.Map,
  coordinates: ICoordinates[],
  maxZoom?: number
) => {
  const bounds = new google.maps.LatLngBounds();
  coordinates.forEach(c => bounds.extend(mapCoordinatesToLatLng(c)));
  map.fitBounds(bounds, 30);

  if (maxZoom) {
    const zoom = map.getZoom();
    map.setZoom(zoom > maxZoom ? maxZoom : zoom);
  }
};
