import React, { ReactElement, useEffect, useRef } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import FdrBookmarkBlock from 'components/domain/fdr-bookmark/fdr-bookmark-block';
import { FdrDisrupters } from 'components/domain/fdr-disrupter';
import { FdrImageTeaser } from 'components/domain/fdr-teaser-card/fdr-image-teaser';
import { CardItemBreadcrumbs } from 'components/domain/recommendations/Recommendations.common';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { ThirdHeading } from 'theme/headings';
import {
  ProductRecommendationWrapper,
  TeaserImageCardWrapper,
} from './fdr-product-recommendation.styles';
import { IFdrRecommenderProduct } from './fdr-product-recommender.types';

export function FdrProductRecommendation(
  props: IFdrRecommenderProduct & {
    className?: string;
    lazy?: boolean;
    getImageHeight?: (height: number) => void;
  }
): ReactElement {
  const {
    id,
    name,
    link,
    features = [],
    disrupters,
    image,
    lazy,
    className,
    bookmarked,
    getImageHeight,
  } = props;
  const imageRef = useRef<HTMLDivElement>(null);
  const { h } = useElementSize(imageRef);

  useEffect(() => {
    getImageHeight && getImageHeight(h);
  }, [h, getImageHeight]);

  return (
    <ProductRecommendationWrapper className={className} data-id="product">
      <TeaserImageCardWrapper ref={imageRef}>
        <FdrImageTeaser
          className="image-card"
          cfg={{
            link: link,
            lazy: lazy,
          }}
          image={image}
        >
          {disrupters && disrupters.length ? (
            <FdrDisrupters
              disrupters={disrupters}
              className="product-recommendation-disrupter"
            />
          ) : null}
        </FdrImageTeaser>
        <FdrBookmarkBlock
          objectId={id}
          active={bookmarked}
          trackingComponent={BookmarkComponentType.RECOMMENDER}
        />
      </TeaserImageCardWrapper>
      <FdrRoute link={link}>
        <div className="heading-wrap">
          <ThirdHeading className="heading-title">{name}</ThirdHeading>
        </div>
        <div className="breadcrumb-wrap">
          <CardItemBreadcrumbs
            className="features-list"
            breadcrumbs={features.filter(feature => feature.enabled)}
          />
          <BsIcon name={'chevron-long-right'} size="lg" />
        </div>
      </FdrRoute>
    </ProductRecommendationWrapper>
  );
}
