import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrCreateBookmarkGroupMutationVariables = Types.Exact<{
  input: Types.FdrCreateBookmarkGroupInput;
}>;

export type FdrCreateBookmarkGroupMutation = {
  __typename?: 'Mutation';
  fdrCreateBookmarkGroup: {
    __typename?: 'FdrCreateBookmarkGroupResponse';
    success: boolean;
    entity?: {
      __typename?: 'FdrBookmarkGroup';
      id: string;
      name: string;
    } | null;
  };
};

export const FdrCreateBookmarkGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrCreateBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCreateBookmarkGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrCreateBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrCreateBookmarkGroupMutationFn = Apollo.MutationFunction<
  FdrCreateBookmarkGroupMutation,
  FdrCreateBookmarkGroupMutationVariables
>;

/**
 * __useFdrCreateBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useFdrCreateBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrCreateBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrCreateBookmarkGroupMutation, { data, loading, error }] = useFdrCreateBookmarkGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrCreateBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrCreateBookmarkGroupMutation,
    FdrCreateBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrCreateBookmarkGroupMutation,
    FdrCreateBookmarkGroupMutationVariables
  >(FdrCreateBookmarkGroupDocument, options);
}
export type FdrCreateBookmarkGroupMutationHookResult = ReturnType<
  typeof useFdrCreateBookmarkGroupMutation
>;
export type FdrCreateBookmarkGroupMutationResult =
  Apollo.MutationResult<FdrCreateBookmarkGroupMutation>;
export type FdrCreateBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  FdrCreateBookmarkGroupMutation,
  FdrCreateBookmarkGroupMutationVariables
>;
