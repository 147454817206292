import { useTranslation } from 'next-i18next';
import React, { ReactElement, useRef } from 'react';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  FdrHelpOverlay,
  FdrHelpOverlayMobile,
} from 'components/domain/fdr-help-overlay';
import {
  trackHelpOverlayCloseClick,
  trackHelpOverlayOpenClick,
} from 'components/domain/tracking/helpOverlayTracking';
import { FdrSiteToolsButtonsWrapper } from './fdr-sitetools.styles';

export function FdrContactSiteTool(): ReactElement {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const helpOverlayButtonRef = useRef<HTMLButtonElement>(null);
  const helpOverlayDropdownRef = useRef<HTMLDivElement>(null);

  const [isHelpVisible, , closeHelp, toggleHelp] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [helpOverlayDropdownRef, helpOverlayButtonRef],
    () => {
      if (!isHelpVisible) return;
      closeHelp();
    }
  );

  function handleSiteToolButtonClick() {
    toggleHelp();
    isHelpVisible ? trackHelpOverlayCloseClick() : trackHelpOverlayOpenClick();
  }

  function handleHelpOverlayClose() {
    closeHelp();
    trackHelpOverlayCloseClick();
  }

  return (
    <>
      <FdrSiteToolsButtonsWrapper
        data-id="contacts-site-tool"
        className={`site-tool-button ${
          isHelpVisible ? 'help-overlay-shown' : ''
        }`}
      >
        <BsButtonWithIcon
          ref={helpOverlayButtonRef}
          variant="headerBtn"
          icon={{ name: isHelpVisible ? 'close-icon' : 'contact' }}
          onClick={handleSiteToolButtonClick}
        />
        <span className="tooltip">{t('contact.button')}</span>
      </FdrSiteToolsButtonsWrapper>
      {mobile ? (
        <FdrHelpOverlayMobile
          visible={isHelpVisible}
          onClose={handleHelpOverlayClose}
        />
      ) : (
        <>
          {isHelpVisible ? (
            <FdrHelpOverlay
              dropDownRef={helpOverlayDropdownRef}
              onClose={closeHelp}
            />
          ) : null}
        </>
      )}
    </>
  );
}
