import React, { HTMLAttributes } from 'react';
import { CSSObject } from 'styled-components';

export const CARDS_PER_PAGE = 3;
export const CARDS_PER_PAGE_THEME = 4;
export const CARDS_PER_PAGE_THEME_SMALLDESKTOP = 3;
export const CARDS_GAP = 20;
export const SWIPER_ITEM_SIZE_RATIO = 0.8;
export const CARD_MAX_WIDTH = 380;
export const SECTION_WIDTH = 1440;

export interface ICardSectionSlidersProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'style'> {
  className?: string;
  page: number;
  total?: number;
  perPage?: number;
  onChangePage?(page: number): void;
  placeholder?(index: number): React.ReactElement;
  hideDelimiter?: boolean;
  cardsGap?: number;
  slideStyles?: CSSObject;
  slideClass?: string;
}
