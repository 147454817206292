import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { ProductInfoB2B } from '@hotelplan/supergraph-api';
import { removeExtraSpaces } from 'components/domain/string/string.utils';
import { FdrTypeFlag, FdrTypeFlagsWrapper } from './fdr-search.styles';

interface IFdrSearchProductInfoProps extends ProductInfoB2B {
  leadText?: string;
}

export function FdrSearchProductInfo({
  route,
  duration,
  priceFrom,
  typeFlags,
  leadText,
}: IFdrSearchProductInfoProps): ReactElement {
  const [t] = useTranslation('search');

  return (
    <>
      {removeExtraSpaces(duration) ? (
        <div>
          <span>{t('search.results.duration')}</span> <span>{duration}</span>
        </div>
      ) : null}
      {removeExtraSpaces(route) ? (
        <div>
          <span>{route}</span>
        </div>
      ) : null}
      {removeExtraSpaces(priceFrom) ? (
        <div>
          <span>{t('search.results.price')}</span> <span>{priceFrom}</span>
        </div>
      ) : null}
      {removeExtraSpaces(leadText) ? (
        <div
          className="lead-info"
          dangerouslySetInnerHTML={{ __html: leadText }}
        />
      ) : null}
      {typeFlags?.length ? <FdrSearchTypeFlags typeFlags={typeFlags} /> : null}
    </>
  );
}

interface ISearchItemProps {
  typeFlags?: string[];
}

function FdrSearchTypeFlags({ typeFlags }: ISearchItemProps): ReactElement {
  if (!typeFlags) return null;

  return (
    <FdrTypeFlagsWrapper>
      {typeFlags.map((flag, index) => (
        <FdrTypeFlag key={index}>{flag}</FdrTypeFlag>
      ))}
    </FdrTypeFlagsWrapper>
  );
}
