import React from 'react';
import { TCustomMapControl } from '@hotelplan/fdr.regular.fusion.fdr-map';
import closeFullscreenIcon from 'public/images/svg/close-fullscreen.svg';
import openFullscreenIcon from 'public/images/svg/open-fullscreen.svg';
import resetMapIcon from 'public/images/svg/reset-map.svg';

interface IFullScreenControl {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultIconStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  border: '2px solid #fff',
  borderRadius: '3px',
  background: 'white',
  boxShadow: '0 2px 6px rgba(0,0,0,.3)',
  cursor: 'pointer',
  padding: '6px',
};

const fullScreenIconStyles = { ...defaultIconStyles, margin: '10px' };
const resetMapIconStyles = {
  ...defaultIconStyles,
  marginRight: '10px',
};

export const createFullScreenControl =
  ({ onChange }: IFullScreenControl) =>
  (): TCustomMapControl => {
    if (process.browser) {
      const fullScreenControl = document.createElement('div');
      const controlUI = document.createElement('button');
      const controlIcon = document.createElement('img');

      Object.entries(fullScreenIconStyles).forEach(([prop, value]) => {
        controlUI.style[prop] = value;
      });

      controlIcon.src = openFullscreenIcon;

      const handleClick = () => {
        onChange(prev => {
          controlIcon.src = !prev ? closeFullscreenIcon : openFullscreenIcon;
          return !prev;
        });
      };

      controlUI.addEventListener('click', handleClick);

      controlUI.appendChild(controlIcon);
      fullScreenControl.appendChild(controlUI);

      return {
        element: fullScreenControl,
        cleanup: () => controlUI.removeEventListener('click', handleClick),
      };
    }
  };

interface IResetMapControl {
  onClick: () => void;
}

export const createResetMapControl =
  ({ onClick }: IResetMapControl) =>
  (): TCustomMapControl => {
    if (process.browser) {
      const resetMapControl = document.createElement('div');
      const controlUI = document.createElement('button');
      const controlIcon = document.createElement('img');

      Object.entries(resetMapIconStyles).forEach(([prop, value]) => {
        controlUI.style[prop] = value;
      });

      controlIcon.src = resetMapIcon;

      const handleClick = () => {
        onClick && onClick();
      };

      controlUI.addEventListener('click', handleClick);

      controlUI.appendChild(controlIcon);
      resetMapControl.appendChild(controlUI);

      return {
        element: resetMapControl,
        cleanup: () => controlUI.removeEventListener('click', handleClick),
      };
    }
  };
