import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const FdrScrollToTopButtonWrapper = styled.div<{ show: boolean }>(
  ({ show }) =>
    sx2CssThemeFn({
      display: show ? 'block' : 'none',
      position: 'fixed',
      zIndex: '3',
      bottom: ['72px', 4],
      right: [3, 4],
    })
);

export const FdrScrollButton = styled(BsButtonWithIcon).attrs({
  testId: 'scroll-to-top',
  variant: 'iconBtn',
  icon: { name: 'scroll-to-top-icon' },
})(({ theme: { colors, media, space } }) => ({
  width: '42px',
  height: '42px',
  background: colors.paperGrey,
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: '5px',
  [media.mobile]: {
    marginBottom: space[2],
  },
  '&:hover, &:focus, &:focus-visible, &:active': {
    cursor: 'pointer',
    background: colors.paperGrey,
    border: `1px solid ${colors.black}`,
  },
  svg: {
    width: '20px',
    height: '20px',
    fill: 'transparent',
  },
}));
