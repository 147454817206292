import { track } from '@hotelplan/libs.tracking-events';
import { ClickEventActionType, TrackingEventType } from './tracking.types';

export const trackMediaGalleryFullScreenClick = (): void => {
  track({
    event: TrackingEventType.CLICK,
    eventMetadata: {
      action: ClickEventActionType.ENLARGE,
      target: 'full-screen-gallery',
    },
    _clear: true,
  });
};

export const trackMediaGalleryLinkClick = (): void => {
  track({
    event: TrackingEventType.CLICK,
    eventMetadata: {
      action: ClickEventActionType.GO_TO,
      target: 'gallery-related-pdp',
    },
    _clear: true,
  });
};
