import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { useFdrAddBookmarkMutation } from 'schemas/query/bookmark/fdr-add-bookmark.generated';

export function useAddBookmarkItemWithoutGroup() {
  const [mutation, { data, loading }] = useFdrAddBookmarkMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: [`FdrBookmarkCount`, 'FdrBookmarkGroupList'],
  });

  async function addBookmarkItem(
    pageType: FdrBookmarkObjectType,
    objectId: string
  ) {
    mutation({
      variables: {
        input: {
          objectId,
          objectType: pageType,
        },
      },
    });
  }

  return {
    addBookmarkItem,
    data,
    loading,
  };
}
