import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBookmarkContextListQueryVariables = Types.Exact<{
  groupId?: Types.InputMaybe<Types.Scalars['ID']>;
  pagination: Types.FdrPaginationInput;
}>;

export type FdrBookmarkContextListQuery = {
  __typename?: 'Query';
  themeGroup: {
    __typename?: 'FdrBookmarksResponse';
    success: boolean;
    message?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark'; id: string }
      | { __typename?: 'FdrProductBookmark'; id: string }
      | {
          __typename?: 'FdrThemeBookmark';
          id: string;
          theme?: { __typename?: 'FdrTheme'; id: string } | null;
        }
    > | null;
  };
  geoGroup: {
    __typename?: 'FdrBookmarksResponse';
    success: boolean;
    message?: string | null;
    bookmarks?: Array<
      | {
          __typename?: 'FdrGeoBookmark';
          id: string;
          geo?:
            | { __typename?: 'FdrContinent'; id: string }
            | { __typename?: 'FdrCountry'; id: string }
            | { __typename?: 'FdrCountryGroup'; id: string }
            | { __typename?: 'FdrDestination'; id: string }
            | { __typename?: 'FdrRegion'; id: string }
            | { __typename?: 'FdrResort'; id: string }
            | null;
        }
      | { __typename?: 'FdrProductBookmark'; id: string }
      | { __typename?: 'FdrThemeBookmark'; id: string }
    > | null;
  };
  atGroup: {
    __typename?: 'FdrBookmarksResponse';
    success: boolean;
    message?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark'; id: string }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | { __typename?: 'FdrAdventureTravel'; name: string; id: string }
            | { __typename?: 'FdrCruise'; name: string; id: string }
            | { __typename?: 'FdrHotel'; name: string; id: string }
            | { __typename?: 'FdrRoundTrip'; name: string; id: string }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark'; id: string }
    > | null;
  };
  hotelGroup: {
    __typename?: 'FdrBookmarksResponse';
    success: boolean;
    message?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark'; id: string }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | { __typename?: 'FdrAdventureTravel'; name: string; id: string }
            | { __typename?: 'FdrCruise'; name: string; id: string }
            | { __typename?: 'FdrHotel'; name: string; id: string }
            | { __typename?: 'FdrRoundTrip'; name: string; id: string }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark'; id: string }
    > | null;
  };
  rtGroup: {
    __typename?: 'FdrBookmarksResponse';
    success: boolean;
    message?: string | null;
    bookmarks?: Array<
      | { __typename?: 'FdrGeoBookmark'; id: string }
      | {
          __typename?: 'FdrProductBookmark';
          id: string;
          product?:
            | { __typename?: 'FdrAdventureTravel'; name: string; id: string }
            | { __typename?: 'FdrCruise'; name: string; id: string }
            | { __typename?: 'FdrHotel'; name: string; id: string }
            | { __typename?: 'FdrRoundTrip'; name: string; id: string }
            | null;
        }
      | { __typename?: 'FdrThemeBookmark'; id: string }
    > | null;
  };
};

export type FdrProductBookmarkFragment = {
  __typename?: 'FdrProductBookmark';
  product?:
    | { __typename?: 'FdrAdventureTravel'; name: string; id: string }
    | { __typename?: 'FdrCruise'; name: string; id: string }
    | { __typename?: 'FdrHotel'; name: string; id: string }
    | { __typename?: 'FdrRoundTrip'; name: string; id: string }
    | null;
};

export const FdrProductBookmarkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductBookmark' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductBookmark' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrBookmarkContextListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBookmarkContextList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'themeGroup' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'THEME' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrThemeBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'theme' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'geoGroup' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'GEO' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrGeoBookmark' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'atGroup' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'ADVENTURE_TRAVEL' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductBookmark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotelGroup' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'HOTEL' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductBookmark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rtGroup' },
            name: { kind: 'Name', value: 'fdrBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bookmarkType' },
                      value: { kind: 'EnumValue', value: 'ROUND_TRIP' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductBookmark' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductBookmarkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBookmarkContextListQuery__
 *
 * To run a query within a React component, call `useFdrBookmarkContextListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBookmarkContextListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBookmarkContextListQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrBookmarkContextListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrBookmarkContextListQuery,
    FdrBookmarkContextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBookmarkContextListQuery,
    FdrBookmarkContextListQueryVariables
  >(FdrBookmarkContextListDocument, options);
}
export function useFdrBookmarkContextListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBookmarkContextListQuery,
    FdrBookmarkContextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBookmarkContextListQuery,
    FdrBookmarkContextListQueryVariables
  >(FdrBookmarkContextListDocument, options);
}
export type FdrBookmarkContextListQueryHookResult = ReturnType<
  typeof useFdrBookmarkContextListQuery
>;
export type FdrBookmarkContextListLazyQueryHookResult = ReturnType<
  typeof useFdrBookmarkContextListLazyQuery
>;
export type FdrBookmarkContextListQueryResult = Apollo.QueryResult<
  FdrBookmarkContextListQuery,
  FdrBookmarkContextListQueryVariables
>;
