import { SxStyleProp } from '@hotelplan/util.theme.sxc';

export const pinContent: SxStyleProp = {
  '.map-pin-content': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    bottom: '-6px',
    color: 'primary',
  },
  '.map-pin-wrapper:after': {
    borderTopColor: 'transparent',
  },
};
