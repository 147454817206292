import {
  fdrHeroCriteria,
  fdrHomeHeroCriteria,
} from 'components/util/criterias/hero.dmc';
import { fdrMarketingRecommendation } from 'components/util/criterias/marketingRecommendation.dmc';
import {
  fdrMediaFull,
  fdrMediaPreview,
} from 'components/util/criterias/media.dmc';

export const fdrSearchPageVars = {
  heroCriteria: fdrHeroCriteria,
  blogCriteria: fdrHomeHeroCriteria,
  mediaFull: fdrMediaFull,
  mediaPreview: fdrMediaPreview,
  fdrMarketing: fdrMarketingRecommendation,
  input: { pagination: { page: 0, perPage: 20 } },
};
