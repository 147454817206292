import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import { MediaVideoFragmentDoc } from 'schemas/fragment/media/fdr-media.generated';
export type HeroMediaImageFragment = {
  __typename?: 'FdrImage';
  alt: string;
  description?: string | null;
  copyright?: string | null;
  rawUrl: string;
  resized: Array<{
    __typename?: 'FdrResizedImage';
    id: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }>;
};

export type FdrHeroMediaGalleryFragment = {
  __typename?: 'FdrHeroMediaGallery';
  title?: string | null;
  titleColor?: string | null;
  flagline?: string | null;
  media: Array<
    | { __typename: 'FdrAudio' }
    | { __typename: 'FdrExternalMedia' }
    | {
        __typename: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      }
    | { __typename: 'FdrPdf' }
    | {
        __typename: 'FdrVideo';
        copyright?: string | null;
        width?: number | null;
        height?: number | null;
        preview?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          mediaPreviewResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        resourceLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }
  >;
};

export const HeroMediaImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'heroMediaImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'fdrImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resized' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'heroCriteria' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrResized' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHeroMediaGalleryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHeroMediaGallery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHeroMediaGallery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flagline' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'heroMediaImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrVideo' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'mediaVideo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HeroMediaImageFragmentDoc.definitions,
    ...MediaVideoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
