import React from 'react';
import FdrHeroShareTool from 'components/domain/fdr-hero-share-tool/fdr-hero-share-tool';
import { TFdrHeroMediaGalleryComponent } from './fdr-hero-section-types';
import { FdrHeroTextBlock } from './fdr-hero-section.styles';

interface IFdrAnyHeroContentProps {
  component: TFdrHeroMediaGalleryComponent;
  bookmarked: boolean;
}

export const FdrAnyHeroContent: React.FC<IFdrAnyHeroContentProps> = ({
  component,
  bookmarked,
}) => {
  const { title, titleColor, flagline } = component;

  return (
    <>
      <FdrHeroShareTool component={component} bookmarked={bookmarked} />
      <FdrHeroTextBlock title={title} text={flagline} color={titleColor} />
    </>
  );
};
