import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FdrPagination, FdrSearchMode } from '@hotelplan/supergraph-api';
import { useFdrExactSearchContext } from 'components/domain/fdr-exact-search';
import { FDR_SEARCH_SECTION_KEY_PREFIX } from 'components/domain/fdr-search-overlay';
import { useAgencySearch } from 'components/domain/fdr-search/useAgencySearch';
import { useGeneralSearch } from 'components/domain/fdr-search/useGeneralSearch';
import { FdrTextSearchAgenciesQuery } from 'schemas/query/search/fdr-text-search-agencies.generated';
import { FdrTextSearchQuery } from 'schemas/query/search/fdr-text-search.generated';
import {
  DEFAULTS_RESULT_PER_PAGE,
  FDR_SEARCH_SECTION_KEYS,
  mapSectionKeysToSearchTypes,
} from './fdr-search.constants';

interface IFdrTextSearchOptions {
  perPage?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  isAllItems?: boolean;
}

interface IUseFdrTextSearch {
  currentPage: number;
  pagination: FdrPagination;
  itemList: Array<
    | FdrTextSearchQuery['fdrTextSearch']['itemList'][number]
    | FdrTextSearchAgenciesQuery['fdrSearchAgencies']['agencies'][number]
    | undefined
  >;
  loading: boolean;
  fetchItems(nextPage: number): void;
  fetchMore(
    nextPage: number,
    perPage: number
  ): Promise<
    ApolloQueryResult<FdrTextSearchAgenciesQuery | FdrTextSearchQuery>
  >;
}

const DEFAULT_PER_PAGE = 3;

export function useFdrTextSearch(
  searchInput: string,
  sectionKey: typeof FDR_SEARCH_SECTION_KEYS[number],
  { perPage, fetchPolicy, isAllItems }: IFdrTextSearchOptions = {}
): IUseFdrTextSearch {
  const perPageCount = perPage || DEFAULT_PER_PAGE;

  const [exactSearch] = useFdrExactSearchContext();

  const searchSectionKey = `${FDR_SEARCH_SECTION_KEY_PREFIX}${sectionKey}_page`;
  const storedPage = isAllItems
    ? 0
    : typeof window !== 'undefined'
    ? Number(sessionStorage.getItem(searchSectionKey))
    : 0;

  const [currentPage, setCurrentPage] = useState(storedPage);

  const isAgenciesSection = sectionKey === 'agencies';
  const searchTypes = mapSectionKeysToSearchTypes.get(sectionKey);

  const searchMode: FdrSearchMode = exactSearch
    ? FdrSearchMode.Phrase
    : FdrSearchMode.PartialWord;

  const {
    data: fdrTextSearchData,
    fetchMore: fdrTextSearchFetchMore,
    loading: textSearchLoading,
  } = useGeneralSearch(
    isAgenciesSection,
    searchInput,
    fetchPolicy,
    perPageCount,
    searchMode,
    searchTypes,
    storedPage
  );

  const {
    data: fdrAgenciesSearchData,
    fetchMore: fdrAgenciesFetchMore,
    loading: agenciesSearchLoading,
  } = useAgencySearch(
    isAgenciesSection,
    searchInput,
    fetchPolicy,
    exactSearch,
    perPageCount,
    storedPage
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedPageNumber = Number(sessionStorage.getItem(searchSectionKey));

      if (currentPage !== storedPageNumber) {
        setCurrentPage(storedPageNumber);
      }
    }
  }, [searchInput]);

  const itemList: Array<
    | FdrTextSearchQuery['fdrTextSearch']['itemList'][number]
    | FdrTextSearchAgenciesQuery['fdrSearchAgencies']['agencies'][number]
    | undefined
  > = isAgenciesSection
    ? fdrAgenciesSearchData?.fdrSearchAgencies?.agencies
    : fdrTextSearchData?.fdrTextSearch?.itemList || [];

  const pagination = isAgenciesSection
    ? fdrAgenciesSearchData?.fdrSearchAgencies?.pagination
    : fdrTextSearchData?.fdrTextSearch?.pagination;

  const fetchItems = useCallback(
    (nextPage: number, save = true) => {
      const firstIndex = nextPage * DEFAULTS_RESULT_PER_PAGE;
      const lastIndex = firstIndex + DEFAULTS_RESULT_PER_PAGE;

      if (save) {
        setCurrentPage(nextPage);
        sessionStorage.setItem(searchSectionKey, `${nextPage}`);
      }

      if (itemList.slice(firstIndex, lastIndex).filter(Boolean).length > 0)
        return Promise.resolve(
          isAgenciesSection ? fdrAgenciesSearchData : fdrTextSearchData
        );

      return isAgenciesSection
        ? fdrAgenciesFetchMore(nextPage, 3)
        : fdrTextSearchFetchMore(nextPage, 3);
    },
    [fdrAgenciesFetchMore, fdrTextSearchFetchMore, itemList]
  );

  const fetchMore = useCallback(
    (nextPage: number, fetchingCount: number) => {
      return isAgenciesSection
        ? fdrAgenciesFetchMore(nextPage, fetchingCount)
        : fdrTextSearchFetchMore(nextPage, fetchingCount);
    },
    [isAgenciesSection]
  );

  return useMemo(
    () => ({
      pagination,
      currentPage,
      fetchItems,
      fetchMore,
      itemList,
      loading: isAgenciesSection ? agenciesSearchLoading : textSearchLoading,
    }),
    [
      pagination,
      currentPage,
      fetchItems,
      fetchMore,
      itemList,
      isAgenciesSection,
      agenciesSearchLoading,
      textSearchLoading,
    ]
  );
}
