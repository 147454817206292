import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import {
  BookmarkComponentType,
  trackAddBookmark,
  trackRemoveBookmark,
} from 'components/domain/tracking/bookmarkTracking';
import { useBookmarkContext } from './context/fdr-bookmark-context';
import FdrBookmarkIcon from './fdr-bookmark-icon';
import useBookmarked from './hooks/useBookmarked';

interface IWishlistIconPros {
  pageType: FdrBookmarkObjectType;
  objectId: string;
  active?: boolean;
  trackingComponent?: BookmarkComponentType;
}

const FdrSingularBookmarkGroupBlock: React.FC<IWishlistIconPros> = ({
  pageType,
  objectId,
  active,
  trackingComponent,
}) => {
  const { bookmarked } = useBookmarked(objectId, pageType, active);
  const bookmarkContext = useBookmarkContext();
  const [, { language }] = useTranslation();

  async function handleClick() {
    const group = bookmarkContext.bookmarkGroups?.[0];

    if (bookmarked) {
      if (trackingComponent) {
        trackRemoveBookmark(trackingComponent, language);
      }

      bookmarkContext.removeBookmark({
        objectId,
        pageType,
        groupId: group.id,
      });
    } else {
      if (trackingComponent) {
        trackAddBookmark(trackingComponent, language);
      }

      bookmarkContext.addBookmark({ objectId, pageType, groupId: group?.id });
    }
  }

  return <FdrBookmarkIcon onClick={handleClick} active={bookmarked} />;
};

export default FdrSingularBookmarkGroupBlock;
