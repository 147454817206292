import styled from 'styled-components';

export const PrimaryHeading = styled.h1(
  ({ theme: { text } }) => text.primaryHeading
);

export const SecondaryHeading = styled.h2(
  ({ theme: { text } }) => text.secondaryHeading
);

export const ThirdHeading = styled.h3({
  fontSize: '22px',
});

export const CopyText = styled.h3(({ theme: { fontSizes } }) => ({
  fontSize: fontSizes[2],
  fontWeight: 'normal',
}));

export const H4Heading = styled.h4({
  fontSize: '22px',
  lineHeight: 1.2,
});

export const FlaglineText = styled.span(
  ({ theme: { text } }) => text.groupHeading
);
