import styled from 'styled-components';
import { FdrMediaCard } from 'components/candidate/fdr-media-card';

export const FdrScalableImage = styled(FdrMediaCard)({
  width: '100%',
  overflow: 'hidden',
  ':before': {
    zIndex: 2,
  },
  ':after': {
    content: "''",
    display: 'block',
    width: '100%',
    paddingBottom: '83%',
    pointerEvents: 'none',
  },
  'img, .video': {
    position: 'absolute',
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    maxHeight: '100%',
  },
});
