import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import { FdrThemeRecommendationSlider } from 'components/domain/fdr-page-components/recommenders/theme/fdr-theme-recommendation-slider';
import { FdrImageTeaser } from 'components/domain/fdr-teaser-card/fdr-image-teaser';
import {
  ListContainer,
  RecommendationsGrid,
} from 'components/domain/recommendations/Recommendations.common';
import { FdrThemeRecommenderFragment } from 'schemas/fragment/recommenders/theme/fdr-theme-recommender.generated';

type TFdrThemeRecommenderProps = {
  displayType?: string;
  id?: string;
  title?: string;
  bgColor?: string;
  fontColor?: string;
  items: Omit<
    FdrThemeRecommenderFragment['themePage']['themes'][number],
    'id' | '__typename'
  >[];
} & HTMLAttributes<HTMLDivElement>;

const ThemeListContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides && 0,
  },
  '.pages': {
    color: colors.white,
  },
}));

const ThemeListWithSlidesNavigationContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, titleColor, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides && 0,
  },
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  paddingRight: '40px',
  paddingLeft: '40px',
  '.count': {
    color: titleColor || colors.white,
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

export function FdrThemeRecommender({
  displayType = 'SWIPE',
  title,
  items,
  bgColor,
  fontColor,
  ...divProps
}: TFdrThemeRecommenderProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const isSlides = displayType === 'SWIPE';
  const isSliderPagination = !mobile;

  const ThemeListContainerVariant = isSliderPagination
    ? ThemeListWithSlidesNavigationContainer
    : ThemeListContainer;

  const itemsResult = items.map((item, index) => (
    <FdrImageTeaser
      cfg={{
        title: item.title,
        link: item.webMeta.descriptionWebMeta.link,
        lazy: isSlides && index > 2,
      }}
      key={index}
      image={item.image}
      data-id="theme-card"
    />
  ));

  return (
    <div
      className="themes-section"
      data-id="theme-recommendations-section"
      {...divProps}
      style={{
        ...(divProps.style || {}),
        color: fontColor,
        backgroundColor: bgColor,
      }}
    >
      <ThemeListContainerVariant
        data-display={displayType}
        isSlides={isSlides}
        titleColor={fontColor}
      >
        {!isSlides ? (
          <CardsSection title={title} page={1} totalPages={1}>
            <RecommendationsGrid style={{ borderColor: fontColor }}>
              {itemsResult}
            </RecommendationsGrid>
          </CardsSection>
        ) : (
          <FdrThemeRecommendationSlider
            title={title}
            count={items.length}
            titleColor={fontColor}
          >
            {itemsResult}
          </FdrThemeRecommendationSlider>
        )}
      </ThemeListContainerVariant>
    </div>
  );
}
