import { ReactElement } from 'react';
import { FdrAuthPanelDesktop } from 'components/candidate/fdr-auth-panel';
import {
  FdrContactSiteTool,
  FdrSearchSiteTool,
  FdrSiteToolsWrapper,
  FdrWishlistSiteTool,
} from 'components/domain/fdr-sitetools';

export function FdrHeaderSiteTools(): ReactElement {
  return (
    <FdrSiteToolsWrapper>
      <FdrWishlistSiteTool />
      <FdrSearchSiteTool />
      <FdrContactSiteTool />
      <FdrAuthPanelDesktop />
    </FdrSiteToolsWrapper>
  );
}
