import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import * as Yup from 'yup';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  FdrB2BMarkupFormWrapper,
  FdrFormContentWrapper,
  FdrFormWrapper,
  FdrMarkupFormSubmitButton,
} from './fdr-b2b-markup-form.styles';
import { setB2BMarkupCookie } from './fdr-b2b-markup-form.utils';

interface IMarkupForm {
  markup: number;
}

const MarkupFormValidationSchema = Yup.object<IMarkupForm>({
  markup: Yup.number().min(0).required('forms:errors.required').integer(),
});

export function FdrB2bMarkupForm(): ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('forms');
  const { b2bMarkup } = useRequestContextHeaders();

  const [isFormOpened, , , toggleForm] = useToggleState(false);

  function handleMarkupApply(values: any) {
    setB2BMarkupCookie(values.markup);

    requestAnimationFrame(() => {
      window.location.href = `${window.location.href}`;
    });
  }

  if (mobile) return null;

  return (
    <FdrB2BMarkupFormWrapper>
      <button
        data-id="markup-control"
        onClick={toggleForm}
        className="markup-control-button"
      >
        <span>{isFormOpened ? '-' : '+'}</span>
      </button>
      {isFormOpened && (
        <FdrFormWrapper>
          <Formik<IMarkupForm>
            initialValues={{
              markup: b2bMarkup || 0,
            }}
            onSubmit={handleMarkupApply}
            validationSchema={MarkupFormValidationSchema}
          >
            {formik => {
              return (
                <form
                  noValidate
                  onSubmit={(e: unknown) =>
                    formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
                  }
                >
                  <FdrFormContentWrapper>
                    <div className="form-item-validate">
                      <ValidatedTextInput
                        hideLabel
                        name="markup"
                        autoComplete="given-name"
                      />
                    </div>
                    <div className="form-item-submit">
                      <div>
                        <FdrMarkupFormSubmitButton type="submit">
                          {t('forms:apply.button')}
                        </FdrMarkupFormSubmitButton>
                      </div>
                    </div>
                  </FdrFormContentWrapper>
                </form>
              );
            }}
          </Formik>
        </FdrFormWrapper>
      )}
    </FdrB2BMarkupFormWrapper>
  );
}
