import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrDisrupterFragmentDoc } from 'schemas/fragment/disrupter/fdr-disrupter.generated';
import { FdrDescriptionLinkFragmentDoc } from 'schemas/query/search/fdr-last-viewed.generated';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrProductInfoB2bFragmentDoc } from 'schemas/fragment/product/fdr-product-info-b2b.generated';
import { FdrAdventureTravelRouteFragmentDoc } from 'schemas/fragment/product/fdr-adventure-travel-route.generated';
import {
  FdrProductFeatureGroupFragmentDoc,
  FdrB2BInfoGroupsFragmentDoc,
} from 'schemas/fragment/b2b-info-groups/fdr-b2b-info-groups.generated';
import { FdrHotelBreadcrumbsFragmentDoc } from 'schemas/query/product/fdr-product-breadcrumbs.generated';
import { DescriptionGeoLinkFragmentDoc } from 'schemas/query/geo/fdr-geo-breadcrumbs.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
export type FdrAtSearchItemFragment = {
  __typename?: 'FdrAdventureTravelSearchItem';
  debugInfo?: string | null;
  adventureTravel: {
    __typename?: 'FdrAdventureTravel';
    name: string;
    isInBookmarks: boolean;
    teaser: string;
    leadText?: string | null;
    id: string;
    images?: Array<{
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    }> | null;
    googleStaticMapImage?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
    productInfoB2B?: {
      __typename?: 'ProductInfoB2B';
      route?: string | null;
      duration: string;
      priceFrom?: string | null;
      typeFlags?: Array<string> | null;
    } | null;
    route?: Array<{
      __typename?: 'FdrAdventureTravelRoutePoint';
      locationName: string;
      coordinates: {
        __typename?: 'FdrCoordinates';
        latitude: number;
        longitude: number;
      };
    }> | null;
    featureGroups?: Array<{
      __typename?: 'FdrProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'FdrProductFeature';
        id: string;
        name: string;
        enabled: boolean;
      }>;
    }> | null;
  };
};

export type FdrHotelSearchItemFragment = {
  __typename?: 'FdrHotelSearchItem';
  debugInfo?: string | null;
  hotel: {
    __typename?: 'FdrHotel';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
    resort: {
      __typename?: 'FdrResort';
      publicId: string;
      name: string;
      destination: {
        __typename?: 'FdrDestination';
        publicId: string;
        name: string;
        country: {
          __typename?: 'FdrCountry';
          publicId: string;
          name: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        };
        region?: {
          __typename?: 'FdrRegion';
          publicId: string;
          name: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        } | null;
      };
    };
  };
};

export type FdrRoundTripSearchItemFragment = {
  __typename?: 'FdrRoundTripSearchItem';
  debugInfo?: string | null;
  roundTrip: {
    __typename?: 'FdrRoundTrip';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    mapImages?: Array<{
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    }> | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    productInfoB2B?: {
      __typename?: 'ProductInfoB2B';
      route?: string | null;
      duration: string;
      priceFrom?: string | null;
      typeFlags?: Array<string> | null;
    } | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrContinentSearchItemFragment = {
  __typename?: 'FdrContinentSearchItem';
  debugInfo?: string | null;
  continent: {
    __typename?: 'FdrContinent';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrCountryGroupSearchItemFragment = {
  __typename?: 'FdrCountryGroupSearchItem';
  debugInfo?: string | null;
  countryGroup: {
    __typename?: 'FdrCountryGroup';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrCountrySearchItemFragment = {
  __typename?: 'FdrCountrySearchItem';
  debugInfo?: string | null;
  country: {
    __typename?: 'FdrCountry';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrRegionSearchItemFragment = {
  __typename?: 'FdrRegionSearchItem';
  debugInfo?: string | null;
  region: {
    __typename?: 'FdrRegion';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrResortSearchItemFragment = {
  __typename?: 'FdrResortSearchItem';
  debugInfo?: string | null;
  resort: {
    __typename?: 'FdrResort';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrThemeSearchItemFragment = {
  __typename?: 'FdrThemeSearchItem';
  debugInfo?: string | null;
  theme: {
    __typename?: 'FdrTheme';
    name: string;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrThemeWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrBlogArticleSearchItemFragment = {
  __typename?: 'FdrBlogArticleSearchItem';
  debugInfo?: string | null;
  blogArticle: {
    __typename?: 'FdrBlogArticle';
    text?: string | null;
    id: string;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrCatalogueSearchItemFragment = {
  __typename?: 'FdrCatalogPageSearchItem';
  debugInfo?: string | null;
  catalogPage: {
    __typename?: 'FdrCatalogPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrAgencyOverviewSearchItemFragment = {
  __typename?: 'FdrAgencyOverviewPageSearchItem';
  debugInfo?: string | null;
  agencyOverviewPage: {
    __typename?: 'FdrAgencyOverviewPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrNewsletterSearchItemFragment = {
  __typename?: 'FdrNewsletterSubscriptionPageSearchItem';
  debugInfo?: string | null;
  newsletterSubscriptionPage: {
    __typename?: 'FdrNewsletterSubscriptionPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrStaticPageSearchItemFragment = {
  __typename?: 'FdrStaticPageSearchItem';
  debugInfo?: string | null;
  staticPage: {
    __typename?: 'FdrStaticPage';
    text?: string | null;
    name?: string | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
  };
};

export type FdrSearchImageFragment = {
  __typename?: 'FdrImage';
  alt: string;
  description?: string | null;
  copyright?: string | null;
  rawUrl: string;
  resized: Array<{
    __typename?: 'FdrResizedImage';
    id: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }>;
};

export const FdrSearchImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'fdrImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resized' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '380' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '274' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '468' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '388' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrResized' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAtSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrATSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAdventureTravelSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adventureTravel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teaser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'googleStaticMapImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disrupters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDisrupter' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productInfoB2B' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductInfoB2b' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'route' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAdventureTravelRoute',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'googleStaticMapImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductFeatureGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrDescriptionLinkFragmentDoc.definitions,
    ...FdrProductInfoB2bFragmentDoc.definitions,
    ...FdrAdventureTravelRouteFragmentDoc.definitions,
    ...FdrProductFeatureGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHotelSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHotelSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotelSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disrupters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDisrupter' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHotelBreadcrumbs' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrDescriptionLinkFragmentDoc.definitions,
    ...FdrHotelBreadcrumbsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrRoundTripSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrRoundTripSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRoundTripSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roundTrip' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mapImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disrupters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDisrupter' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productInfoB2B' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductInfoB2b' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrDisrupterFragmentDoc.definitions,
    ...FdrProductInfoB2bFragmentDoc.definitions,
    ...FdrDescriptionLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrContinentSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrContinentSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrContinentSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrCountryGroupSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrCountryGroupSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCountryGroupSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrCountrySearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrCountrySearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCountrySearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrRegionSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrRegionSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRegionSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrResortSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrResortSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrResortSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrThemeSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrThemeSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrThemeSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isInBookmarks' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionWebMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrBlogArticleSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrBlogArticleSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrBlogArticleSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogArticle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'title' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrCatalogueSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrCatalogueSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCatalogPageSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'title' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAgencyOverviewSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAgencyOverviewSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAgencyOverviewPageSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agencyOverviewPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'title' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrNewsletterSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrNewsletterSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrNewsletterSubscriptionPageSearchItem',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newsletterSubscriptionPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'title' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrStaticPageSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrStaticPageSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrStaticPageSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staticPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'title' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchImage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrSearchImageFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
