import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrCoordinatesFragment = {
  __typename?: 'FdrCoordinates';
  latitude: number;
  longitude: number;
};

export const FdrCoordinatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrCoordinates' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCoordinates' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
