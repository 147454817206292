import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useFdrGetLinkUrlWithJotform } from 'components/domain/fdr-jotform';
import {
  FdrFeedbackModal,
  FdrFooterFeedbackLink,
  FdrFooterFeedbackWrapper,
  ResponsiveIFrame,
} from './fdr-footer-feedback.styles';

export function FdrFooterFeedback(): ReactElement {
  const { t } = useTranslation('common');

  const jotformLink = useFdrGetLinkUrlWithJotform(
    t('common:footer_feedback_box.link')
  );

  const [isShowFeedbackModal, showFeedbackModal, hideFeedbackModal] =
    useToggleState(false);

  return (
    <FdrFooterFeedbackWrapper>
      <FdrFooterFeedbackLink
        onClick={showFeedbackModal}
        icon={{ name: 'feedback' }}
      >
        <span className="link-text">
          {t('common:footer_feedback_box.linkName')}
        </span>
      </FdrFooterFeedbackLink>
      <FdrFeedbackModal
        title=""
        name="FeedbackBoxModal"
        show={isShowFeedbackModal}
        onClose={hideFeedbackModal}
      >
        <ResponsiveIFrame
          title={`footer_feedback_box`}
          src={jotformLink}
          width="100%"
        />
      </FdrFeedbackModal>
    </FdrFooterFeedbackWrapper>
  );
}
