import React, { ReactElement } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrAuthPanelMobile } from 'components/candidate/fdr-auth-panel';
import { FdrWishlistSiteTool } from 'components/domain/fdr-sitetools';
import { useGetFdrPopUpMenuQuery } from 'schemas/query/pop-up-menu/fdr-pop-up-menu.generated';
import {
  FdrMobileMenuContentWrapper,
  FdrMobileMenuItemWrapper,
  FdrMobileMenuNavWrapper,
} from './fdr-mobile-menu-dropdown.styles';

interface IFdrMobileMenuDropdownProps {
  onClose?(): void;
}

export function FdrMobileMenuDropdown({
  onClose,
}: IFdrMobileMenuDropdownProps): ReactElement {
  const { channelType } = useAuthentication();

  const { data, loading } = useGetFdrPopUpMenuQuery();
  const isB2C = channelType !== AuthChannelType.B2B;

  return (
    <FdrMobileMenuNavWrapper data-id="mobile-nav">
      <FdrMobileMenuContentWrapper>
        {loading ? (
          <FdrMobileMenuItemWrapper>
            <p>{`Loading...`}</p>
          </FdrMobileMenuItemWrapper>
        ) : (
          <>
            {data.fdrPopUpMenu.items.map((popUpItem, index) => (
              <FdrMobileMenuItemWrapper
                key={`menu-icon-${index}`}
                data-id={`testId-${popUpItem.title}`}
              >
                <FdrRoute link={popUpItem.link}>
                  <button className="bottom-menu-item" onClick={onClose}>
                    <span className="icon-label">{popUpItem.title}</span>
                  </button>
                </FdrRoute>
              </FdrMobileMenuItemWrapper>
            ))}
            <FdrMobileMenuItemWrapper
              data-id="testId-bookmarks"
              className="stand-alone"
            >
              <FdrWishlistSiteTool />
            </FdrMobileMenuItemWrapper>
            {!isB2C ? (
              <FdrMobileMenuItemWrapper
                data-id="fdr-mobile-logout"
                className="stand-alone"
              >
                <FdrAuthPanelMobile />
              </FdrMobileMenuItemWrapper>
            ) : null}
          </>
        )}
      </FdrMobileMenuContentWrapper>
    </FdrMobileMenuNavWrapper>
  );
}
