import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrCopyBookmarkMutationVariables = Types.Exact<{
  input: Types.FdrCopyBookmarkInput;
}>;

export type FdrCopyBookmarkMutation = {
  __typename?: 'Mutation';
  fdrCopyBookmark: { __typename?: 'FdrCopyBookmarkResponse'; success: boolean };
};

export const FdrCopyBookmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrCopyBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCopyBookmarkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrCopyBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrCopyBookmarkMutationFn = Apollo.MutationFunction<
  FdrCopyBookmarkMutation,
  FdrCopyBookmarkMutationVariables
>;

/**
 * __useFdrCopyBookmarkMutation__
 *
 * To run a mutation, you first call `useFdrCopyBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrCopyBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrCopyBookmarkMutation, { data, loading, error }] = useFdrCopyBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrCopyBookmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrCopyBookmarkMutation,
    FdrCopyBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrCopyBookmarkMutation,
    FdrCopyBookmarkMutationVariables
  >(FdrCopyBookmarkDocument, options);
}
export type FdrCopyBookmarkMutationHookResult = ReturnType<
  typeof useFdrCopyBookmarkMutation
>;
export type FdrCopyBookmarkMutationResult =
  Apollo.MutationResult<FdrCopyBookmarkMutation>;
export type FdrCopyBookmarkMutationOptions = Apollo.BaseMutationOptions<
  FdrCopyBookmarkMutation,
  FdrCopyBookmarkMutationVariables
>;
