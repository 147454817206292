import React, { useState } from 'react';

type TLayoutContext = {
  headerHeight: number;
  infoHeight: number;
  setHeaderHeight(n: number): void;
  setInfoHeight(n: number): void;
};

export const LayoutContext = React.createContext<TLayoutContext>({
  headerHeight: 0,
  infoHeight: 0,
  setHeaderHeight: () => void 0,
  setInfoHeight: () => void 0,
});

export const LayoutProviderWrapper: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);

  return (
    <LayoutContext.Provider
      value={{
        infoHeight,
        setInfoHeight,
        headerHeight,
        setHeaderHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export function useLayoutContext(): TLayoutContext {
  return React.useContext(LayoutContext);
}
