import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrROCWrapper = styled.div(
  sx2CssThemeFn({
    borderRadius: ['roundedTop', '0px'],
    px: [4, '70px'],
    pt: [4, '40px'],
    pb: [0, '30px'],
    flexBasis: [null, '50%'],
    mr: [null, 4],
    backgroundColor: 'background',
    boxShadow: ['none', 'blockShadow'],
  })
);

export const FdrROCContent = styled.div(
  ({ theme, theme: { space, media, fontSizes } }) => ({
    marginBottom: space[3],
    listStyle: 'none',
    [media.tablet]: {
      marginBottom: 0,
    },
    li: {
      paddingLeft: '30px',
      marginBottom: space[2],
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        color: 'black',
        left: 0,
        top: '3px',
        width: '20px',
        height: '20px',
        backgroundImage: 'url("/images/svg/Success.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [media.tablet]: {
          top: space[1],
          width: '25px',
          height: '25px',
        },
      },
      [media.tablet]: {
        paddingLeft: '35px',
        fontSize: fontSizes[3],
      },
    },
    p: {
      marginBottom: space[1],
      [media.tablet]: {
        marginBottom: space[2],
      },
    },
    a: theme.link.text,
  })
);

export const FdrFooterHeading = styled.h3(({ theme }) => theme.footer.heading);
