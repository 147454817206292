import { ReactElement } from 'react';
import { useMetaModulesContext } from '@hotelplan/components.common.meta-modules';
import { FdrCookieDisclaimer } from '@hotelplan/fdr.regular.fusion.fdr-cookie-disclaimer';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useFdrCookieDisclaimerQuery } from 'schemas/query/cookie-disclaimer/fdr-cookie-disclaimer.generated';
import { FdrCookiesDisclaimerWrapper } from './fdr-cookie-disclaimer-section.styles';

export function FdrCookieDisclaimerSection(): ReactElement {
  const { hideCookieConsent } = useMetaModulesContext();
  const [isShowCookieDisclaimer, , hide] = useToggleState(!hideCookieConsent);

  const result = useFdrCookieDisclaimerQuery({
    skip: hideCookieConsent,
  });

  if (!isShowCookieDisclaimer || !result?.data?.fdrCookieDisclaimer?.mainText) {
    return null;
  }

  return (
    <FdrCookiesDisclaimerWrapper>
      <FdrCookieDisclaimer
        text={result?.data?.fdrCookieDisclaimer?.mainText}
        onAccept={hide}
      />
    </FdrCookiesDisclaimerWrapper>
  );
}
