import { useFdrCreateBookmarkGroupMutation } from 'schemas/query/bookmark/fdr-create-bookmark-group.generated';

export function useCreateBookmarkGroup() {
  const [mutation, { data, loading }] = useFdrCreateBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['FdrBookmarkGroupList'],
  });

  async function createBookmarkGroup(groupName: string) {
    return mutation({
      variables: {
        input: {
          bookmarkGroupName: groupName,
        },
      },
    });
  }

  return {
    createBookmarkGroup,
    data,
    loading,
  };
}
