import { ParsedUrlQuery } from 'querystring';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';

function getGeoType(query: ParsedUrlQuery): string {
  const id = query?.id || '';

  return 'Geo Page '.concat(
    id.includes('c-') ? 'Country' : id.includes('k-') ? 'Continent' : 'Region'
  );
}

function getPdpType(query: ParsedUrlQuery): string {
  const id = query?.id || '';

  return id.includes('a-')
    ? 'PDP Adventure Travel'
    : id.includes('h-')
    ? 'PDP Extraordinary Hotel'
    : '';
}

const pageTypeMap = new Map<
  EPageType,
  string | ((query: ParsedUrlQuery) => string)
>([
  [EPageType.GeoObject, getGeoType],
  [EPageType.Home, `Home`],
  [EPageType.GeoOverview, `Geo Overview Page`],
  [EPageType.ThemeObject, `Theme page`],
  [EPageType.ThemeOverview, `Theme overview page`],
  [EPageType.Pdp, getPdpType],
  [EPageType.BlogOverview, `Blog overview page`],
  [EPageType.BlogArticle, `Blog Detailpage`],
  [EPageType.CatalogOverview, `Catalogues`],
  [EPageType.AgencyOverview, `Agency overview`],
  [EPageType.AgencyObject, `Agency Detailpage`],
  [EPageType.Static, `Static page`],
]);

interface IFdrOgTypeProps {
  pageType: EPageType;
}

export function FdrOgType({ pageType }: IFdrOgTypeProps): ReactElement {
  const { query } = useRouter();

  const typeEntry = pageTypeMap.get(pageType);
  const type =
    typeof typeEntry === 'function' ? typeEntry(query) : typeEntry || '';

  return (
    <Head>
      <meta key="og:type" property="og:type" content={type} />
    </Head>
  );
}
