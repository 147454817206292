import { QueryResult } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FdrTextSearchHistory } from '@hotelplan/supergraph-api';
import { FdrExactSearch } from 'components/domain/fdr-exact-search';
import { useFdrAddSearchHistoryMutation } from 'schemas/mutation/search/fdr-add-search-history.generated';
import {
  FdrLastSearchedDocument,
  FdrLastSearchedQuery,
  FdrLastSearchedQueryVariables,
  useFdrLastSearchedQuery,
} from 'schemas/query/search/fdr-last-searched.generated';
import {
  FdrLastViewedDocument,
  FdrLastViewedQuery,
  FdrLastViewedQueryVariables,
  useFdrLastViewedQuery,
} from 'schemas/query/search/fdr-last-viewed.generated';
import { useFdrSearchOverlayQuery } from 'schemas/query/search/fdr-search-overlay.generated';
import { FdrSearchOverlayContent } from './fdr-search-overlay-content';
import {
  FdrExactSearchWrapper,
  FdrSearchOverlayModal,
} from './fdr-search-overlay.styles';
import {
  closeFdrSearchOverlay,
  FDR_SEARCH_VALUE_KEY,
  FdrSearchOverlayEvent,
  mapFdrLastViewedToHistoryItems,
} from './fdr-search-overlay.utils';
import {
  IHistoryResponse,
  useFdrLastSearchedViewed,
} from './useFdrLastSearchedViewed';
import { useFdrTextSearchLinkWithSection } from './useFdrTextSearchLinkWithSection';

interface IFdrSearchOverlayProps {
  isInitialOpened?: boolean;
}

export function FdrSearchOverlay({
  isInitialOpened = false,
}: IFdrSearchOverlayProps): React.ReactElement {
  const router = useRouter();

  const [isOpened, open, close] = useToggleState(isInitialOpened);
  const onceOpened = useRef(isOpened);

  if (isOpened) {
    onceOpened.current = true;
  }

  function handleCloseOverlay() {
    close();
    sessionStorage.removeItem(FDR_SEARCH_VALUE_KEY);
  }

  const searchedItems = useFdrLastSearchedViewed<FdrLastSearchedQuery>({
    useHistoryQuery(): QueryResult<
      FdrLastSearchedQuery,
      FdrLastSearchedQueryVariables
    > {
      return useFdrLastSearchedQuery({
        variables: {
          page: 0,
        },
        skip: !onceOpened.current,
        notifyOnNetworkStatusChange: true,
      });
    },
    getHistoryResponse(
      data?: FdrLastSearchedQuery
    ): IHistoryResponse | undefined {
      return {
        pagination: data?.fdrSearchHistory?.pagination,
        historyItems: data?.fdrSearchHistory?.entries
          .map((e: FdrTextSearchHistory) => ({
            text: e.textSearchCriteria.text,
            link: e.webMeta.link,
          }))
          .filter(Boolean),
      };
    },
  });

  const viewedItems = useFdrLastSearchedViewed<FdrLastViewedQuery>({
    useHistoryQuery(): QueryResult<
      FdrLastViewedQuery,
      FdrLastViewedQueryVariables
    > {
      return useFdrLastViewedQuery({
        variables: {
          page: 0,
        },
        skip: !onceOpened.current,
        notifyOnNetworkStatusChange: true,
      });
    },
    getHistoryResponse(
      data?: FdrLastViewedQuery
    ): IHistoryResponse | undefined {
      return {
        pagination: data?.fdrVisitHistory?.pagination,
        historyItems: data?.fdrVisitHistory?.entries
          ?.map(e => mapFdrLastViewedToHistoryItems(e.object))
          .filter(Boolean),
      };
    },
  });

  const [addSearchHistory] = useFdrAddSearchHistoryMutation({
    refetchQueries: [FdrLastViewedDocument, FdrLastSearchedDocument],
  });

  useEffect(function openCloseEventsEffect() {
    window.addEventListener(FdrSearchOverlayEvent.Open, open);
    window.addEventListener(FdrSearchOverlayEvent.Close, handleCloseOverlay);

    return function eventsCleanup() {
      window.removeEventListener(FdrSearchOverlayEvent.Open, open);
      window.removeEventListener(
        FdrSearchOverlayEvent.Close,
        handleCloseOverlay
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeFdrSearchOverlay);
    router.events.on('routeChangeError', closeFdrSearchOverlay);

    return () => {
      router.events.off('routeChangeComplete', closeFdrSearchOverlay);
      router.events.off('routeChangeError', closeFdrSearchOverlay);
    };
  }, [router.events]);

  const fdrSearchOverlayQuery = useFdrSearchOverlayQuery();

  const searchValue = fdrSearchOverlayQuery?.data?.fdrSearchOverlay?.input;
  const searchTrends =
    fdrSearchOverlayQuery?.data?.fdrSearchOverlay?.searchTrends;
  const mostSearched =
    fdrSearchOverlayQuery?.data?.fdrSearchOverlay?.mostSearched;

  const requestLink = useFdrTextSearchLinkWithSection();

  async function searchHandler(query: string, sectionKeys?: string[]) {
    await requestLink(query, sectionKeys);
    await addSearchHistory({
      variables: { searchedText: query },
    });
  }

  return (
    <FdrSearchOverlayModal
      name="search-overlay"
      show={isOpened}
      onClose={handleCloseOverlay}
      title={<FdrExactSearchHandler />}
    >
      <FdrSearchOverlayContent
        initialSearchValue={searchValue}
        searchTrends={searchTrends}
        mostSearched={mostSearched}
        onSearch={searchHandler}
        searchedItems={searchedItems}
        viewedItems={viewedItems}
      />
    </FdrSearchOverlayModal>
  );
}

function FdrExactSearchHandler(): ReactElement {
  const { channelType } = useAuthentication();

  if (channelType !== AuthChannelType.B2B) return null;

  return (
    <FdrExactSearchWrapper>
      <FdrExactSearch />
    </FdrExactSearchWrapper>
  );
}
