import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { card } from '@hotelplan/components.common.mixins';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';

export const FdrInfoCardContentWrapper = styled.div(
  sx2CssThemeFn(card, {
    border: '1px solid',
    borderColor: 'borderGreyFifth',
    py: [3, 4],
    px: [3, 4],
    height: ['auto', '100%'],
    pt: [4, '90px'],
  })
);

export const FdrInfoCardImage = styled(FdrImg)(
  sx2CssThemeFn({
    width: ['24px', '34px'],
    height: ['24px', '34px'],
  })
);

export const FdrHelpOverlayBoxWrap = styled.div<{ boxesCount?: number }>(
  ({ theme }) => theme.helpOverlay.wrapper,
  ({ boxesCount, theme: { media } }) => ({
    width: '100%',
    [media.tablet]: {
      width: `${(1 / boxesCount) * 100}%`,
    },
  })
);

export const FdrLinkWrapper = styled(FdrRoute)({
  width: '100%',
  height: '100%',
  '&:hover': {
    color: 'inherit',
  },
});
