import {
  query2FdrCriteria,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const fdrBlogSpecs: TFdrMediaMetaMap = new Map([
  [
    `1040w_desktop`,
    {
      s: { width: 497, height: 590 },
      q: { maxW: 1240 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `1240w_desktop`,
    {
      s: { width: 695, height: 590 },
      q: { minW: 1240.1, maxW: 1495 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `1495w_desktop`,
    {
      s: { width: 952, height: 640 },
      q: { minW: 1495.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
]);

export const fdrBlogCriteria = query2FdrCriteria(fdrBlogSpecs);
