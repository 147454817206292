import {
  EOrientation,
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import { lCfg, pCfg } from 'components/util/criterias/base.dmc';
import { fdrHeroMobileImagesSpecs } from 'components/util/criterias/hero.dmc';

const fdrMediaMobileSpecs: Array<[string, TFdrMediaMeta]> =
  fdrHeroMobileImagesSpecs;

const fdrMediaMobileFullSizeSpecs: Array<[string, TFdrMediaMeta]> = [
  [`375w_mobile`, pCfg(375, 740, FdrImageResizeMode.Fit)],
  [`414w_mobile`, pCfg(414, 915, FdrImageResizeMode.Fit)],
  [`768w_mobile`, pCfg(768, 1024, FdrImageResizeMode.Fit)],
  [`912w_mobile`, pCfg(912, 1368, FdrImageResizeMode.Fit)],

  // mobile landscape
  [`740w_mobile`, lCfg(740, 375, FdrImageResizeMode.Fit)],
  [`915w_mobile`, lCfg(915, 414, FdrImageResizeMode.Fit)],
  [`1024w_mobile`, lCfg(1024, 768, FdrImageResizeMode.Fit)],
  [`1368w_mobile`, lCfg(1368, 912, FdrImageResizeMode.Fit)],
];

const fdrMediaDesktopFullSizeSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1920w_desktop`, lCfg(1920, 1080, FdrImageResizeMode.Fit)],
  [`2560w_desktop`, lCfg(2560, 1440, FdrImageResizeMode.Fit)],
  [`3440w_desktop`, lCfg(3440, 1440, FdrImageResizeMode.Fit)],
  [`3840w_desktop`, lCfg(3840, 2160, FdrImageResizeMode.Fit)],
];

export const fdrMediaFullSpecs: TFdrMediaMetaMap = new Map([
  ...fdrMediaMobileFullSizeSpecs,
  ...fdrMediaDesktopFullSizeSpecs,
]);

export const fdrMediaPreviewSpecs: TFdrMediaMetaMap = new Map([
  ...fdrMediaMobileSpecs,
  [
    `1440w_desktop`,
    {
      s: { width: 1440, height: 640 },
      mode: FdrImageResizeMode.FillDown,
      q: { minW: 1368.1 },
      orientation: EOrientation.landscape,
    },
  ],
]);

export const fdrMediaPreview = query2FdrCriteria(fdrMediaPreviewSpecs);
export const fdrMediaFull = query2FdrCriteria(fdrMediaFullSpecs);
