import { useFdrBookmarkAddSharedBookmarkGroupMutation } from 'schemas/query/bookmark/fdr-bookmark-add-shared-bookmark-group.generated';

const useAddSharedBookmarkGroup = () => {
  const [
    addSharedBookmarkGroupMutation,
    {
      data: addSharedBookmarkGroupMutationData,
      loading: addSharedBookmarkGroupMutationLoading,
      error: addSharedBookmarkGroupMutationError,
    },
  ] = useFdrBookmarkAddSharedBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['FdrBookmarkGroupList'],
  });

  return {
    addSharedBookmarkGroupMutation,
    addSharedBookmarkGroupMutationData,
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationError,
  };
};

export default useAddSharedBookmarkGroup;
