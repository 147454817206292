import { ReactElement, useEffect, useRef } from 'react';
import { FdrInformationNotification } from '@hotelplan/fdr.regular.fusion.fdr-information-notification';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { Container } from 'components/domain/container';
import { useLayoutContext } from 'components/domain/layout/Layout.context';
import { useFdrInfoNotificationQuery } from 'schemas/query/info-notofication/fdr-info-notification.generated';
import { FdrInfoNotificationContainer } from './fdr-info-notification.styles';

export function FdrInfoNotification(): ReactElement {
  const { setInfoHeight } = useLayoutContext();
  const infoNotificationData = useFdrInfoNotificationQuery();

  const containerRef = useRef<HTMLDivElement>(null);
  const { h } = useElementSize(containerRef, { throttle: 100 });

  const infoNotification =
    infoNotificationData?.data?.fdrNotifications.information?.[0];

  useEffect(() => {
    setInfoHeight(h);
  }, [h]);

  if (!infoNotification?.mainText) return null;

  return (
    <FdrInfoNotificationContainer id="notification-info" ref={containerRef}>
      <Container>
        <FdrInformationNotification
          text={infoNotification.mainText}
          image={{
            image: infoNotification.icon,
          }}
        />
      </Container>
    </FdrInfoNotificationContainer>
  );
}
