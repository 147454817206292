import React, { useEffect, useRef, useState } from 'react';
import { FdrAdventureTravelRoutePoint } from '@hotelplan/supergraph-api';
import { useActiveProduct } from 'components/domain/fdr-geo/trips/useActiveProduct';
import { useMapCoordinates } from 'components/domain/fdr-geo/trips/useMapCoordinates';
import { BasePolyline } from 'components/domain/map-constructor/BasePolyline';
import FdrGeoProductRoutePins from './fdr-geo-product-route-pins';

const FdrGeoProductsRoute: React.FC = () => {
  const activeProduct = useActiveProduct();
  const points = activeProduct?.routePoints;
  const pointsRef = useRef<FdrAdventureTravelRoutePoint[]>();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (pointsRef.current !== points) {
      setActive(false);
      pointsRef.current = points;
    }
  }, [points]);

  useMapCoordinates(points, true);

  return (
    <>
      {points && (
        <>
          <BasePolyline
            only={true}
            points={points}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            onClick={() => setActive(true)}
            hovered={hovered}
            active={active}
          />
          <FdrGeoProductRoutePins
            points={points}
            showAllLabels={hovered || active}
          />
        </>
      )}
    </>
  );
};

export default FdrGeoProductsRoute;
