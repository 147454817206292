import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import {
  BsRsvGallery,
  OverlaySlideInfo,
  SlideInfo,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { renderMedia } from 'components/util/criterias';
import { FdrMediaGalleryFragment } from 'schemas/fragment/media-gallery/fdr-media-gallery.generated';
import { FdrMediaGalleryContainer } from './fdr-media-gallery.styles';
import { mapGalleryMediaToImageMediaItems } from './fdr-media-gallery.utils';

interface IFdrResponsiveMediaGalleryProps {
  media: FdrMediaGalleryFragment['media'];
}

export const FdrResponsiveMediaGallery: FC<IFdrResponsiveMediaGalleryProps> = ({
  media,
}) => {
  const [t] = useTranslation('common');

  if (!media.length) return null;

  const mappedItems = mapGalleryMediaToImageMediaItems(media);

  return (
    <FdrMediaGalleryContainer>
      <BsRsvGallery
        renderSlide={renderMedia}
        items={mappedItems}
        overlaySlideInfo={(item, slideIndex, count, isHideNavigation) => (
          <OverlaySlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
        slideInfo={(item, slideIndex, count, isHideNavigation) => (
          <SlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
      />
    </FdrMediaGalleryContainer>
  );
};
