import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { FdrReasonsOfConfidence } from 'components/candidate/fdr-footer';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrLinksList } from '@hotelplan/fdr.regular.fusion.fdr-links-list';
import { FdrFooterSocials } from '@hotelplan/fdr.regular.fusion.fdr-footer-socials';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';

export const FdrFooterWrapper = styled.footer(({ theme: { media } }) =>
  sx2CssThemeFn({
    '.footer-copyright': {
      fontSize: '10px',
      fontWeight: 'bold',
      paddingTop: 3,
      paddingBottom: [3, 5],
      color: 'black',
    },
    '.th-brands': {
      flexShrink: [null, 0],
      flexDirection: [null, 'column'],
      alignItems: [null, 'flex-start'],
      justifyContent: 'flex-start',
      padding: '0px',
      '.brand-link': {
        marginRight: [5, '0px'],
        padding: '0px',
        marginBottom: [null, 4],
        maxWidth: [null, '130px'],

        width: '100%',
        img: {
          maxHeight: 'fit-content',
          maxWidth: [null, '130px'],
          width: '100%',
        },
      },
      [media.tablet]: {
        paddingTop: 4,
      },
      [media.desktop]: {
        paddingTop: 3,
      },
    },
    'a:hover:not(.newsletter-link, .lang-link, .link)': {
      color: 'white',
      textDecoration: 'underline',
    },
  })
);

export const FdrFooterContentWrapper = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'darkGrey',
    color: 'white',
    position: 'relative',
  })
);

export const FdrFooterTopSection = styled(Container)(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    paddingTop: '40px',
    paddingBottom: 6,
    borderBottom: '1px solid #727771',
    justifyContent: 'space-between',
    [media.tablet]: {
      flexWrap: 'wrap',
    },
  })
);

export const FdrFooterMiddleSection = styled(Container)(
  ({ theme: { icons } }) =>
    sx2CssThemeFn({
      py: [5, '40px'],
      borderBottom: '1px solid #727771',
      '.th-social': {
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: [null, '200px'],
        justifyContent: 'flex-start',
        border: 'none',
        marginBottom: '0px',
        '.social-media-title': {
          display: 'none',
        },
      },
      '.social-media-list': {
        justifyContent: 'flex-start',
        paddingBottom: [2, 3],
        paddingRight: '0px',
        paddingLeft: '0px',
      },
      '.social-media-list a': {
        marginRight: '14px',
        '&:last-child': {
          marginRight: 0,
        },
        marginTop: 0,
        marginBottom: [0, 3],
      },
      '.social-media-icon': {
        ...icons.xxlg,
      },
    })
);

export const FdrFooterLogoWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    mb: 3,
    width: '210px',
    height: '65px',
    [media.tablet]: {
      marginRight: '85px',
      flexShrink: 0,
    },
  })
);

export const FdrFooterLogo = styled(FdrImg)(
  sx2CssThemeFn({
    '&:hover': {
      cursor: 'pointer',
    },
  })
);

export const FdrFooterTagline = styled.span(
  sx2CssThemeFn({
    display: 'block',
    fontSize: '15px',
    textTransform: 'uppercase',
    pr: 3,
  })
);

export const FdrReasonsOfConfidenceWrapper = styled(FdrReasonsOfConfidence)(
  sx2CssThemeFn({
    background: 'transparent',
    padding: '0px',
    marginTop: 3,
    marginRight: 3,
    flexBasis: 'auto',
    flexGrow: '1',
    ul: {
      display: 'grid',
      gridTemplateColumns: [null, `repeat(3, 256px)`],
      gap: [null, '0 44px'],
    },
    li: {
      fontSize: 1,
      letterSpacing: '0.5px',
      paddingLeft: '20px',
      lineHeight: 1.4,
      p: {
        marginBottom: 0,
      },
      'p:not(:last-child)': {
        marginBottom: 2,
      },
      '&:before': {
        content: "''",
        backgroundImage: 'url(/images/svg/chevron-right-solid.svg)',
        width: '10px',
        height: '10px',
        fontSize: '14px',
        top: '5px',
        backgroundSize: '7px',
      },
    },
    a: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  })
);

export const FdrFooterMainContentWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media screen and (max-width: 896px) and (orientation: landscape)': {
      flexWrap: 'nowrap',
    },
    [media.tablet]: {
      flexWrap: 'nowrap',
    },
  })
);

export const FdrFooterLinks = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    [media.mobile]: {
      width: '100%',
    },
    '.social-media-title': {
      display: 'none',
    },
    '.social-media-icon': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export const FdrNewsletterLink = styled(FdrRoute)(
  ({ theme }) => theme.buttons.main,
  sx2CssThemeFn({
    mb: [3, '40px'],
    textAlign: 'center',
    backgroundColor: 'redWeb',
    borderColor: 'redWeb',
  })
);

export const FdrFooterFeedbackBoxWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: [`column-reverse`, 'row'],
    justifyContent: 'space-between',
    '.links-list': {
      marginLeft: 0,
    },
  })
);

export const FdrLinksListWrapper = styled(FdrLinksList)(
  ({ theme: { space, media, fontSizes } }) => ({
    padding: `${space[4]} 0px`,
    border: 'none',
    marginBottom: '0px',
    columnGap: space[3],
    '.list-item': {
      flex: '0 0 auto',
      a: {
        fontSize: fontSizes[1],
      },
      fontSize: fontSizes[1],
    },
    [media.tablet]: {
      padding: `${space[4]} 0px`,
    },
  })
);

export const FdrFooterSocialsWrapper = styled(FdrFooterSocials)(
  sx2CssThemeFn({
    border: ['none', 'none'],
    mb: [0, 0],
  })
);
