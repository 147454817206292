import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const ProductRecommendationCustomContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    marginBottom: 6,
    paddingRight: 3,
    justifyContent: 'space-between',
    '.filters-wrapper': {
      marginBottom: 0,
    },
  })
);

const ProductRecommendationCustomContent: React.FC = ({ children }) => {
  if (!React.Children.count(children)) {
    return null;
  }

  return (
    <ProductRecommendationCustomContentWrapper>
      {children}
    </ProductRecommendationCustomContentWrapper>
  );
};

export default ProductRecommendationCustomContent;
