import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';

const Link = styled(BsLink)(({ theme }) => theme.link.uiPrimary);

const FdrGeoTripsListReturnButton: React.FC<{ onClick(): void }> = ({
  onClick,
}) => {
  const [t] = useTranslation('geo');

  return (
    <div>
      <Link
        style={{ margin: '10px 0' }}
        onClick={onClick}
        icon={{ name: 'chevron-long-left' }}
      >
        <span className="link-text">{t('map.tripList.showAllTrips')}</span>
      </Link>
    </div>
  );
};

export default FdrGeoTripsListReturnButton;
