import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAddressFragmentDoc } from 'schemas/fragment/address/fdr-address.generated';
import { FdrOpeningHoursFragmentDoc } from 'schemas/fragment/opening-hours/fdr-opening-hours.generated';
import { FdrCoordinatesFragmentDoc } from 'schemas/fragment/geo/fdrCoordinates.generated';
import { FdrWebMetaFragmentDoc } from 'schemas/fragment/web-meta/fdr-web-meta.generated';
export type FdrAgencyFragment = {
  __typename?: 'FdrAgency';
  id: string;
  name: string;
  phone: string;
  email: string;
  timezone: string;
  brand: Types.FdrAgencyBrand;
  openStatus: Types.FdrAgencyOpenStatus;
  review?: {
    __typename?: 'FdrAgencyRating';
    totalReviewCount?: number | null;
    averageRating?: number | null;
  } | null;
  address?: {
    __typename?: 'FdrAgencyAddress';
    zip?: string | null;
    city?: string | null;
    region?: string | null;
    street?: string | null;
    country?: string | null;
  } | null;
  openingHours?: {
    __typename?: 'FdrOpeningHours';
    monday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    tuesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    wednesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    thursday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    friday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    saturday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    sunday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
  } | null;
  coordinates?: {
    __typename?: 'FdrCoordinates';
    latitude: number;
    longitude: number;
  } | null;
  webMeta: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
};

export const FdrAgencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAgency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'review' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalReviewCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOpeningHours' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCoordinates' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAddressFragmentDoc.definitions,
    ...FdrOpeningHoursFragmentDoc.definitions,
    ...FdrCoordinatesFragmentDoc.definitions,
    ...FdrWebMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
