import React from 'react';
import styled from 'styled-components';
import { MapProvider } from '@hotelplan/components.common.map';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { productFdrMapOptions } from 'components/domain/fdr-map';

const PRODUCT_ROUTES_MAP_ZOOM = 12;
const Container = styled.div(({ theme }) =>
  sx2CssThemeFn({
    position: 'relative',
    width: `100%`,
    height: [`inherit`, 'calc(100vh - 53px)'],
    overflow: 'hidden',
    ...theme.map.controls,
    '.gm-style-iw-c.nearby': {
      marginLeft: '-300px',
      marginTop: '-195px',
    },
    '.gm-style-iw-c.related': {
      marginLeft: '30px',
      marginTop: '-180px',
      minWidth: '300px !important',
    },
  })
);

const FdrProductRouteMapModalBodyContainer: React.FC = ({ children }) => {
  return (
    <Container>
      <MapProvider
        styles={{
          width: `100%`,
          height: `100%`,
        }}
        zoom={PRODUCT_ROUTES_MAP_ZOOM}
        options={productFdrMapOptions}
      >
        {children}
      </MapProvider>
    </Container>
  );
};

FdrProductRouteMapModalBodyContainer.displayName =
  'ProductRouteMapModalBodyContainer';

export default FdrProductRouteMapModalBodyContainer;
