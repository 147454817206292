import { format } from 'url';
import Head from 'next/head';
import React, { ReactElement } from 'react';
import type { BreadcrumbList, WithContext } from 'schema-dts';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';

interface IFdrStructuredDataForBreadcrumbs {
  breadcrumbs: Array<TFdrBreadcrumb>;
  origin: string;
}

function fdrStructuredDataForBreadcrumbs({
  breadcrumbs,
  origin,
}: IFdrStructuredDataForBreadcrumbs): WithContext<BreadcrumbList> {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs
      .filter(b => !!b)
      .map((breadcrumb, index) => {
        try {
          if (breadcrumb?.link?.url) {
            const itemURL = format(new URL(breadcrumb.link.url, origin));

            return {
              '@type': 'ListItem',
              position: index + 1,
              ...(breadcrumb?.caption && {
                name: breadcrumb.caption,
              }),
              ...(itemURL && {
                item: itemURL,
              }),
            };
          }
        } catch (error) {
          console.error(`Invalid URL: `, breadcrumb.link.url, origin, error);
        }
      }),
  };
}

export function FdrStructuredDataBreadcrumbs(
  props: IFdrStructuredDataForBreadcrumbs
): ReactElement {
  if (!process.browser) {
    if (!props.breadcrumbs?.length) return null;

    return (
      <Head>
        <script
          key="breadcrumbs-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(fdrStructuredDataForBreadcrumbs(props)),
          }}
        />
      </Head>
    );
  }

  return null;
}

export const FdrStructuredDataBreadcrumbsPages: Array<EPageType> = [
  EPageType.GeoOverview,
  EPageType.GeoObject,
  EPageType.Pdp,
  EPageType.ThemeOverview,
  EPageType.ThemeObject,
  EPageType.BlogOverview,
  EPageType.BlogArticle,
  EPageType.AgencyOverview,
  EPageType.AgencyObject,
];
