import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrPdpMediaGalleryContainer = styled(Container)(
  sx2CssThemeFn({
    my: ['30px', '70px'],
    px: ['0px', 4],
    '.open-preview': {
      mr: [3, '40px'],
      mb: 3,
      '.icon': {
        fill: 'transparent',
        width: '32px',
        height: '32px',
      },
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
    '.gallery-title': {
      mb: [4, 7],
      pl: [5, 0],
    },
  })
);
