import React from 'react';
import {
  FdrMediaAutoplayGallery,
  TMediaItem,
} from '@hotelplan/fdr.regular.fusion.fdr-media-autoplay-gallery';
import { MediaGalleryContainer } from 'components/domain/fdr-page-components/fdr-page-components.styles';
import { renderResponsiveImg } from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { mapMediaItemsImageCriteriaField } from 'components/util/criterias/mapImageCriteriaField';
import {
  fdrMediaFullSpecs,
  fdrMediaPreviewSpecs,
} from 'components/util/criterias/media.dmc';
import { FdrMediaGalleryFragment } from 'schemas/fragment/media-gallery/fdr-media-gallery.generated';
import { isAllImages } from './fdr-media-gallery.utils';
import { FdrResponsiveMediaGallery } from './fdr-responsive-media-gallery';

export function FdrMediaGalleryPageComponent({
  component,
}: {
  component: FdrMediaGalleryFragment;
}) {
  if (isAllImages(component?.media)) {
    return <FdrResponsiveMediaGallery media={component?.media} />;
  }

  const mediaItems = mapMediaItemsImageCriteriaField(
    component?.media as unknown as Array<TMediaItem>
  );

  return (
    <MediaGalleryContainer>
      <FdrMediaAutoplayGallery
        ratio={0.34}
        mediaItems={mediaItems}
        imageElement={renderResponsiveImg(`image-media`, fdrMediaFullSpecs)}
        previewImage={renderResponsiveImg(
          `preview-media`,
          fdrMediaPreviewSpecs
        )}
      />
    </MediaGalleryContainer>
  );
}
