import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { MapClusterer, PinCluster } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import { useActiveProduct } from 'components/domain/fdr-geo/trips/useActiveProduct';
import { useMapCoordinates } from 'components/domain/fdr-geo/trips/useMapCoordinates';
import BasePin from 'components/domain/map-constructor/BasePin';
import { DarkInfoWindow } from 'components/domain/map-constructor/DarkInfoWindow';
import FdrGeoTripsPinsRelatedRoutes from './fdr-geo-trips-pins-related-routes';
import { TProductPin, TRelatedPin } from './fdr-geo-trips-pins.utils';

const MapCluster = styled.div(
  sx2CssThemeFn(({ theme: { colors } }) => ({
    position: 'relative',
    width: '40px',
    height: '40px',
    '.icon': {
      color: colors.redWeb,
    },
  }))
);

const MapClustererWrapper = styled(MapClusterer)({
  '.map-pin-content': {
    background: 'transparent',
    boxShadow: 'none',
  },
  '.map-pin-wrapper:after': {
    display: 'none',
  },
});

const PinHolder = styled.div({
  position: 'relative',
});

function renderPinContent(
  onClick: (markers?: Array<TProductPin | TRelatedPin>) => void
): (cluster?: PinCluster) => ReactElement {
  return function renderCluster(cluster?: PinCluster) {
    const markers = cluster?.getMarkers()?.map(m => (m as any).object);

    return (
      <MapCluster
        onClick={() => {
          onClick(markers);
        }}
      >
        <Icon size="xxlg" name="white-border-cluster-icon" />
      </MapCluster>
    );
  };
}

interface IProductsProps {
  id: string;
}

const FdrGeoTripsPinsProducts: React.FC<IProductsProps> = ({ id }) => {
  const idRef = useRef<string>(null);

  const active = useActiveProduct();
  const {
    activeProduct,
    setActiveProduct,
    setActiveTrip,
    productPins,
    setClusterMarkers,
    setActiveRoutePoint,
  } = useContext(FdrGeoTripsContext);

  // use force: true to update the map bounds when the geo page was changed
  useMapCoordinates(Array.from(productPins.values()), idRef.current !== id);

  const pins = Array.from(productPins);

  const filteredPins = pins.filter(
    ([, trip]) => active?.tripId !== trip.tripId
  );

  const onClusterClick = (markers?: Array<TProductPin | TRelatedPin>) => {
    setActiveProduct(null);
    setActiveRoutePoint(null);
    setClusterMarkers(markers);
  };

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  return (
    <MapClustererWrapper
      renderPinContent={renderPinContent(onClusterClick)}
      className="products-cluster"
      stopPropagation={false}
      minClusterSize={5}
    >
      <FdrGeoTripsPinsRelatedRoutes />
      {filteredPins.map(([pinId, pin]) => {
        return (
          <PinHolderContainer
            key={`product-pin-${pinId}`}
            id={pinId}
            pin={pin}
            activeProduct={activeProduct}
            setActiveProduct={setActiveProduct}
            setActiveTrip={setActiveTrip}
            setActiveRoutePoint={setActiveRoutePoint}
            setClusterMarkers={setClusterMarkers}
          />
        );
      })}
    </MapClustererWrapper>
  );
};

interface IPinHolderContainerProps {
  id: string;
  pin: TProductPin;
  activeProduct: string;
  setActiveProduct: React.Dispatch<React.SetStateAction<string>>;
  setActiveTrip: React.Dispatch<React.SetStateAction<string>>;
  setActiveRoutePoint: React.Dispatch<React.SetStateAction<string>>;
  setClusterMarkers: React.Dispatch<
    React.SetStateAction<Array<TProductPin | TRelatedPin>>
  >;
}

function PinHolderContainer({
  id,
  pin,
  activeProduct,
  setActiveProduct,
  setActiveTrip,
  setActiveRoutePoint,
  setClusterMarkers,
}: IPinHolderContainerProps): ReactElement | null {
  const [hovered, setHovered] = useState<string>();

  return (
    <PinHolder
      onMouseOver={() => setHovered(id)}
      onMouseOut={() => setHovered(undefined)}
    >
      <BasePin
        item={pin}
        active={activeProduct === id}
        onClick={() => {
          setActiveRoutePoint(null);
          setClusterMarkers(null);
          setActiveProduct(id);
          setActiveTrip(pin.tripId);
        }}
        className={'new-roundtrip-pin'}
      >
        {hovered === id && <DarkInfoWindow>{pin.pointName}</DarkInfoWindow>}
      </BasePin>
    </PinHolder>
  );
}

export default FdrGeoTripsPinsProducts;
