import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrAgencyAddress, ProductInfoB2B } from '@hotelplan/supergraph-api';
import { CardItemBreadcrumbs } from 'components/domain/recommendations/Recommendations.common';
import { ThirdHeading } from 'theme/headings';
import { FdrSearchProductInfo } from './fdr-search-product-info';
import {
  FdrDescriptionTitle,
  FdrInnerDescriptionWrapper,
  FdrTeaserDescription,
  SearchOpeningStatus,
} from './fdr-search.styles';

const searchItemOptions = {
  extraHotels: { highlightedText: false, leadInfoCutLines: 3 },
  regularHotels: { highlightedText: false, leadInfoCutLines: 3 },
  themes: { highlightedText: true },
  blogArticles: { highlightedText: false },
  other: { highlightedText: true },
};

interface IFdrSearchItemDescriptionProps {
  name: string;
  highlightedText: string;
  addressDetails?: FdrAgencyAddress;
  opened?: boolean;
  breadcrumbs?: string[];
  productInfoB2B?: ProductInfoB2B;
  sectionKey?: string;
  imageHeight: number;
  mainText?: string;
  leadText?: string;
  b2cTypeFlags?: string[];
}

export function FdrSearchItemDescription({
  sectionKey,
  imageHeight,
  name,
  breadcrumbs,
  highlightedText,
  mainText,
  productInfoB2B,
  opened,
  addressDetails,
  leadText,
  b2cTypeFlags,
}: IFdrSearchItemDescriptionProps): ReactElement {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();

  const isB2B = channelType === AuthChannelType.B2B;

  return (
    <FdrTeaserDescription
      imageHeight={imageHeight}
      leadInfoCutLines={searchItemOptions[sectionKey]?.leadInfoCutLines}
    >
      <FdrInnerDescriptionWrapper>
        <FdrDescriptionTitle mobile={mobile}>
          <ThirdHeading>{name}</ThirdHeading>
        </FdrDescriptionTitle>
        {breadcrumbs?.length ? (
          <CardItemBreadcrumbs
            breadcrumbs={breadcrumbs}
            className="search-item-breadcrumbs"
          />
        ) : null}
        {highlightedText && searchItemOptions[sectionKey]?.highlightedText ? (
          <div
            className="description-text"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          />
        ) : null}
        {mainText ? (
          <div
            className="main-text"
            dangerouslySetInnerHTML={{ __html: mainText }}
          />
        ) : null}
        <FdrSearchProductInfo
          duration={isB2B && productInfoB2B?.duration}
          priceFrom={isB2B && productInfoB2B?.priceFrom}
          route={isB2B && productInfoB2B?.route}
          leadText={leadText}
          typeFlags={isB2B ? productInfoB2B?.typeFlags : b2cTypeFlags}
        />
        {opened || addressDetails ? (
          <>
            <span>{addressDetails?.street}</span>
            <br />
            <span>{`${addressDetails?.zip} ${addressDetails?.city}`}</span>
            {opened !== null ? (
              <SearchOpeningStatus showStatusIcon opened={opened} />
            ) : null}
          </>
        ) : null}
      </FdrInnerDescriptionWrapper>
      <BsIcon name="chevron-long-right" className="icon" size="lg" />
    </FdrTeaserDescription>
  );
}
