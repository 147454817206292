import styled from 'styled-components';

const Container = styled.div(({ theme: { media, space } }) => ({
  maxWidth: '1480px',
  margin: 'auto',
  paddingLeft: '20px',
  paddingRight: '20px',
  [media.mobile]: {
    paddingLeft: space[3],
    paddingRight: space[3],
  },
}));

export const SlidersNavigationContainer = styled(Container)({
  maxWidth: '1520px',
  paddingLeft: '40px',
  paddingRight: '40px',
  '.count': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

export default Container;
