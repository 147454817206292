import { track } from '@hotelplan/libs.tracking-events';
import { FdrLink } from '@hotelplan/supergraph-api';
import { THistoryObject } from './fdr-search-overlay.types';
import { IHistoryItem } from './useFdrLastSearchedViewed';

export const FDR_SEARCH_VALUE_KEY = 'searchValue';
export const FDR_SEARCH_SECTION_KEY_PREFIX = 'saved_section_';

export enum FdrSearchOverlayEvent {
  Open = `fdr-search-overlay-open`,
  Close = `fdr-search-overlay-close`,
}

export function openFdrSearchOverlay(): void {
  window.dispatchEvent(new Event(FdrSearchOverlayEvent.Open));
}

export function closeFdrSearchOverlay(): void {
  window.dispatchEvent(new Event(FdrSearchOverlayEvent.Close));
}

export function trackFdrSearchSuggestion(source: string, target: string): void {
  track({
    event: `🚀 click.searchSuggestion`,
    _clear: true,
    eventMetadata: {
      source,
      target,
      component: `search-overlay`,
    },
  });
}

export function mapFdrLastViewedToHistoryItems(
  historyObject: THistoryObject
): IHistoryItem {
  function getHistoryItem(item: {
    name: string;
    webMeta?: { descriptionWebMeta?: { link: FdrLink }; link?: FdrLink };
  }): IHistoryItem {
    if (!item) return null;

    const { webMeta } = item;

    function hasDescriptionWebMeta(
      meta: typeof webMeta
    ): meta is { descriptionWebMeta: { link: FdrLink } } {
      return 'descriptionWebMeta' in meta;
    }

    return {
      text: item.name,
      link: hasDescriptionWebMeta(webMeta)
        ? webMeta.descriptionWebMeta.link
        : webMeta.link,
    };
  }

  switch (historyObject.__typename) {
    case 'FdrHistoryVisitedProduct': {
      return getHistoryItem(historyObject.product);
    }
    case 'FdrHistoryVisitedGeo': {
      return getHistoryItem(historyObject.geo);
    }
    case 'FdrHistoryVisitedTheme': {
      return getHistoryItem(historyObject.theme);
    }
    case 'FdrHistoryVisitedStaticPage': {
      return getHistoryItem(historyObject.staticPage);
    }
    case 'FdrHistoryVisitedAgency': {
      return getHistoryItem(historyObject.agency);
    }
  }
}
