import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef } from 'react';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import trackMenu, { MenuEventType } from '@hotelplan/fdr.lib.track.menu';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FdrMobileMenuDropdown } from 'components/domain/fdr-mobile-menu-dropdown';
import { FdrSiteToolsButtonsWrapper } from './fdr-sitetools.styles';

export function FdrMenuSiteTool(): ReactElement {
  const [isMobileNavShown, , closeMobileNav, toggleNavigation] =
    useToggleState(false);

  const mobileNavButtonRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  function handleNabButtonClick() {
    toggleNavigation();
    trackMenu({
      type: isMobileNavShown
        ? MenuEventType.MENU_CLOSE
        : MenuEventType.MENU_OPEN,
    });
  }

  function handleCloseMobileNav() {
    closeMobileNav();
    trackMenu({
      type: MenuEventType.MENU_CLOSE,
    });
  }

  useOnClickOutside(wrapperRef, handleCloseMobileNav, isMobileNavShown);

  useEffect(() => {
    router.events.on('routeChangeStart', handleCloseMobileNav);

    return () => {
      router.events.off('routeChangeStart', handleCloseMobileNav);
    };
  }, [router.events]);

  return (
    <div ref={wrapperRef}>
      <FdrSiteToolsButtonsWrapper
        data-id="menu-site-tool"
        className={`site-tool-button ${isMobileNavShown ? 'nav-shown' : ''}`}
      >
        <BsButtonWithIcon
          variant="headerBtn"
          ref={mobileNavButtonRef}
          icon={{ name: isMobileNavShown ? 'menu-active' : 'menu' }}
          onClick={handleNabButtonClick}
        />
      </FdrSiteToolsButtonsWrapper>
      {isMobileNavShown ? (
        <FdrMobileMenuDropdown onClose={handleCloseMobileNav} />
      ) : null}
    </div>
  );
}
