import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useLogoRoute } from '@hotelplan/fdr.hook.use-logo-route';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrBrandLogos } from '@hotelplan/fdr.regular.fusion.fdr-brand-logos';
import { FdrFooterCopyright } from '@hotelplan/fdr.regular.fusion.fdr-footer-copyright';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import {
  FdrLinkOpenMethod,
  FdrLinkType,
  FdrMenu,
} from '@hotelplan/supergraph-api';
import { Container } from 'components/domain/container';
import { FdrFooterFeedback } from 'components/domain/fdr-footer-feedback';
import { FdrFooterLangControl } from 'components/domain/fdr-footer-lang-control';
import { FdrFooterMenu } from 'components/domain/fdr-footer-menu';
import { useFdrFooterQuery } from 'schemas/query/footer/fdr-footer.generated';
import {
  FdrFooterContentWrapper,
  FdrFooterFeedbackBoxWrapper,
  FdrFooterLinks,
  FdrFooterLogo,
  FdrFooterLogoWrapper,
  FdrFooterMainContentWrapper,
  FdrFooterMiddleSection,
  FdrFooterSocialsWrapper,
  FdrFooterTagline,
  FdrFooterTopSection,
  FdrFooterWrapper,
  FdrLinksListWrapper,
  FdrNewsletterLink,
  FdrReasonsOfConfidenceWrapper,
} from './fdr-footer.styles';

interface IFdrFooterProps {
  tagline?: string;
  navLinks: Array<FdrMenu>;
}

export function FdrFooter({
  navLinks,
  tagline,
}: IFdrFooterProps): ReactElement {
  const [t, { language }] = useTranslation('common');
  const logoRoute = useLogoRoute();

  const fdrFooterQuery = useFdrFooterQuery();

  const newsletterSubscriptionRoute = getRouteByPageTypeLocale(
    EPageType.NewsletterSubscription,
    language
  );

  const {
    logo,
    menus,
    reasonsOfConfidenceMainTitle,
    reasonsOfConfidenceMainText,
    brandLogos,
    socialMedias,
  } = fdrFooterQuery?.data?.fdrFooter || {};

  return (
    <FdrFooterWrapper>
      <FdrFooterContentWrapper>
        <FdrFooterTopSection>
          <div>
            {logo ? (
              <FdrFooterLogoWrapper>
                <FdrRoute {...logoRoute}>
                  <FdrFooterLogo className="footer-logo" image={logo} />
                </FdrRoute>
              </FdrFooterLogoWrapper>
            ) : null}
            {tagline ? <FdrFooterTagline>{tagline}</FdrFooterTagline> : null}
          </div>
          <FdrReasonsOfConfidenceWrapper
            title={reasonsOfConfidenceMainTitle}
            text={reasonsOfConfidenceMainText}
          />
          <FdrBrandLogos brandLogos={brandLogos} className="th-brands" />
        </FdrFooterTopSection>
      </FdrFooterContentWrapper>
      <FdrFooterContentWrapper>
        <FdrFooterMiddleSection>
          <FdrFooterMainContentWrapper>
            <FdrFooterMenu navLinks={navLinks} />
            <FdrFooterLinks>
              <FdrNewsletterLink
                className="newsletter-link"
                link={{
                  url: newsletterSubscriptionRoute.builder({}),
                  type: FdrLinkType.Internal,
                  openMethod: FdrLinkOpenMethod.ThisTab,
                }}
              >
                {t('newsletter.button')}
              </FdrNewsletterLink>
              <FdrFooterSocialsWrapper items={socialMedias} />
              <FdrFooterLangControl />
            </FdrFooterLinks>
          </FdrFooterMainContentWrapper>
        </FdrFooterMiddleSection>
      </FdrFooterContentWrapper>
      <FdrFooterContentWrapper>
        <Container>
          <FdrFooterFeedbackBoxWrapper>
            <FdrLinksListWrapper links={menus} />
            <FdrFooterFeedback />
          </FdrFooterFeedbackBoxWrapper>
        </Container>
      </FdrFooterContentWrapper>
      <Container>
        <FdrFooterCopyright copyright={t('copyright.mainText')} />
      </Container>
    </FdrFooterWrapper>
  );
}
