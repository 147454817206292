import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrHeaderFragmentDoc } from 'schemas/fragment/header/fdrHeader.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHeaderQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FdrHeaderQuery = {
  __typename?: 'Query';
  fdrHeader?: {
    __typename?: 'FdrHeader';
    menus: Array<{
      __typename?: 'FdrMenu';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
      menuItems?: Array<{
        __typename?: 'FdrMenuItem';
        caption: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }> | null;
    }>;
    logo: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    };
    contactInformation?: {
      __typename?: 'FdrContactInformation';
      mainTitle?: string | null;
      phoneNumber: string;
      phoneTitle?: string | null;
      timezone: string;
      link?: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      } | null;
      openingHours?: {
        __typename?: 'FdrOpeningHours';
        monday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        tuesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        wednesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        thursday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        friday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        saturday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        sunday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const FdrHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHeader' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHeader' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHeader' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHeaderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHeaderQuery__
 *
 * To run a query within a React component, call `useFdrHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrHeaderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrHeaderQuery,
    FdrHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FdrHeaderQuery, FdrHeaderQueryVariables>(
    FdrHeaderDocument,
    options
  );
}
export function useFdrHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHeaderQuery,
    FdrHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FdrHeaderQuery, FdrHeaderQueryVariables>(
    FdrHeaderDocument,
    options
  );
}
export type FdrHeaderQueryHookResult = ReturnType<typeof useFdrHeaderQuery>;
export type FdrHeaderLazyQueryHookResult = ReturnType<
  typeof useFdrHeaderLazyQuery
>;
export type FdrHeaderQueryResult = Apollo.QueryResult<
  FdrHeaderQuery,
  FdrHeaderQueryVariables
>;
