/* eslint-disable i18next/no-literal-string */
import type { CSSObject, DefaultTheme } from 'styled-components';
import { sx2CssTheme, sxApply } from '@hotelplan/util.theme.sxc';

const baseColors = {
  white: '#fff',
  black: '#000',
  gray: [
    '#fff',
    '#E1E1E1',
    '#EBEFF0',
    '#DDE5E7',
    '#EBEBEB',
    '#DDDDDD',
    '#F5F4F4',
  ],
};

const breakpoints = ['896px', '897px', '1025px'];

const MEDIA_SMALL_SCREEN = '@media screen and (max-width: 394px)';
const MEDIA_MOBILE = `@media screen and (max-width: ${breakpoints[0]})`;
const MEDIA_TABLET = `@media screen and (min-width: ${breakpoints[1]})`;
const MEDIA_DESKTOP = `@media screen and (min-width: ${breakpoints[2]})`;

const MOBILE_SCREEN_LANDSCAPE = `@media screen and (max-width: ${breakpoints[0]}) and (orientation: landscape)`;

const TABLET_SCREEN_PORTRAIT = `@media screen and (max-width: ${breakpoints[1]}) and (orientation: portrait)`;

const media = {
  ie: '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)',
  // @TODO Remove if it's not mobile, use [theme.media.mobile] instead
  small: MEDIA_SMALL_SCREEN,
  mobile: MEDIA_MOBILE,
  tablet: MEDIA_TABLET,
  desktop: MEDIA_DESKTOP,
};

const fontSizes = [14, 16, 18, 20, 22, 26, 28, 34, 44].map(n => n + 'px');

export const spaces: number[] = [0, 4, 8, 16, 24, 28, 32, 48, 64, 80];

const space = spaces.map(n => n + 'px');

export const FONT_FAMILY = '"NeueHelvetica", "Helvetica", sans-serif';

const colors: DefaultTheme['colors'] = {
  white: '#fff',
  black: '#000',
  fontColor: '#000000',
  background: '#ffffff',
  darkGrey: '#2C2D2A',
  markupForm: '#2C2D2A',
  stoneGrey: '#727771',
  secondary: '#727771',
  cardsDelimiter: '#727771',
  paperGrey: '#F5F1EC',
  lightGreyThird: '#F5F1EC',
  lightGrey: '#D4D4D4',
  borderColor: '#D4D4D4',
  borderGreyFifth: '#D4D4D4',
  lightGreySecond: '#F6F6F6',
  redVivid: '#E73A31',
  primary: '#E4251B',
  redWeb: '#E4251B',
  redHover: '#B61E16',
  redActive: '#A01A13',
  roseFine: '#FDE3D7',
  redBurgundy: '#7A2739',
  green: '#5C7449',
  greenOlive: '#5B5D49',
  greenHay: '#B7C9A9',
  greenLight: '#E5EBE0',
  blueOcean: '#537A93',
  blueMorning: '#A9CDC9',
  blueLight: '#DEE7ED',
  bgLightBlue: '#DEE7ED',
  goldSavannah: '#BA9155',
  goldLight: '#F0E8DB',
  modalShadow: 'rgba(0, 0, 0, 0.8)',
  authModalBackgroundColor: baseColors.white,
  pinWrapper: '#fff',
  activePinWrapper: '#E4251B',
  inactivePin: '#7A2739',
  oldRoundtripsPinHover: '#405F72',
  oldRoundtripsPinActive: '#375162',
  error: '#E4251B',
  success: '#5C7449',
  imageColorOverlay: 'rgba(231, 58, 49, 0.39)',
};

const shadows: DefaultTheme['shadows'] = {
  cardShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  langDropdown: '0 4px 14px 0 rgba(0,0,0,0.17)',
  large: '0px 2px 4px rgba(0, 0, 0, 0.52)',
};

const transition: DefaultTheme['transition'] = {
  fast: 'all 0.2s ease-in',
  slow: 'all 0.3s ease-in',
};

const radii: DefaultTheme['radii'] = {
  default: '8px',
  circle: 99999,
  message: '5px',
  roundedTop: '4px 4px 0px 0px',
  roundedBottom: '0px 0px 4px 4px',
};

export const icons: DefaultTheme['icons'] = {
  xs: {
    width: '13px',
    height: '13px',
  },
  sm: {
    width: '17px',
    height: '17px',
  },
  md: {
    width: '20px',
    height: '20px',
  },
  lg: {
    width: '25px',
    height: '25px',
  },
  xlg: {
    width: '30px',
    height: '30px',
  },
  xxlg: {
    width: '42px',
    height: '42px',
  },
};

const basicTheme = {
  breakpoints,
  media,
  colors,
  space,
  fontSizes,
  radii,
  transition,
  shadows,
  icons,
};

const sxGroup = sxApply(basicTheme);

const ctaStyles: CSSObject = {
  backgroundColor: colors.redWeb,
  fontSize: fontSizes[1],
  fontWeight: 450,
  letterSpacing: '0.5px',
  color: baseColors.white,
  lineHeight: '1.4',
  textAlign: 'center',
  padding: `${space[2]} ${space[4]}`,
  borderRadius: '5px',
  border: '2px solid',
  borderColor: colors.redWeb,
  boxShadow: `0px 0px 0px ${colors.redWeb}`,
  width: 'fit-content',
  [media.mobile]: {
    width: '100%',
  },
  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    width: 'fit-content',
  },
  '&:hover': {
    backgroundColor: colors.redHover,
    borderColor: colors.redHover,
    color: baseColors.white,
    textDecoration: 'underline',
  },
  '&:focus': {
    backgroundColor: colors.redActive,
    borderColor: colors.redActive,
    color: baseColors.white,
    textDecoration: 'underline',
  },
  '&:active': {
    backgroundColor: colors.redActive,
    borderColor: colors.redActive,
    color: baseColors.white,
    textDecoration: 'underline',
  },
};

const ctaStylesOnRedBg: CSSObject = {
  ...ctaStyles,
  backgroundColor: baseColors.white,
  color: colors.redWeb,
  borderColor: colors.white,
  '&:hover': {
    backgroundColor: colors.paperGrey,
    color: colors.redHover,
    textDecoration: 'underline',
  },
  '&:focus': {
    backgroundColor: colors.paperGrey,
    borderColor: colors.redHover,
    color: colors.redHover,
    textDecoration: 'underline',
  },
  '&:active': {
    backgroundColor: colors.roseFine,
    borderColor: colors.roseFine,
    color: colors.redActive,
    textDecoration: 'underline',
  },
};

const text: DefaultTheme['text'] = sxGroup({
  groupHeading: {
    fontSize: fontSizes[1],
    lineHeight: fontSizes[2],
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    display: 'inline-block',
  },
  primaryHeading: {
    fontSize: '32px',
    [media.tablet]: {
      fontSize: '42px',
    },
  },
  searchHeading: {
    fontSize: '42px',
    [media.tablet]: {
      fontSize: '65px',
    },
  },
  secondaryHeading: {
    fontSize: '30px',
    [media.tablet]: {
      fontSize: '38px',
    },
  },
  copyText: {
    fontSize: fontSizes[2],
  },
  success: {
    color: colors.success,
  },
  error: {
    color: colors.redWeb,
  },
  textBlockWrap: {
    maxWidth: [null, '818px'],
    mx: [null, 'auto'],
  },
  richText: {
    a: {
      fontSize: 2,
      display: 'inline',
      textDecoration: 'underline',
      fontWeight: '450',
      '&:hover, &:focus': {
        color: colors.redHover,
        textDecoration: 'underline',
      },
      '&:active': {
        color: colors.redActive,
        textDecoration: 'underline',
      },
      '&.request-button': {
        display: 'inline-block',
        textDecoration: 'none',
        ...ctaStyles,
        [media.mobile]: {
          width: '100%',
        },
        '&.red-bg': {
          ...ctaStylesOnRedBg,
        },
        span: {
          fontSize: fontSizes[1],
        },
      },
    },
    ul: {
      fontSize: 2,
      mb: 2,
      li: {
        listStyle: 'disc',
        ml: ['20px', '24px'],
      },
    },
    ol: {
      fontSize: 2,
      mb: 2,
      li: {
        listStyle: 'decimal',
        ml: ['20px', '23px'],
      },
    },
    h1: {
      fontSize: '32px',
      mb: 4,
      [media.tablet]: {
        fontSize: '48px',
        marginBottom: '40px',
      },
    },
    h2: {
      fontSize: '30px',
      mb: 3,
      pt: 3,
      [media.tablet]: {
        fontSize: '30px',
      },
    },
    h3: {
      fontSize: '26px',
      mb: 3,
    },
    h4: {
      fontSize: '22px',
      mb: 3,
    },
    h5: {
      fontSize: '12px',
      textTransform: 'uppercase',
      display: 'inline-block',
      mb: 3,
    },
    p: {
      fontSize: 2,
      mb: 3,
    },
    span: {
      fontSize: '12px',
      mb: 3,
    },
  },
});

const notificationCardWrapCss = sxGroup({
  textAlign: 'center',
  backgroundColor: 'background',
  borderColor: 'borderGreyFifth',
  p: [null, 3],
  pt: 4,
  borderRadius: 'default',
  boxShadow: 'cardShadow',
});

const customCheckboxControl = {
  height: ['25px', '20px'],
  width: ['25px', '20px'],
  position: 'relative',
  border: '1px solid',
  borderColor: 'borderColor',
  borderRadius: '2px',
  order: -1,
  marginRight: [2, '10px'],
  backgroundColor: 'white',
  flexShrink: 0,
  '&::after': {
    content: "''",
    position: 'absolute',
    opacity: 0,
    left: ['7px', '6px'],
    top: ['1px', '2px'],
    width: ['8px', '6px'],
    height: ['17px', '12px'],
    border: '1px solid',
    borderColor: 'redWeb',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(40deg)',
  },
};

const mapControls = {
  '.gm-ui-hover-effect': {
    top: '5px !important',
    right: '5px !important',
    background: '#fff !important',
    borderRadius: '50%',
    opacity: 1,
  },
  '.gm-style-iw-c': {
    position: 'relative',
    maxWidth: 'unset !important',
  },
  '.gm-style-iw-d': {
    maxHeight: 'unset !important',
    overflow: 'unset !important',
    '.infoContent': {
      width: '300px',
      height: '195px',
      background: 'white',
      borderRadius: '10px',
      boxShadow: '0px 2px 6px #00000029',
      maxHeight: '195px !important',
      zIndex: 0,
      overflow: 'hidden',
    },
    '.diamondContent': {
      width: '300px',
      height: '360px',
      background: 'white',
      borderRadius: '10px',
      boxShadow: '0px 2px 6px #00000029',
      maxHeight: '360px !important',
      zIndex: 0,
      overflow: 'hidden',
    },
  },
  '.gallery-with-map-title': {
    marginBottom: '45px',
  },
  '.map-nearby-container': {
    padding: '10px',
    margin: '10px 10px 0 0',
    height: '40px',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '2px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;',
    label: { margin: 0 },
  },
  '.map-control-button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '40px',
    border: '2px solid #fff',
    margin: '10px',
    borderRadius: '3px',
    background: 'white',
    boxShadow: '0 2px 6px rgba(0,0,0,.3)',
    cursor: 'pointer',
    padding: '8px',
    img: {
      width: '24px',
    },
    '.map-control-caption': {
      marginLeft: '8px',
    },
  },
  '.map-nearby-input-wrapper': {
    input: {
      display: 'none',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      lineHeight: 'normal',
      color: 'stoneGrey',
    },
    'input:checked ~ .customCheckboxInput': {
      borderColor: 'white',
      background: colors.redWeb,
    },
    'input:checked ~ .customCheckboxInput::after': {
      borderColor: 'white',
      opacity: 1,
    },
    '.customCheckboxInput': customCheckboxControl,
    '&:hover input ~ .customCheckboxInput': {
      backgroundColor: colors.borderColor,
    },
    '&:hover input:checked ~ .customCheckboxInput': {
      backgroundColor: colors.redWeb,
    },
  },
  '.infoWindow-image': {
    overflow: 'hidden',
    height: '105px',
    width: '300px',
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '.map-with-nearby button.gm-control-active.gm-fullscreen-control': {
    top: '50px !important',
  },
  '.infoWindow-name': {
    width: '190px',
    maxHeight: '75px',
    fontSize: '15px',
    fontWeight: 'bold',
    overflow: 'hidden',
    padding: '10px 20px 0 10px',
    color: 'black',
    '& .arrow-icon': {
      color: colors.redWeb,
      position: 'absolute',
      top: '0',
      right: '-20px',
      '& svg, & use': {
        outline: 'none',
        '&:hover, &:active': { outline: 'none' },
      },
    },
  },
  '.infoWindow-text-wrapper': {
    display: '-webkit-box',
    position: 'relative',
    maxHeight: '75px',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    '.infoWindow-text': {
      display: 'inline',
    },
  },
  '.infoWindow-link-box:hover .open-icon': {
    color: 'white',
    '& svg': {
      borderRadius: '20px',
      background: colors.redWeb,
    },
  },
  '.open-icon': {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    textAlign: 'center',
    color: colors.redWeb,
    width: '90px',
    '& span': { color: 'black' },
    '& svg': {
      height: '36px',
      width: '36px',
      margin: '0 auto',
    },
    '& svg, & use': {
      outline: 'none',
      '&:hover, &:active': { outline: 'none' },
    },
  },
};

const theme = Object.freeze<DefaultTheme>({
  ...basicTheme,
  auth: sxGroup({
    panelContent: {
      display: ['flex', 'block'],
      position: 'relative',
      mb: [3, 0],
      lineHeight: '0',
      button: {
        '.icon': {
          width: '20px',
          height: '20px',
          mt: '50%',
          transform: 'translateY(-50%)',
          mb: 3,
          color: 'redWeb',
          fill: 'transparent',
        },
        '&:hover': {
          borderWidth: ['0px', '1px'],
          borderStyle: 'solid',
          borderColor: 'redHover',
          '.icon': {
            color: 'redHover',
          },
          '.contact-text': {
            display: 'block',
            position: 'absolute',
            top: '53px',
            width: 'max-content',
            '&:first-letter': {
              textTransform: `capitalize`,
            },
          },
        },
      },

      '.contact-text': {
        display: 'none',
        color: 'white',
        fontWeight: '400',
        fontSize: 1,
        lineHeight: '22px',
        letterSpacing: '0.5px',
        backgroundColor: 'darkGrey',
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        padding: '5px 10px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '110',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '16px',
          height: '16px',
          left: '50%',
          top: '-8px',
          backgroundColor: 'darkGrey',
          transform: 'translateX(-50%)  rotate(-45deg)',
          zIndex: '1',
        },
      },
    },
    logoutIconHolder: {
      position: 'relative',
      display: 'flex',
      flexDirection: [null, 'column'],
      alignItems: 'center',
      fontSize: '0px',
      color: 'lightGreySecond',
      fontWeight: 'bold',
      mr: '8px',
      '.icon': {
        width: '18px',
        height: '18px',
        my: '0',
        transform: 'none',
        color: 'redWeb',
        fill: 'transparent',
      },
    },
    loginLayoutBlockStyles: {
      maxWidth: [null, '485px'],
      backgroundColor: 'white',
      boxShadow: [null, 'blockShadow'],
      mx: [null, 'auto'],
      flexWrap: 'wrap',
      '.layout-text': {
        fontSize: [null, 3],
        mb: [4, '50px'],
      },
    },
    formTitleStyles: {
      fontSize: [4, '25px'],
      mb: [4, '25px'],
      fontWeight: 'normal',
    },
    formTextStyles: {
      fontSize: [2, 3],
      mb: [4, '25px'],
      fontWeight: 'normal',
    },
    b2bLoginLayout: {
      backgroundColor: 'redVivid',
      pt: [0, '145px'],
      pb: [0, '75px'],
      mt: ['110px', 0],
    },
    formModalWrapperStyles: {
      width: '100%',
      maxWidth: '450px',
      position: 'relative',
      height: [null, 'auto'],
      borderRadius: ['roundedTop', '0px'],
      '.modal-body, .modal-header': {
        backgroundColor: 'white',
      },
    },
    errorMessage: {
      mb: 3,
      color: colors.error,
    },
    loginForm: {
      '.text-input': {
        mb: [3, 4],
        '&:last-of-type': {
          mb: 4,
        },
      },
      '.login-form-submit': {
        width: '100%',
        marginBottom: [3, '20px'],
      },
      '.login-form-forgot-button': {
        py: 2,
      },
      '.error': {
        mt: '-20px',
        mb: 4,
      },
    },
  }),
  metaModules: {
    cookies: sxGroup({
      wrap: {
        // IPAD_MINI_SCREEN
        [`@media screen and (min-width: 768px) and (max-width: 1024px)`]: {
          flexWrap: 'nowrap',
        },
        borderTop: '1px solid white',
        boxShadow: '20px 0px 20px 0px #0000004D',
        position: 'fixed',
        bottom: ['90px', '0'],
        zIndex: '10',
        width: '100%',
        backgroundColor: 'redBurgundy',
        flexWrap: ['wrap', 'nowrap'],
        justifyContent: [null, 'space-between'],
        alignItems: 'center',
        mt: 4,
      },
      text: {
        fontSize: [null, 3],
        pl: [4, 6],
        pr: 4,
        py: [3, 4],
        a: {
          color: 'redWeb',
        },
      },
      acceptButton: {
        ...ctaStylesOnRedBg,
        mr: [null, 6],
      },
    }),
  },
  usp: {
    boxWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      alignItems: 'center',
      width: '90%',
      minHeight: '380px',
      border: `1px solid ${colors.darkGrey}`,
      padding: `${space[5]} ${space[3]}`,
      [media.tablet]: {
        width: 'calc(100% / 3)',
        flexShrink: 1,
      },
      [TABLET_SCREEN_PORTRAIT]: {
        maxWidth: '290px',
      },
    },
    title: {
      fontSize: fontSizes[5],
      textAlign: 'center',
    },
    subTitle: {
      fontSize: fontSizes[5],
      textAlign: 'center',
    },
    text: {
      fontSize: fontSizes[2],
      textAlign: 'center',
    },
    icon: {
      width: '140px',
      height: '110px',
      marginTop: '40px',
      marginBottom: '30px',
    },
  },
  inputs: {
    baseInput: sx2CssTheme(basicTheme, {
      border: '1px solid',
      borderColor: 'black',
      borderRadius: '0px',
      width: '100%',
      py: 2,
      px: ['12px', '20px'],
      fontSize: [null, 0],
      height: '50px',
      '::placeholder': {
        color: colors.stoneGrey,
      },
      letterSpacing: 'inherit',
      ':disabled': {
        background: 'lightGreyFirst',
        cursor: 'default',
      },
      fontWeight: 900,
      background: 'transparent',
    }),
    select: {
      '-webkit-appearance': 'none',
      border: '1px solid',
      borderColor: 'black',
      borderRadius: 0,
      background: baseColors.white,
      width: '100%',
      fontSize: fontSizes[1],
      paddingTop: '13px',
      paddingBottom: '13px',
      paddingLeft: '10px',
      paddingRight: space[5],
      lineHeight: 'normal',
      appearance: 'none',
      height: '50px',
      '::-ms-expand': {
        display: 'none',
      },
      [media.tablet]: {
        paddingTop: '11px',
        paddingBottom: '11px',
        paddingLeft: '13px',
      },
    },
    error: {
      '.status-icon': {
        color: colors.error,
        right: '35px',
        left: 'auto',
        top: 'auto',
        bottom: '1px',
        ...icons.lg,
      },
      '.input': {
        borderColor: colors.error,
        paddingLeft: '12px',
        paddingRight: '45px',

        '~ .icon': {
          color: colors.error,
          right: '15px',
          left: 'auto',
          ...icons.lg,
        },

        [media.tablet]: {
          paddingLeft: '20px',
          paddingRight: '52px',
          '~ .icon': {
            right: '20px',
          },
        },
      },
    },
    success: {
      '.status-icon': {
        color: colors.success,
        right: '35px',
        left: 'auto',
        top: 'auto',
        bottom: '1px',
        ...icons.lg,
      },
      '.input': {
        borderColor: colors.success,
        paddingLeft: '12px',
        paddingRight: '45px',

        '~ .icon': {
          color: colors.success,
          right: '15px',
          left: 'auto',
          ...icons.lg,
        },

        [media.tablet]: {
          paddingLeft: '20px',
          paddingRight: '52px',
          '~ .icon': {
            right: '20px',
          },
        },
      },
    },
    searchInput: {
      '.input': {
        border: '1px solid transparent',
        borderRadius: '5px',
        fontSize: fontSizes[2],
        fontWeight: '500',
        paddingLeft: '44px',
        paddingRight: '40px',
        appearance: 'none',
        background: 'white',
        [media.tablet]: {
          paddingLeft: '56px',
          height: '58px',
        },
        '&::placeholder': {
          color: 'lightGrey',
        },
        '&:focus': {
          borderColor: '#808080',
        },
      },
      '.input-icon': {
        left: '11px',
        [media.tablet]: {
          left: '19px',
        },
      },
    },
    formSelectInput: {
      '.input': {
        minHeight: '50px',
        border: '1px solid black',
        backgroundColor: 'transparent',
        color: '#B9B8B5',
        fontSize: fontSizes[0],
        [media.tablet]: {
          fontSize: fontSizes[1],
        },
      },
      '.icon': {
        color: '#B9B8B5',
        left: '80px',
        bottom: '20px',
      },
    },
  },
  card: sxGroup({
    cardsSectionHeader: {
      marginBottom: space[5],
      h2: {
        fontSize: '15px',
        fontWeight: '400',
      },
    },
    cardSectionTotalCount: {
      fontSize: '12px',
      fontWeight: 900,
      color: colors.redWeb,
      marginLeft: 2,
    },
    teaserCard: {
      a: {
        '&:focus-visible': {
          outline: 'none',
          '&:before': {
            width: '100%',
            height: '100%',
            content: "''",
            outline: '5px solid',
            outlineOffset: '-5px',
            position: 'absolute',
            zIndex: 2,
            top: 0,
            left: 0,
            outlineColor: `redHover`,
          },
          img: {
            transform: 'scale(1.1)',
            transition: 'all 0.5s ease',
          },
          '.card-text': {
            '.icon': {
              transform: 'translateX(10px) rotate(180deg)',
            },
          },
        },
      },
      '.card-text .icon': {
        transition: 'transform 0.5s ease',
      },
      '.teaser-media img': {
        transition: 'all 0.5s ease',
      },
      '&:hover': {
        '.teaser-media img': {
          transform: 'scale(1.1)',
        },

        '.card-text': {
          '.icon': {
            transform: 'translateX(10px) rotate(180deg)',
          },
        },
      },
      '.card-title': {
        width: '100%',
        display: 'flex',
        fontSize: fontSizes[4],
        justifyContent: 'space-between',
        '.icon': {
          transition: 'all 0.5s ease',
          width: '24px',
          height: '24px',
        },
      },
      '.media-card-content': {
        paddingLeft: space[3],
        paddingBottom: space[3],
        paddingTop: space[3],
        bottom: 0,
        fontSize: '14px',
        top: 0,
        '.card-title, .card-subtitle': {
          fontSize: '22px',
          lineHeight: 1.2,
        },
        '.card-image-title, .card-subtitle': {
          maxWidth: '400px',
        },
        '.card-subtitle': {
          paddingBottom: space[2],
          ...text.groupHeading,
          fontSize: '15px',
        },
        '.chevron-icon': {
          marginLeft: '10px',
          alignSelf: 'flex-end',
          marginBottom: 0,
          flexShrink: 0,
        },
        '.disrupter-wrapper': {
          marginBottom: 'auto',
        },
      },
      '.teaser-media': {
        borderRadius: 'unset',
        maxHeight: '80vh',
        display: 'block',
        '&:hover': {
          color: 'inherit',
        },
      },
    },
  }),
  map: {
    pinWrapper: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid',
    },
    pinContent: {
      position: 'absolute',
      borderRadius: '4px',
      bottom: '6px',
      transform: 'translate(-50%, 0)',
      padding: '4px',
      cursor: 'pointer',
      boxShadow: shadows.cardShadow,
      color: 'white',
      fontSize: fontSizes[1],
      [media.tablet]: {
        fontSize: fontSizes[2],
      },
    },
    controls: sxGroup(mapControls),
  },
  text,
  link: {
    primary: {
      transition: transition.fast,
      '&:hover': {
        color: colors.redWeb,
      },
    },
    cta: {
      ...ctaStyles,
      '&:hover': {
        color: baseColors.white,
      },
      textAlign: 'center',
      px: 4,
    },
    ctaOnRedBg: {
      ...ctaStylesOnRedBg,
    },
    text: {
      color: colors.redWeb,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    allOffers: {
      fontSize: '18px',
      color: colors.redWeb,
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1',
      [MEDIA_TABLET]: {
        fontSize: '25px',
        alignItems: 'flex-start',
      },
      svg: {
        marginLeft: space[3],
        ...icons.lg,
        [MEDIA_TABLET]: {
          marginLeft: space[4],
          ...icons.xlg,
        },
      },
    },
    iconLink: {
      fontSize: '0px',
      letterSpacing: 'initial',
      padding: 1,
    },
    imgLink: {
      fontSize: '0px',
      letterSpacing: 'initial',
    },
    blockLink: {
      display: 'block',
      '&:hover': { color: 'initial' },
    },
    breadcrumbLink: {
      border: '2px solid rgba(0,0,0,0.05)',
      borderRadius: '5px',
      padding: '4px 8px 4px 8px',
      color: colors.stoneGrey,
      marginRight: '18px',
      textDecoration: 'underline',
      display: 'inline-block',
      marginBottom: space[2],
      ':hover': {
        ':after': {
          color: colors.stoneGrey,
        },
      },
      '&.breadcrumb-link': {
        display: 'inline-block',
      },
      '&.breadcrumb-link:not(:last-child):after': {
        display: 'inline-block',
        position: 'absolute',
        marginLeft: '17px',
      },
    },
  },
  buttons: sxGroup({
    primary: ctaStyles,
    secondary: {
      background: colors.white,
      color: colors.redWeb,
    },
    primaryOnRedBg: ctaStylesOnRedBg,
    secondaryOnRedBg: {
      ...ctaStylesOnRedBg,
      background: colors.redBurgundy,
      color: baseColors.white,
    },
    main: {
      ...ctaStyles,
    },
    cookieAccept: {
      ...ctaStylesOnRedBg,
      width: ['100%', 'auto'],
      flexShrink: '0',
      mr: [null, 6],
    },
    flat: {
      ...ctaStyles,
      transition: transition.fast,
      width: 'auto',
      '&:hover': {
        background: colors.redWeb,
      },
      '&:active': {
        background: colors.redBurgundy,
      },
    },
    tagBtn: {
      background: baseColors.white,
      border: '1px solid',
      borderColor: colors.redWeb,
      borderRadius: '5px',
      color: colors.redWeb,
      fontSize: fontSizes[1],
      lineHeight: '22px',
      padding: '9px 26px 11px 26px',
      fontWeight: 500,
      height: '42px',
      margin: '1px',
      '&:hover': {
        background: colors.redHover,
        outlineColor: colors.redHover,
        color: baseColors.white,
        textDecoration: 'underline',
      },
      '&:focus': {
        background: colors.redActive,
        outline: '1px solid',
        outlineColor: colors.redHover,
        border: '1px solid',
        borderColor: colors.redActive,
        color: baseColors.white,
      },
      '&:active': {
        background: colors.redActive,
        outlineColor: colors.redActive,
        color: baseColors.white,
      },
    },
    iconBtn: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: space[1],
      fontSize: '0px',
      color: 'inherit',
      letterSpacing: 'initial',
      lineHeight: 1.3,
      svg: {
        margin: 0,
      },
      '&:hover,&:focus,&:active': {
        backgroundColor: colors.redHover,
        borderColor: colors.redHover,
        svg: {
          fill: colors.white,
        },
      },
    },
    linkBtn: {
      background: 'transparent',
      color: colors.redWeb,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      '.icon-link': {
        marginRight: spaces[3],
      },
      '.text-link, .load-more-text': {
        display: 'flex',
        color: colors.redWeb,
        position: 'relative',
        paddingBottom: '10px',
        alignItems: 'center',
        lineHeight: '22px',
        '&::after': {
          position: 'absolute',
          bottom: '6px',
          left: 0,
          display: 'block',
          height: '2px',
          content: '""',
          width: '100%',
          backgroundColor: colors.redWeb,
          opacity: 0.2,
          transition: '0.2s ease-in',
        },
        '&::before': {
          position: 'absolute',
          bottom: '6px',
          left: 0,
          display: 'block',
          height: '2px',
          content: '""',
          width: '2px',
          backgroundColor: colors.redWeb,
        },
        '&:hover': {
          '&::after': {
            opacity: 1,
            backgroundColor: colors.redHover,
          },
        },
      },
      '&:hover,&:focus,&:active': {
        textDecoration: 'none',
        '.text-link, .load-more-text': {
          color: colors.redHover,
          '&::after': {
            opacity: 1,
            backgroundColor: colors.redHover,
          },
          '.icon': {
            fill: colors.redHover,
          },
        },
        '&:active': {
          color: colors.redActive,
          '.icon': {
            fill: colors.redActive,
          },
        },
      },
    },
    accordionBtn: {
      textAlign: 'left',
      background: baseColors.white,
      color: baseColors.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 0,
      borderBottom: '1px solid',
      borderColor: colors.lightGreyFirst,
      width: '100%',
      padding: `${space[3]} ${space[0]}`,
      fontSize: '18px',
      fontWeight: 'bold',
      letterSpacing: '0.7px',
      [media.tablet]: {
        fontSize: fontSizes[3],
        lineHeight: 1.3,
      },
    },
    productCardBtn: {
      ...ctaStyles,
      borderRadius: '0px 0px 3px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '22px',
      height: '38px',
      padding: 0,
      [media.tablet]: {
        width: '26px',
        height: '42px',
      },
      svg: {
        height: '12px',
      },
    },
    applyButton: {
      ...ctaStyles,
      width: '100%',
      borderRadius: '0px',
    },
    floatButton: {
      color: colors.white,
      backgroundColor: colors.redWeb,
      padding: '10px 15px',
      borderRadius: '5px 5px 0 0',
      lineHeight: 1,
      textAlign: 'center',
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.redHover,
        textDecoration: 'underline',
      },
      '&:focus, &:active': {
        backgroundColor: colors.redActive,
        textDecoration: 'underline',
      },
      '.request-button-caption': {
        fontSize: fontSizes[1],
        fontWeight: 450,
      },
    },
    headerBtn: {
      background: colors.paperGrey,
      borderRadius: '5px',
      width: '42px',
      height: '42px',
      svg: {
        width: '20px',
      },
    },
  }),
  requestButton: {
    floating: {
      position: 'fixed',
      top: '50%',
      right: '-30px',
      transformOrigin: 'bottom center',
      transform: 'translateY(-50%) translateX(50%) rotate(-90deg)',
      zIndex: 9,
      '.request-button': {
        paddingBottom: '43px',
      },
    },
    static: {
      display: 'flex',
      flexDirection: 'row-reverse',
      '.request-button': {
        padding: '12px 30px 15px',
        borderRadius: 'unset',
        lineHeight: 1.2,
        textDecoration: 'none',
        '&:hover': {
          color: colors.white,
          backgroundColor: colors.redHover,
          textDecoration: 'underline',
        },
        '&:focus, &:active': {
          color: colors.white,
          backgroundColor: colors.redActive,
          textDecoration: 'underline',
        },
      },
    },
  },
  responsiveMediaGallery: sxGroup({
    carouselWrapper: {
      overlay: {
        position: 'relative',
        width: '100%',
        ':after': {
          content: "''",
          display: 'block',
          width: '100%',
          paddingBottom: '54%',
          pointerEvents: 'none',
        },
        '.image': {
          maxWidth: ['100%', '85%'],
          width: '100%',
          objectFit: `cover`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateY(-50%) translateX(-50%)',
        },
        '.slider-list, .slider-slide': {
          height: '100% !important',
          width: '100% !important',
        },
        '.slide-info': {
          left: [0, 4],
          right: [0, 4],
          pl: [3, '44px'],
          '&:before': {
            right: 0,
          },
        },
      },
      preview: {
        position: 'relative',
        width: '100%',
        ':after': {
          content: "''",
          display: 'block',
          width: '100%',
          paddingBottom: '54%',
          pointerEvents: 'none',
        },
        '.slider-list, .slider-slide': {
          height: '100% !important',
          width: '100% !important',
        },
        '.disable': {
          display: 'none',
        },
      },
    },
    carouselBody: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '.slider-control-centerleft': {
        left: ['15px!important', '20px!important'],
      },
      '.slider-control-centerright': {
        right: ['15px!important', '20px!important'],
      },
      '.slider-control-bottomright': {
        zIndex: 2,
      },
    },
    chevron: {
      cursor: 'pointer',
      width: '45px',
      height: '45px',
      color: 'white',
      display: ['none', 'block'],
      filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7))',
    },
  }),
  brandLogos: sxGroup({
    items: {
      px: [2, 0],
      py: [6, 8],
      mb: '0px',
      borderBottom: 'none',
    },
    imageLink: {
      padding: space[1],
      '&:not(:last-child)': {
        marginRight: space[3],
        [media.tablet]: {
          marginRight: '40px',
        },
      },
      '.brand-image': {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  }),
  tooltip: {
    whiteSpace: 'nowrap',
    top: '-150%',
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'white',
    borderRadius: radii.default,
    position: 'absolute',
    display: 'block',
    visibility: 'hidden',
    boxShadow: shadows.tooltip,
    padding: '7px 11px',
    fontSize: fontSizes[1],
    fontWeight: 'bold',
    ':after': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '10px 7px 0 7px',
      borderColor: 'white transparent transparent transparent',
      position: 'absolute',
      bottom: '-9px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  header: {
    wrap: {
      borderBottom: '1px solid',
      borderColor: colors.borderColor,
      position: 'relative',
    },
    logo: {
      flexShrink: 0,
      alignSelf: 'flex-start',
      marginBottom: space[4],
      img: {
        maxWidth: '208px',
        height: '64px',
        display: 'block',
        [media.tablet]: {
          maxWidth: '267px',
          height: '82px',
        },
      },
    },
    body: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '1480px',
      minHeight: '60px',
      alignItems: 'center',
      padding: `0 ${space[4]}`,
      margin: '0 auto',
      [media.tablet]: {
        minHeight: 'none',
        paddingRight: 0,
      },
    },
  },
  footer: {
    heading: {
      fontSize: fontSizes[3],
      marginBottom: space[2],
      textAlign: 'center',
      letterSpacing: '0.8px',
      [media.tablet]: {
        fontSize: '25px',
        marginBottom: space[3],
        textAlign: 'start',
      },
    },
  },
  notifications: sxGroup({
    mantainanceNotification: {
      position: 'fixed',
      bottom: ['0px', 5],
      width: '100%',
      maxWidth: '770px',
      borderRadius: 'default',
      zIndex: '10',
      overflow: [null, 'hidden'],
      boxShadow: 'notificationShadow',
      '.notification-body': {
        backgroundColor: 'white',
        color: 'black',
      },
      ...text.richText,
      'p:last-child': {
        mb: 0,
      },
      '.warning-notification-button': {
        border: 'none',
        width: 'auto',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 0,
      borderBottom: '1px solid',
      borderColor: colors.lightGreyFirst,
      paddingTop: space[1],
      paddingBottom: space[1],
      height: '32px',
      overflow: 'hidden',
      'a:hover': {
        color: colors.redWeb,
      },
      'div *, div p': {
        fontSize: fontSizes[1],
      },
    },
    notificationInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      overflow: 'hidden',
      'a:hover': {
        color: 'redWeb',
      },
    },
    notificationInfoContent: {
      color: colors.black,
      '*, p': {
        fontSize: fontSizes[0],
        letterSpacing: 1.2,
      },
      a: {
        textDecoration: 'underline',
      },
    },
  }),
  helpOverlay: sxGroup({
    wrapper: {
      '&:not(:last-child)': {
        mr: [0, '18px'],
      },
    },
    schedule: {
      mt: [1, 3],
      '> p': {
        fontSize: [1, 3],
        fontWeight: [null, 'bold'],
      },
    },
  }),
  agency: sxGroup({
    mapSearch: {
      wrapper: {
        mb: 6,
      },
      box: {
        display: 'flex',
        backgroundColor: colors.paperGrey,
        zIndex: 2,
        '.base-input-wrapper': {
          backgroundColor: colors.paperGrey,
          position: 'relative',
        },
        '.input': {
          borderRadius: 'default',
          border: `1px solid ${colors.paperGrey}`,
          backgroundColor: colors.white,
        },
        '.map-search-controls': {
          width: ['80%', 'calc(100% / 3)'],
          maxWidth: '380px',
          flexShrink: [1, 0],

          '.input-icon': {
            left: space[3],
            zIndex: 12,
          },
          '.input': {
            paddingLeft: space[7],
          },
          '.autocomplete-dropdown': {
            position: 'absolute',
            left: 0,
            backgroundColor: colors.paperGrey,
            zIndex: 11,
            borderRadius: 'default',
            overflow: 'hidden',
            '> div': {
              border: `none`,
            },
          },
        },
      },
      geo: {
        ml: [null, '40px'],
        fontSize: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '.icon': {
          marginRight: '10px',
        },
      },
    },
  }),
  hero: sxGroup({
    wrap: {
      height: ['100vh', '800px'],
      '&.home-hero-section, &.home-hero-section .image-media': {
        height: '100vh',
      },
      '&.is-pdp&:not(.is-hotel)': {
        height: ['100vh', 'calc(100vh + 200px)'],
      },
      '.hero-media-wrapper:after': {
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0.5) 100%)',
      },
    },
  }),
  counter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '50%',
    fontSize: fontSizes[2],
    boxShadow: shadows.large,
    fontWeight: 'normal',
    position: 'absolute',
  },
  languageControlWrapper: sxGroup({
    mb: space[0],
    alignSelf: 'center',
    width: ['100%', 'auto'],
    borderTop: ['1px solid', 'none'],
    borderColor: 'borderColor',
    pt: [space[2], '3px'],
    ml: [null, space[7]],
    '.icon': {
      width: ['15px', '13px'],
      height: ['10px', '8px'],
      bottom: ['20px', '18px'],
      right: '6px',
      top: ['15px', '18px'],
    },
    '.input': {
      textTransform: 'uppercase',
      fontSize: [null, fontSizes[2]],
    },
  }),
  forms: sxGroup({
    unsubscribeForm: {
      form: {
        maxWidth: '750px',
        pb: ['30px', '90px'],
        mx: 'auto',
        '.unsubscribe-text-input': {
          mb: 4,
          '& .label': {
            display: 'none',
          },
        },
      },
      submitBtn: {
        textAlign: [null, `right`],
        '.form-submit-button': {
          width: ['100%', 'auto'],
        },
      },
      checkboxes: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        mb: [3, 5],
        '.checkbox': {
          pl: 0,
          mb: [3, 0],
          fontSize: '14px',
          fontWeight: 900,
          color: '#000000',
        },
      },
    },
    filtersForm: {
      display: ['grid', 'flex'], // fix firefox sticky covering issue
      flexDirection: 'column',
      maxHeight: '100%',
    },
    fullNameWrapper: {
      display: 'flex',
      flexDirection: ['column', 'row'],
      gap: [null, '20px'],
      mb: [null, 5],
      '.label': {
        fontSize: '0px',
        margin: '0px',
      },
    },
    bannerFormWrap: {
      backgroundColor: [null, 'bgLightBlue'],
      pt: [0, '127px'],
      position: [null, 'relative'],
      display: [null, 'flex'],
      flexDirection: [null, 'column'],
      '.tabControls': {
        height: ['67px', '80px'],
      },
    },
    bannerForm: {
      position: [null, 'absolute'],
      top: [null, '45px'],
      maxWidth: [null, '1170px'],
      width: [null, '100%'],
      left: [null, '50%'],
      transform: [null, 'translate(-50%)'],
      zIndex: '3',
    },
    staticBannerForm: {
      mx: 'auto',
    },
    shortBannerForm: {
      position: 'static',
      transform: 'none',
      borderRadius: 'default',
      boxShadow: '0 2px 4px 2px rgba(0,0,0,0.06)',
      mb: [null, '20px'],
      '.form-container': {
        textAlign: 'center',
        pt: [3, '10px'],
        px: [null, '22px'],
        pb: [null, '10px'],
        '& .input': {
          height: [null, '48px'],
          fontSize: [null, 2],
        },
        '& .input ~ .icon': {
          left: [null, '15px'],
        },
        '.search-button': {
          py: [null, '14px'],
        },
      },
      '.travelTypes': {
        mb: 0,
      },
      '.tabControls': {
        height: [null, '54px'],
        px: 4,
      },
      '.travelTypeItem': {
        flex: '0 0 auto',
      },
    },
    emailForm: {
      maxWidth: '750px',
      mx: 'auto',
      pb: ['30px', '90px'],
      '.error': {
        mt: -3,
        mb: 3,
        fontSize: '12px',
      },
      label: {
        fontSize: '0px',
        margin: '0px',
      },
      '.customRadioInput': {
        border: '1px solid black',
        background: 'transparent',
        width: '16px',
        height: '16px',
      },
      '.label:hover .input ~ .customRadioInput': {
        bg: 'transparent',
      },
      '.input:checked ~ .customRadioInput': {
        border: '1px solid black',
        position: 'relative',
        background: 'white',
        '&:after': {
          content: '""',
          display: 'block',
          width: '10px',
          height: '10px',
          bg: 'redWeb',
          borderRadius: '50%',
          ml: '2px',
          mt: '2px',
        },
      },
      '.radio-group-container label': {
        fontWeight: 900,
        fontSize: 0,
        mr: 5,
        span: {
          mb: 0,
        },
      },
      '.radio-group-container': {
        mb: 2,
        position: 'relative',
        '& .label:last-child': {
          position: 'relative',
          '&:after': {
            content: '"*"',
            position: 'absolute',
            display: 'block',
            color: 'redWeb',
            fontSize: 5,
            fontWeight: 'normal',
            top: '-10px',
            right: '-18px',
          },
        },
      },
      '& .text-input': {
        position: 'relative',
        '&:after': {
          content: '"*"',
          position: 'absolute',
          display: 'block',
          color: 'redWeb',
          fontSize: 5,
          top: '-10px',
          right: '-15px',
        },
      },
      '.mandatory-information-description': {
        fontSize: '10px',
        position: 'relative',
        display: 'block',
        pl: 3,
        mt: 2,
        mb: 3,
        '&:after': {
          content: '"*"',
          position: 'absolute',
          display: 'block',
          color: 'redWeb',
          fontSize: 5,
          top: '-10px',
          left: '0px',
        },
      },
      '.radio-group-input': {
        mb: 3,
        div: {
          display: 'flex',
          flexDirection: 'row',
        },
        '.label': {
          mr: 0,
          pl: 0,
        },
        '.label:first-child': {
          mr: 7,
        },
      },
      '.text-input': {
        marginBottom: [5, 0],
      },
      submitButtonWrapper: {
        display: 'flex',
        flexDirection: ['column', 'row-reverse'],
        justifyContent: 'space-between',
        p: {
          fontSize: '12px',
          maxWidth: [null, '400px'],
        },
        '.form-submit-button': {
          width: ['100%', 'auto'],
          float: [null, 'right'],
          marginLeft: 'auto',
          mb: [4, 0],
        },
      },
    },
    label: {
      pl: ['5px'],
      color: 'stoneGrey',
      mb: [1, 2],
    },
    customCheckboxControl,
  }),
  autocomplete: sxGroup({
    mobileModal: {
      '.modal-wrapper': {
        backgroundColor: colors.paperGrey,
      },
      '.modal-header': {
        borderColor: colors.cardsDelimiter,
      },
      '.input': {
        borderRadius: 'default',
        border: `1px solid ${colors.paperGrey}`,
        backgroundColor: colors.white,
      },
    },
    tagBlock: {
      flexDirection: 'column',
      flex: '1 auto',
      marginY: ['15px', 0],
      maxHeight: ['100%', '50vh'],
      overflowY: ['none', 'auto'],
      px: [null, 3],
      pt: [null, '14px'],
      width: [null, '100%'],
      backgroundColor: [null, 'background'],
      boxShadow: [null, '0 3px 5px 1px rgba(0,0,0,0.06)'],
      border: ['none', '2px solid'],
      borderColor: [null, colors.paperGrey],
      borderTop: 'none',
      borderRadius: 'default',
    },
    inputWrapStyles: {
      borderRadius: 'default',
      border: `1px solid ${colors.paperGrey}`,
      backgroundColor: 'white',
      flexWrap: 'wrap',
      alignItems: 'center',
      p: [1, 0],
    },
    suggestionList: {
      borderBottom: '1px solid ',
      borderColor: 'paperGrey',
      '.autocomplete-option > .-icon': {
        mr: '14px',
      },
      pb: 2,
    },
    tagWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      py: 1,
      px: 2,
      border: '1px solid',
      borderColor: 'borderInput',
      borderRadius: 'default',
      backgroundColor: 'background',
      m: 1,
      mr: 0,
      color: 'stoneGrey',
    },
    autocompleteRemoveButtonStyles: {
      ml: 1,
      color: 'darkGray',
    },
  }),
  catalogs: sxGroup({
    catalogsHeading: {
      mb: [4, 5],
      mx: [4, 0],
    },
    catalogsOrderHeading: {
      mb: [0, 3],
    },
    catalogFormSubmitButton: {
      width: ['100%', '200px'],
      position: 'relative',
    },
    catalogForm: {
      '.error': {
        fontSize: fontSizes[0],
      },
      'fieldset+.error': {
        mt: '-10px',
      },
      '.radio-group-container label': {
        fontSize: 0,
        mr: 5,
        span: {
          mb: 0,
        },
      },
      '.radio-group-container': {
        mb: 2,
        position: 'relative',
        '& .label:last-child': {
          position: 'relative',
          '&:after': {
            content: '"*"',
            position: 'absolute',
            display: 'block',
            color: 'redWeb',
            fontSize: 5,
            top: '-10px',
            right: '-18px',
          },
        },
      },
      label: {
        fontSize: '0px',
        margin: '0px',
      },
      '.customRadioInput': {
        border: '1px solid black',
        background: 'white',
        width: '16px',
        height: '16px',
      },
      '.label:hover .input ~ .customRadioInput': {
        bg: 'white',
      },
      '.input:checked ~ .customRadioInput': {
        border: '1px solid black',
        position: 'relative',
        background: 'white',
        '&:after': {
          content: '""',
          display: 'block',
          width: '10px',
          height: '10px',
          bg: 'redWeb',
          borderRadius: '50%',
          ml: '2px',
          mt: '2px',
        },
      },
      '.order-catalogs-checkbox': {
        fontSize: '12px',
      },
    },
    catalogsFormContent: {
      display: 'flex',
      mx: '-5px',
      flexWrap: 'wrap',
      mb: ['20px', 0],
    },
    catalogsListWrap: {
      p: '6px',
      pb: 1,
      background: 'none',
      borderColor: '#dddddd',
      a: {
        display: 'block',
        fontSize: '0px',
      },
      borderRadius: '0px',
      '.content': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'redWeb',
        p: 1,
        '.catalog-links': {
          display: 'flex',
          justifyContent: 'space-between',
        },
        '.link-wrapper': {
          mr: '5px',
        },
        a: {
          display: 'inherit',
        },
      },
    },
    catalogList: {
      display: 'grid',
      flexWrap: 'wrap',
      gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr'],
      mx: [4, 0],
      gap: ['0 20px', '10px 20px'],
      mb: ['50px', '45px'],
    },
    catalogsOrderedCatalogsList: {
      display: 'grid',
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      rowGap: ['20px', 3],
      columnGap: ['18px', '30px'],
      mb: [2, 5],
    },
    catalogCardWrap: {
      mb: ['30px', 3, 3],
      a: {
        width: '100%',
      },
    },
    catalogsDownloadLink: {},
    catalogCardInfoFormWrap: {
      display: 'flex',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      py: ['10px', 3],
      maxWidth: '430px',
      width: '100%',
      mb: [5, '50px'],
      pr: 2,
      flexShrink: '0',
      '.imageWrap': {
        flexShrink: 0,
      },
    },
    catalogDeleteButtonWrap: {
      textAlign: 'end',
      pt: [3, 2],
      lineHeight: 1,
      color: 'redWeb',
      button: {
        borderRadius: '5px',
        p: 1,
        '&:hover': {
          color: 'white',
        },
      },
    },
    catalogCardInfoImage: {
      display: 'block',
      width: ['75px', '87px'],
      height: '100%',
      objectFit: `cover`,
      mr: 3,
    },
    catalogCardInfoFormContent: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      '.wrapper': {
        width: '100%',
      },
      '.heading': {
        fontSize: [2, 3],
        fontWeight: 'normal',
        mb: [1, 2],
      },
      '.catalogDates': {
        fontSize: [1, 2],
        color: 'stoneGrey',
      },
    },
  }),
  modals: sxGroup({
    notificationCardWrap: notificationCardWrapCss,
    modalContentStyles: {
      flex: '1',
      flexDirection: 'column',
      p: 4,
      pb: 5,

      '> *': {
        flexShrink: '0',
      },
    },
    catalogErrorModal: {
      '.modal-body': {
        p: 4,
      },
      '.modal-wrapper': {
        width: '100%',
        maxWidth: '770px',
      },
      '.modal-shadow': {
        backgroundColor: colors.modalShadow,
      },
      '.modal-header': {
        color: 'white',
        background: colors.redBurgundy,
      },
      '.modal-title': {
        mr: 4,
        ...text.groupHeading,
      },
    },
    wideModalWrapper: {
      maxWidth: [null, '970px'],
      borderRadius: ['roundedTop', '0px'],
      height: [null, 'auto'],
    },
    wideModalHeader: {
      py: [2, '14px'],
      px: ['20px', '36px'],
    },
    actionlessModalWrapper: {
      maxWidth: [null, '970px'],
      borderRadius: 'default',
      mx: [4, 0],
      alignSelf: 'center',
    },
    smallModalWrapper: {
      width: ['100%', 'auto'],
      [media.ie]: { height: 'auto' },
      '.modal-body': {
        backgroundColor: baseColors.white,
      },
      '.modal-title': {
        ...text.groupHeading,
        mr: 4,
      },
    },
    commonModalBody: {
      px: [4, '40px'],
      pt: [4, '30px'],
      pb: [4, '40px'],
    },
    notificationCardWrapWithError: {
      ...notificationCardWrapCss,
      paddingTop: 0,
      boxShadow: 'none',
    },
    failureModal: {
      '.modal-wrapper': {
        maxWidth: '770px',
      },
      '.modal-body': {
        backgroundColor: '#ffffff',
      },
    },
    externalOverlay: {
      '.modal-wrapper': {
        height: '100%',
        backgroundColor: colors.paperGrey,
        borderRadius: 'unset',
      },
      '.modal-header': {
        color: colors.white,
        border: 'none',
        paddingTop: space[3],
        paddingBottom: space[2],
        [media.tablet]: {
          paddingTop: '40px',
        },
        '.icon': {
          width: '30px',
          height: '30px',
          color: 'black',
        },
      },
      '.modal-body': {
        paddingBottom: '46px',
        [media.tablet]: {
          padding: '0 120px 46px 120px',
        },
      },
      iframe: {
        height: '100%',
        border: 'none',
      },
    },
    forgotPasswordModal: {
      '.modal-wrapper': {
        maxWidth: [null, '770px'],
      },
    },
  }),
  menu: sxGroup({
    nav: {
      fontSize: fontSizes[1],
      textTransform: 'none',
      fontWeight: 400,
      lineHeight: '1.4',
      color: baseColors.black,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    headerMenu: {
      width: ['100%', 'auto'],
      backgroundColor: baseColors.white,
      mb: [3, '0px'],
      flex: [1, '0 1 auto'],
    },
    navBarItem: {
      maxWidth: [null, '270px'],
      width: '100%',
      mr: [null, '40px'],
    },
    navBarLink: {
      py: ['20px', '12px'],
      px: [1, 2],
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      mb: '-1px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: ['14px', 1],
      lineHeight: [null, '1.3'],
      '.icon': {
        color: 'redWeb',
      },
      textTransform: ['uppercase', 'initial'],
    },
    subLinksListWrap: {
      position: 'absolute',
      backgroundColor: colors.background,
      width: '100%',
      maxWidth: '1285px',
      top: '101%',
      transform: 'translateX(-50%)',
      left: '50%',
      py: 7,
      opacity: '0',
      zIndex: '-1000',
      '&:hover, &:focus-within, &:focus, &:focus-visible': {
        zIndex: '999',
        opacity: '1',
      },
    },
    subLinksList: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      maxWidth: '1170px',
      width: '100%',
      maxHeight: '480px',
      mx: 'auto',
    },
    menuLinkItem: {
      px: '14px',
      borderTop: '2px solid',
      borderColor: 'transparent',
      '&:hover + .sub-links-list, &:focus-within + .sub-links-list': {
        display: 'flex',
        zIndex: '999',
        opacity: '1',
      },
      height: [null, '70px'],
      '> a': {
        minHeight: [null, '70px'],
      },
    },
  }),
  mediaGallery: sxGroup({
    mediaGalleryStyles: {
      '.slider-control-centerleft': {
        left: '25px!important',
      },
      '.slider-control-centerright': {
        right: '25px!important',
      },
    },
    mediaGalleryModal: {
      '.lightbox-media-gallery': {
        height: '100%',
      },
      '.modal-wrapper': {
        maxHeight: '100%',
        bg: 'darkGrey',
      },
      '.modal-header': {
        color: baseColors.white,
        border: 'none',
        pr: [5, '30px'],
        position: 'fixed',
        width: '100%',
        bg: '#2C2D2A80',
        zIndex: 2,
        '.close-button': {
          '&:hover, &:focus, &:active': {
            '.icon': {
              fill: 'currentColor',
            },
          },
        },
        [MOBILE_SCREEN_LANDSCAPE]: {
          position: 'fixed',
          zIndex: 2,
          bg: 'rgba(46,46,43,.5)',
          px: 4,
          width: '100%',
        },
        button: {
          bg: 'transparent',
        },
      },
      '.modal-body': {
        display: 'block',
        width: '100%',
        height: '100%',
        bg: 'darkGrey',
        overflow: 'hidden',
        [MOBILE_SCREEN_LANDSCAPE]: {
          mb: 0,
        },
      },
      '.slide-info': {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        color: baseColors.white,
        flexDirection: ['column-reverse', 'row-reverse'],
        justifyContent: 'flex-end',
        py: 3,
        mx: [0, 'auto'],
        bg: '#2C2D2A80',
        px: [4, 'calc(15% / 2)'],
        fontSize: fontSizes[0],
        '&:empty': {
          background: 'transparent',
        },
        '&:before': {
          display: 'none',
        },
        '.slide-number': {
          margin: 0,
          pl: 0,
          pr: '30px',
          mb: ['15px', null],
          color: baseColors.white,
          position: 'fixed',
          top: [3, 4],
          left: [4, 'calc(15% / 2)'],
        },
        '.slide-title': {
          fontWeight: 900,
        },
      },
      '.noDescription': {
        p: 0,
      },
      '.single-item': {
        px: [null, 'calc(15% / 2)'],
      },
      '.single-item, .general-item': {
        '.image': {
          height: '100%',
          width: '100%',
          objectFit: 'contain',
          [MOBILE_SCREEN_LANDSCAPE]: {
            px: '40px',
          },
        },
      },
    },
  }),
  boosters: sxGroup({
    boosterWrap: {
      display: 'flex',
      width: '100%',
      padding: [`0 ${space[2]}`, 0],
      marginBottom: space[2],
      backgroundColor: colors.bgDarkBlueSecond,
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      borderRadius: radii.default,
      color: 'white',
      '.booster-text': {
        fontSize: [null, '30px'],
        pl: [2, '30px'],
        pr: 2,
        py: [2, 3],
        lineHeight: '1.3',
        variant: 'text.richText',
        'p:last-child': {
          mb: 0,
        },
        a: {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },
    },
  }),
  mediaItem: sxGroup({
    cardContent: {
      px: 4,
      pb: ['30px', 4],
      color: 'white',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
      '.card-title': {
        fontSize: [3, '30px'],
      },
      '.card-subtitle': {
        fontSize: [2, 3],
      },
      '.wishlist-icon': {
        position: 'absolute',
        right: '16px',
        top: '16px',
        width: '26px',
        height: '26px',
        color: 'white',
        cursor: 'pointer',
      },
      '.button-text': {
        display: 'none',
      },
    },
  }),
  shareForm: {},
});

export {
  baseColors,
  colors,
  shadows,
  transition,
  media,
  fontSizes,
  space,
  radii,
};

// @ts-ignore
export default theme;
