import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrCoordinatesFragmentDoc } from 'schemas/fragment/geo/fdrCoordinates.generated';
export type FdrAdventureTravelRouteFragment = {
  __typename?: 'FdrAdventureTravelRoutePoint';
  locationName: string;
  coordinates: {
    __typename?: 'FdrCoordinates';
    latitude: number;
    longitude: number;
  };
};

export const FdrAdventureTravelRouteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAdventureTravelRoute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAdventureTravelRoutePoint' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'locationName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCoordinates' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrCoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
