import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import FdrGeoTripsPinsProducts from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins-products';
import {
  findUniqProductRoutePins,
  TProductPin,
} from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import FdrGeoProductsRoute from 'components/domain/fdr-geo/route/fdr-geo-products-route';
import FdrGeoTripsList from 'components/domain/fdr-geo/trips-list/fdr-geo-trips-list';
import FdrResetMapButton from 'components/domain/fdr-geo/trips/fdr-geo-trips-reset-map-button';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import FdrProductRouteMapModalBodyContainer from './fdr-product-route-map-modal-body-container';
import { TFdrRouteMapProduct } from './fdr-product-route-map.types';
import {
  ProductRouteMapModalDesktop,
  ProductRouteMapModalMobile,
} from './fdr-product-route-modal.styles';

interface IProductRouteMapModalProps {
  id: string;
  products: Omit<TFdrRouteMapProduct, 'bookmarked' | 'mainText'>[];
  isMapOpened: boolean;
  onClose: () => void;
  title?: string;
}

export const FdrProductRouteMapModal: React.FC<IProductRouteMapModalProps> = ({
  products,
  isMapOpened,
  onClose,
  id,
  title,
}) => {
  const [t] = useTranslation(['common', 'search']);
  const { mobile } = useDeviceType();

  const [activeRoutePoint, setActiveRoutePoint] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const [clusterMarkers, setClusterMarkers] = useState(null);
  const [activeTrip, setActiveTrip] = useState(null);
  const [animateModal, setAnimateModal] = useState(false);

  const hasWindow = typeof window !== 'undefined';
  const windowHeight = hasWindow ? `${window.innerHeight.toString()}px` : '';

  useEffect(() => {
    setAnimateModal(isMapOpened);
  }, [isMapOpened]);

  const productPins: Map<string, TProductPin> = useMemo(
    () =>
      findUniqProductRoutePins(
        products.map(p => ({ ...p, routePoints: p.routePoints ?? [] }))
      ),
    [products]
  );

  const resetMap = () => {
    setActiveRoutePoint(null);
    setActiveProduct(null);
    setClusterMarkers(null);
    setActiveTrip(null);
  };

  const MapModal = mobile
    ? ProductRouteMapModalMobile
    : ProductRouteMapModalDesktop;

  return (
    <MapModal
      windowHeight={windowHeight}
      animateModal={animateModal}
      name="map"
      title={title ? title : t('product.routeMap.modalTitle')}
      show={isMapOpened}
      onClose={() => {
        onClose();
        resetMap();
      }}
    >
      <FdrGeoTripsContext.Provider
        value={{
          activeRoutePoint,
          activeProduct,
          setClusterMarkers,
          clusterMarkers,
          setActiveRoutePoint,
          setActiveProduct,
          activeTrip,
          setActiveTrip,
          routePoints: new Map(),
          productPins,
          isFullScreen: true,
        }}
      >
        <FdrGeoTripsList />
        <FdrProductRouteMapModalBodyContainer key="product-route-map">
          <FdrGeoTripsPinsProducts id={id} />
          <FdrGeoProductsRoute />
          <FdrResetMapButton onClick={resetMap} />
        </FdrProductRouteMapModalBodyContainer>
      </FdrGeoTripsContext.Provider>
    </MapModal>
  );
};
