import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type GetFdrHelpOverlayQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetFdrHelpOverlayQuery = {
  __typename?: 'Query';
  fdrHelpOverlay?: {
    __typename?: 'FdrHelpOverlay';
    id: string;
    boxes?: Array<
      | {
          __typename?: 'FdrHelpOverlayBox';
          id: string;
          mainText: string;
          mainTitle: string;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
          icon: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          };
        }
      | {
          __typename?: 'FdrHelpOverlayBoxChat';
          id: string;
          mainText: string;
          mainTitle: string;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
          icon: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          };
        }
      | {
          __typename?: 'FdrHelpOverlayBoxPhone';
          id: string;
          mainText: string;
          mainTitle: string;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
          icon: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          };
        }
    > | null;
  } | null;
};

export type FdrHelpOverlayBoxFragment = {
  __typename?: 'FdrHelpOverlayBox';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
  icon: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
};

export type FdrHelpOverlayBoxChatFragment = {
  __typename?: 'FdrHelpOverlayBoxChat';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
  icon: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
};

export type FdrHelpOverlayBoxPhoneFragment = {
  __typename?: 'FdrHelpOverlayBoxPhone';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
  icon: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
};

export const FdrHelpOverlayBoxFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBox' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBox' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '55' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '100' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '70' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayBoxChatFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBoxChat' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBoxChat' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '55' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '100' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '70' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayBoxPhoneFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBoxPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBoxPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '55' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '100' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '70' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FILL' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetFdrHelpOverlayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFdrHelpOverlay' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHelpOverlay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBox' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBoxChat' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBoxPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHelpOverlayBoxFragmentDoc.definitions,
    ...FdrHelpOverlayBoxChatFragmentDoc.definitions,
    ...FdrHelpOverlayBoxPhoneFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetFdrHelpOverlayQuery__
 *
 * To run a query within a React component, call `useGetFdrHelpOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFdrHelpOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFdrHelpOverlayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFdrHelpOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFdrHelpOverlayQuery,
    GetFdrHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFdrHelpOverlayQuery,
    GetFdrHelpOverlayQueryVariables
  >(GetFdrHelpOverlayDocument, options);
}
export function useGetFdrHelpOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFdrHelpOverlayQuery,
    GetFdrHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFdrHelpOverlayQuery,
    GetFdrHelpOverlayQueryVariables
  >(GetFdrHelpOverlayDocument, options);
}
export type GetFdrHelpOverlayQueryHookResult = ReturnType<
  typeof useGetFdrHelpOverlayQuery
>;
export type GetFdrHelpOverlayLazyQueryHookResult = ReturnType<
  typeof useGetFdrHelpOverlayLazyQuery
>;
export type GetFdrHelpOverlayQueryResult = Apollo.QueryResult<
  GetFdrHelpOverlayQuery,
  GetFdrHelpOverlayQueryVariables
>;
