import { QueryResult } from '@apollo/client';
import type { ApolloQueryResult } from '@apollo/client/core';
import React from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TGeoItem } from 'components/domain/fdr-page-components/recommenders/geo/fdr-geo-recommender-item';
import { FdrGeoRecommenderFragment } from 'schemas/fragment/recommenders/geo/fdr-geo-recommender.generated';
import {
  FdrGetGeoComponentQuery,
  useFdrGetGeoComponentQuery,
} from 'schemas/query/component/fdr-get-geo-component.generated';

export type TQueryResult = QueryResult<FdrGetGeoComponentQuery>;

export type TQueryResultWithPatchedFetch = Omit<TQueryResult, 'fetchMore'> & {
  fetchMore: (page: number) => ReturnType<TQueryResult['fetchMore']>;
};

export function useGetGeoComponent(id: string): TQueryResultWithPatchedFetch {
  const result = useFdrGetGeoComponentQuery({
    variables: {
      input: { ids: [id] },
      geoPage: 0,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrGetGeoComponentQuery>> {
      return result.fetchMore({
        variables: {
          input: { ids: [id] },
          geoPage: page,
        },
      });
    },
  };
}

export function useGetFdrComponent(
  id: string,
  initialItems: Array<TGeoItem>,
  pagination: FdrPagination,
  useGet: (id: string, page: number) => TQueryResultWithPatchedFetch
) {
  const [items, setItems] = React.useState<Array<TGeoItem>>(initialItems);
  const [page, setPage] = React.useState<number>(0);

  const { fetchMore } = useGet(id, 0);

  return {
    items,
    page,
    hasMore: items.length < pagination.total,
    nextPage() {
      const newPage = page + 1;
      return fetchMore(newPage)
        .then(result => {
          setItems(prev => [
            ...prev,
            ...((
              (result.data as FdrGetGeoComponentQuery).fdrComponents
                .components[0] as FdrGeoRecommenderFragment
            ).itemsPage.items as Array<TGeoItem>),
          ]);

          setPage(newPage);
        })
        .catch(console.error);
    },
  };
}
