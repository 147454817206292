import { ReactElement, useState } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrPageComponents } from 'components/domain/fdr-page-components';
import { fdrSearchPageVars } from 'components/page/search/search.vars';
import { useFdrSearchPageComponentsQuery } from 'schemas/query/search/fdr-search-page-components.generated';
import { useFdrSrlEmptyResultTextQuery } from 'schemas/query/srl/fdr-srl-empty-result-text.generated';
import { FdrSearchSection } from './fdr-search-section';
import { B2B_SECTION_KEYS, B2C_SECTION_KEYS } from './fdr-search.constants';
import {
  EmptySearchResult,
  FdrSearchSectionContainer,
  FdrSearchSectionWithSlidersNavigationContainer,
} from './fdr-search.styles';

interface IFdrSearchSectionsProps {
  searchInput: string;
}

export function FdrSearchSections({
  searchInput,
}: IFdrSearchSectionsProps): ReactElement {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const [isContentExist, setIsContentExist] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const SearchSectionContainerVariant = !mobile
    ? FdrSearchSectionWithSlidersNavigationContainer
    : FdrSearchSectionContainer;

  const isB2B = channelType === AuthChannelType.B2B;
  const sectionKeys = isB2B ? B2B_SECTION_KEYS : B2C_SECTION_KEYS;

  const { data } = useFdrSrlEmptyResultTextQuery();

  return (
    <>
      <SearchSectionContainerVariant>
        {sectionKeys.map(function (
          sectionKey:
            | typeof B2B_SECTION_KEYS[number]
            | typeof B2C_SECTION_KEYS[number],
          index: number
        ) {
          return (
            <FdrSearchSection
              key={`${sectionKey}-${index}`}
              sectionKey={sectionKey}
              searchInput={searchInput}
              setIsContentExist={setIsContentExist}
              setIsLoading={setIsLoading}
            />
          );
        })}
        {!isLoading && !isContentExist ? (
          <EmptySearchResult
            dangerouslySetInnerHTML={{
              __html: data?.fdrProductSearchResultListPage?.text,
            }}
          />
        ) : null}
      </SearchSectionContainerVariant>
      {!isContentExist ? <FdrSearchPageComponents /> : null}
    </>
  );
}

function FdrSearchPageComponents(): ReactElement {
  const { data, loading } = useFdrSearchPageComponentsQuery({
    variables: fdrSearchPageVars,
  });

  // TODO: add skeleton ?
  if (loading) return null;

  return (
    <FdrPageComponents
      components={
        data?.fdrProductSearchResultListPage?.pageComponents?.components || []
      }
    />
  );
}
