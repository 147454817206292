import { useGoogleMap } from '@react-google-maps/api';
import React, { useContext, useEffect } from 'react';
import { TPinType } from 'components/domain/fdr-geo/trips-list/fdr-geo-trips.types';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import { useMapCoordinates } from 'components/domain/fdr-geo/trips/useMapCoordinates';
import BasePin from 'components/domain/map-constructor/BasePin';
import { fitBounds } from './fdr-geo-trips-pins.utils';

const FdrGeoTripsPinsRelatedRoutes: React.FC = () => {
  const map = useGoogleMap();
  const {
    activeRoutePoint,
    setActiveRoutePoint,
    setActiveTrip,
    routePoints,
    setActiveProduct,
    productPins,
  } = useContext(FdrGeoTripsContext);

  const selected = routePoints.get(activeRoutePoint);

  useMapCoordinates([
    ...Array.from(routePoints.values()),
    ...Array.from(productPins.values()),
  ]);

  useEffect(() => {
    if (!selected) return;
    requestAnimationFrame(() => {
      fitBounds(map, [selected.coordinates], 10);
    });
  }, [selected]);

  const pins = Array.from(routePoints);
  return (
    <>
      {pins.map(([id, pin]) => {
        return (
          <BasePin
            key={`product-pin-${id}`}
            item={pin}
            active={activeRoutePoint === id}
            onClick={() => {
              setActiveProduct(null);
              setActiveRoutePoint(stId => {
                if (stId !== id) {
                  setActiveTrip(null);
                }

                for (const roundtrip of pin.roundtrips) {
                  if (id === roundtrip.relatedId) {
                    setActiveTrip(roundtrip.tripId);
                    break;
                  }
                }
                return id;
              });
            }}
            className={
              pin.type === TPinType.RELATED_ROUTES
                ? 'old-roundtrip-pin'
                : 'new-roundtrip-pin'
            }
          />
        );
      })}
    </>
  );
};

export default FdrGeoTripsPinsRelatedRoutes;
