import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { useFdrAddBookmarkMutation } from 'schemas/query/bookmark/fdr-add-bookmark.generated';

export function useAddBookmarkToBookmarkGroup() {
  const [mutation, { data, loading }] = useFdrAddBookmarkMutation({
    refetchQueries: [
      `FdrBookmarkCount`,
      // eslint-disable-next-line i18next/no-literal-string
      'FdrBookmarkGroupList',
    ],
  });

  async function addBookmarkToBookmarkGroup(
    pageType: FdrBookmarkObjectType,
    objectId: string,
    groupId: string
  ) {
    return mutation({
      variables: {
        input: {
          objectId,
          bookmarkGroupId: groupId,
          objectType: pageType,
        },
      },
    });
  }

  return {
    addBookmarkToBookmarkGroup,
    data,
    loading,
  };
}
