import { track } from '@hotelplan/libs.tracking-events';

export enum PaginationArrowSide {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum PaginationArrowClickSource {
  PRODUCTS = 'products',
  SIMILAR_DESTINATION = 'similar-destination',
  THEME = 'theme',
  SRL = 'srl',
}

export const trackPaginationArrowClick = ({
  target,
  source,
}: {
  target: PaginationArrowSide;
  source: PaginationArrowClickSource;
}): void => {
  track({
    event: `🚀 recommender.browse`,
    eventMetadata: {
      source: source,
      target: target,
    },
    _clear: true,
  });
};
