import React, { PropsWithChildren, ReactElement } from 'react';
import { Container } from 'components/domain/container';
import {
  GridRecommendationBlock,
  GridRecommendationItems,
  GridRecommendationSectionMainTitle,
} from 'components/domain/recommendations/CommonGridRecommendationSection.styles';
import { TGeoItem } from './fdr-geo-recommender-item';

interface IFdrCommonGridRecommenderUnpaginatedProps {
  recommendationItem: React.FC<PropsWithChildren<{ item: TGeoItem }>>;
  items: Array<TGeoItem>;
  bgColor?: string;
  fontColor?: string;
  id?: string;
  title?: string;
}

export function FdrCommonGridRecommenderUnpaginated(
  props: PropsWithChildren<IFdrCommonGridRecommenderUnpaginatedProps>
): ReactElement {
  const {
    recommendationItem: RecommendationItem,
    bgColor,
    id,
    fontColor,
    title,
    items,
    children,
  } = props;

  return (
    <GridRecommendationBlock
      fontColor={fontColor}
      bgColor={bgColor}
      data-id="geo-recommendations-section"
      className="geo-recommender"
      id={id}
    >
      <Container>
        <GridRecommendationSectionMainTitle>
          {title}
        </GridRecommendationSectionMainTitle>
        <GridRecommendationItems>
          {items.map((item, index) => (
            <RecommendationItem item={item} key={index} />
          ))}
        </GridRecommendationItems>
        {children}
      </Container>
    </GridRecommendationBlock>
  );
}
