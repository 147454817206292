import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const GeoSkeletonTitleWrapper = styled.div(
  sx2CssThemeFn({
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
);

export const GeoSkeletonCardWrapper = styled.div(
  sx2CssThemeFn({
    height: '308px',
    width: '100%',
  })
);
