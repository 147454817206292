import React, { ReactElement } from 'react';
import { languageList } from '@hotelplan/fdr.lib.i18n.lang-config';
import {
  FdrLang,
  FdrLangControlWrapper,
} from './fdr-footer-lang-control.styles';
import useLanguage from './useLanguage';

export function FdrFooterLangControl(): ReactElement {
  const [language, changeLanguage] = useLanguage();

  async function handleChangeLanguage(event: React.MouseEvent<HTMLDivElement>) {
    const nextLang = event.currentTarget.getAttribute(`data-lang`);
    await changeLanguage(nextLang);
  }

  return (
    <FdrLangControlWrapper>
      {languageList.map(lang => (
        <FdrLang
          key={`language-${lang}`}
          data-lang={lang}
          className={lang === language ? 'active lang-link' : 'lang-link'}
          onClick={handleChangeLanguage}
        >
          {lang}
        </FdrLang>
      ))}
    </FdrLangControlWrapper>
  );
}
