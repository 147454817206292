import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useBookmarkContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import { FdrSearchResetButton } from 'components/domain/fdr-search-overlay';
import {
  BookmarkPopupButtonRow,
  BookmarkPopupContentRow,
  BookmarkPopupFormSubmitButton,
  BookmarkPopupTextInputContainer,
} from './fdr-bookmark-modal-popup.styles';

export interface IBookmarkPopupNewGroupFormState {
  name: string;
}

const BookmarkPopupNewGroupFormValidationSchema =
  Yup.object<IBookmarkPopupNewGroupFormState>({
    name: Yup.string().required('forms:errors.required'),
  });

const BookmarkGroupNameForm = styled.form({
  width: '100%',
});

interface IBookmarkPopupNewGroupFormProps {
  onSubmit(
    values: IBookmarkPopupNewGroupFormState,
    helpers: FormikHelpers<IBookmarkPopupNewGroupFormState>
  ): void;
  onCancel?(): void;
}

const FdrBookmarkPopupNewGroupForm: React.FC<IBookmarkPopupNewGroupFormProps> =
  ({ onSubmit, onCancel }) => {
    const bookmarkContext = useBookmarkContext();
    const [t] = useTranslation(['common']);
    const defaultBookmarkGroupNameCount =
      bookmarkContext.bookmarkGroups.length + 1;
    const inputName = 'name';
    const inputRef = useRef<HTMLInputElement>();
    const { mobile } = useDeviceType();

    return (
      <Formik<IBookmarkPopupNewGroupFormState>
        initialValues={{
          name: t('bookmark.group.defaultName', {
            count: defaultBookmarkGroupNameCount,
          }),
        }}
        validationSchema={BookmarkPopupNewGroupFormValidationSchema}
        onSubmit={onSubmit}
      >
        {formik => {
          return (
            <BookmarkGroupNameForm
              noValidate
              onSubmit={(e: unknown) =>
                formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
              }
            >
              <BookmarkPopupContentRow>
                <BookmarkPopupTextInputContainer>
                  <ValidatedTextInput
                    required
                    name={inputName}
                    className="text-input"
                    showErrorMessage={false}
                    hideLabel={true}
                    inputRef={inputRef}
                  />
                  {formik.values.name?.length > 0 && (
                    <FdrSearchResetButton
                      type="reset"
                      variant="iconBtn"
                      icon={{
                        name: 'close-icon',
                        size: mobile ? 'sm' : 'md',
                      }}
                      onClick={() => {
                        formik.setFieldValue(inputName, '');
                        inputRef.current?.focus();
                      }}
                    >
                      {t('common:close')}
                    </FdrSearchResetButton>
                  )}
                </BookmarkPopupTextInputContainer>
              </BookmarkPopupContentRow>
              {!onCancel && (
                <BookmarkPopupContentRow>
                  <BookmarkPopupFormSubmitButton
                    disabled={formik.isSubmitting}
                    className="form-submit-button"
                    type="submit"
                  >
                    {t('bookmark.group.createAndSave.button')}
                  </BookmarkPopupFormSubmitButton>
                </BookmarkPopupContentRow>
              )}
              {onCancel && (
                <BookmarkPopupButtonRow>
                  <BsButton variant="tagBtn" onClick={onCancel}>
                    {t('bookmark.group.edit.cancel')}
                  </BsButton>
                  <BsButton
                    disabled={formik.isSubmitting}
                    className="form-submit-button"
                    type="submit"
                  >
                    {t('bookmark.group.edit.apply')}
                  </BsButton>
                </BookmarkPopupButtonRow>
              )}
            </BookmarkGroupNameForm>
          );
        }}
      </Formik>
    );
  };

export default FdrBookmarkPopupNewGroupForm;
