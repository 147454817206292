import { useEffect, useState } from 'react';
import { TBookmarkInput } from 'components/domain/fdr-bookmark/fdr-bookmark-types';
import { useFdrAddBookmarksMutation } from 'schemas/query/bookmark/fdr-add-bookmarks.generated';
import { useFdrDeleteBookmarksMutation } from 'schemas/query/bookmark/fdr-delete-bookmarks.generated';

interface IUpdateBookmarks {
  (
    toAdd: TBookmarkInput[] | undefined,
    toDelete: TBookmarkInput[] | undefined
  ): Promise<void>;
}

export function useUpdateBookmarks(): {
  loading: boolean;
  updateBookmarks: IUpdateBookmarks;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const [bookmarkUpdateList, setBookmarkUpdateList] = useState<
    {
      toAdd: TBookmarkInput[];
      toDelete: TBookmarkInput[];
    }[]
  >([]);
  const [addBookmarksMutation] = useFdrAddBookmarksMutation();
  const [deleteBookmarksMutation] = useFdrDeleteBookmarksMutation();

  const updateBookmarks: IUpdateBookmarks = async (toAdd, toDelete) => {
    if (toAdd?.length || toDelete?.length) {
      setBookmarkUpdateList(prevState => {
        return prevState.concat([
          {
            toAdd: toAdd || [],
            toDelete: toDelete || [],
          },
        ]);
      });
    }
  };

  useEffect(() => {
    const processUpdate = async () => {
      if (!loading && bookmarkUpdateList.length) {
        setLoading(true);

        const bookmarkUpdateItem = bookmarkUpdateList[0];

        setBookmarkUpdateList(prev => {
          return prev.slice(1);
        });

        try {
          if (bookmarkUpdateItem.toAdd.length) {
            await addBookmarksMutation({
              variables: {
                input: {
                  bookmarks: bookmarkUpdateItem.toAdd.map(b => ({
                    objectId: b.objectId,
                    objectType: b.pageType,
                    bookmarkGroupId: b.groupId,
                  })),
                },
              },
            });
          }

          if (bookmarkUpdateItem.toDelete.length) {
            await deleteBookmarksMutation({
              variables: {
                input: {
                  bookmarks: bookmarkUpdateItem.toDelete.map(b => ({
                    objectId: b.objectId,
                    objectType: b.pageType,
                    bookmarkGroupId: b.groupId,
                  })),
                },
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };

    processUpdate();
  }, [loading, bookmarkUpdateList]);

  return {
    updateBookmarks,
    loading,
  };
}
