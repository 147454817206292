import getConfig from 'next/config';
import { registerPageRoutes } from '@hotelplan/libs.router-config';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';

const {
  publicRuntimeConfig: {
    DOMAIN_DE: domainDE = process.env.DOMAIN_DE,
    DOMAIN_FR: domainFR = process.env.DOMAIN_FR,
  } = {},
} = getConfig();

registerPageRoutes(
  [
    {
      pageType: EPageType.Home,
      match: `/`,
      page: '/',
      locale: `de`,
    },
    {
      pageType: EPageType.Home,
      match: `/`,
      page: '/',
      locale: `fr`,
    },
    {
      pageType: EPageType.B2BLogin,
      match: '/b2b/login',
      page: '/b2b-login',
      locale: 'de',
    },
    {
      pageType: EPageType.B2BLogin,
      match: '/b2b/login',
      page: '/b2b-login',
      locale: 'fr',
    },
    {
      pageType: EPageType.Srl,
      match: '/suche',
      page: '/search',
      locale: 'de',
    },
    {
      pageType: EPageType.Srl,
      match: '/recherche',
      page: '/search',
      locale: 'fr',
    },
    {
      pageType: EPageType.AgencyObject,
      match: '/reisebueros/:params/:id',
      page: '/agency',
      locale: 'de',
    },
    {
      pageType: EPageType.AgencyObject,
      match: '/agence-de-voyage/:params/:id',
      page: '/agency',
      locale: 'fr',
    },
    {
      pageType: EPageType.GeoObject,
      match: '/reisen/:params*/:id([kgcrdf]-\\d+)',
      page: '/geo',
      locale: 'de',
    },
    {
      pageType: EPageType.GeoObject,
      match: '/voyager/:params*/:id([kgcrdf]-\\d+)',
      page: '/geo',
      locale: 'fr',
    },
    {
      pageType: EPageType.GeoOverview,
      match: '/reisen/reiseziele',
      page: '/geo-overview',
      locale: 'de',
    },
    {
      pageType: EPageType.GeoOverview,
      match: '/voyager/destinations',
      page: '/geo-overview',
      locale: 'fr',
    },
    {
      pageType: EPageType.ThemeObject,
      match: '/reisen/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'de',
    },
    {
      pageType: EPageType.ThemeObject,
      match: '/voyager/:params/:id(t-\\d+)',
      page: '/theme',
      locale: 'fr',
    },
    {
      pageType: EPageType.CombinedThemeGeo,
      match:
        '/reisen/:geoparams*/:geoId([kgcrdf]-\\d+)/:themeparams/:themeId(t-\\d+)',
      page: '/theme-geo',
      locale: 'de',
    },
    {
      pageType: EPageType.CombinedThemeGeo,
      match:
        '/voyager/:geoparams*/:geoId([kgcrdf]-\\d+)/:themeparams/:themeId(t-\\d+)',
      page: '/theme-geo',
      locale: 'fr',
    },
    {
      pageType: EPageType.ThemeOverview,
      match: '/reisen/reiseerlebnisse',
      page: '/theme-overview',
      locale: 'de',
    },
    {
      pageType: EPageType.ThemeOverview,
      match: '/voyager/experiences',
      page: '/theme-overview',
      locale: 'fr',
    },
    // Pdp
    {
      pageType: EPageType.Pdp,
      match: `/reisen/:destination/:params*/:id([aoh]-\\d+)`,
      page: '/pdp',
      locale: 'de',
    },
    {
      pageType: EPageType.Pdp,
      match: `/voyager/:destination/:params*/:id([aoh]-\\d+)`,
      page: '/pdp',
      locale: 'fr',
    },
    // Newsletter subscription
    {
      pageType: EPageType.NewsletterSubscription,
      match: `/newsletter/anmeldung`,
      page: '/newsletter-subscription',
      locale: 'de',
    },
    {
      pageType: EPageType.NewsletterSubscription,
      match: `/newsletter/abonnement`,
      page: '/newsletter-subscription',
      locale: 'fr',
    },
    {
      pageType: EPageType.NewsletterConfirmation,
      match: `/newsletter/anmeldung-bestaetigen`,
      page: '/newsletter-subscription-confirm',
      locale: 'de',
    },
    {
      pageType: EPageType.NewsletterConfirmation,
      match: `/newsletter/abonnement-confirmer`,
      page: '/newsletter-subscription-confirm',
      locale: 'fr',
    },
    {
      pageType: EPageType.NewsletterFinalization,
      match: `/newsletter/anmeldung-erfolgreich`,
      page: '/newsletter-subscription-success',
      locale: 'de',
    },
    {
      pageType: EPageType.NewsletterFinalization,
      match: `/newsletter/abonnement-succes`,
      page: '/newsletter-subscription-success',
      locale: 'fr',
    },
    {
      pageType: EPageType.NewsletterUnsubscription,
      match: `/newsletter/abmeldung`,
      page: '/newsletter-unsubscribe',
      locale: 'de',
    },
    {
      pageType: EPageType.NewsletterUnsubscription,
      match: `/newsletter/desabonnement`,
      page: '/newsletter-unsubscribe',
      locale: 'fr',
    },
    {
      pageType: EPageType.NewsletterUnsubscriptionFinalization,
      match: `/newsletter/abmeldung-erfolgreich`,
      page: '/newsletter-unsubscribe-success',
      locale: 'de',
    },
    {
      pageType: EPageType.NewsletterUnsubscriptionFinalization,
      match: `/newsletter/desabonnement-succes`,
      page: '/newsletter-unsubscribe-success',
      locale: 'fr',
    },
    {
      pageType: EPageType.EmailAdvertisingRevocation,
      match: `/email-permission/widerrufen`,
      page: '/email-revoke',
      locale: 'de',
    },
    {
      pageType: EPageType.EmailAdvertisingRevocation,
      match: `/email-permission/retracter`,
      page: '/email-revoke',
      locale: 'fr',
    },
    {
      pageType: EPageType.EmailAdvertisingRevocationConfirmation,
      match: `/email-permission/widerruf-erfolgreich`,
      page: '/email-revoke-success',
      locale: 'de',
    },
    {
      pageType: EPageType.EmailAdvertisingRevocationConfirmation,
      match: `/email-permission/retractation-succes`,
      page: '/email-revoke-success',
      locale: 'fr',
    },
    // AgencyOverview
    {
      pageType: EPageType.AgencyOverview,
      match: `/reisebueros`,
      page: `/agency-overview`,
      locale: 'de',
    },
    {
      pageType: EPageType.AgencyOverview,
      match: `/agence-de-voyage`,
      page: `/agency-overview`,
      locale: 'fr',
    },
    // Catalogs
    {
      pageType: EPageType.CatalogOverview,
      match: `/travelbooks`,
      page: '/catalogs',
      locale: 'de',
    },
    {
      pageType: EPageType.CatalogOverview,
      match: `/travelbooks`,
      page: '/catalogs',
      locale: 'fr',
    },
    // Blog Article
    {
      pageType: EPageType.BlogArticle,
      match: `/reisen/:title/:id(b-\\d+)`,
      page: '/blog-article',
      locale: 'de',
    },
    {
      pageType: EPageType.BlogArticle,
      match: `/voyager/:title/:id(b-\\d+)`,
      page: '/blog-article',
      locale: 'fr',
    },
    // Blog Overview
    {
      pageType: EPageType.BlogOverview,
      match: `/reisen/reiseberichte`,
      page: '/blog-overview',
      locale: 'de',
    },
    {
      pageType: EPageType.BlogOverview,
      match: `/voyager/recits-de-voyage`,
      page: '/blog-overview',
      locale: 'fr',
    },
    // Bookmark
    {
      pageType: EPageType.Bookmark,
      match: '/merkliste',
      page: '/bookmark',
      locale: 'de',
    },
    {
      pageType: EPageType.Bookmark,
      match: '/liste-de-favoris',
      page: '/bookmark',
      locale: 'fr',
    },
    {
      pageType: EPageType.Bookmark,
      match: '/merkliste/teilen/:id',
      page: '/bookmark-share',
      locale: 'de',
    },
    {
      pageType: EPageType.Bookmark,
      match: '/liste-de-favoris/partager/:id',
      page: '/bookmark-share',
      locale: 'fr',
    },
    // Sitemap page
    {
      pageType: EPageType.Static,
      match: '/sitemap',
      page: '/sitemap',
      locale: 'de',
    },
    {
      pageType: EPageType.Static,
      match: '/sitemap',
      page: '/sitemap',
      locale: 'fr',
    },
    // Static
    {
      pageType: EPageType.Static,
      match: `/:slug*`,
      page: '/[...slug]',
      locale: `de`,
      fallback: true,
    },
    {
      pageType: EPageType.Static,
      match: `/:slug*`,
      page: '/[...slug]',
      locale: `fr`,
      fallback: true,
    },
  ].map(conf => ({
    ...conf,
    domain: { de: domainDE, fr: domainFR }[conf.locale],
  }))
);
