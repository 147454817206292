import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { FdrImage, FdrMenu, FdrRequestButton } from '@hotelplan/supergraph-api';
import { useLayoutContext } from 'components/domain/layout/Layout.context';
import { FdrHeaderInner } from './fdr-header-inner';
import { FdrHeaderBox, FdrHeaderStickyWrapper } from './fdr-header.styles';

const SCROLL_EVENTS = [`click`, `touchstart`, `touchend`, `scroll`];

interface IFdrHeaderProps {
  logo: FdrImage;
  navLinks: Array<FdrMenu>;
  breadcrumbSection?: ReactNode;
  requestButton?: FdrRequestButton;
}

export function FdrHeader({
  logo,
  navLinks,
  children,
  requestButton,
  breadcrumbSection,
}: PropsWithChildren<IFdrHeaderProps>): ReactElement {
  const headerRef = useRef(null);
  const headerWrapperRef = useRef<HTMLDivElement>();

  const { mobile } = useDeviceType();
  const { setHeaderHeight, infoHeight } = useLayoutContext();
  const { h: headerHeight } = useElementSize(headerRef, { throttle: 0 });

  const headerElement = headerWrapperRef.current;

  function handleScroll() {
    const isSticky = window.scrollY > infoHeight && !mobile;

    isSticky
      ? headerElement.classList.add('sticky')
      : headerElement.classList.remove('sticky');
  }

  function removeScrollListeners() {
    SCROLL_EVENTS.forEach(event => {
      document.removeEventListener(event, handleScroll);
    });
  }

  function addScrollListeners() {
    SCROLL_EVENTS.forEach(event => {
      document.addEventListener(event, handleScroll);
    });
  }

  useEffect(() => {
    if (!headerElement || !infoHeight) return removeScrollListeners;

    addScrollListeners();

    return removeScrollListeners;
  }, [headerElement, mobile, infoHeight]);

  useEffect(() => {
    setHeaderHeight(headerHeight - infoHeight);
  }, [headerHeight, infoHeight]);

  return (
    <FdrHeaderStickyWrapper
      id="header-sticky-wrapper"
      ref={headerWrapperRef}
      infoHeight={infoHeight}
      className={!infoHeight && !mobile && 'sticky'}
    >
      <FdrHeaderBox ref={headerRef}>
        {children}
        {mobile && breadcrumbSection ? breadcrumbSection : null}
        <FdrHeaderInner
          logo={logo}
          navLinks={navLinks}
          requestButton={requestButton}
          isWithBreadcrumbs={!!breadcrumbSection}
        />
        {!mobile && breadcrumbSection ? breadcrumbSection : null}
      </FdrHeaderBox>
    </FdrHeaderStickyWrapper>
  );
}
