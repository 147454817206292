import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FdrProductRouteMapModal } from './fdr-product-route-map-modal';
import { TFdrRouteMapProduct } from './fdr-product-route-map.types';

const ProductRouteMapButton = styled(BsButtonWithIcon)(
  ({ theme: { colors } }) => ({
    marginLeft: 'auto',
    background: colors.lightGreyThird,
    height: '42px',
    width: '42px',
    borderRadius: '5px',
    svg: {
      color: 'black',
      fill: 'transparent',
    },
    '&:hover,&:focus,&:active': {
      svg: {
        color: colors.white,
        fill: 'transparent',
      },
    },
    '&:disabled': {
      opacity: 0.5,
      background: colors.lightGreyThird,
      cursor: 'auto',
      '&:hover,&:focus,&:active': {
        svg: {
          color: 'black',
          fill: 'transparent',
        },
      },
    },
  })
);

interface IFdrProductMapProps {
  id: string;
  products: Array<TFdrRouteMapProduct>;
  onMapOpenClick?(): void;
  modalTitle?: string;
}

export function FdrProductRouteMap(
  props: IFdrProductMapProps
): React.ReactElement {
  const { id, products, onMapOpenClick, modalTitle } = props;

  const { mobile } = useDeviceType();
  const [isMapOpened, openMap, closeMap] = useToggleState(false);

  const handleMapOpenClick = () => {
    openMap();
    onMapOpenClick?.();
  };

  useEffect(() => {
    if (!window) return;

    window.document.body.style.overflow = isMapOpened ? 'hidden' : 'initial';
  }, [isMapOpened]);

  const renderMap = useCallback(() => {
    if (!products?.length) return null;

    return (
      <FdrProductRouteMapModal
        products={products}
        isMapOpened={isMapOpened}
        onClose={closeMap}
        id={id}
        title={modalTitle}
      />
    );
  }, [closeMap, isMapOpened, products, id, mobile]);

  return (
    <>
      <ProductRouteMapButton
        variant="iconBtn"
        icon={{
          name: 'map-icon',
          size: 'md',
        }}
        className="product-routing-map-button"
        disabled={!products?.length}
        onClick={handleMapOpenClick}
      />
      {renderMap()}
    </>
  );
}
