import React, { ReactElement } from 'react';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrHelpOverlayBox } from 'components/candidate/fdr-help-overlay';
import { FdrHelpOverlayBoxChatFragment } from 'schemas/query/help-overlay/fdr-help-overlay.generated';
import { FlaglineText, ThirdHeading } from 'theme/headings';
import {
  FdrHelpOverlayContent,
  FdrInfoCardWrapper,
} from './fdr-help-overlay.styles';

interface IFdrChatBoxProps {
  box: FdrHelpOverlayBoxChatFragment;
  boxesCount: number;
  onClick?(e: React.MouseEvent): void;
}

export function FdrChatBox({
  box,
  boxesCount,
  onClick,
}: IFdrChatBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  if (!box) return null;

  return (
    <FdrHelpOverlayBox
      boxesCount={boxesCount}
      link={box.targetUrl}
      onClick={onClick}
    >
      <FdrInfoCardWrapper infoCardImage={box.icon}>
        <div className="help-overlay-content-wrap">
          {box.icon.alt && !mobile ? (
            <FlaglineText className="image-tagline">
              {box.icon.alt}
            </FlaglineText>
          ) : null}
          <FdrHelpOverlayContent>
            <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
            {!mobile && (
              <p
                className="image-text"
                dangerouslySetInnerHTML={{ __html: box.mainText }}
              />
            )}
          </FdrHelpOverlayContent>
        </div>
        <BsLink icon={{ name: 'chevron-long-right' }}>
          <span className="link-text">{box.mainTitle}</span>
        </BsLink>
      </FdrInfoCardWrapper>
    </FdrHelpOverlayBox>
  );
}
