import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrB2BInfoGroupsFragment = {
  __typename?: 'FdrProductB2bInformationGroup';
  id: string;
  name?: string | null;
  textInformation?: Array<{
    __typename?: 'FdrTitleText';
    title: string;
    text: string;
  }> | null;
  featuresInformation?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
};

export type FdrProductFeatureGroupFragment = {
  __typename?: 'FdrProductFeatureGroup';
  id: string;
  name: string;
  features: Array<{
    __typename?: 'FdrProductFeature';
    id: string;
    name: string;
    enabled: boolean;
  }>;
};

export type FdrProductFeatureFragment = {
  __typename?: 'FdrProductFeature';
  id: string;
  name: string;
  enabled: boolean;
};

export const FdrProductFeatureFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductFeature' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductFeature' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrProductFeatureGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductFeatureGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductFeatureGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductFeature' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductFeatureFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrB2BInfoGroupsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrB2BInfoGroups' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductB2bInformationGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuresInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductFeatureGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductFeatureGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
