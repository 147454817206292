import styled from 'styled-components';

export const DarkInfoWindow = styled.div(({ theme: { colors } }) => ({
  left: '50%',
  position: 'absolute',
  background: colors.darkGrey,
  boxShadow: `0px 2px 12px rgba(0, 0, 0, 0.1)`,
  color: 'white',
  borderRadius: '5px',
  padding: '5px 10px',
  fontSize: '16px',
  transform: 'translate(-50%, 10px)',
  '&:after': {
    content: '""',
    background: colors.darkGrey,
    transform: 'translateX(-8px) rotate(45deg)',
    height: '1px',
    width: '1px',
    border: `8px solid ${colors.darkGrey}`,
    position: 'absolute',
    top: '-8px',
    left: '50%',
  },
}));
