import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrBooster, FdrRequestButton } from '@hotelplan/supergraph-api';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import {
  ExternalOverlay,
  useExternalOverlay,
} from 'components/domain/external-overlay';
import { BookmarkContextProvider } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import BookmarkModalPopup from 'components/domain/fdr-bookmark/modal/fdr-bookmark-modal-popup';
import { FdrBreadcrumbsSection } from 'components/domain/fdr-breadcrumbs';
import { FdrFooter } from 'components/domain/fdr-footer';
import { FdrHeader } from 'components/domain/fdr-header';
import { FdrInfoNotification } from 'components/domain/fdr-info-notification';
import { FdrGclIdProvider } from 'components/domain/fdr-jotform';
import { FdrMetaModules } from 'components/domain/fdr-meta-modules';
import { FdrMobileMenu } from 'components/domain/fdr-mobile-menu';
import { FdrSearchOverlay } from 'components/domain/fdr-search-overlay';
import { LayoutProviderWrapper } from 'components/domain/layout/Layout.context';
import {
  FdrStructuredDataBreadcrumbs,
  FdrStructuredDataBreadcrumbsPages,
} from 'components/domain/structured-data';
import { useFdrHeaderQuery } from 'schemas/query/header/fdr-header.generated';
import { FdrOgType } from './fdr-og-type';

interface ILayoutProps {
  tagline?: string;
  pageType: EPageType;
  gclidValue: string;
  origin?: string;
  boosters?: Array<FdrBooster>;
  fdrBreadcrumbs?: Array<TFdrBreadcrumb>;
  fdrRequestButton?: FdrRequestButton;
}

function Layout({
  children,
  tagline,
  pageType,
  gclidValue,
  origin,
  boosters,
  fdrBreadcrumbs,
  fdrRequestButton,
}: PropsWithChildren<ILayoutProps>): ReactElement {
  const { show, close, link, loading } = useExternalOverlay();
  const { mobile } = useDeviceType();

  const { data: fdrHeaderData } = useFdrHeaderQuery();

  const fdrNavLinks = fdrHeaderData?.fdrHeader?.menus;

  const fdrBreadcrumbsComponent = useMemo(() => {
    if (!fdrBreadcrumbs?.length) return null;

    return (
      <>
        <FdrBreadcrumbsSection breadcrumbs={fdrBreadcrumbs} />
        {origin && FdrStructuredDataBreadcrumbsPages.includes(pageType) && (
          <FdrStructuredDataBreadcrumbs
            breadcrumbs={fdrBreadcrumbs}
            origin={origin}
          />
        )}
      </>
    );
  }, [fdrBreadcrumbs]);

  return (
    <LayoutProviderWrapper>
      <FdrGclIdProvider gclidValue={gclidValue}>
        <BookmarkContextProvider>
          <FdrOgType pageType={pageType} />
          <FdrHeader
            navLinks={fdrNavLinks}
            logo={fdrHeaderData?.fdrHeader?.logo}
            breadcrumbSection={fdrBreadcrumbsComponent}
            requestButton={fdrRequestButton}
          >
            <FdrInfoNotification />
          </FdrHeader>
          <main>{children}</main>
          <FdrFooter navLinks={fdrNavLinks} tagline={tagline} />
          {mobile ? <FdrMobileMenu requestButton={fdrRequestButton} /> : null}
          <BookmarkModalPopup />
          <FdrSearchOverlay />
          <ExternalOverlay
            showOverlay={show}
            closeOverlay={close}
            href={link}
            loading={loading}
          />
          <FdrMetaModules boosters={boosters} />
        </BookmarkContextProvider>
      </FdrGclIdProvider>
    </LayoutProviderWrapper>
  );
}

export default Layout;
