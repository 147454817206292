import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrSrlBreadcrumbsDocument,
  FdrSrlBreadcrumbsQuery,
  FdrSrlBreadcrumbsQueryVariables,
} from 'schemas/query/srl/fdr-srl-breadcrumbs.generated';
import {
  createBreadcrumb,
  getHardcodedCaption,
  getHomeBreadcrumb,
} from './utils.common';

export async function getFdrSearchBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrSrlBreadcrumbsQuery,
    FdrSrlBreadcrumbsQueryVariables
  >({
    query: FdrSrlBreadcrumbsDocument,
  });

  const homeBreadcrumb = getHomeBreadcrumb(data, lang);

  return [
    ...homeBreadcrumb, // home
    createBreadcrumb(null, getHardcodedCaption(EPageType.Srl, lang), true),
  ];
}
