import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TGeoItem } from 'components/domain/fdr-page-components/recommenders/geo/fdr-geo-recommender-item';
import {
  TQueryResultWithPatchedFetch,
  useGetFdrComponent,
} from 'components/domain/fdr-page-components/useGetFdrComponent';
import { LoadMoreButtonWithWrapper } from 'components/domain/recommendations/LoadMoreButton';
import { FdrCommonGridRecommenderUnpaginated } from './fdr-common-grid-recommender-unpaginated';

interface IFdrCommonGridRecommenderProps {
  recommendationItem: React.FC<PropsWithChildren<{ item: TGeoItem }>>;
  loadMoreText?: string;
  bgColor?: string;
  fontColor?: string;
  id?: string;
  title?: string;
  pagination?: FdrPagination;
  items: Array<TGeoItem>;
  useGet: (id: string) => TQueryResultWithPatchedFetch;
}

export function FdrCommonGridRecommender(
  props: IFdrCommonGridRecommenderProps
): ReactElement {
  const {
    recommendationItem,
    loadMoreText,
    id,
    pagination,
    items: geoItems,
    useGet,
    title,
  } = props;
  const [t] = useTranslation('common');

  const { items, hasMore, nextPage } = useGetFdrComponent(
    id,
    geoItems,
    pagination,
    useGet
  );

  return (
    <FdrCommonGridRecommenderUnpaginated
      title={title}
      items={items}
      recommendationItem={recommendationItem}
    >
      {hasMore ? (
        <LoadMoreButtonWithWrapper onClick={nextPage}>
          {loadMoreText ? loadMoreText : t('common:load.more')}
        </LoadMoreButtonWithWrapper>
      ) : null}
    </FdrCommonGridRecommenderUnpaginated>
  );
}
