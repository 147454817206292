import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAddBookmarkMutationVariables = Types.Exact<{
  input: Types.FdrAddBookmarkInput;
}>;

export type FdrAddBookmarkMutation = {
  __typename?: 'Mutation';
  fdrAddBookmark: {
    __typename?: 'FdrAddBookmarkResponse';
    success: boolean;
    message?: string | null;
    entity?: { __typename?: 'FdrBookmark'; id: string } | null;
  };
};

export const FdrAddBookmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrAddBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAddBookmarkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAddBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrAddBookmarkMutationFn = Apollo.MutationFunction<
  FdrAddBookmarkMutation,
  FdrAddBookmarkMutationVariables
>;

/**
 * __useFdrAddBookmarkMutation__
 *
 * To run a mutation, you first call `useFdrAddBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrAddBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrAddBookmarkMutation, { data, loading, error }] = useFdrAddBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrAddBookmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrAddBookmarkMutation,
    FdrAddBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrAddBookmarkMutation,
    FdrAddBookmarkMutationVariables
  >(FdrAddBookmarkDocument, options);
}
export type FdrAddBookmarkMutationHookResult = ReturnType<
  typeof useFdrAddBookmarkMutation
>;
export type FdrAddBookmarkMutationResult =
  Apollo.MutationResult<FdrAddBookmarkMutation>;
export type FdrAddBookmarkMutationOptions = Apollo.BaseMutationOptions<
  FdrAddBookmarkMutation,
  FdrAddBookmarkMutationVariables
>;
