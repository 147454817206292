import { FdrSearchType } from '@hotelplan/supergraph-api';

export const MIN_ALL_RESULTS_PAGE_ITEMS_COUNT = 4;
export const DEFAULTS_RESULT_PER_PAGE = 3;
export const DEFAULT_IMAGE_HEIGHT = 387;
export const CARDS_GAP = 20;

// Order is important
export const B2C_SECTION_KEYS = [
  `adventuretravel`,
  `extraHotels`,
  `geo`,
  `themes`,
  `agencies`,
  `blogArticles`,
  `other`,
] as const;

// Order is important
export const B2B_SECTION_KEYS = [
  `adventuretravel`,
  `roundtrip`,
  `extraHotels`,
  `regularHotels`,
  `geo`,
  `themes`,
  `blogArticles`,
  `other`,
  `agencies`,
] as const;

export const FDR_SEARCH_SECTION_KEYS = Array.from(
  new Set([...B2C_SECTION_KEYS, ...B2B_SECTION_KEYS])
);

export const mapSectionKeysToSearchTypes = new Map<
  typeof FDR_SEARCH_SECTION_KEYS[number],
  Array<FdrSearchType>
>([
  [`adventuretravel`, [FdrSearchType.Adventuretravel]],
  [`regularHotels`, [FdrSearchType.Hotel]],
  [`extraHotels`, [FdrSearchType.ExtraHotel]],
  [`roundtrip`, [FdrSearchType.Roundtrip]],
  [`blogArticles`, [FdrSearchType.BlogArticle]],
  [`themes`, [FdrSearchType.Theme]],
  [
    `geo`,
    [
      FdrSearchType.Country,
      FdrSearchType.Continent,
      FdrSearchType.Countrygroup,
      FdrSearchType.Region,
    ],
  ],
  [
    `other`,
    [
      FdrSearchType.StaticPage,
      FdrSearchType.CataloguePage,
      FdrSearchType.AgencyOverviewPage,
      FdrSearchType.NewsletteRregistrationPage,
    ],
  ],
]);
