import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrMoveBookmarkMutationVariables = Types.Exact<{
  input: Types.FdrMoveBookmarkInput;
}>;

export type FdrMoveBookmarkMutation = {
  __typename?: 'Mutation';
  fdrMoveBookmark: { __typename?: 'FdrMoveBookmarkResponse'; success: boolean };
};

export const FdrMoveBookmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrMoveBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrMoveBookmarkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrMoveBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrMoveBookmarkMutationFn = Apollo.MutationFunction<
  FdrMoveBookmarkMutation,
  FdrMoveBookmarkMutationVariables
>;

/**
 * __useFdrMoveBookmarkMutation__
 *
 * To run a mutation, you first call `useFdrMoveBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrMoveBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrMoveBookmarkMutation, { data, loading, error }] = useFdrMoveBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrMoveBookmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrMoveBookmarkMutation,
    FdrMoveBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrMoveBookmarkMutation,
    FdrMoveBookmarkMutationVariables
  >(FdrMoveBookmarkDocument, options);
}
export type FdrMoveBookmarkMutationHookResult = ReturnType<
  typeof useFdrMoveBookmarkMutation
>;
export type FdrMoveBookmarkMutationResult =
  Apollo.MutationResult<FdrMoveBookmarkMutation>;
export type FdrMoveBookmarkMutationOptions = Apollo.BaseMutationOptions<
  FdrMoveBookmarkMutation,
  FdrMoveBookmarkMutationVariables
>;
