import { ReactElement, useEffect } from 'react';
import { FdrRequestButton } from '@hotelplan/supergraph-api';
import { externalLinkSubject } from 'components/domain/external-overlay';
import { useFdrGetLinkWithJotform } from 'components/domain/fdr-jotform';
import {
  ClickEventSourceType,
  trackRequestFormClick,
} from 'components/domain/tracking/tracking.utils';
import {
  FdrHeaderRequestButtonLink,
  FdrHeaderRequestButtonWrapper,
} from './fdr-request-button.styles';

interface IFdrHeaderRequestButtonProps {
  requestButton?: FdrRequestButton;
}

export function FdrHeaderRequestButton({
  requestButton,
}: IFdrHeaderRequestButtonProps): ReactElement {
  const linkWithJotform = useFdrGetLinkWithJotform(requestButton?.link);

  useEffect(
    function initRequestLinkLoad() {
      if (linkWithJotform) {
        externalLinkSubject.next(linkWithJotform.url);
      }
    },
    [linkWithJotform]
  );

  function handleClick() {
    if (!linkWithJotform) return;
    trackRequestFormClick(ClickEventSourceType.MENU);
  }

  return (
    <FdrHeaderRequestButtonWrapper onClick={handleClick}>
      <FdrHeaderRequestButtonLink
        className="request-button"
        link={linkWithJotform}
      >
        <span
          className="request-button-caption"
          dangerouslySetInnerHTML={{
            __html:
              requestButton?.staticCaption || requestButton?.objectCaption,
          }}
        />
      </FdrHeaderRequestButtonLink>
    </FdrHeaderRequestButtonWrapper>
  );
}
