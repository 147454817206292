import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import FdrHeroShareTool from 'components/domain/fdr-hero-share-tool/fdr-hero-share-tool';
import { openFdrSearchOverlay } from 'components/domain/fdr-search-overlay';
import { TFdrHeroMediaGalleryComponent } from './fdr-hero-section-types';
import { FdrHeroTextBlockGeo, FdrSearchInput } from './fdr-hero-section.styles';

interface IFdrHeroGeoContentProps {
  component: TFdrHeroMediaGalleryComponent;
  bookmarked: boolean;
}

export const FdrHeroGeoContent: React.FC<IFdrHeroGeoContentProps> = ({
  component,
  bookmarked,
}) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('search');

  const { title, titleColor } = component;

  return (
    <>
      <FdrHeroShareTool component={component} bookmarked={bookmarked} />
      <FdrHeroTextBlockGeo title={title} text="" color={titleColor} />
      <FdrSearchInput
        hideLabel
        placeholder={t('search:searchInput.placeholder')}
        icon={{
          name: `search`,
          size: mobile ? 'sm' : 'md',
        }}
        onClick={openFdrSearchOverlay}
      />
    </>
  );
};
