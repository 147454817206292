import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrAllItemsButton } from 'components/domain/fdr-search';
import { FdrTextSearchAutocompleteQuery } from 'schemas/query/search/fdr-text-search-autocomplete.generated';
import {
  getNextFdrAutoSuggestItem,
  getPrevFdrAutoSuggestItem,
  getSearchTermPosition,
  mapSearchItemToNormalizedSearchItem,
  useFdrSearchAutoSuggestKeyPress,
} from './fdr-search-auto-suggest.utils';
import {
  FdrNonProductColumn,
  FdrNonProductLink,
  FdrProductColumn,
  FdrProductContent,
  FdrProductHeader,
  FdrProductHeaderContainer,
  FdrProductImage,
  FdrProductImageContainer,
  FdrProductLink,
  FdrProductShowAllLinkContainer,
  FdrSearchAutoSuggestContent,
  FdrSearchAutoSuggestHighlight,
  FdrSearchAutoSuggestTitle,
} from './fdr-search-overlay.styles';
import { IFdrAutoSuggestItem, TSearchItem } from './fdr-search-overlay.types';
import { useFdrTextSearchAutoComplete } from './useFdrTextSearchAutoComplete';

interface ISearchAutoSuggestProps {
  searchValue: string;
  showError: boolean;
  onChange?(state: {
    count: number;
    activeItem: IFdrAutoSuggestItem | null;
  }): void;
  onSelect?(item: IFdrAutoSuggestItem): void;
  onShowAll?(): void;
}

export function FdrSearchAutoSuggest({
  searchValue,
  onSelect,
  showError,
  onChange,
  onShowAll,
}: ISearchAutoSuggestProps): ReactElement {
  const [t] = useTranslation(['search']);
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();

  const [active, setActive] = useState<IFdrAutoSuggestItem>(null);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const [autoSuggestList, setAutoSuggestList] = useState<
    Array<IFdrAutoSuggestItem>
  >([]);

  const debounceSearchValueCallback = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
    }, 200),
    []
  );

  function handleData(
    productData: FdrTextSearchAutocompleteQuery,
    nonProductData: FdrTextSearchAutocompleteQuery
  ) {
    const nonProducts = (nonProductData?.fdrTextAutocomplete?.itemList || [])
      .map(
        (item: TSearchItem, index) =>
          ({
            index,
            item: mapSearchItemToNormalizedSearchItem(item),
            type: 'nonProduct',
          } as IFdrAutoSuggestItem)
      )
      .filter(searchItem => searchItem.item);

    const products = (productData?.fdrTextAutocomplete?.itemList || [])
      .map(
        (item: TSearchItem, index) =>
          ({
            index: nonProducts.length + index,
            item: mapSearchItemToNormalizedSearchItem(item),
            type: 'product',
          } as IFdrAutoSuggestItem)
      )
      .filter(searchItem => searchItem.item);

    setActive(null);
    setAutoSuggestList([...nonProducts, ...products]);
  }

  const { data: productData } = useFdrTextSearchAutoComplete({
    searchValue: debouncedSearchValue,
    searchType: 'product',
    onCompleted: resProductData => {
      if (nonProductData) {
        handleData(resProductData, nonProductData);
      }
    },
  });

  const { data: nonProductData } = useFdrTextSearchAutoComplete({
    searchValue: debouncedSearchValue,
    searchType: 'nonProduct',
    onCompleted: resNonProductData => {
      if (productData) {
        handleData(productData, resNonProductData);
      }
    },
  });

  useEffect(() => debounceSearchValueCallback(searchValue), [searchValue]);

  useFdrSearchAutoSuggestKeyPress({
    upHandler: () =>
      setActive(getPrevFdrAutoSuggestItem(active, autoSuggestList)),
    downHandler: () =>
      setActive(getNextFdrAutoSuggestItem(active, autoSuggestList)),
    enterHandler: () => onSelect && active && onSelect(active),
  });

  useEffect(() => {
    if (autoSuggestList.length) {
      onChange({ count: autoSuggestList.length, activeItem: active });
      return;
    }

    onChange({ count: 0, activeItem: null });
  }, [autoSuggestList]);

  if (!autoSuggestList.length) return null;

  const products = autoSuggestList.filter(
    searchItem => searchItem.type === 'product'
  );

  const nonProducts = autoSuggestList.filter(
    searchItem => searchItem.type === 'nonProduct'
  );

  return (
    <FdrSearchAutoSuggestContent
      flex={!mobile && products.length > 0 && nonProducts.length > 0}
      showError={showError}
    >
      {nonProducts.length > 0 && (
        <FdrNonProductColumn
          flex={!mobile && products.length > 0}
          className="non-product"
        >
          <FdrSearchAutoSuggestTitle>
            {t('searchAutoSuggest.nonProducts.title')}
          </FdrSearchAutoSuggestTitle>
          {nonProducts.map((nonProd, index) => {
            return (
              <FdrNonProductLink
                key={index}
                active={
                  active && active.item.link.url === nonProd.item.link.url
                }
                onMouseOver={() => {
                  setActive(nonProd);
                  onChange({
                    count: autoSuggestList.length,
                    activeItem: active,
                  });
                }}
                onMouseOut={() => {
                  setActive(null);
                  onChange({ count: autoSuggestList.length, activeItem: null });
                }}
                onClick={e => {
                  e.preventDefault();
                  onSelect(nonProd);
                }}
              >
                {nonProd.item.name ? (
                  <FdrSearchAutoSuggestName
                    name={nonProd.item.name}
                    searchValue={debouncedSearchValue}
                  />
                ) : null}
              </FdrNonProductLink>
            );
          })}
        </FdrNonProductColumn>
      )}
      {products.length > 0 && (
        <FdrProductColumn
          flex={!mobile && nonProducts.length > 0}
          className="product"
        >
          <FdrProductHeaderContainer>
            <FdrProductHeader>
              {channelType !== AuthChannelType.B2B
                ? t('searchAutoSuggest.products.title')
                : t('searchAutoSuggest.products.title.b2b')}
            </FdrProductHeader>
          </FdrProductHeaderContainer>
          {products.map((prod, index) => {
            return (
              <FdrProductLink
                key={index}
                active={active && active.item.link.url === prod.item.link.url}
                onMouseOver={() => {
                  setActive(prod);
                  onChange({
                    count: autoSuggestList.length,
                    activeItem: active,
                  });
                }}
                onMouseOut={() => {
                  setActive(null);
                  onChange({ count: autoSuggestList.length, activeItem: null });
                }}
                onClick={e => {
                  e.preventDefault();
                  onSelect(prod);
                }}
              >
                <FdrProductImageContainer>
                  <FdrProductImage image={prod.item.image} />
                </FdrProductImageContainer>
                <FdrProductContent>{prod.item.name}</FdrProductContent>
              </FdrProductLink>
            );
          })}
          {onShowAll && (
            <FdrProductShowAllLinkContainer>
              <FdrAllItemsButton
                onClick={event => {
                  event.preventDefault();
                  onShowAll();
                }}
              >
                {t('searchAutoSuggest.showAll')}
              </FdrAllItemsButton>
            </FdrProductShowAllLinkContainer>
          )}
        </FdrProductColumn>
      )}
    </FdrSearchAutoSuggestContent>
  );
}

interface IFdrSearchAutoSuggestNameProps {
  name: string;
  searchValue: string;
}

function FdrSearchAutoSuggestName({
  name,
  searchValue,
}: IFdrSearchAutoSuggestNameProps): ReactElement {
  const searchTermPosition = getSearchTermPosition(name, searchValue);

  if (searchTermPosition) {
    const prefix =
      (searchTermPosition && name.substring(0, searchTermPosition[0])) || null;

    const match =
      (searchTermPosition &&
        name.substring(searchTermPosition[0], searchTermPosition[1] + 1)) ||
      null;

    const postfix =
      (searchTermPosition &&
        name.substring(searchTermPosition[0], searchTermPosition[1] + 1)) ||
      null;

    return (
      <>
        {prefix && (
          <FdrSearchAutoSuggestHighlight>
            {name.substring(0, searchTermPosition[0])}
          </FdrSearchAutoSuggestHighlight>
        )}
        {match ?? name}
        {postfix && (
          <FdrSearchAutoSuggestHighlight>
            {name.substring(searchTermPosition[1] + 1)}
          </FdrSearchAutoSuggestHighlight>
        )}
      </>
    );
  }

  return <>{name}</>;
}
