import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrGeoRecommenderFragmentDoc } from 'schemas/fragment/recommenders/geo/fdr-geo-recommender.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrGetGeoComponentQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  geoPage?: Types.Scalars['Int'];
}>;

export type FdrGetGeoComponentQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | {
          __typename?: 'FdrGeoRecommender';
          id: string;
          title?: string | null;
          fgColor?: string | null;
          bgColor?: string | null;
          itemsPage: {
            __typename?: 'FdrGeoRecommenderItem';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<
              | {
                  __typename?: 'FdrContinent';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'FdrCountry';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'FdrCountryGroup';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'FdrDestination';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'FdrRegion';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'FdrResort';
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
            > | null;
          };
        }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export const FdrGetGeoComponentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrGetGeoComponent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'geoPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrGeoRecommender',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrGeoRecommenderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrGetGeoComponentQuery__
 *
 * To run a query within a React component, call `useFdrGetGeoComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrGetGeoComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrGetGeoComponentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      geoPage: // value for 'geoPage'
 *   },
 * });
 */
export function useFdrGetGeoComponentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrGetGeoComponentQuery,
    FdrGetGeoComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrGetGeoComponentQuery,
    FdrGetGeoComponentQueryVariables
  >(FdrGetGeoComponentDocument, options);
}
export function useFdrGetGeoComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrGetGeoComponentQuery,
    FdrGetGeoComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrGetGeoComponentQuery,
    FdrGetGeoComponentQueryVariables
  >(FdrGetGeoComponentDocument, options);
}
export type FdrGetGeoComponentQueryHookResult = ReturnType<
  typeof useFdrGetGeoComponentQuery
>;
export type FdrGetGeoComponentLazyQueryHookResult = ReturnType<
  typeof useFdrGetGeoComponentLazyQuery
>;
export type FdrGetGeoComponentQueryResult = Apollo.QueryResult<
  FdrGetGeoComponentQuery,
  FdrGetGeoComponentQueryVariables
>;
