import React, { ReactElement } from 'react';
import { FdrTextBlock } from '@hotelplan/fdr.regular.basis.fdr-text-block';
import { FdrRawText } from '@hotelplan/fdr.regular.fusion.fdr-raw-text';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrBackgroundWrapper } from 'components/domain/fdr-background-wrapper';
import FdrBenefitsSection from 'components/domain/fdr-benefits-section/fdr-benefits-section';
import FdrContactSection from 'components/domain/fdr-contacts-section/fdr-contact-section';
import {
  getFdrProductRecommenderProducts,
  mapProductFeatureGroupsToProductFeatures,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrMediaGalleryPageComponent } from 'components/domain/fdr-page-components/media-gallery/fdr-media-gallery-page-component';
import { FdrAgencyRecommender } from 'components/domain/fdr-page-components/recommenders/agency/fdr-agency-recommender';
import FdrBlogRecommenderSection from 'components/domain/fdr-page-components/recommenders/blog-article/fdr-blog-recommender';
import { FdrCommonGridRecommender } from 'components/domain/fdr-page-components/recommenders/geo/fdr-common-grid-recommender';
import {
  FdrGeoRecommenderItem,
  TGeoItem,
} from 'components/domain/fdr-page-components/recommenders/geo/fdr-geo-recommender-item';
import { FdrMarketingRecommender } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-marketing-recommender';
import { FdrThemeRecommender } from 'components/domain/fdr-page-components/recommenders/theme/fdr-theme-recommender';
import { useGetGeoComponent } from 'components/domain/fdr-page-components/useGetFdrComponent';
import {
  isFdrAdventureTravelProduct,
  isFdrHotelProduct,
} from 'components/domain/fdr-product/fdr-product.utils';
import { FdrUspBoxes } from 'components/domain/fdr-usp-boxes';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import {
  FdrFaqContainer,
  FdrFaqWrapper,
  RichTextContainer,
} from './fdr-page-components.styles';
import FdrHeroMediaGalleryPageComponent from './media-gallery/fdr-hero-media-gallery-page-component';
import { FdrProductRecommender } from './recommenders/product/fdr-product-recommender';

interface IFdrPageComponentsProps {
  components?: FdrPageComponentsFragment['components'];
  bookmarked?: boolean;
}

export function FdrPageComponents({
  components,
  bookmarked,
}: IFdrPageComponentsProps): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <>
      {components?.map(function renderPageComponent(component, i) {
        switch (component.__typename) {
          case 'FdrMarkdownText':
          case 'FdrRawText': {
            const isRawText = component.__typename === 'FdrRawText';
            const topPadding = component.topPadding;
            const bottomPadding = component.bottomPadding;
            const textWidth = component.width;

            const imgUrl = component?.bgImage?.resized.filter(
              image => image.id === (mobile ? '1x_mobile' : '1x_desktop')
            )[0]?.link?.url;

            const textBlockWidth =
              textWidth === 'LARGE' ? 'large-text-width' : '';

            const textBlockBottomPadding =
              bottomPadding === 'PADDING_NONE'
                ? 'none-padding-bottom'
                : bottomPadding === 'PADDING_SMALL'
                ? 'small-padding-bottom'
                : '';

            const textBlockTopPadding =
              topPadding === 'PADDING_NONE'
                ? 'none-padding-top'
                : topPadding === 'PADDING_SMALL'
                ? 'small-padding-top'
                : '';

            return (
              <FdrBackgroundWrapper
                key={i}
                image={imgUrl}
                bgColor={component?.bgColor}
                fontColor={component?.fgColor}
              >
                <RichTextContainer
                  className={`${textBlockBottomPadding} ${textBlockTopPadding} anchor-section`}
                >
                  {isRawText ? (
                    <FdrRawText
                      uid={`raw-text-${i}`}
                      text={component?.text}
                      className={`raw-text ${textBlockWidth}`}
                    />
                  ) : (
                    <FdrTextBlock
                      text={component?.text}
                      className={`markdown-component ${textBlockWidth}`}
                    />
                  )}
                </RichTextContainer>
              </FdrBackgroundWrapper>
            );
          }

          case 'FdrFaq': {
            const isPrevIsFaq = components[i - 1]?.__typename === 'FdrFaq';
            const isNextIsFaq = components[i + 1]?.__typename === 'FdrFaq';

            return (
              <FdrFaqContainer key={i}>
                <FdrFaqWrapper
                  mt={!isPrevIsFaq}
                  mb={!isNextIsFaq}
                  {...component}
                />
              </FdrFaqContainer>
            );
          }

          case 'FdrHeroMediaGallery': {
            return (
              <FdrHeroMediaGalleryPageComponent
                key={i}
                component={component}
                bookmarked={bookmarked}
              />
            );
          }

          case 'FdrMediaGallery': {
            return (
              <FdrMediaGalleryPageComponent component={component} key={i} />
            );
          }

          case 'FdrUspBoxesComponent': {
            return component?.boxes ? (
              <FdrUspBoxes
                key={i}
                boxes={component.boxes}
                backgroundColor={component.bgColor}
                color={component.fgColor}
              />
            ) : null;
          }

          case 'FdrBenefitsComponent': {
            return (
              <FdrBenefitsSection
                key={`benefits-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrBlogArticleRecommender': {
            return (
              <FdrBlogRecommenderSection
                key={`blog-article-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrContactModule': {
            return (
              <FdrContactSection
                key={`contacts-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrMarketingRecommender': {
            return (
              <FdrMarketingRecommender
                key={`recommendation-group-${i}`}
                component={component}
              />
            );
          }

          case 'FdrAgencyRecommender': {
            return (
              <FdrAgencyRecommender
                key={`agency-recommendation-section-${component.id}`}
                fgColor={component.fgColor}
                bgColor={component.bgColor}
                title={component.title}
                agencies={component.agencies.agenciesPage || []}
              />
            );
          }

          case 'FdrGeoRecommender': {
            if (!component.itemsPage.items.length) return null;

            return (
              <FdrCommonGridRecommender
                key={`geo-rec-section-${component.id}`}
                bgColor={component.bgColor}
                fontColor={component.fgColor}
                id={component.id}
                title={component.title}
                items={component.itemsPage.items as TGeoItem[]}
                pagination={component.itemsPage.pagination}
                recommendationItem={FdrGeoRecommenderItem}
                useGet={useGetGeoComponent}
              />
            );
          }

          case 'FdrThemeRecommender': {
            return (
              <FdrThemeRecommender
                key={`static-theme-rec-group-${i}`}
                title={component.title}
                id={component.id}
                bgColor={component.bgColor}
                fontColor={component.fgColor}
                items={component.themePage.themes}
                displayType={component.displayType}
              />
            );
          }

          case 'FdrThemeGeoRecommender':
            if (component.displayType === 'SWIPE')
              return (
                <FdrThemeRecommender
                  key={`static-theme-rec-group-${i}`}
                  title={component.title}
                  id={component.id}
                  bgColor={component.bgColor}
                  fontColor={component.fgColor}
                  items={component.itemsPage.items.map(({ theme }) => theme)}
                  displayType={component.displayType}
                />
              );

            return (
              <FdrCommonGridRecommender
                key={`geo-rec-section-${component.id}`}
                bgColor={component.bgColor}
                fontColor={component.fgColor}
                id={component.id}
                title={component.title}
                items={component.itemsPage.items.map(
                  ({ geo }) => geo as TGeoItem
                )}
                pagination={component.itemsPage.pagination}
                recommendationItem={FdrGeoRecommenderItem}
                useGet={useGetGeoComponent}
              />
            );

          case 'FdrProductRecommender': {
            const products = getFdrProductRecommenderProducts(component);

            return (
              <FdrProductRecommender
                key={`static-product-rec-group-${i}`}
                testId="product-recommendations-section"
                component={{
                  ...component,
                  name: component.title,
                  items: products.map(p => ({
                    ...p,
                    link: p.webMeta.descriptionWebMeta.link,
                    image: p.images[0],
                    features: isFdrHotelProduct(p)
                      ? []
                      : mapProductFeatureGroupsToProductFeatures(
                          p.featureGroups
                        ),
                    route: isFdrAdventureTravelProduct(p)
                      ? p.route?.map(r => r.locationName)
                      : [],
                  })),
                  total: component.productPage.pagination.total,
                }}
              />
            );
          }

          default: {
            return null;
          }
        }
      })}
    </>
  );
}
