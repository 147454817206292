import { useTranslation } from 'next-i18next';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/featureList';
import { FlaglineText } from 'theme/headings';
import { FdrSearchAutoSuggest } from './fdr-search-auto-suggest';
import {
  FdrAutoSuggestOverlay,
  FdrSearchButton,
  FdrSearchDetailsContent,
  FdrSearchForm,
  FdrSearchInput,
  FdrSearchInputError,
  FdrSearchInputWrap,
  FdrSearchResetButton,
  FdrSearchResultList,
  FdrSearchResultTags,
  FdrSearchResultTagsWrapper,
} from './fdr-search-overlay.styles';
import {
  FDR_SEARCH_SECTION_KEY_PREFIX,
  FDR_SEARCH_VALUE_KEY,
  trackFdrSearchSuggestion,
} from './fdr-search-overlay.utils';
import { IHistoryItems } from './useFdrLastSearchedViewed';

const DEFAULT_MIN_LENGTH = 3;
const AUTO_SUGGEST_SEARCH_VALUE_LENGTH = 2;
const ADVENTURE_TRAVEL_SECTION_KEY = 'adventuretravel';

interface IFdrSearchOverlayContentProps {
  searchedItems: IHistoryItems;
  viewedItems: IHistoryItems;
  initialSearchValue?: string;
  searchTrends?: string[];
  mostSearched?: string[];
  onSearch?(query: string, sectionKeys?: string[]): void;
  minLength?: number;
}

export function FdrSearchOverlayContent({
  initialSearchValue,
  searchTrends = [],
  mostSearched = [],
  onSearch,
  minLength = DEFAULT_MIN_LENGTH,
  searchedItems,
  viewedItems,
}: IFdrSearchOverlayContentProps): ReactElement {
  const { isEnabled } = useFeatureToggle();
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const [t] = useTranslation(['common', 'search']);

  const inputRef = useRef<HTMLInputElement>(null);

  const [showError, setShowError] = useState<boolean>(false);
  const [autoSuggestState, setAutoSuggestState] = useState(null);
  const [blockAutoSuggest, setBlockAutoSuggest] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(
    initialSearchValue || ''
  );
  const [autoSuggestOverlayShown, setAutoSuggestOverlayShown] =
    useState<boolean>(false);

  const sessionStorageSearchValue =
    sessionStorage.getItem(FDR_SEARCH_VALUE_KEY);

  function setDefaultValue() {
    setShowError(false);
    setSearchValue('');
  }

  function submitHandler(submittedQuery = searchValue, sectionKeys?: string[]) {
    if (submittedQuery.length < minLength) {
      setShowError(true);
      return;
    }

    if (autoSuggestState?.activeItem) {
      return;
    }

    onSearch(submittedQuery, sectionKeys);
  }

  function goFor(query: string, sectionKeys?: string[]) {
    clearSectionsPosition();
    setSearchValue(query);
    requestAnimationFrame(() => {
      submitHandler(query, sectionKeys);
    });
  }

  const onShowAll = useCallback(() => {
    goFor(searchValue, [ADVENTURE_TRAVEL_SECTION_KEY]);
  }, [searchValue]);

  function clearSectionsPosition() {
    const savedSectionsPositionsKeys = Object.keys(sessionStorage).filter(
      key => key.includes(FDR_SEARCH_SECTION_KEY_PREFIX) && key
    );
    savedSectionsPositionsKeys.forEach(key => {
      sessionStorage.removeItem(key);
    });
  }

  useEffect(function inputAutoFocusEffect() {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (autoSuggestState?.count > 0) {
      setAutoSuggestOverlayShown(true);
    } else {
      setAutoSuggestOverlayShown(false);
    }
  }, [autoSuggestState]);

  useEffect(() => {
    if (!sessionStorageSearchValue || searchValue === sessionStorageSearchValue)
      return;

    setBlockAutoSuggest(false);
  }, [searchValue, sessionStorageSearchValue]);

  useEffect(() => {
    if (!sessionStorageSearchValue) return;

    setSearchValue(sessionStorageSearchValue);
    setBlockAutoSuggest(true);
  }, [sessionStorageSearchValue]);

  const {
    items: recentSearched,
    nextPage: nextSearchedPage,
    hasMore: hasMoreSearched,
    loading: searchedLoading,
  } = searchedItems;

  const {
    items: recentViewed,
    nextPage: nextViewedPage,
    hasMore: hasMoreViewed,
    loading: viewedLoading,
  } = viewedItems;

  return (
    <FdrSearchDetailsContent>
      <FdrAutoSuggestOverlay
        visible={autoSuggestOverlayShown}
        onClick={() => {
          setDefaultValue();
          setAutoSuggestOverlayShown(false);
          inputRef.current.focus();
        }}
      />
      <FdrSearchForm
        autoSuggestOverlayShown
        role="search"
        onSubmit={e => {
          e.preventDefault();
          clearSectionsPosition();
          submitHandler();
        }}
      >
        <FdrSearchInputWrap>
          <FdrSearchInput
            className={showError ? 'error' : undefined}
            inputRef={inputRef}
            hideLabel
            type="search"
            placeholder={t('search:searchInput.placeholder')}
            icon={{
              name: `search`,
              size: mobile ? 'sm' : 'md',
            }}
            value={searchValue}
            onChange={e => {
              const nextValue = e.target.value;
              if (showError && nextValue.length >= minLength) {
                setShowError(false);
              }
              setSearchValue(nextValue);
            }}
          />
          {searchValue.length ? (
            <FdrSearchResetButton
              type="reset"
              variant="iconBtn"
              icon={{
                name: 'close-icon',
                size: mobile ? 'sm' : 'md',
              }}
              onClick={setDefaultValue}
            >
              {t('search:searchInput.submit')}
            </FdrSearchResetButton>
          ) : null}
          {showError ? (
            <FdrSearchInputError>
              {t('search:searchInput.error')}
            </FdrSearchInputError>
          ) : null}
        </FdrSearchInputWrap>
        <FdrSearchButton
          type="submit"
          icon={{
            name: 'chevron-long-right',
            size: 'md',
          }}
        >
          <span className="search-submit-button-text">
            {t('search:searchInput.submit')}
          </span>
        </FdrSearchButton>
        {isEnabled(FeatureList.AutoSuggest) &&
        searchValue.length >= AUTO_SUGGEST_SEARCH_VALUE_LENGTH &&
        !blockAutoSuggest ? (
          <FdrSearchAutoSuggest
            searchValue={searchValue}
            showError={showError}
            onChange={state => {
              setAutoSuggestState(state);
            }}
            onSelect={({ type, item }) => {
              if (type === 'nonProduct') {
                goFor(item?.name);
              } else {
                if (typeof window === 'undefined') return;
                window.location.href = item?.link?.url;
              }
            }}
            onShowAll={
              channelType !== AuthChannelType.B2B ? onShowAll : undefined
            }
          />
        ) : null}
      </FdrSearchForm>
      <FdrSearchResultTagsWrapper autoSuggestOverlayShown>
        <FdrSearchResultTags>
          <FlaglineText className="tagline-text">
            {t('search:mostSearched')}
          </FlaglineText>
          <div className="search-result-tags most-searched">
            {mostSearched?.map(mostSearchedQuery => (
              <BsButton
                key={mostSearchedQuery}
                variant="tagBtn"
                className="search-result-tags-items"
                onClick={e => {
                  trackFdrSearchSuggestion(`most-used`, mostSearchedQuery);
                  setSearchValue((e.target as Element).textContent);
                  goFor(mostSearchedQuery);
                }}
              >
                {mostSearchedQuery}
              </BsButton>
            ))}
          </div>
        </FdrSearchResultTags>
        <FdrSearchResultTags>
          <FlaglineText className="tagline-text">
            {t('search:inTrends')}
          </FlaglineText>
          <div className="search-result-tags search-trends">
            {searchTrends?.map(trendQuery => (
              <BsButton
                key={trendQuery}
                variant="tagBtn"
                className="search-result-tags-items"
                onClick={e => {
                  trackFdrSearchSuggestion(`in-trend`, trendQuery);
                  setSearchValue((e.target as Element).textContent);
                  goFor(trendQuery);
                }}
              >
                {trendQuery}
              </BsButton>
            ))}
          </div>
        </FdrSearchResultTags>
      </FdrSearchResultTagsWrapper>
      <FdrSearchResultList>
        <div className="heading-wrap">
          <FlaglineText className="tagline-text">
            {t('search:recentSearched')}
          </FlaglineText>
        </div>
        {recentSearched?.length > 0 ? (
          <ul className="list">
            {recentSearched.map((record, index) => (
              <li key={index}>
                <FdrRoute
                  link={record.link}
                  onClick={() => {
                    clearSectionsPosition();
                    trackFdrSearchSuggestion(`last-seen`, record.text);
                  }}
                >
                  {record.text}
                </FdrRoute>
              </li>
            ))}
          </ul>
        ) : null}
        {searchedLoading ? t('search:loading') : null}
        {!searchedLoading && hasMoreSearched ? (
          <BsButtonWithIcon
            variant="linkBtn"
            onClick={nextSearchedPage}
            icon={{
              name: 'chevron-long-right',
              size: 'sm',
            }}
          >
            {t('loadMore.button')}
          </BsButtonWithIcon>
        ) : null}
      </FdrSearchResultList>

      <FdrSearchResultList>
        <div className="heading-wrap">
          <FlaglineText className="tagline-text">
            {t('search:recentViewed')}
          </FlaglineText>
        </div>
        {recentViewed?.length > 0 ? (
          <ul className="list">
            {recentViewed.map((record, index) => (
              <li key={index}>
                <FdrRoute
                  link={record.link}
                  onClick={() =>
                    trackFdrSearchSuggestion(`last-seen`, record.text)
                  }
                >
                  {record.text}
                </FdrRoute>
              </li>
            ))}
          </ul>
        ) : null}
        {viewedLoading ? t('search:loading') : null}
        {!viewedLoading && hasMoreViewed ? (
          <BsButtonWithIcon
            variant="linkBtn"
            onClick={nextViewedPage}
            icon={{
              name: 'chevron-long-right',
              size: 'sm',
            }}
          >
            {t('loadMore.button')}
          </BsButtonWithIcon>
        ) : null}
      </FdrSearchResultList>
    </FdrSearchDetailsContent>
  );
}
