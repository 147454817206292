import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import ShareForm from 'components/domain/share-form/ShareForm';

export const ShareLink = styled(Link)(({ theme: { colors } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '42px',
  height: '42px',
  borderRadius: '5px',
  backgroundColor: colors.paperGrey,
  ':hover, :focus-visible': {
    border: `1px solid ${colors.redHover}`,
    color: colors.redHover,
  },
  '.icon': {
    fill: 'transparent',
  },
}));

export const PrintButton = styled(Button).attrs({
  variant: 'iconBtn',
})({
  backgroundColor: 'transparent',
  padding: '0px',
  '&:not(:first-child)': {
    marginLeft: '15px',
  },
});

export const ShareOverlayWrap = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    '.bookmark-site-tools-button-wrapper + &': {
      width: '100%',
    },
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    position: 'absolute',
    maxWidth: '600px',
    width: '90%',
    top: 0,
    right: 0,
    backgroundColor: colors.white,
    borderRadius: '5px',
    padding: '20px',
    zIndex: 10,
    color: '#000000',
    textShadow: 'none',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.1)',
    '.tagline-text': {
      fontSize: '15px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
  })
);

export const SearchDetailsContent = styled.div(
  ({ theme: { fontSizes, media } }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    [media.tablet]: {
      gap: '20px',
    },
    '.heading': {
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: '700',
      [media.tablet]: {
        fontSize: '24px',
        lineHeight: fontSizes[7],
      },
    },
  })
);

export const ImageWrap = styled.div({
  width: '70px',
  height: '70px',
  flexShrink: 0,
  '.image': {
    display: 'block',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  '.copyright': {
    display: 'none',
  },
});

export const ShareSocialNetworksList = styled.div({
  display: 'flex',
  gap: '16px',
});

export const ModalCloseButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: { name: 'close' },
  'data-id': `close`,
})(
  sx2CssThemeFn({
    position: 'absolute',
    top: [3, 3],
    right: [3, 3],
    backgroundColor: 'inherit',
    '.icon': {
      variant: 'icons.lg',
      fill: `black`,
      marginRight: '0px',
    },
    '&:hover,&:focus,&:active': {
      backgroundColor: 'transparent',
      borderColor: 'none',
      svg: {
        fill: 'inherit',
      },
    },
  })
);

export const ShareFormWrap = styled(ShareForm)(({ theme: { colors } }) =>
  sx2CssThemeFn({
    gap: '16px',
    '.label': {
      fontSize: '0px',
      margin: '0px',
    },
    '.input-wrap': {
      marginRight: 0,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '42px',
      height: '42px',
      borderRadius: '5px',
      backgroundColor: colors.paperGrey,
      margin: 0,
      ':hover, :focus, :focus-visible, :active': {
        border: `1px solid ${colors.redHover}`,
        backgroundColor: colors.paperGrey,
        '.icon': {
          color: colors.redHover,
        },
      },
      ':focus-visible': {
        border: `2px solid ${colors.black}`,
      },
      '.icon': {
        fill: 'transparent',
        width: 'auto',
        height: '20px',
      },
    },
    '.input': {
      height: '42px',
      px: 2,
      py: '10px',
      border: 'none',
      borderBottom: `1px solid ${colors.darkGrey}`,
      background: 'none',
      fontWeight: '450',
      fontSize: 1,
      lineHeight: '22px',
      letterSpacing: '0.5px',
    },
  })
);
