import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const LeftBtn = styled(BsButtonWithIcon).attrs({
  variant: 'iconBtn',
  icon: {
    name: 'chevron-left',
    size: 'md',
  },
})(({ theme: { colors } }) => ({
  background: colors.paperGrey,
  transform: 'rotate(180deg)',
  height: '42px',
  width: '42px',
  borderRadius: '5px',
  color: colors.black,
  '&:disabled': {
    opacity: 0.5,
  },
}));

export const RightBtn = styled(LeftBtn)({
  transform: 'rotate(0deg)',
});

export const PaginationBlock = styled.div({
  display: 'flex',
  marginLeft: 'auto',
});

export const Pages = styled.div(
  sx2CssThemeFn({
    fontSize: 1,
    fontWeight: '700',
    letterSpacing: '3px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: 'black',
  })
);
