import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrLink } from '@hotelplan/supergraph-api';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrGeoBreadcrumbsDocument,
  FdrGeoBreadcrumbsQuery,
  FdrGeoBreadcrumbsQueryVariables,
} from 'schemas/query/geo/fdr-geo-breadcrumbs.generated';
import {
  FdrHomePageUrlDocument,
  FdrHomePageUrlQuery,
  FdrHomePageUrlQueryVariables,
} from 'schemas/query/home/fdr-home-page-url.generated';
import {
  createBreadcrumb,
  getHardcodedCaption,
  getHomeBreadcrumb,
} from './utils.common';

function getCommonGeoBreadcrumbs(
  data: {
    fdrHomePage?: { webMeta: { link: FdrLink } };
    fdrGeoOverviewPage?: { webMeta: { link: FdrLink } };
  },
  lang: string
): Array<TFdrBreadcrumb> {
  return [
    ...getHomeBreadcrumb(data, lang),
    createBreadcrumb(
      data?.fdrGeoOverviewPage?.webMeta.link || null,
      getHardcodedCaption(EPageType.GeoOverview, lang)
    ),
  ];
}

export async function getFdrGeoOverviewBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrHomePageUrlQuery,
    FdrHomePageUrlQueryVariables
  >({
    query: FdrHomePageUrlDocument,
  });

  const breadcrumbs = getCommonGeoBreadcrumbs(data, lang);
  breadcrumbs[1].disabled = true;

  return breadcrumbs;
}

export async function getFdrGeoBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrGeoBreadcrumbsQuery,
    FdrGeoBreadcrumbsQueryVariables
  >({
    query: FdrGeoBreadcrumbsDocument,
    variables: { id: pageId },
  });

  const commonBreadcrumbs = getCommonGeoBreadcrumbs(data, lang);

  switch (data?.fdrGeo.__typename) {
    case 'FdrContinent': {
      return [
        ...commonBreadcrumbs, // home, geo overview
        createBreadcrumb(null, data.fdrGeo.name, true), // current geo
      ];
    }
    case 'FdrCountry': {
      return [
        ...commonBreadcrumbs, // home, geo overview
        // continent
        createBreadcrumb(
          data.fdrGeo.continent.webMeta.descriptionWebMeta.link,
          data.fdrGeo.continent.name
        ),
        createBreadcrumb(null, data.fdrGeo.name, true), // current geo
      ];
    }
    case 'FdrRegion': {
      return [
        ...commonBreadcrumbs, // home, geo overview
        // continent
        createBreadcrumb(
          data.fdrGeo.country.continent.webMeta.descriptionWebMeta.link,
          data.fdrGeo.country.continent.name
        ),
        // country
        createBreadcrumb(
          data.fdrGeo.country.webMeta.descriptionWebMeta.link,
          data.fdrGeo.country.name
        ),
        createBreadcrumb(null, data.fdrGeo.name, true), // current geo
      ];
    }
    default: {
      return commonBreadcrumbs;
    }
  }
}
