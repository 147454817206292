import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { openFdrSearchOverlay } from 'components/domain/fdr-search-overlay';
import { FdrSiteToolsButtonsWrapper } from './fdr-sitetools.styles';

export function FdrSearchSiteTool(): React.ReactElement {
  const [t] = useTranslation('search');

  return (
    <FdrSiteToolsButtonsWrapper
      className="site-tool-button-2"
      role="button"
      data-id="search-site-tool-2"
    >
      <BsButtonWithIcon
        variant="headerBtn"
        icon={{ name: 'search' }}
        onClick={openFdrSearchOverlay}
      />
      <span className="tooltip">{t('search:searchInput.submit')}</span>
    </FdrSiteToolsButtonsWrapper>
  );
}
