import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrProductRecommenderContainer } from 'components/domain/fdr-product-recommender';
import CommonGridRecommendationSection from 'components/domain/recommendations/CommonGridRecommendationSection';
import ProductRecommendationCustomContent from 'components/domain/recommendations/ProductRecommendationsSectionCustomContent';
import {
  PRODUCT_GRID_ITEMS_PER_PAGE,
  ProductRecommendationView,
} from 'components/domain/recommendations/Recommendations.constants';
import { FdrProductRecommendation } from './fdr-product-recommendation';
import {
  IFdrRecommenderProduct,
  IFdrRecommenderProductComponent,
} from './fdr-product-recommender.types';

const ProductRecommendationGridItem: React.FC<{
  item: IFdrRecommenderProduct;
}> = ({ item }) => {
  return <FdrProductRecommendation key={item.link.url} {...item} />;
};

interface IFdrProductRecommenderProps {
  component: IFdrRecommenderProductComponent;
  id?: string;
  loadMoreText?: string;
  view?: ProductRecommendationView;
  testId?: string;
  sectionHeaderTitle?: string;
}

export function FdrProductRecommender({
  component,
  id,
  loadMoreText,
  view = ProductRecommendationView.SLIDER,
  testId,
  children,
  sectionHeaderTitle,
}: PropsWithChildren<IFdrProductRecommenderProps>): ReactElement {
  if (view === ProductRecommendationView.SLIDER) {
    return (
      <FdrProductRecommenderContainer
        id={id}
        testId={testId}
        sectionTitle={sectionHeaderTitle}
        customContent={
          <ProductRecommendationCustomContent>
            {children}
          </ProductRecommendationCustomContent>
        }
        {...component}
      />
    );
  }

  // todo: change memory pagination to fetching
  return (
    <CommonGridRecommendationSection<IFdrRecommenderProduct>
      items={component.items as IFdrRecommenderProduct[]}
      recommendationItem={ProductRecommendationGridItem}
      perPage={PRODUCT_GRID_ITEMS_PER_PAGE}
      id={id}
      mainTitle={component.name}
      loadMoreText={loadMoreText}
      testId={testId}
    >
      {children}
    </CommonGridRecommendationSection>
  );
}
