import React, { FC } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import { LeftBtn } from './ArrowPagiantion.styles';
import { IArrowPaginationProps } from './ArrowPagination';
import {
  handleLeftArrowClick,
  handleRightArrowClick,
} from './ArrowPagination.utils';

export const LeftArrowButton = styled(LeftBtn)<{ imageHeight?: number }>(
  ({ imageHeight, theme: { colors } }) =>
    sx2CssThemeFn({
      position: 'absolute',
      top: imageHeight ? `${imageHeight / 2 - 21}px` : 'calc(50% - 21px)',
      left: '-20px',
      zIndex: 20,
      boxShadow: '0px -4px 6px 0px #00000033',
      '&:focus,&:active': {
        background: colors.paperGrey,
        border: 'none',
        svg: {
          fill: colors.black,
        },
      },
      '&:hover': {
        backgroundColor: colors.paperGrey,
        borderColor: colors.black,
        border: '1px solid',
        svg: {
          fill: colors.black,
        },
      },
    })
);

export const RightArrowButton = styled(LeftArrowButton)(
  sx2CssThemeFn({
    left: 'auto',
    right: '-20px',
    transform: 'rotate(0deg)',
    boxShadow: '0px 4px 6px 0px #00000033',
  })
);

export const SliderArrowPagination: FC<
  IArrowPaginationProps & {
    imageHeight?: number;
    trackingSource?: PaginationArrowClickSource;
  }
> = ({
  children,
  onNextPage,
  onPrevPage,
  page,
  totalPages,
  imageHeight,
  trackingSource,
}) => {
  return (
    <>
      {page > 0 ? (
        <LeftArrowButton
          imageHeight={imageHeight + 80}
          onClick={() => {
            handleLeftArrowClick({ onPrevPage, trackingSource });
          }}
          disabled={page <= 0}
          data-id="prev"
        />
      ) : null}
      {children}
      {page !== totalPages - 1 ? (
        <RightArrowButton
          imageHeight={imageHeight + 80}
          onClick={() => {
            handleRightArrowClick({ onNextPage, trackingSource });
          }}
          disabled={page >= totalPages - 1}
          data-id="next"
        />
      ) : null}
    </>
  );
};
