import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrNotificationsWrapper = styled.div(
  sx2CssThemeFn({
    position: 'fixed',
    bottom: ['76px', 0],
    left: '0',
    zIndex: '101',
    width: '100%',
  })
);
