import { useGoogleMap } from '@react-google-maps/api';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { FdrMapInfoBlock } from 'components/domain/fdr-map';
import { FdrCustomInfoWindow } from './fdr-custom-info-window';
import { FdrDoublePolyline } from './fdr-double-polyline';
import { FdrRouteContext } from './fdr-routes.context';

interface IFdrRoutePolylinesProps {
  defaultZoom?: number;
  isGeo?: boolean;
}

export const FdrRoutePolylines: React.FC<IFdrRoutePolylinesProps> = ({
  defaultZoom,
  isGeo = false,
}): ReactElement => {
  const [latLng, setLatLng] = useState(null);
  const map = useGoogleMap();
  const pageType = usePageType<EPageType>();

  const { click, isRouteMode, products, active, showNearBy, hasCurrent } =
    useContext(FdrRouteContext);

  const list = useMemo(
    () => products?.map(i => i.routePoints)?.filter(i => i?.length > 0),
    [products]
  );

  useIsomorphicLayoutEffect(() => {
    if (!showNearBy && latLng) {
      setLatLng(null);
      click(null);
    }
  }, [showNearBy]);

  const requiredReturnConditions = !isRouteMode || !map;
  const additionReturnCondition =
    list.length === 1 ||
    (hasCurrent && (list.length > 1 || !list[0]) && !showNearBy);

  useEffect(() => {
    if (requiredReturnConditions || additionReturnCondition || !list[0]) return;

    const bounds = new google.maps.LatLngBounds();

    isGeo
      ? list.forEach(product => {
          product.forEach(route => {
            bounds.extend(mapCoordinatesToLatLng(route.coordinates));
          });
        })
      : list[0].forEach(route => {
          bounds.extend(mapCoordinatesToLatLng(route.coordinates));
        });

    requestAnimationFrame(() => {
      map.fitBounds(bounds, 30);
      if (isRouteMode && defaultZoom) {
        map.setZoom(defaultZoom);
      }
    });
  }, [requiredReturnConditions, showNearBy, additionReturnCondition]);

  if (!isRouteMode) return null;

  const selected = products[active];
  const content = selected ? <FdrCustomInfoWindow selected={selected} /> : null;

  const handleInfoBlockClose = () => {
    setLatLng(null);
    click(undefined);
  };

  return (
    <>
      {list.map((items, key) => {
        return (
          <FdrDoublePolyline
            key={key}
            keyCode={key}
            items={items}
            list={list}
            setLatLng={setLatLng}
          />
        );
      })}

      {latLng && showNearBy && (
        <FdrMapInfoBlock
          latLng={latLng}
          onCloseClick={handleInfoBlockClose}
          isOpenedByRouteOnGeo={pageType === EPageType.GeoObject}
        >
          {content}
        </FdrMapInfoBlock>
      )}
    </>
  );
};
