import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrOpeningHoursFragment = {
  __typename?: 'FdrOpeningHours';
  monday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  tuesday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  wednesday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  thursday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  friday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  saturday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
  sunday?: {
    __typename?: 'FdrWorkingDay';
    shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
  } | null;
};

export type FdrWorkingDayFragment = {
  __typename?: 'FdrWorkingDay';
  shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
};

export type FdrShiftFragment = { __typename?: 'FdrShift'; from: any; to: any };

export const FdrShiftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrShift' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrShift' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrWorkingDayFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWorkingDay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrWorkingDay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrShift' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrShiftFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrOpeningHoursFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrOpeningHours' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrOpeningHours' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tuesday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wednesday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thursday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'friday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sunday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWorkingDay' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrWorkingDayFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
