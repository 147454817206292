import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  BookmarkModalPopupType,
  TShareBookmarkGroupReplace,
  useBookmarkModalPopupContext,
} from 'components/domain/fdr-bookmark/context/fdr-bookmark-modal-popup-context';
import useAddSharedBookmarkGroup from 'components/domain/fdr-bookmark/hooks/useAddSharedBookmarkGroup';
import useRedirectToBookmarkPage from 'components/domain/fdr-bookmark/hooks/useRedirectToBookmarkPage';
import {
  BookmarkPopup,
  BookmarkPopupButtonRow,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentView,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './fdr-bookmark-modal-popup.styles';

const BookmarkGroupShareModalPopupButtonRow = styled(BookmarkPopupButtonRow)(
  () =>
    sx2CssThemeFn({
      flexDirection: ['column-reverse' as const, 'row'],
      button: {
        width: ['100%', 'initial'],
        marginTop: ['10px', 'initial'],
        '&:last-child': {
          marginTop: [0, 'initial'],
        },
      },
    })
);

const FdrBookmarkGroupShareModalPopup: React.FC = () => {
  const bookmarkModalPopupContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common', 'bookmark']);
  const { redirect: redirectToBookmarkPage } = useRedirectToBookmarkPage();
  const {
    payload: { bookmarkGroup },
  } = bookmarkModalPopupContext.modal as TShareBookmarkGroupReplace;

  const {
    addSharedBookmarkGroupMutation,
    addSharedBookmarkGroupMutationData,
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationError,
  } = useAddSharedBookmarkGroup();

  useEffect(() => {
    if (
      (typeof addSharedBookmarkGroupMutationData?.fdrAddSharedBookmarkGroup
        ?.result?.success !== 'undefined' &&
        !addSharedBookmarkGroupMutationData.fdrAddSharedBookmarkGroup.result
          .success) ||
      addSharedBookmarkGroupMutationError
    ) {
      bookmarkModalPopupContext.open({
        type: BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_ERROR,
      });
    } else if (
      addSharedBookmarkGroupMutationData?.fdrAddSharedBookmarkGroup?.result
        ?.success
    ) {
      bookmarkModalPopupContext.close();
      redirectToBookmarkPage();
    }
  }, [addSharedBookmarkGroupMutationError, addSharedBookmarkGroupMutationData]);

  const onApply = useCallback(() => {
    if (
      addSharedBookmarkGroupMutationLoading ||
      addSharedBookmarkGroupMutationData
    )
      return;

    addSharedBookmarkGroupMutation({
      variables: { input: { bookmarkGroupId: bookmarkGroup } },
    });
  }, [
    addSharedBookmarkGroupMutationLoading,
    addSharedBookmarkGroupMutationData,
  ]);

  const onCancel = useCallback(() => {
    bookmarkModalPopupContext.close();
    redirectToBookmarkPage();
  }, []);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark:group.share.replace.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={onCancel}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent view={BookmarkPopupContentView.COMPACT}>
        <BookmarkPopupTextRow>
          <p>{t('bookmark:group.share.replace.text-1')}</p>
          <p>{t('bookmark:group.share.replace.text-2')}</p>
        </BookmarkPopupTextRow>
        <BookmarkGroupShareModalPopupButtonRow>
          <BsButton variant="tagBtn" onClick={onCancel}>
            {t('bookmark:group.share.replace.cancel')}
          </BsButton>
          <BsButton
            disabled={addSharedBookmarkGroupMutationLoading}
            className="form-submit-button"
            onClick={onApply}
          >
            {t('bookmark:group.share.replace.apply')}
          </BsButton>
        </BookmarkGroupShareModalPopupButtonRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default withLazyHydrate(FdrBookmarkGroupShareModalPopup, {
  whenIdle: true,
  noWrapper: true,
});
