import { track } from '@hotelplan/libs.tracking-events';
import { TrackingEventType } from './tracking.types';

export const trackHelpOverlayOpenClick = (): void => {
  track({
    event: TrackingEventType.CLICK,
    eventMetadata: {
      action: `goto-contact`,
      source: `menu`,
    },
    _clear: true,
  });
};

export const trackHelpOverlayCloseClick = (): void => {
  track({
    event: `🚀 helpoverlay.close`,
    eventMetadata: {
      source: `header`,
    },
    _clear: true,
  });
};
