import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrSrlEmptyResultTextQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrSrlEmptyResultTextQuery = {
  __typename?: 'Query';
  fdrProductSearchResultListPage?: {
    __typename?: 'FdrProductSearchResultListPage';
    text?: string | null;
  } | null;
};

export const FdrSrlEmptyResultTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrSrlEmptyResultText' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProductSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrSrlEmptyResultTextQuery__
 *
 * To run a query within a React component, call `useFdrSrlEmptyResultTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSrlEmptyResultTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSrlEmptyResultTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrSrlEmptyResultTextQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrSrlEmptyResultTextQuery,
    FdrSrlEmptyResultTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrSrlEmptyResultTextQuery,
    FdrSrlEmptyResultTextQueryVariables
  >(FdrSrlEmptyResultTextDocument, options);
}
export function useFdrSrlEmptyResultTextLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrSrlEmptyResultTextQuery,
    FdrSrlEmptyResultTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrSrlEmptyResultTextQuery,
    FdrSrlEmptyResultTextQueryVariables
  >(FdrSrlEmptyResultTextDocument, options);
}
export type FdrSrlEmptyResultTextQueryHookResult = ReturnType<
  typeof useFdrSrlEmptyResultTextQuery
>;
export type FdrSrlEmptyResultTextLazyQueryHookResult = ReturnType<
  typeof useFdrSrlEmptyResultTextLazyQuery
>;
export type FdrSrlEmptyResultTextQueryResult = Apollo.QueryResult<
  FdrSrlEmptyResultTextQuery,
  FdrSrlEmptyResultTextQueryVariables
>;
