import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrDeleteBookmarkGroupMutationVariables = Types.Exact<{
  input: Types.FdrDeleteBookmarkGroupInput;
}>;

export type FdrDeleteBookmarkGroupMutation = {
  __typename?: 'Mutation';
  fdrDeleteBookmarkGroup: {
    __typename?: 'FdrDeleteBookmarkGroupResponse';
    success: boolean;
  };
};

export const FdrDeleteBookmarkGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrDeleteBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrDeleteBookmarkGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrDeleteBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrDeleteBookmarkGroupMutationFn = Apollo.MutationFunction<
  FdrDeleteBookmarkGroupMutation,
  FdrDeleteBookmarkGroupMutationVariables
>;

/**
 * __useFdrDeleteBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useFdrDeleteBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrDeleteBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrDeleteBookmarkGroupMutation, { data, loading, error }] = useFdrDeleteBookmarkGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrDeleteBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrDeleteBookmarkGroupMutation,
    FdrDeleteBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrDeleteBookmarkGroupMutation,
    FdrDeleteBookmarkGroupMutationVariables
  >(FdrDeleteBookmarkGroupDocument, options);
}
export type FdrDeleteBookmarkGroupMutationHookResult = ReturnType<
  typeof useFdrDeleteBookmarkGroupMutation
>;
export type FdrDeleteBookmarkGroupMutationResult =
  Apollo.MutationResult<FdrDeleteBookmarkGroupMutation>;
export type FdrDeleteBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  FdrDeleteBookmarkGroupMutation,
  FdrDeleteBookmarkGroupMutationVariables
>;
