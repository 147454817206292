import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButton } from '@hotelplan/core.basis.bs-button';

export const FdrB2BMarkupFormWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    zIndex: 10,
    display: 'inline-block',
    top: 'calc(100% + 5px)',
    left: '50%',
    transform: 'translateX(-50%)',
    '.markup-control-button': {
      display: 'flex',
      borderRadius: '50%',
      background: 'white',
      boxShadow: 'tooltip',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 4,
      span: {
        height: '40px', // adjust font shift
      },
    },
    '&, > button': {
      width: '20px',
      height: '20px',
    },
  })
);

export const FdrFormWrapper = styled.div(
  sx2CssThemeFn({
    px: 4,
    py: 3,
    zIndex: 4,
    position: 'absolute',
    borderTop: '1px solid',
    display: 'inline-block',
    boxShadow: 'landDropdown',
    borderColor: 'borderColor',
    top: 'calc(100% + 10px)',
    right: 'calc(100% - 20px)',
    backgroundColor: 'markupForm',
  })
);

export const FdrFormContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    '.form-item-validate': {
      height: '52px',
      width: '250px',
      mr: 3,
    },
    '.form-item-submit': {
      width: '150px',
      height: '52px',
    },
  })
);

export const FdrMarkupFormSubmitButton = styled(BsButton)(
  sx2CssThemeFn({
    px: 3,
  })
);
