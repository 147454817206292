import React, { ReactElement } from 'react';
import { FdrDisrupter as FdrDisrupterType } from '@hotelplan/supergraph-api';
import { FdrDisrupterBody, FdrDisruptersWrapper } from './fdr-disrupter.styles';

interface IFdrDisruptersProps {
  disrupters: Array<FdrDisrupterType>;
  className?: string;
}

export function FdrDisrupters({
  disrupters,
  className,
}: IFdrDisruptersProps): ReactElement {
  return (
    <FdrDisruptersWrapper className={className}>
      {disrupters.map((item, index) => {
        return <FdrDisrupter {...item} key={`disrupter-${index}`} />;
      })}
    </FdrDisruptersWrapper>
  );
}

export function FdrDisrupter({
  text,
  fgColor,
  bgColor,
}: FdrDisrupterType): ReactElement {
  return (
    <FdrDisrupterBody
      color={fgColor}
      backgroundColor={bgColor}
      className="disrupter"
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </FdrDisrupterBody>
  );
}
