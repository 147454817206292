import clamp from 'lodash/clamp';
import max from 'lodash/max';
import React, { PropsWithChildren, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  CARDS_GAP,
  CARDS_PER_PAGE_THEME,
  CARDS_PER_PAGE_THEME_SMALLDESKTOP,
  SWIPER_ITEM_SIZE_RATIO,
  SECTION_WIDTH,
  ICardSectionSlidersProps,
} from 'components/domain/customizable-slider/CustomizableSlider.constants';
import { SliderWrapper, SliderItems } from './CardsSection.styles';
import { useScrollPosition } from './useScrollPosition';

const SwiperWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
});

const SwiperItemWrapper = styled.div<{
  hideDelimiter: boolean;
  cardsGap: number;
}>(({ theme: { media, fontSizes }, cardsGap }) => ({
  position: 'relative',
  flexShrink: 0,
  width: `${SWIPER_ITEM_SIZE_RATIO * 100}%`,
  marginRight: `${cardsGap}px`,
  '> div': {
    aspectRatio: '173 / 230',
    '@media screen and (max-width: 1023px)': {
      width: '300px',
      height: '430px',
    },
  },
  '&:not(:last-child)': {
    marginRight: `${cardsGap}px`,
  },
  img: {
    pointerEvents: 'none',
  },
  a: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    width: '100%',
  },
  '.teaser-media': {
    '&img': {
      transition: 'all 0.5s ease',
    },
    '&:after': {
      paddingBottom: `unset`,
      height: '100%',
    },
  },
  '.media-card-content': {
    padding: '20px',
    '.card-title': {
      display: 'flex',
      fontSize: fontSizes[4],
      justifyContent: 'space-between',
      span: {
        maxWidth: '85%',
      },
      '.icon': {
        flexShrink: 0,
        transition: 'all 0.5s ease',
        width: '24px',
        height: '24px',
        alignSelf: 'flex-end',
      },
    },
  },

  [media.mobile]: {
    width: '300px',
    height: '430px',
  },
  [media.tablet]: {
    width: '300px',
  },
}));

function CardsSectionSwiper({
  className,
  total,
  page,
  onChangePage,
  placeholder,
  style,
  children,
  hideDelimiter,
  cardsGap = CARDS_GAP,
}: PropsWithChildren<ICardSectionSlidersProps>): React.ReactElement {
  const smallerDesktop =
    typeof window !== 'undefined' &&
    window.innerWidth <= 1249 &&
    window.innerWidth >= 1024;
  const perPage = smallerDesktop
    ? CARDS_PER_PAGE_THEME_SMALLDESKTOP
    : CARDS_PER_PAGE_THEME;
  const count = React.Children.count(children);
  const restCount = total - count;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLDivElement>(null);

  const minIndexToShow = (page - 1) * perPage;
  const maxIndexToShow = (page + 1) * perPage + (perPage - 1);

  useScrollPosition(
    wrapperRef,
    firstItemRef,
    onChangePage,
    cardsGap,
    perPage,
    page
  );

  return (
    <SwiperWrapper ref={wrapperRef} className={className} style={style}>
      {React.Children.map(children, (child, index) => (
        <SwiperItemWrapper
          ref={index === 0 ? firstItemRef : undefined}
          key={index}
          hideDelimiter={hideDelimiter}
          cardsGap={cardsGap}
        >
          {index >= minIndexToShow && index <= maxIndexToShow
            ? child
              ? child
              : placeholder?.(index)
            : null}
        </SwiperItemWrapper>
      ))}
      {Array.from({ length: restCount }).map((_, index) => {
        const realIdx = count + index;
        return (
          <SwiperItemWrapper
            key={realIdx}
            hideDelimiter={hideDelimiter}
            cardsGap={cardsGap}
          >
            {realIdx >= minIndexToShow && realIdx <= maxIndexToShow
              ? placeholder?.(realIdx)
              : null}
          </SwiperItemWrapper>
        );
      })}
    </SwiperWrapper>
  );
}

const SliderItemWrapper = styled(SwiperItemWrapper)<{
  cardsGap: number;
  isSliderPagination?: boolean;
}>(({ theme: { fontSizes }, cardsGap, isSliderPagination }) => ({
  width: `calc(${SECTION_WIDTH}px / 4)`,
  flexShrink: 1,
  transition: 'all 0.5s ease',
  '@media screen and (min-width: 1024px) and (max-width: 1249px)': {
    width: `calc((100vw - ${
      isSliderPagination ? 97 : 40
    }px - (2 * ${cardsGap}px)) / 3)`, //100vh - 2x20px padding left - 2x gap
    flexShrink: 0,
  },
  '& a': {
    width: '100%',
    display: 'block',
  },
  '&:last-child': {
    marginRight: 0,
  },
  '&:nth-child(3n):after, &:empty:after, &.last:after': {
    width: 0,
  },
  '.teaser-media img': {
    transition: 'all 0.5s ease',
  },
  '.teaser-media': {
    '&img': {
      transition: 'all 0.5s ease',
    },
    '&:after': {
      paddingBottom: `unset`,
      height: '100%',
    },
  },
  '.media-card-content': {
    padding: '20px',
    '.card-title': {
      display: 'flex',
      fontSize: fontSizes[4],
      justifyContent: 'space-between',
      '.icon': {
        transition: 'all 0.5s ease',
        width: '24px',
        height: '24px',
      },
    },
  },
  '&:hover': {
    '.teaser-media img': {
      transform: 'scale(1.1)',
      transition: 'all 0.5s ease',
    },
    '.breadcrumb-wrap': {
      '.icon': {
        marginRight: 0,
        transition: 'all 0.5s ease',
      },
    },
    '.media-card-content': {
      '.icon': {
        marginRight: 0,
        transition: 'all 0.5s ease',
      },
    },
  },
}));

const SingleItemWrapper = styled.div<{ isSliderPagination?: boolean }>({
  position: 'relative',
  width: '100%',
  maxWidth: `${SECTION_WIDTH}px`,
  flexShrink: 0,
  img: {
    pointerEvents: 'none',
  },
  a: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    width: '100%',
  },
});

function CardSectionSlider({
  children,
  className,
  page,
  perPage = CARDS_PER_PAGE_THEME,
  total = 0,
  placeholder,
  style,
  onChangePage,
  hideDelimiter,
  cardsGap = CARDS_GAP,
  isSliderPagination,
}: PropsWithChildren<
  ICardSectionSlidersProps & { isSliderPagination: boolean }
>): React.ReactElement {
  const count = React.Children.count(children);
  const notLoadedCount = max([total - count, 0]);
  const possiblePagesCount = total ? Math.ceil(total / perPage) : 1;
  const loadedCeilCount = possiblePagesCount * CARDS_PER_PAGE_THEME;
  const missingPlaceCount = loadedCeilCount - total;
  const singleItemPerPage = perPage === 1;

  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    onSwiped({ dir }) {
      const nextPage = clamp(
        dir === 'Left' ? page + 1 : dir === 'Right' ? page - 1 : page,
        0,
        possiblePagesCount - 1
      );
      nextPage !== page && onChangePage?.(nextPage);
    },
  });

  const minIndexToShow = (page - 1) * perPage;
  const maxIndexToShow = (page + 1) * perPage + (perPage - 1);

  const SliderItem = singleItemPerPage ? SingleItemWrapper : SliderItemWrapper;
  const leftGap = singleItemPerPage ? 0 : page * cardsGap;
  const extraProps = {
    ...(!singleItemPerPage && { hideDelimiter, cardsGap }),
  };

  return (
    <SliderWrapper
      {...handlers}
      className={className}
      style={style}
      draggable={false}
    >
      <SliderItems
        style={{
          width: `calc(${possiblePagesCount * 100}% + ${
            (possiblePagesCount - 1) * cardsGap
          }px)`,
          left: page > 0 ? `calc(${-100 * page}% - ${leftGap}px)` : 0,
        }}
      >
        {React.Children.map(children, (child, index) => (
          <SliderItem
            className={count - 1 === index ? `last` : undefined}
            key={index}
            isSliderPagination={isSliderPagination}
            {...extraProps}
          >
            {/* Do not render items out of bounds */}
            {index >= minIndexToShow && index <= maxIndexToShow
              ? child
                ? child
                : placeholder?.(index)
              : null}
          </SliderItem>
        ))}
        {Array.from({ length: notLoadedCount }).map((_, index) => {
          const realIdx = count + index;
          return (
            <SliderItem key={realIdx} {...extraProps}>
              {realIdx >= minIndexToShow && realIdx <= maxIndexToShow
                ? placeholder?.(realIdx)
                : null}
            </SliderItem>
          );
        })}
        {Array.from({ length: missingPlaceCount }).map((_, index) => (
          <SliderItem key={total + index} {...extraProps} />
        ))}
      </SliderItems>
    </SliderWrapper>
  );
}

export function AdaptiveCardsSlider(
  props: PropsWithChildren<
    ICardSectionSlidersProps & { isSliderPagination: boolean }
  >
): React.ReactElement {
  const { mobile } = useDeviceType();
  return mobile ? (
    <CardsSectionSwiper {...props} />
  ) : (
    <CardSectionSlider {...props} />
  );
}
