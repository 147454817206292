import styled from 'styled-components';
import { sx2CssTheme, sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const ToolsWrap = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  '.counter': {
    fontSize: '0px',
  },
  '.button-text': {
    fontSize: '0px',
  },
  '.icon': {
    marginBottom: '0px',
  },
  '.wishlistSiteTool': {
    marginRight: '0px',
    alignSelf: 'flex-end',
    '.icon': {
      width: '23px',
      height: '23px',
    },
  },
});

export const HeroToolsWrap = styled(ToolsWrap)(
  sx2CssThemeFn({
    position: 'relative',
    gap: '10px',
    right: [3, 0],
    '@media screen and (min-width: 1024px) and (max-width: 1096px)': {
      top: '20px',
      right: '20px',
    },
    '.share-icon, .print-pdf-icon, .wishlist-icon': {
      color: 'white',
    },
  })
);

export const SiteToolsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'flex-start',
    gap: [2, 1],
  })
);

export const SiteToolsButtonsWrapper = styled.div(
  ({ theme: { colors }, theme }) =>
    sx2CssTheme(theme, {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: 'lightGreySecond',
      fontWeight: 'bold',
      fontSize: '0px',
      '.icon': {
        width: '20px',
        height: '20px',
        marginTop: '50%',
        transform: 'translateY(-50%)',
        marginBottom: 3,
        color: 'redWeb',
        fill: 'transparent',
      },
      '&.bookmark-site-tools-button-wrapper': {
        fontSize: '16px',
        color: colors.redWeb,
        fontWeight: 450,
        '.button-text': {
          display: 'none',
        },
        '.icon': {
          marginTop: '0px',
          transform: 'none',
          marginBottom: '0px',
          color: 'redWeb',
          fill: 'transparent',
          padding: '11px',
          boxSizing: 'content-box' as const,
          backgroundColor: colors.lightGreyThird,
          borderRadius: '5px',
        },
      },
      '&.help-overlay-shown': {
        button: {
          backgroundColor: 'roseFine',
          '.icon': {
            color: 'redActive',
          },
        },
      },
      '.print-pdf-icon': {
        width: '20px',
      },
      '.contact-text': {
        display: 'none',
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '.wishlist-wrapper': {
        display: [null, 'flex'],
        flexDirection: [null, 'column'],
        alignItems: [null, 'center'],
        color: 'lightGreySecond',
        fontWeight: 'bold',
        '.wishlist-icon': {
          position: 'relative!important',
          right: '0!important',
          top: '0!important',
          marginLeft: '10px',
        },
      },
      button: {
        overflow: 'hidden',
        '~ .tooltip': {
          position: 'absolute',
          top: '53px',
          width: 'max-content',
          '&:first-letter': {
            textTransform: `capitalize`,
          },
        },
        '&:focus-visible': {
          outline: `revert`,
        },
        '&:hover': {
          border: '1px solid',
          borderColor: 'redHover',
          '.icon': {
            color: 'redHover',
          },
          '~ .tooltip': {
            display: 'block',
            color: 'white',
            fontWeight: '400',
            fontSize: 1,
            lineHeight: '22px',
            letterSpacing: '0.5px',
            backgroundColor: 'darkGrey',
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            padding: '5px 10px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '10',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '16px',
              height: '16px',
              left: '50%',
              top: '-8px',
              backgroundColor: 'darkGrey',
              transform: 'translateX(-50%)  rotate(-45deg)',
              zIndex: '1',
            },
          },
        },
      },
    })
);
