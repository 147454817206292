import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrCatalogsBreadcrumbsDocument,
  FdrCatalogsBreadcrumbsQuery,
  FdrCatalogsBreadcrumbsQueryVariables,
} from 'schemas/query/catalogs/fdr-catalogs-breadcrumbs.generated';
import { mapFdrBreadcrumbs } from './utils.common';

export async function getFdrCatalogsBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrCatalogsBreadcrumbsQuery,
    FdrCatalogsBreadcrumbsQueryVariables
  >({
    query: FdrCatalogsBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrCatalogPage.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}
