import React, { ReactElement, useCallback, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrLink } from '@hotelplan/supergraph-api';
import FdrBlogArticleItem from 'components/domain/fdr-page-components/recommenders/blog-article/fdr-blog-article-item';
import { RecommendationListContainer } from 'components/domain/recommendations/RecommendationSliderSection';
import { FdrBlogArticleRecommenderFragment } from 'schemas/fragment/recommenders/blog-article/blog-article-recommneder.generated';
import {
  BlogArticleLink,
  BlogArticleRecommendationSliderSection,
} from './fdr-blog-article.styles';

interface IBlogArticleRecommendationSectionProps {
  component: FdrBlogArticleRecommenderFragment;
}

const DESKTOP_ITEMS_COUNT = 3;

function BlogArticleRecommendationsSection({
  component,
}: IBlogArticleRecommendationSectionProps): ReactElement {
  const [imageHeight, setImageHeight] = useState<number | undefined>();
  const { mobile } = useDeviceType();
  const updateImageHeight = useCallback((height: number) => {
    setImageHeight(height);
  }, []);

  const articles = component.blogArticleItems.blogArticles || [];

  if (!articles.length) return null;

  return (
    <>
      <RecommendationListContainer id={component.id}>
        <BlogArticleRecommendationSliderSection
          title={component.title}
          count={articles.length}
          itemsPerPage={DESKTOP_ITEMS_COUNT}
          isSliderPagination={!mobile}
          imageHeight={imageHeight}
          showHeader={false}
        >
          {articles.map(function (item) {
            return (
              <BlogArticleLink
                key={`blog-article-${item.publicId}`}
                link={item.webMeta.link as FdrLink}
              >
                <FdrBlogArticleItem
                  item={item}
                  onImageHeightChange={updateImageHeight}
                />
              </BlogArticleLink>
            );
          })}
        </BlogArticleRecommendationSliderSection>
      </RecommendationListContainer>
    </>
  );
}

export default BlogArticleRecommendationsSection;
