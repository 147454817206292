import '@hotelplan/libs.polyfills';
import 'routes.config';
import compose from 'lodash/fp/compose';
import merge from 'lodash/fp/merge';
import React from 'react';
import { IInitializeApolloConfig } from '@hotelplan/fdr.lib.apollo.client';
import {
  setSentryFeaturesConfig,
  useAppSentryConfig,
} from '@hotelplan/fdr.lib.sentry.sentry-config';
import { bootstrapFdrApp } from '@hotelplan/fdr.setup.client';
import { appWithFeatureToggle } from '@hotelplan/libs.feature-toggle';
import thTheme from '@hotelplan/style.travelhouse-style';
import appWithBookmarkModalPopup from 'components/domain/fdr-bookmark/appWithBookmarkModalPopup';
import { appWithFdrExactSearch } from 'components/domain/fdr-exact-search';
import { useFdrAddViewedHistory } from 'components/domain/fdr-search-overlay';
import Layout from 'components/domain/layout/Layout';
import { apolloTypePolicies } from 'config/apolloTypePolicies';
import { FeatureList } from 'config/featureList';
import resultTypes from 'config/types/introspectionResult';
import nextI18NextConfig from 'next-i18next.config.js';
import theme from 'theme';
import { GlobalStyle } from 'theme/globalStyles';
import 'normalize.css';
import 'public/css/fonts.css';

const generatedApolloPossibleTypes = resultTypes.possibleTypes;

const App = compose(
  appWithFdrExactSearch,
  appWithFeatureToggle({
    featureUpdatesEndpoint: `/api/feature-toggle/updates`,
    onGetConfig(config) {
      setSentryFeaturesConfig(config);
    },
  }),
  appWithBookmarkModalPopup
)(
  bootstrapFdrApp({
    version: process.env.VERSION,
    theme: merge(theme, thTheme),
    GlobalStyle,
    apolloPossibleTypes: generatedApolloPossibleTypes,
    apolloTypePolicies:
      apolloTypePolicies as IInitializeApolloConfig['typePolicies'],
    i18nextConfig: nextI18NextConfig,
    metaOptions: ({ language }) => ({
      favIconDefault: `/favicon.ico`,
      favIconSvg: `/meta-data/${language}/favicon.svg`,
      appleTouchIcon: `/apple-touch-icon.png`,
      webManifest: `/meta-data/${language}/manifest.webmanifest`,
      favIconColor: '#E73A31',
    }),
    endpointBasePath: `/api/auth`,
    getLayoutComponent({ pageProps }): React.ReactElement {
      return (
        <Layout
          tagline={pageProps.tagline}
          pageType={pageProps.page}
          gclidValue={pageProps.gclidValue}
          origin={pageProps.origin}
          boosters={pageProps.boosters}
          fdrBreadcrumbs={pageProps.fdrBreadcrumbs}
          fdrRequestButton={pageProps.fdrRequestButton}
        />
      );
    },
    useSideEffects({ pageProps, user }) {
      useFdrAddViewedHistory(pageProps);
      useAppSentryConfig({
        user,
      });
    },
    pageViewOptions: {
      exposeFeatures: true,
      // @ts-ignore @todo: adjust component
      omitScriptStrategy: true,
      splitPageViewEventFeatureToggle: FeatureList.SplitPageviewEvent,
    },
    openGraphOptions: {
      enable: true,
      fallback: locale => {
        return {
          title: {
            ['de']: `Grosse Reisen nach Mass weltweit und individuell - travelhouse`,
            ['fr']: `tourisme pour tous - Inspiration et voyages sur mesure`,
          }[locale],
        };
      },
    },
  })
);

export default App;
