import React, { FC } from 'react';
import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const LoadMoreWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px auto 0px',
    '.link-text': {
      fontWeight: '600',
      fontSize: 1,
    },
  })
);

const Link = styled(BsLink)(({ theme }) => theme.link.uiPrimary);

interface ILoadMoreButtonProps {
  onClick: () => void;
}

export const LoadMoreButton: FC<ILoadMoreButtonProps> = ({
  onClick,
  children,
}) => (
  <Link
    tabIndex={0}
    onClick={onClick}
    onKeyPress={e => {
      e.key === 'Enter' ? onClick() : null;
    }}
    className="load-more"
    role={'button'}
    icon={{ name: 'chevron-down' }}
  >
    <span className="link-text">{children}</span>
  </Link>
);

export const LoadMoreButtonWithWrapper: FC<ILoadMoreButtonProps> = ({
  onClick,
  children,
}) => (
  <LoadMoreWrapper className="load-more-wrapper">
    <Link
      tabIndex={0}
      onClick={onClick}
      onKeyPress={e => {
        e.key === 'Enter' ? onClick() : null;
      }}
      className="load-more"
      role={'button'}
      icon={{ name: 'chevron-down' }}
    >
      <span className="link-text">{children}</span>
    </Link>
  </LoadMoreWrapper>
);
