import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';

export const FdrBreadcrumbsList = styled.ul(
  sx2CssThemeFn({
    fontSize: [1, 2],
  })
);

export const FdrBreadcrumbLabel = styled.div<{ disabled?: boolean }>(
  ({ disabled }) =>
    sx2CssThemeFn({
      color: disabled ? `black` : 'primary',
      cursor: disabled ? 'auto' : 'pointer',
      display: 'inline-block',
      mr: 2,
    })
);

export const FdrBreadcrumbLink = styled(FdrRoute)<{ delimiter: string }>(
  ({ delimiter, theme }) =>
    sx2CssThemeFn({
      ...theme.link.breadcrumbLink,
      display: 'inline',
      '&:not(:last-child):after': {
        display: 'inline-block',
        content: `"${delimiter}"`,
        ml: 2,
      },
    })
);
