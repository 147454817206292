import { FrdBookmarkItemType } from '@hotelplan/supergraph-api';
import { fdrBlogCriteria } from 'components/util/criterias/blog.dmc';
import { fdrHeroCriteria } from 'components/util/criterias/hero.dmc';
import { fdrMarketingRecommendation } from 'components/util/criterias/marketingRecommendation.dmc';
import {
  fdrMediaFull,
  fdrMediaPreview,
} from 'components/util/criterias/media.dmc';

export const fdrBookmarkPageDefaultPagination = {
  page: 0,
  perPage: 100,
};

export const fdrBookmarkPageSingleItemPagination = {
  page: 0,
  perPage: 1,
};

export const fdrBookmarkPageComponentsVars = {
  heroCriteria: fdrHeroCriteria,
  mediaFull: fdrMediaFull,
  mediaPreview: fdrMediaPreview,
  blogCriteria: fdrBlogCriteria,
  fdrMarketing: fdrMarketingRecommendation,
  input: { pagination: fdrBookmarkPageDefaultPagination },
};

export const fdrBookmarkItemPagesDefault = [
  {
    bookmarkItemType: FrdBookmarkItemType.Geo,
    pagination: fdrBookmarkPageDefaultPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.Theme,
    pagination: fdrBookmarkPageDefaultPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductA,
    pagination: fdrBookmarkPageDefaultPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductH,
    pagination: fdrBookmarkPageDefaultPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductO,
    pagination: fdrBookmarkPageDefaultPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductS,
    pagination: fdrBookmarkPageDefaultPagination,
  },
];

export const fdrBookmarkItemPagesSingleItem = [
  {
    bookmarkItemType: FrdBookmarkItemType.Geo,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.Theme,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductA,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductH,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductO,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
  {
    bookmarkItemType: FrdBookmarkItemType.ProductS,
    pagination: fdrBookmarkPageSingleItemPagination,
  },
];
