import React, { ReactElement } from 'react';
import { FdrBooster } from '@hotelplan/supergraph-api';
import { FdrBoostersSection } from 'components/domain/fdr-boosters-section';
import { FdrCookieDisclaimerSection } from 'components/domain/fdr-cookie-disclaimer-section';
import { FdrMaintenanceNotification } from 'components/domain/fdr-maintenance-notification';
import { FdrScrollToTop } from 'components/domain/fdr-scroll-to-top';
import { FdrNotificationsWrapper } from './fdr-meta-modules.styles';

interface IFdrMetaModulesProps {
  boosters: Array<FdrBooster>;
}

export function FdrMetaModules({
  boosters,
}: IFdrMetaModulesProps): ReactElement {
  return (
    <>
      <FdrNotificationsWrapper>
        <FdrMaintenanceNotification />
        <FdrCookieDisclaimerSection />
      </FdrNotificationsWrapper>
      <FdrScrollToTop />
      <FdrBoostersSection boosters={boosters} />
    </>
  );
}
