import { useEffect, useState } from 'react';
import {
  useIsomorphicLayoutEffect,
  useToggleState,
} from '@hotelplan/libs.hooks-react';
import { FdrLinkOpenMethod } from '@hotelplan/supergraph-api';
import { externalLinkSubject } from './ExternalOverlay.subject';

interface IExternalOverlayApi {
  show: boolean;
  close(): void;
  link?: string;
  loading: boolean;
}

const REQUEST_FORM_HASH = '#requestform';

export function useExternalOverlay(): IExternalOverlayApi {
  const [initRequestForm] = useState(
    () =>
      typeof window !== 'undefined' &&
      window.location.hash === REQUEST_FORM_HASH
  );
  const [show, open, close] = useToggleState(initRequestForm);
  const [loading, setLoading] = useState(initRequestForm);
  const [link, setLink] = useState<string | undefined>(undefined);

  useIsomorphicLayoutEffect(() => {
    if (link) return;

    const subscription = externalLinkSubject.subscribe(linkCandidate => {
      setLink(linkCandidate);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  });

  useEffect(function handleClick() {
    function catchLink(ev) {
      if (
        ev.altKey ||
        ev.ctrlKey ||
        ev.metaKey ||
        ev.shiftKey ||
        ev.defaultPrevented
      ) {
        return true;
      }

      let anchor = null;
      for (let n = ev.target; n.parentNode; n = n.parentNode) {
        if (n.nodeName === 'A') {
          anchor = n;
          break;
        }
      }

      if (!anchor || anchor.target !== FdrLinkOpenMethod.Overlay) return true;
      ev.preventDefault();
      setLink(anchor.href);
      open();
    }

    document.addEventListener('click', catchLink);

    return function () {
      document.removeEventListener('click', catchLink);
    };
  });

  return {
    show,
    close,
    link,
    loading,
  };
}
