import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrDisrupterFragment = {
  __typename?: 'FdrDisrupter';
  text: string;
  bgColor?: string | null;
  fgColor?: string | null;
};

export const FdrDisrupterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrDisrupter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrDisrupter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
