import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type GetFdrPopUpMenuQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetFdrPopUpMenuQuery = {
  __typename?: 'Query';
  fdrPopUpMenu?: {
    __typename?: 'FdrPopUpMenu';
    items?: Array<{
      __typename?: 'FdrPopUpMenuItem';
      title: string;
      link?: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      } | null;
      icon: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      };
    }> | null;
  } | null;
};

export type PopUpMenuItemFragment = {
  __typename?: 'FdrPopUpMenuItem';
  title: string;
  link?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
  icon: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
};

export const PopUpMenuItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'popUpMenuItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrPopUpMenuItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'main',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '50' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '50' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetFdrPopUpMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFdrPopUpMenu' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrPopUpMenu' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'popUpMenuItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PopUpMenuItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetFdrPopUpMenuQuery__
 *
 * To run a query within a React component, call `useGetFdrPopUpMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFdrPopUpMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFdrPopUpMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFdrPopUpMenuQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFdrPopUpMenuQuery,
    GetFdrPopUpMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFdrPopUpMenuQuery,
    GetFdrPopUpMenuQueryVariables
  >(GetFdrPopUpMenuDocument, options);
}
export function useGetFdrPopUpMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFdrPopUpMenuQuery,
    GetFdrPopUpMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFdrPopUpMenuQuery,
    GetFdrPopUpMenuQueryVariables
  >(GetFdrPopUpMenuDocument, options);
}
export type GetFdrPopUpMenuQueryHookResult = ReturnType<
  typeof useGetFdrPopUpMenuQuery
>;
export type GetFdrPopUpMenuLazyQueryHookResult = ReturnType<
  typeof useGetFdrPopUpMenuLazyQuery
>;
export type GetFdrPopUpMenuQueryResult = Apollo.QueryResult<
  GetFdrPopUpMenuQuery,
  GetFdrPopUpMenuQueryVariables
>;
