import { ReactElement } from 'react';
import { FdrRequestButton } from '@hotelplan/supergraph-api';
import { FdrHeaderRequestButton } from 'components/domain/fdr-request-button';
import {
  FdrContactSiteTool,
  FdrMenuSiteTool,
  FdrSearchSiteTool,
  FdrSiteToolsWrapper,
} from 'components/domain/fdr-sitetools';
import {
  FdrMobileMenuItemsWrapper,
  FdrMobileMenuWrapper,
} from './fdr-mobile-menu.styles';

interface IFdrMobileMenuProps {
  requestButton?: FdrRequestButton;
}

export function FdrMobileMenu({
  requestButton,
}: IFdrMobileMenuProps): ReactElement {
  return (
    <FdrMobileMenuWrapper className="fdr-mobile-menu">
      <FdrMobileMenuItemsWrapper>
        <FdrSiteToolsWrapper>
          <FdrMenuSiteTool />
          <FdrSearchSiteTool />
          <FdrContactSiteTool />
        </FdrSiteToolsWrapper>
        <FdrHeaderRequestButton requestButton={requestButton} />
      </FdrMobileMenuItemsWrapper>
    </FdrMobileMenuWrapper>
  );
}
