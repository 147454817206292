import React from 'react';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import {
  LeftBtn,
  Pages,
  PaginationBlock,
  RightBtn,
} from './ArrowPagiantion.styles';
import {
  handleLeftArrowClick,
  handleRightArrowClick,
} from './ArrowPagination.utils';

export interface IArrowPaginationProps {
  onPrevPage?(): void;
  onNextPage?(): void;
  page: number;
  totalPages: number;
  trackingSource?: PaginationArrowClickSource;
}

export function ArrowPagination({
  className,
  page,
  totalPages,
  onNextPage,
  onPrevPage,
  trackingSource,
}: IArrowPaginationProps & { className?: string }): React.ReactElement {
  return (
    <PaginationBlock className={className} data-id={`pagination`}>
      {page > 0 ? (
        <LeftBtn
          onClick={() => {
            handleLeftArrowClick({ onPrevPage, trackingSource });
          }}
          disabled={page <= 0}
          data-id="prev"
        />
      ) : null}
      <Pages className="pages">
        {page + 1}/{totalPages}
      </Pages>
      <RightBtn
        onClick={() => {
          handleRightArrowClick({ onNextPage, trackingSource });
        }}
        disabled={page >= totalPages - 1}
        data-id="next"
      />
    </PaginationBlock>
  );
}
