import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  RecommendationListContainer,
  RecommendationSliderSection,
} from 'components/domain/recommendations/RecommendationSliderSection';
import { FdrAgencyItemFragment } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import {
  FdrAgencyItemPlaceholder,
  FdrAgencyOverviewItem,
  FdrFlexibleAgencyItem,
} from './fdr-flexible-agency-item';

const AGENCIES_PLACEHOLDER_COUNT = 3;

interface IAgencyRecommendationProps {
  title?: string;
  fgColor?: string;
  bgColor?: string;
  agencies: Array<FdrAgencyItemFragment>;
  loading?: boolean;
  agencyMapSearch?: React.ReactNode;
}

export function FdrAgencyRecommender({
  agencyMapSearch,
  loading,
  ...recommenderComponent
}: PropsWithChildren<IAgencyRecommendationProps>): ReactElement {
  const [t] = useTranslation('agency');
  const pageType = usePageType<EPageType>();

  const { fgColor, bgColor, agencies, title } = recommenderComponent;
  const count = loading ? AGENCIES_PLACEHOLDER_COUNT : agencies?.length;

  const FdrAgencyItem =
    pageType === EPageType.AgencyOverview
      ? FdrAgencyOverviewItem
      : FdrFlexibleAgencyItem;

  const renderedAgencies = loading
    ? Array.from({ length: count }).map((_, index) => (
        <FdrAgencyItemPlaceholder key={`agency-card-${index}`} />
      ))
    : agencies?.map((agency, i) => {
        return (
          <FdrAgencyItem
            key={`agency-recommendation-item-${i}`}
            agency={agency}
          />
        );
      });

  return (
    <div
      style={{ color: fgColor, backgroundColor: bgColor }}
      data-id="agency-recommendations-section"
    >
      <RecommendationListContainer>
        <RecommendationSliderSection
          title={title || t('agency:agencies.title')}
          count={count}
          loading={loading}
          titleColor={fgColor}
          customContent={agencyMapSearch}
        >
          {renderedAgencies}
        </RecommendationSliderSection>
      </RecommendationListContainer>
    </div>
  );
}
