import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const IPAD_MINI_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px)`;
const IPAD_MINI_LANDSCAPE_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`;

export const FdrFooterMenuWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    [media.desktop]: {
      flexWrap: 'nowrap',
    },
    [IPAD_MINI_LANDSCAPE_SCREEN]: {
      mb: -4,
    },
  })
);

export const FdrFooterMenuColumn = styled.ul(({ theme: { media } }) =>
  sx2CssThemeFn({
    padding: 0,
    flexBasis: '100%',
    borderBottom: 'none',
    borderColor: 'borderColor',
    [IPAD_MINI_SCREEN]: {
      flexBasis: '47%',
      mb: 4,
      pr: 3,
    },
    [media.desktop]: {
      flexBasis: '260px',
      marginRight: '40px',
    },
    marginBottom: [4, 2],
    [IPAD_MINI_LANDSCAPE_SCREEN]: {
      mb: 3,
    },
  })
);

export const FdrFooterMenuMainItem = styled.li<{ fullWidth?: boolean }>(
  sx2CssThemeFn({
    margin: [`0 0 10px`, `0 0 20px 0`],
    fontWeight: '700',
    fontSize: '15px',
    textTransform: `uppercase`,
    letterSpacing: '1px',
  }),
  ({ fullWidth }) =>
    fullWidth
      ? sx2CssThemeFn({
          width: ['100%', 'auto'],
          a: {
            width: ['100%', 'auto'],
          },
        })
      : {}
);

export const FdrFooterMenuLinksWrapper = styled.ul(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: ['100%', 'auto'],
  })
);

export const FdrFooterMenuSubItem = styled.li(
  sx2CssThemeFn({
    fontSize: [0, 1],
    marginBottom: 2,
  })
);
