import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

export const FdrFooterFeedbackWrapper = styled.div(
  sx2CssThemeFn({
    boxShadow: 'blockShadow',
    display: 'flex',
    alignItems: 'center',
    pt: ['20px', 0],
  })
);

export const FdrFooterFeedbackLink = styled(BsLink)(({ theme }) => ({
  ...theme.link.uiWhite,
  textDecoration: 'none !important',
}));

export const FdrFeedbackModal = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxWidth: ['initial', '572px'],
      maxHeight: ['80vh', '600px'],
      height: '100%',
      borderRadius: 'unset',
      padding: 0,
      mx: [3, 0],
      mb: ['20%', 0],
      overflowY: 'auto',
    },
    '.modal-header': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderBottom: 'none',
      zIndex: 1,
    },
  })
);

export const ResponsiveIFrame = styled.iframe(
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: '10px',
    width: '100%',
    height: '100%',
    border: 'none',
  })
);
