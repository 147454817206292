import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBookmarkAddSharedBookmarkGroupMutationVariables = Types.Exact<{
  input: Types.FdrAddSharedBookmarkGroupInput;
}>;

export type FdrBookmarkAddSharedBookmarkGroupMutation = {
  __typename?: 'Mutation';
  fdrAddSharedBookmarkGroup: {
    __typename?: 'FdrAddSharedBookmarkGroupResponse';
    result: {
      __typename?: 'FdrAddSharedBookmarkGroupResult';
      success: boolean;
      group?: {
        __typename?: 'FdrBookmarkGroup';
        id: string;
        name: string;
      } | null;
    };
  };
};

export const FdrBookmarkAddSharedBookmarkGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrBookmarkAddSharedBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAddSharedBookmarkGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAddSharedBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrBookmarkAddSharedBookmarkGroupMutationFn =
  Apollo.MutationFunction<
    FdrBookmarkAddSharedBookmarkGroupMutation,
    FdrBookmarkAddSharedBookmarkGroupMutationVariables
  >;

/**
 * __useFdrBookmarkAddSharedBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useFdrBookmarkAddSharedBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrBookmarkAddSharedBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrBookmarkAddSharedBookmarkGroupMutation, { data, loading, error }] = useFdrBookmarkAddSharedBookmarkGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrBookmarkAddSharedBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrBookmarkAddSharedBookmarkGroupMutation,
    FdrBookmarkAddSharedBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrBookmarkAddSharedBookmarkGroupMutation,
    FdrBookmarkAddSharedBookmarkGroupMutationVariables
  >(FdrBookmarkAddSharedBookmarkGroupDocument, options);
}
export type FdrBookmarkAddSharedBookmarkGroupMutationHookResult = ReturnType<
  typeof useFdrBookmarkAddSharedBookmarkGroupMutation
>;
export type FdrBookmarkAddSharedBookmarkGroupMutationResult =
  Apollo.MutationResult<FdrBookmarkAddSharedBookmarkGroupMutation>;
export type FdrBookmarkAddSharedBookmarkGroupMutationOptions =
  Apollo.BaseMutationOptions<
    FdrBookmarkAddSharedBookmarkGroupMutation,
    FdrBookmarkAddSharedBookmarkGroupMutationVariables
  >;
