import { useTranslation } from 'next-i18next';
import React, { ReactElement, useMemo } from 'react';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { FdrLink, FdrLinkType } from '@hotelplan/supergraph-api';
import { useBookmarkContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import {
  FdrSiteToolsButtonsWrapper,
  FdrWishlistIconCounter,
} from './fdr-sitetools.styles';

export function FdrWishlistSiteTool(): ReactElement {
  const { mobile } = useDeviceType();
  const bookmarkContext = useBookmarkContext();
  const [t, { language }] = useTranslation('bookmark');

  const bookmarkPageRoute = useMemo(() => {
    return getRouteByPageTypeLocale(EPageType.Bookmark, language);
  }, [language]);

  const bookmarkPageUrl: FdrLink = {
    url: bookmarkPageRoute.builder({}),
    type: FdrLinkType.Internal,
  };

  return (
    <FdrRoute link={bookmarkPageUrl}>
      <FdrSiteToolsButtonsWrapper className="wishlistSiteTool">
        <FdrWishlistIconCounter className="wishlistIconCounter">
          <BsButtonWithIcon
            variant="headerBtn"
            icon={{ name: 'wishlist-icon' }}
          />
          {bookmarkContext.count > 0 && (
            <span className="counter">{bookmarkContext.count}</span>
          )}
          <span className={`${mobile ? 'mobile-tooltip' : 'tooltip'}`}>
            {t('bookmarkLink')}
          </span>
        </FdrWishlistIconCounter>
      </FdrSiteToolsButtonsWrapper>
    </FdrRoute>
  );
}
