import { FdrBookmarkGroup } from '@hotelplan/supergraph-api';

export enum BookmarkEditModalActionType {
  RESET,
  SET_VIEW,
}

export enum BookmarkEditModalView {
  DEFAULT,
  REMOVE,
  MOVE,
  COPY,
}

export type BookmarkEditModalActionPayload = {
  view: BookmarkEditModalView;
  bookmarkGroup?: FdrBookmarkGroup;
};

export type TBookmarkEditModalActionSetView = {
  type: BookmarkEditModalActionType.SET_VIEW;
  payload: BookmarkEditModalActionPayload;
};

export type TBookmarkEditModalActionReset = {
  type: BookmarkEditModalActionType.RESET;
};

export type TBookmarkEditModalAction =
  | TBookmarkEditModalActionSetView
  | TBookmarkEditModalActionReset;

export type TBookmarkEditModalState = {
  view: BookmarkEditModalView;
  bookmarkGroup?: FdrBookmarkGroup;
};

export const bookmarkEditModalReducer = (
  state: TBookmarkEditModalState,
  action: TBookmarkEditModalAction
): TBookmarkEditModalState => {
  switch (action.type) {
    case BookmarkEditModalActionType.SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
        ...(action.payload.bookmarkGroup && {
          bookmarkGroup: action.payload.bookmarkGroup,
        }),
      };
    default:
      return {
        ...state,
        view: BookmarkEditModalView.DEFAULT,
      };
  }
};

export const bookmarkEditModalInitialState: TBookmarkEditModalState = {
  view: BookmarkEditModalView.DEFAULT,
};
