import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';

export const FdrHeaderRequestButtonWrapper = styled.div(
  sx2CssThemeFn({
    height: '42px',
    '.request-button': {
      color: 'white',
      borderRadius: '5px',
      height: '100%',
      marginLeft: '5px',
      width: '177px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '.request-button-caption': {
        color: 'white',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
  })
);

export const FdrHeaderRequestButtonLink = styled(FdrRoute)(
  ({ theme }) => theme.buttons.floatButton
);

export const FdrRequestButtonLink = styled(FdrRoute)(({ theme }) =>
  sx2CssThemeFn({
    ...theme.buttons.primary,
    '&.red-bg': theme.buttons.primaryOnRedBg,
  })
);

export const FdrStaticRequestButtonBg = styled.div<{ bg?: string }>(
  ({ bg, theme: { colors } }) =>
    sx2CssThemeFn({
      backgroundColor: bg || colors.paperGrey,
      flexGrow: ['1', `unset`],
    })
);

export const FdrStaticRequestButtonWrapper = styled.div(
  ({ theme }) => theme.text.textBlockWrap
);
