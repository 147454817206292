import { memoize } from 'lodash/fp';
import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  GeoSkeletonCardWrapper,
  GeoSkeletonTitleWrapper,
} from 'components/domain/geo/Geo.common.styles';
import SkeletonBaseBlock from 'components/domain/skeleton/SkeletonBaseBlock';
import { DEFAULTS_RESULT_PER_PAGE } from './fdr-search.constants';
import {
  FdrCardsSlider,
  FdrSearchCardsSection,
  FdrTeaserDescription,
} from './fdr-search.styles';

const loadingAnimation = keyframes`
 0% { background-position: 100% 50% }
 50%, 100% { background-position: 0% 50% }
`;

const Block = styled.div`
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(245, 246, 247, 1) 30%,
    rgba(244, 245, 246, 1) 35%,
    rgba(238, 238, 238, 1) 50%,
    rgba(245, 246, 247, 1) 70%
  );
  background-size: 400% 300%;
  margin-bottom: 10px;
  animation: ${loadingAnimation} 1s infinite;
  &.placeholderImage {
    width: 100%;
    padding-bottom: calc(100% / 1.2);
  }
`;

function ItemCardPlaceholder(): React.ReactElement {
  return (
    <div style={{ height: '100%' }}>
      <Block className="placeholderImage" />
      <FdrTeaserDescription>
        <Block style={{ width: '100%', height: '18px' }} />
        <Block style={{ width: '60%', height: '18px' }} />
        <Block style={{ width: '80%', height: '18px' }} />
      </FdrTeaserDescription>
    </div>
  );
}

function GeoItemCardPlaceholder(): React.ReactElement {
  return (
    <GeoSkeletonCardWrapper>
      <SkeletonBaseBlock height="274px" width="100%" />
      <GeoSkeletonTitleWrapper>
        <SkeletonBaseBlock height="20px" width="220px" borderRadius="6px" />
        <SkeletonBaseBlock height="24px" width="24px" borderRadius="6px" />
      </GeoSkeletonTitleWrapper>
    </GeoSkeletonCardWrapper>
  );
}

export const geoSearchCardPlaceholder = memoize(() => (
  <GeoItemCardPlaceholder />
));

export const searchCardPlaceholder = memoize(() => <ItemCardPlaceholder />);

export function FdrSearchSectionSkeleton(): ReactElement {
  return (
    <FdrSearchCardsSection
      page={0}
      totalPages={0}
      loading={true}
      isSRL={true}
      headerStyle={{ height: '42px' }}
    >
      <FdrCardsSlider
        page={0}
        total={3}
        perPage={DEFAULTS_RESULT_PER_PAGE}
        placeholder={searchCardPlaceholder}
      />
    </FdrSearchCardsSection>
  );
}
