import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrLinkFragment = {
  __typename?: 'FdrLink';
  openMethod?: Types.FdrLinkOpenMethod | null;
  legacyUrl?: string | null;
  type: Types.FdrLinkType;
  url: string;
};

export const FdrLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrLink' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'openMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
