import { track } from '@hotelplan/libs.tracking-events';

export function trackBookmarkPage(
  language: string,
  bookmarkCount: number
): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.OPEN_PAGE,
    label: bookmarkCount,
    category: undefined,
    component: 'bookmark-page',
    position: undefined,
    language: language,
    _clear: true,
  });
}

export function trackAddNewBookmarkGroup(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.ADD,
    label: 'Neue Merkliste erstellen' as const,
    category: undefined,
    component: 'wishlist-link-text',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackEditBookmarkGroup(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.EDIT,
    label: 'Bearbeiten' as const,
    category: undefined,
    component: 'wishlist-item',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackDeleteBookmarkGroup(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.DELETE,
    label: 'Löschen' as const,
    category: undefined,
    component: 'wishlist-item',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackOpenEditBookmarkPopup(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.EDIT,
    label: 'Bearbeiten' as const,
    category: undefined,
    component: 'bookmark-item',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackEditBookmarkDelete(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.DELETE,
    label: 'Von der Merkliste löschen' as const,
    category: undefined,
    component: 'bookmark-popup',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackEditBookmarkMove(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.MOVE,
    label: 'In eine andere Merkliste verschieben' as const,
    category: undefined,
    component: 'bookmark-popup',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackEditBookmarkCopy(language: string): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.COPY,
    label: 'In eine andere Merkliste kopieren' as const,
    category: undefined,
    component: 'bookmark-popup',
    position: undefined,
    language,
    _clear: true,
  });
}

export function trackAddBookmark(
  bookmarkComponentType: BookmarkComponentType,
  language: string
): void {
  track({
    event: BOOKMARK_EVENT_NAME,
    action: BookmarkEventAction.ADD,
    label: 'Bookmark hinzufügen' as const,
    category: undefined,
    component: 'bookmark-heart-icon',
    position: bookmarkComponentType,
    language,
    _clear: true,
  });
}

export function trackRemoveBookmark(
  bookmarkComponentType: BookmarkComponentType,
  language: string
): void {
  if (bookmarkComponentType == BookmarkComponentType.BOOKMARK_PAGE) {
    track({
      event: BOOKMARK_EVENT_NAME,
      action: BookmarkEventAction.REMOVE,
      label: 'Bookmark entfernen von Merkliste' as const,
      category: undefined,
      component: 'bookmark-item',
      position: undefined,
      language,
      _clear: true,
    });
  } else {
    track({
      event: BOOKMARK_EVENT_NAME,
      action: BookmarkEventAction.REMOVE,
      label: 'Bookmark entfernen' as const,
      category: undefined,
      component: 'bookmark-heart-icon',
      position: bookmarkComponentType,
      language,
      _clear: true,
    });
  }
}

export const BOOKMARK_EVENT_NAME = 'bookmark';

export enum BookmarkEventAction {
  OPEN_PAGE = 'open page',
  ADD = 'add',
  REMOVE = 'remove',
  EDIT = 'edit',
  DELETE = 'delete',
  MOVE = 'move',
  COPY = 'copy',
}

export enum BookmarkComponentType {
  RECOMMENDER = 'recommender',
  CONTENT = 'content',
  SRL = 'srl',
  BOOKMARK_PAGE = 'bookmark',
}
