import hoistNonReactStatics from 'hoist-non-react-statics';
import { AppProps } from 'next/app';
import React, { ReactElement } from 'react';
import { FdrExactSearchContextProvider } from './fdr-exact-search.context';

export function appWithFdrExactSearch(
  WrappedAppComponent:
    | React.ComponentType<AppProps>
    | React.ElementType<AppProps>
) {
  function AppWithExactSearch(props: AppProps): ReactElement {
    return (
      <FdrExactSearchContextProvider>
        <WrappedAppComponent {...props} />
      </FdrExactSearchContextProvider>
    );
  }

  return hoistNonReactStatics(AppWithExactSearch, WrappedAppComponent);
}
