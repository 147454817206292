import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrNewsletterConfirmationBreadcrumbsDocument,
  FdrNewsletterConfirmationBreadcrumbsQuery,
  FdrNewsletterConfirmationBreadcrumbsQueryVariables,
} from 'schemas/query/newsletter/breadcrumbs/fdr-newsletter-confirmation-breadcrumbs.generated';
import {
  FdrNewsletterFinalizationBreadcrumbsDocument,
  FdrNewsletterFinalizationBreadcrumbsQuery,
  FdrNewsletterFinalizationBreadcrumbsQueryVariables,
} from 'schemas/query/newsletter/breadcrumbs/fdr-newsletter-finalization-breadcrumbs.generated';
import {
  FdrNewsletterSubscriptionBreadcrumbsDocument,
  FdrNewsletterSubscriptionBreadcrumbsQuery,
  FdrNewsletterSubscriptionBreadcrumbsQueryVariables,
} from 'schemas/query/newsletter/breadcrumbs/fdr-newsletter-subscription-breadcrumbs.generated';
import {
  FdrNewsletterUnsubscriptionBreadcrumbsDocument,
  FdrNewsletterUnsubscriptionBreadcrumbsQuery,
  FdrNewsletterUnsubscriptionBreadcrumbsQueryVariables,
} from 'schemas/query/newsletter/breadcrumbs/fdr-newsletter-unsubscription-breadcrumbs.generated';
import {
  FdrNewsletterUnsubscriptionConfirmationBreadcrumbsDocument,
  FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
  FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables,
} from 'schemas/query/newsletter/breadcrumbs/fdr-newsletter-unsubscription-confirmation-breadcrumbs.generated';
import { mapFdrBreadcrumbs } from './utils.common';

export async function getFdrNewsletterSubscriptionBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrNewsletterSubscriptionBreadcrumbsQuery,
    FdrNewsletterSubscriptionBreadcrumbsQueryVariables
  >({
    query: FdrNewsletterSubscriptionBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrNewsletter?.subscription.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrNewsletterConfirmationBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrNewsletterConfirmationBreadcrumbsQuery,
    FdrNewsletterConfirmationBreadcrumbsQueryVariables
  >({
    query: FdrNewsletterConfirmationBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrNewsletter?.confirmation.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrNewsletterFinalizationBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrNewsletterFinalizationBreadcrumbsQuery,
    FdrNewsletterFinalizationBreadcrumbsQueryVariables
  >({
    query: FdrNewsletterFinalizationBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrNewsletter?.finalization.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrNewsletterUnsubscriptionBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrNewsletterUnsubscriptionBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionBreadcrumbsQueryVariables
  >({
    query: FdrNewsletterUnsubscriptionBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrNewsletter?.unsubscription.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrNewsletterUnsubscriptionConfirmationBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >({
    query: FdrNewsletterUnsubscriptionConfirmationBreadcrumbsDocument,
  });

  const breadcrumbs =
    data?.fdrNewsletter?.unsubscriptionConfirmation.breadcrumbs || [];

  return mapFdrBreadcrumbs(breadcrumbs);
}
