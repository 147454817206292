import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { FdrAgencySearchMode } from '@hotelplan/supergraph-api';
import {
  FdrTextSearchAgenciesQuery,
  useFdrTextSearchAgenciesQuery,
} from 'schemas/query/search/fdr-text-search-agencies.generated';

export function useAgencySearch(
  isAgenciesSection: boolean,
  searchInput: string,
  fetchPolicy: WatchQueryFetchPolicy,
  exactSearch: boolean,
  perPageCount: number,
  storedPage: number
): {
  data: FdrTextSearchAgenciesQuery;
  loading: boolean;
  fetchMore: (
    nextPage: number,
    fetchingCount: number
  ) => Promise<ApolloQueryResult<FdrTextSearchAgenciesQuery>>;
} {
  const prevMode = useRef<FdrAgencySearchMode>();
  const [localData, setLocalData] = useState<FdrTextSearchAgenciesQuery>();

  const agencySearchMode: FdrAgencySearchMode = exactSearch
    ? FdrAgencySearchMode.AgencySearchModePhrase
    : FdrAgencySearchMode.AgencySearchModePartialWord;

  const variables = {
    searchInput,
    searchMode: agencySearchMode,
    pagination: { page: storedPage, perPage: perPageCount },
  };

  const { fetchMore: fdrAgenciesFetchMore, loading } =
    useFdrTextSearchAgenciesQuery({
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy:
        agencySearchMode && prevMode.current !== agencySearchMode
          ? `cache-and-network`
          : fetchPolicy || `cache-first`,
      skip: !isAgenciesSection || !searchInput,
      onCompleted(responseData) {
        setLocalData(stored => {
          if (!responseData || !responseData?.fdrSearchAgencies) return stored;
          const search = responseData.fdrSearchAgencies;

          if (!stored || prevMode.current !== agencySearchMode) {
            prevMode.current = agencySearchMode;
            const newList = [
              ...new Array(search.pagination.page * search.pagination.perPage),
              ...search.agencies,
            ];

            return {
              ...responseData,
              fdrSearchAgencies: { ...search, agencies: newList },
            };
          }

          const updatedList = [...stored?.fdrSearchAgencies?.agencies];
          updatedList.splice(
            search.pagination.page * search.pagination.perPage,
            search.agencies.length,
            ...search.agencies
          );

          return {
            ...responseData,
            fdrSearchAgencies: { ...search, agencies: updatedList },
          };
        });
      },
    });

  const fetchMore = useCallback(
    (nextPage: number, fetchingCount: number) => {
      return fdrAgenciesFetchMore({
        variables: {
          ...variables,
          pagination: {
            page: nextPage,
            perPage: fetchingCount,
          },
        },
      });
    },
    [agencySearchMode, searchInput]
  );

  return {
    data: localData,
    loading,
    fetchMore,
  };
}
