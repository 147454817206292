import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrEmailRevokeBreadcrumbsDocument,
  FdrEmailRevokeBreadcrumbsQuery,
  FdrEmailRevokeBreadcrumbsQueryVariables,
} from 'schemas/query/email/breadcrumbs/fdr-email-revoke-breadcrumbs.generated';
import {
  FdrEmailRevokeConfirmationBreadcrumbsDocument,
  FdrEmailRevokeConfirmationBreadcrumbsQuery,
  FdrEmailRevokeConfirmationBreadcrumbsQueryVariables,
} from 'schemas/query/email/breadcrumbs/fdr-email-revoke-confimation-breadcrumbs.generated';
import { mapFdrBreadcrumbs } from './utils.common';

export async function getFdrEmailRevokeBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrEmailRevokeBreadcrumbsQuery,
    FdrEmailRevokeBreadcrumbsQueryVariables
  >({
    query: FdrEmailRevokeBreadcrumbsDocument,
  });

  const breadcrumbs =
    data?.fdrEmailAdvertising.revokeEmail.pageBreadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrEmailRevokeConfirmationBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrEmailRevokeConfirmationBreadcrumbsQuery,
    FdrEmailRevokeConfirmationBreadcrumbsQueryVariables
  >({
    query: FdrEmailRevokeConfirmationBreadcrumbsDocument,
  });

  const breadcrumbs =
    data?.fdrEmailAdvertising.revokeEmailConfirmation.pageBreadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}
