import noop from 'lodash/noop';
import React from 'react';
import styled from 'styled-components';
import { FdrMediaItem } from '@hotelplan/fdr.regular.fusion.fdr-media-item';
import type { FdrLink } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

interface IImageCardProps extends React.PropsWithChildren<{}> {
  testId?: string;
  onClick?(): void;
  className?: string;
  img?: React.ReactNode;
  cfg?: {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    link?: Partial<FdrLink>;
    lazy?: boolean;
  };
}

interface IMediaCardContentProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

const MediaCardContent = styled.div.attrs<IMediaCardContentProps>(
  ({ title, subTitle, children }) => ({
    children: (
      <>
        {title && (
          <div className="card-text">
            <h3 className="card-title">{title}</h3>
          </div>
        )}
        {subTitle && <p className="card-subtitle">{subTitle}</p>}
        {children}
      </>
    ),
    title: undefined,
    subTitle: undefined,
  })
)<IMediaCardContentProps>(({ theme }) =>
  sx2CssThemeFn(theme.mediaItem.cardContent)
) as React.FC<
  Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & IMediaCardContentProps
>;

export const FdrMediaCard: React.FC<IImageCardProps> = ({
  testId,
  onClick = noop,
  className,
  children,
  img,
  cfg = {},
}) => {
  const { link, title, subTitle } = cfg;

  return (
    <FdrMediaItem
      link={link}
      testId={testId}
      onClick={onClick}
      className={className}
    >
      {img}
      <MediaCardContent
        title={title}
        subTitle={subTitle}
        className="media-card-content"
      >
        {children}
      </MediaCardContent>
    </FdrMediaItem>
  );
};
