import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrMenuFragmentDoc } from 'schemas/fragment/regular/fdrMenu.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import { FdrContactDataFragmentDoc } from 'schemas/fragment/regular/fdrContactData.generated';
export type FdrHeaderFragment = {
  __typename?: 'FdrHeader';
  menus: Array<{
    __typename?: 'FdrMenu';
    caption: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
    menuItems?: Array<{
      __typename?: 'FdrMenuItem';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
  }>;
  logo: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
  contactInformation?: {
    __typename?: 'FdrContactInformation';
    mainTitle?: string | null;
    phoneNumber: string;
    phoneTitle?: string | null;
    timezone: string;
    link?: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    } | null;
    openingHours?: {
      __typename?: 'FdrOpeningHours';
      monday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      tuesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      wednesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      thursday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      friday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      saturday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      sunday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
    } | null;
  } | null;
};

export const FdrHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHeader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHeader' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMenu' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '130' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '50' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '207' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '46' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrContactData' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrMenuFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrContactDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
