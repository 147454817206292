import hoistNonReactStatics from 'hoist-non-react-statics';
import { AppProps } from 'next/app';
import React, { ReactElement } from 'react';
import { BookmarkModalPopupContextProvider } from './context/fdr-bookmark-modal-popup-context';

export default function appWithBookmarkModalPopup(
  WrappedAppComponent:
    | React.ComponentType<AppProps>
    | React.ElementType<AppProps>
) {
  function AppBookmarkModalPopUp(props: AppProps): ReactElement {
    return (
      <BookmarkModalPopupContextProvider>
        <WrappedAppComponent {...props} />
      </BookmarkModalPopupContextProvider>
    );
  }

  return hoistNonReactStatics(AppBookmarkModalPopUp, WrappedAppComponent);
}
