import React from 'react';
import styled from 'styled-components';
import { MapPin } from '@hotelplan/components.common.map-pin';
import { FdrCoordinates } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { pinContent } from 'components/domain/fdr-map';

type TProps<T extends { coordinates: FdrCoordinates }> =
  React.PropsWithChildren<{
    active: boolean;
    item: T;
    showDetails?: boolean;
    onClick(): void;
    className?: string;
  }>;

const MapPinWrapper = styled(MapPin)(
  sx2CssThemeFn({
    ...pinContent,
    svg: {
      color: 'redWeb',
    },
    '&.old-roundtrip-pin': {
      svg: {
        color: 'blueOcean',
      },
      '&.active': {
        svg: {
          color: 'oldRoundtripsPinActive',
        },
      },
      ':hover': {
        svg: {
          color: 'oldRoundtripsPinHover',
        },
      },
    },
    '&:hover': {
      svg: { color: 'redHover', transform: 'scale(1.5)' },
    },
    '&.active': {
      svg: { color: 'redActive', transform: 'scale(1.5)' },
      zIndex: 100,
    },
  })
);

const Pin = ({ children }: React.PropsWithChildren<{}>) => (
  <>
    {children}
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.11764 3.37974C4.02419 2.47199 5.10105 1.75181 6.28657 1.26043C7.47209 0.769046 8.74299 0.516113 10.0265 0.516113C11.31 0.516113 12.5809 0.769046 13.7664 1.26043C14.9519 1.75181 16.0288 2.47199 16.9353 3.37974C20.7572 7.19797 19.8773 12.8658 16.9353 17.1844L10.0268 28.9792L3.11764 17.1851C-0.135077 12.6827 -0.556739 7.19866 3.11764 3.37974Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  </>
);

const BasePin: React.FC<TProps<any>> = ({
  item,
  onClick,
  active,
  children,
  className,
}: TProps<any>) => {
  const classNames = [className, active ? 'active' : undefined].filter(i => i);

  return (
    <MapPinWrapper
      coordinates={item.coordinates}
      object={item}
      onClick={onClick}
      className={classNames.join(' ')}
    >
      <Pin />
      {children}
    </MapPinWrapper>
  );
};

export default BasePin;
