import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FdrResponsiveImgWithCopyright from 'components/domain/fdr-copyright/fdr-responsive-img-with-copyright';
import { FdrMarketingMediaSwitch } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-marketing-media-switch';
import {
  createFullScreenFdrImage,
  getResourceLink,
} from 'components/domain/fdr-page-components/recommenders/marketing/fdr-media.util';
import { FdrTeaserCard } from 'components/domain/fdr-teaser-card';
import { fdrMarketingImagesSpecs } from 'components/util/criterias/marketingRecommendation.dmc';
import { FdrMarketingItemFragment } from 'schemas/fragment/regular/fdrMarketingRecommender.generated';

type TFdrSingleMarketingItemProps = {
  item: FdrMarketingItemFragment;
};

const SingleMarketingItem = styled(FdrTeaserCard)(
  sx2CssThemeFn({
    '&:hover': {
      '.teaser-media img': {
        transform: 'scale(1.0)',
      },
    },
    a: {
      '&:focus-visible': {
        '&:before': {
          content: '',
          outline: '1px solid',
          outlineOffset: '0px',
        },
        img: {
          transform: 'scale(1.0)',
          transition: 'all 0.5s ease',
        },
      },
    },
    '.teaser-media:after': {
      paddingBottom: ['70%', '38%'],
    },
    '.media-card-content': {
      maxWidth: '1480px',
      paddingLeft: [3, '20px'],
      paddingRight: [3, '20px'],
      left: '50%',
      transform: 'translateX(-50%)',
      paddingBottom: '30px',
    },
  })
);

export function FdrSingleMarketingItem({ item }: TFdrSingleMarketingItemProps) {
  const media = item.media;
  const image = createFullScreenFdrImage(media);
  const resourceLink = getResourceLink(media);

  return (
    <SingleMarketingItem
      title={item.title}
      cfg={{
        subTitle: item.subtitle,
        title: item.title,
        link: item.link,
      }}
      img={
        <FdrMarketingMediaSwitch
          typename={media.__typename}
          resourceLink={resourceLink}
          lazy={false}
        >
          <FdrResponsiveImgWithCopyright
            image={image}
            specs={fdrMarketingImagesSpecs}
          />
        </FdrMarketingMediaSwitch>
      }
    />
  );
}
