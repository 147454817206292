import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBookmarkGroupListQueryVariables = Types.Exact<{
  input: Types.FdrAllBookmarkGroupsInput;
}>;

export type FdrBookmarkGroupListQuery = {
  __typename?: 'Query';
  fdrAllBookmarkGroups: {
    __typename?: 'FdrAllBookmarkGroupsResponse';
    success: boolean;
    message?: string | null;
    entries?: Array<{
      __typename?: 'FdrBookmarkGroup';
      id: string;
      name: string;
      bookmarksCount: number;
    }> | null;
  };
};

export const FdrBookmarkGroupListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBookmarkGroupList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAllBookmarkGroupsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAllBookmarkGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bookmarksCount' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBookmarkGroupListQuery__
 *
 * To run a query within a React component, call `useFdrBookmarkGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBookmarkGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBookmarkGroupListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrBookmarkGroupListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrBookmarkGroupListQuery,
    FdrBookmarkGroupListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBookmarkGroupListQuery,
    FdrBookmarkGroupListQueryVariables
  >(FdrBookmarkGroupListDocument, options);
}
export function useFdrBookmarkGroupListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBookmarkGroupListQuery,
    FdrBookmarkGroupListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBookmarkGroupListQuery,
    FdrBookmarkGroupListQueryVariables
  >(FdrBookmarkGroupListDocument, options);
}
export type FdrBookmarkGroupListQueryHookResult = ReturnType<
  typeof useFdrBookmarkGroupListQuery
>;
export type FdrBookmarkGroupListLazyQueryHookResult = ReturnType<
  typeof useFdrBookmarkGroupListLazyQuery
>;
export type FdrBookmarkGroupListQueryResult = Apollo.QueryResult<
  FdrBookmarkGroupListQuery,
  FdrBookmarkGroupListQueryVariables
>;
