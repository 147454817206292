import type { IAdventureTravelRouteProduct } from 'components/domain/fdr-adventure-travel-routes';
import { mapProductFeatureGroupsToProductFeatures } from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { IFdrRecommenderProduct } from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommender.types';
import { isFdrAdventureTravelProduct } from 'components/domain/fdr-product/fdr-product.utils';
import { FdrGeoModulesQuery } from 'schemas/query/geo/fdr-geo-modules.generated';
import { FdrGeoPageComponentsQuery } from 'schemas/query/geo/fdr-geo-page-components.generated';
import { FdrGeoSiblingsQuery } from 'schemas/query/geo/fdr-geo-siblings.generated';
import { FdrGeoStaticPageComponentsQuery } from 'schemas/query/geo/fdr-geo-static-page-components.generated';
import {
  TFdrAdventureTravelGeoProduct,
  TFdrContinent,
  TFdrCountry,
  TFdrCountryGroup,
  TFdrDestination,
  TFdrThGeoObject,
  TFdrRegion,
  TFdrResort,
  TTypedGeoObject,
  TFdrThGeoModuleObject,
  TFdrThGeoModuleObjectWithChildren,
  TFdrThGeoObjectWithSiblings,
} from './fdr-geo.types';

export const getFdrThGeoModuleObject = (
  query: FdrGeoModulesQuery
): TFdrThGeoModuleObject => {
  if (!query) return null;

  if (
    isFdrContinent(query.fdrGeo) ||
    isFdrCountry(query.fdrGeo) ||
    isFdrRegion(query.fdrGeo)
  ) {
    return query.fdrGeo;
  }

  return null;
};

export const getFdrGeoChildren = (
  fdrGeo: FdrGeoModulesQuery['fdrGeo']
): TFdrThGeoModuleObjectWithChildren['children'] => {
  if (isFdrContinent(fdrGeo) || isFdrCountry(fdrGeo)) {
    return fdrGeo.children;
  }

  return null;
};

export const getFdrGeoPageComponents = (
  fdrGeo: FdrGeoPageComponentsQuery['fdrGeo']
): TFdrThGeoObject<FdrGeoPageComponentsQuery['fdrGeo']>['pageComponents'] => {
  if (isFdrContinent(fdrGeo) || isFdrCountry(fdrGeo) || isFdrRegion(fdrGeo)) {
    return fdrGeo.pageComponents;
  }

  return null;
};

export const getFdrGeoStaticPageComponents = (
  fdrGeo: FdrGeoStaticPageComponentsQuery['fdrGeo']
): TFdrThGeoObject<
  FdrGeoStaticPageComponentsQuery['fdrGeo']
>['staticContent']['pageComponents'] => {
  if (isFdrContinent(fdrGeo) || isFdrCountry(fdrGeo) || isFdrRegion(fdrGeo)) {
    return fdrGeo.staticContent.pageComponents;
  }

  return null;
};

export const isGeoProductGalleryAvailable = (
  products: TFdrThGeoModuleObject['products']
) => {
  return products?.productsPage?.find(isProductShownInGallery) !== undefined;
};

export const getFdrGeoProductGalleryProducts = (
  products: TFdrThGeoModuleObject['products']
): TFdrAdventureTravelGeoProduct[] => {
  return (products?.productsPage?.filter(isProductShownInGallery) ||
    []) as TFdrAdventureTravelGeoProduct[];
};

const isProductShownInGallery = (
  product: TFdrThGeoModuleObject['products']['productsPage'][number]
): boolean => {
  return (
    isFdrAdventureTravelProduct(product) &&
    !!product.headerImages?.length &&
    !!product.images?.length
  );
};

export const getFdrGeoMapProducts = (
  fdrGeo: TFdrThGeoModuleObject
): IAdventureTravelRouteProduct[] => {
  return fdrGeo?.products?.productsPage
    ?.filter(isFdrAdventureTravelProduct)
    .filter(p => {
      return p.route?.length;
    })
    .map(p => {
      return {
        __typename: p.__typename,
        name: p.name,
        image: p.headerImages[0],
        routePoints: p.route,
        link: p.webMeta.meta.link,
        productInfoB2B: p.productInfoB2B,
      };
    });
};

export const getFdrGeoAdventureTravels = (
  fdrGeo: TFdrThGeoModuleObject
): TFdrAdventureTravelGeoProduct[] => {
  return (
    fdrGeo?.products?.productsPage?.filter(isFdrAdventureTravelProduct) || []
  );
};

export const getFdrThGeoSiblingsObject = (
  query: FdrGeoSiblingsQuery
): TFdrThGeoObjectWithSiblings<FdrGeoSiblingsQuery['fdrGeo']> => {
  if (isFdrCountry(query.fdrGeo) || isFdrRegion(query.fdrGeo)) {
    return query.fdrGeo;
  }

  return null;
};

export const mapFdrAdventureTravelToRecommenderProduct = (
  geoProduct: TFdrAdventureTravelGeoProduct
): IFdrRecommenderProduct => {
  return {
    ...geoProduct,
    image: geoProduct.headerImages[0],
    link: geoProduct.webMeta.meta.link,
    teaser: geoProduct.teaser,
    routePoints: geoProduct.route,
    route: geoProduct.route?.map(r => r.locationName),
    features: mapProductFeatureGroupsToProductFeatures(
      geoProduct.featureGroups
    ),
  };
};

export const isFdrThGeoObject = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrThGeoObject<T> => {
  return (
    isFdrContinent(geoObject) ||
    isFdrCountry(geoObject) ||
    isFdrRegion(geoObject)
  );
};

export const isFdrContinent = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrContinent<T> => {
  return geoObject.__typename === 'FdrContinent';
};

export const isFdrCountryGroup = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrCountryGroup<T> => {
  return geoObject.__typename === 'FdrCountryGroup';
};

export const isFdrCountry = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrCountry<T> => {
  return geoObject.__typename === 'FdrCountry';
};

export const isFdrRegion = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrRegion<T> => {
  return geoObject.__typename === 'FdrRegion';
};

export const isFdrDestination = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrDestination<T> => {
  return geoObject.__typename === 'FdrDestination';
};

export const isFdrResort = <T extends TTypedGeoObject>(
  geoObject: T
): geoObject is TFdrResort<T> => {
  return geoObject.__typename === 'FdrResort';
};
