import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrSearchImageFragmentDoc } from 'schemas/fragment/search/fdr-search-items.generated';
import { FdrOpeningHoursFragmentDoc } from 'schemas/fragment/opening-hours/fdr-opening-hours.generated';
import { FdrAddressFragmentDoc } from 'schemas/fragment/address/fdr-address.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrTextSearchAgenciesQueryVariables = Types.Exact<{
  searchMode: Types.FdrAgencySearchMode;
  searchInput: Types.Scalars['String'];
  pagination: Types.FdrPaginationInput;
}>;

export type FdrTextSearchAgenciesQuery = {
  __typename?: 'Query';
  fdrSearchAgencies: {
    __typename?: 'FdrSearchAgenciesResponse';
    agencies?: Array<
      | {
          __typename?: 'FdrAgency';
          id: string;
          name: string;
          openStatus: Types.FdrAgencyOpenStatus;
          webMeta: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          };
          hero?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          openingHours?: {
            __typename?: 'FdrOpeningHours';
            monday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            tuesday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            wednesday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            thursday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            friday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            saturday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
            sunday?: {
              __typename?: 'FdrWorkingDay';
              shifts?: Array<{
                __typename?: 'FdrShift';
                from: any;
                to: any;
              }> | null;
            } | null;
          } | null;
          address?: {
            __typename?: 'FdrAgencyAddress';
            zip?: string | null;
            city?: string | null;
            region?: string | null;
            street?: string | null;
            country?: string | null;
          } | null;
        }
      | { __typename?: 'FdrAgencyGeoLocation' }
    > | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export type FdrSearchAgencyFragment = {
  __typename?: 'FdrAgency';
  id: string;
  name: string;
  openStatus: Types.FdrAgencyOpenStatus;
  webMeta: {
    __typename?: 'FdrMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  openingHours?: {
    __typename?: 'FdrOpeningHours';
    monday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    tuesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    wednesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    thursday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    friday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    saturday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    sunday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
  } | null;
  address?: {
    __typename?: 'FdrAgencyAddress';
    zip?: string | null;
    city?: string | null;
    region?: string | null;
    street?: string | null;
    country?: string | null;
  } | null;
};

export const FdrSearchAgencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAgency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrSearchImage' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOpeningHours' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrSearchImageFragmentDoc.definitions,
    ...FdrOpeningHoursFragmentDoc.definitions,
    ...FdrAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrTextSearchAgenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrTextSearchAgencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchMode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAgencySearchMode' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrSearchAgencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchMode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchMode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'suggestion' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchInput' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrAgency' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrSearchAgency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchAgencyFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrTextSearchAgenciesQuery__
 *
 * To run a query within a React component, call `useFdrTextSearchAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrTextSearchAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrTextSearchAgenciesQuery({
 *   variables: {
 *      searchMode: // value for 'searchMode'
 *      searchInput: // value for 'searchInput'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrTextSearchAgenciesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrTextSearchAgenciesQuery,
    FdrTextSearchAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrTextSearchAgenciesQuery,
    FdrTextSearchAgenciesQueryVariables
  >(FdrTextSearchAgenciesDocument, options);
}
export function useFdrTextSearchAgenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrTextSearchAgenciesQuery,
    FdrTextSearchAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrTextSearchAgenciesQuery,
    FdrTextSearchAgenciesQueryVariables
  >(FdrTextSearchAgenciesDocument, options);
}
export type FdrTextSearchAgenciesQueryHookResult = ReturnType<
  typeof useFdrTextSearchAgenciesQuery
>;
export type FdrTextSearchAgenciesLazyQueryHookResult = ReturnType<
  typeof useFdrTextSearchAgenciesLazyQuery
>;
export type FdrTextSearchAgenciesQueryResult = Apollo.QueryResult<
  FdrTextSearchAgenciesQuery,
  FdrTextSearchAgenciesQueryVariables
>;
