import { InfoWindow } from '@react-google-maps/api';
import React from 'react';

interface IInfoBlockProps {
  latLng: google.maps.LatLng | null;
  onCloseClick: () => void;
  isOpenedByRouteOnGeo?: boolean;
}

const OFFSET_TOP = 150;
const OFFSET_WIDTH = 150;

export const FdrMapInfoBlock: React.FC<IInfoBlockProps> = ({
  latLng,
  onCloseClick,
  isOpenedByRouteOnGeo,
  children,
}) => {
  return (
    <InfoWindow
      options={{
        pixelOffset: new google.maps.Size(
          isOpenedByRouteOnGeo ? -OFFSET_WIDTH : OFFSET_WIDTH,
          isOpenedByRouteOnGeo ? -OFFSET_TOP : OFFSET_TOP
        ),
      }}
      position={latLng}
      onCloseClick={onCloseClick}
      onLoad={() => {
        setTimeout(() => {
          const infoHolder = document.querySelector(`.gm-style-iw-c`);
          infoHolder?.classList.add(`nearby`);
          infoHolder?.classList.remove(`related`);
        }, 50);
      }}
    >
      <div className="infoContent">{children}</div>
    </InfoWindow>
  );
};
