import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrMobileMenuWrapper = styled.div({
  position: 'sticky',
  bottom: '16px',
  height: 'auto',
  minHeight: '58px',
  zIndex: 99,
  transition: '0.3s ease',
});

export const FdrMobileMenuItemsWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    width: 'fit-content',
    margin: '0 auto',
    bg: 'lightGreySecond',
    borderRadius: '8px',
    padding: '8px',
    alignItems: 'flex-start',
    '.static-request-button-wrapper': {
      marginLeft: [1],
      '.request-button': {
        borderRadius: '5px',
        padding: '9px 26px 11px',
        '.request-button-caption': {
          fontWeight: '400',
          letterSpacing: '0.5px',
          lineHeight: [null, '22px'],
        },
      },
    },
  })
);
