import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BaseInput } from '@hotelplan/components.common.inputs';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';

export const FdrSearchOverlayModal = styled(WhiteBoxModal)(
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      '@media (orientation: landscape)': {
        '.shadow-overlay-children': {
          padding: 0,
        },
      },
      '.modal-body, .modal-header': {
        backgroundColor: 'paperGrey',
      },
      '.modal-wrapper': {
        maxHeight: '100%',
        height: '100%',
      },
      '.modal-header': {
        borderWidth: 0,
        height: [null, 0],
        backgroundColor: ['paperGrey', 'transparent'],
        padding: 0,
      },
      '.close-button': {
        position: ['static', 'absolute'],
        [media.mobile]: {
          py: [4, '40px'],
          pr: ['30px', '50px'],
        },
        [media.tablet]: {
          top: '40px',
          right: '50px',
          zIndex: 2,
        },
        backgroundColor: 'transparent',
        '.icon': {
          variant: 'icons.xlg',
        },
      },
    })
);

export const FdrExactSearchWrapper = styled.div(
  ({ theme: { media, space } }) => ({
    position: 'absolute',
    top: space[4],
    left: space[3],
    zIndex: 2,
    [media.tablet]: {
      top: '40px',
    },
  })
);

export const FdrSearchDetailsContent = styled.div(
  ({ theme: { space, media } }) => ({
    position: 'relative',
    maxWidth: '988px',
    width: '100%',
    paddingLeft: space[4],
    paddingRight: space[4],
    margin: `16px auto ${space[7]}`,
    [media.tablet]: {
      margin: '123px auto 0px',
    },
    [media.mobile]: {
      paddingLeft: space[3],
      paddingRight: space[3],
    },
  })
);

export const FdrSearchResultTagsWrapper = styled.div<{
  autoSuggestOverlayShown: boolean;
}>(({ autoSuggestOverlayShown }) => ({
  ...(autoSuggestOverlayShown && {
    marginTop: '98px',
  }),
}));

export const FdrSearchResultTags = styled.div(
  ({ theme: { space, media } }) => ({
    display: 'flex',
    flexDirection: 'column',
    '.search-result-tags': {
      display: 'flex',
      flexWrap: 'unset',
      gap: space[2],
      height: '60px',
      overflow: 'auto',
      [media.tablet]: {
        height: 'auto',
        overflow: 'hidden',
        flexWrap: 'wrap',
      },
    },
    '.search-result-tags-items': {
      width: 'auto',
      whiteSpace: 'nowrap',
    },
    '.tagline-text': {
      width: '100%',
      fontSize: '15px',
      fontWeight: '400',
      marginBottom: '24px',
      [media.tablet]: {
        marginRight: space[3],
      },
    },
    marginBottom: space[6],
    [media.tablet]: {
      marginBottom: space[7],
    },
  })
);

export const FdrSearchResultList = styled.div(
  ({ theme: { space, fontSizes, media, colors } }) => ({
    '.heading-wrap': {
      marginBottom: space[3],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '.icon': {
        marginRight: space[3],
      },
    },
    '.tagline-text': {
      fontSize: '15px',
      fontWeight: '400',
    },
    '.list': {
      li: {
        listStyle: 'none',
        fontSize: fontSizes[1],
        fontWeight: '700',
        a: {
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        'a:hover': {
          textDecoration: 'underline',
          color: colors.black,
        },
      },
    },
    button: {
      '.icon': {
        marginRight: space[2],
      },
      '&:focus': {
        outline: '1px auto',
        outlineColor: colors.primary,
      },
    },
    marginBottom: space[6],
    [media.tablet]: {
      marginBottom: space[7],
    },
  })
);

export const FdrSearchInput = styled(BaseInput)(
  ({ theme }) => theme.inputs.searchInput,
  ({ theme: { colors } }) => ({
    flex: 1,
    '.input': {
      borderRadius: '5px 0 0 5px',
    },
    '&.error input': {
      borderBottom: '1px solid',
      borderColor: colors.primary, // @todo use `error` color here, refactor if it's needed
    },
  })
);

export const FdrSearchInputWrap = styled.div({
  position: 'relative',
  flex: 1,
});

export const FdrSearchInputError = styled.div(
  ({ theme: { space, colors } }) => ({
    position: 'absolute',
    paddingTop: space[1],
    paddingLeft: space[2],
    color: colors.primary, // @todo use `error` color here, refactor if it's needed
    fontSize: '12px',
  })
);

export const FdrSearchForm = styled.form<{ autoSuggestOverlayShown: boolean }>(
  ({ autoSuggestOverlayShown, theme: { space, media, colors } }) => ({
    display: 'flex',
    position: autoSuggestOverlayShown ? 'absolute' : 'relative',
    marginBottom: space[7],
    svg: {
      fill: 'transparent',
      color: colors.primary,
    },
    ...(autoSuggestOverlayShown && {
      left: 0,
      right: 0,
      marginLeft: space[4],
      marginRight: space[4],
      [media.mobile]: {
        marginLeft: space[3],
        marginRight: space[3],
      },
    }),
  })
);

export const FdrSearchButton = styled(BsButtonWithIcon)(
  ({ theme: { space, media, fontSizes, colors } }) => ({
    borderRadius: '0 5px 5px 0',
    fontWeight: 'normal',
    fontSize: fontSizes[1],
    '@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)':
      {
        display: 'block',
      },
    [media.mobile]: {
      height: '50px',
      width: '52px',
      padding: '0',
      '.search-submit-button-text': {
        display: 'none',
      },
      '.icon': {
        marginTop: '3px',
        fill: 'white',
      },
    },
    [media.tablet]: {
      marginBottom: space[0],
      '.icon': {
        display: 'none',
      },
    },
    '&:focus': {
      outline: '2px solid',
      outlineColor: colors.redHover,
    },
  })
);

export const FdrSearchResetButton = styled(BsButtonWithIcon)(
  ({ theme: { space, colors } }) => ({
    position: 'absolute',
    right: space[3],
    top: '50%',
    transform: 'translateY(-50%)',
    color: colors.lightGreySecond,
    svg: {
      color: colors.lightGreySecond,
    },
    '&:hover': {
      backgroundColor: 'initial',
    },
  })
);

export const FdrAutoSuggestOverlay = styled.div<{ visible: boolean }>(
  ({ visible }) => ({
    ...(!visible && { display: 'none' }),
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
  })
);

export const FdrSearchAutoSuggestContent = styled.div<{
  flex: boolean;
  showError: boolean;
}>(({ flex, showError, theme: { media } }) => ({
  position: 'absolute',
  top: showError ? 82 : 70,
  width: '100%',
  ...(flex && { display: 'flex' }),
  [media.mobile]: {
    top: showError ? 82 : 60,
  },
}));

export const FdrProductColumn = styled.div<{ flex: boolean }>(
  ({ flex, theme: { colors, media, fontSizes } }) => ({
    ...(flex && { flex: '60%' }),
    padding: '20px',
    fontSize: fontSizes[0],
    backgroundColor: colors.paperGrey,
    borderRadius: '0 8px 8px 0',
    [media.mobile]: {
      borderRadius: '0 0 8px 8px',
      marginBottom: '50px',
    },
  })
);

export const FdrNonProductColumn = styled.div<{ flex: boolean }>(
  ({ flex, theme: { colors, media, fontSizes } }) => ({
    ...(flex && { flex: '40%' }),
    padding: '20px',
    fontSize: fontSizes[0],
    backgroundColor: colors.background,
    overflow: 'hidden',
    borderRadius: '8px 0 0 8px',
    [media.mobile]: {
      borderRadius: '8px 8px 0 0',
    },
    ':not(:has(+ .product))': {
      borderRadius: '8px',
    },
  })
);

export const FdrSearchAutoSuggestTitle = styled.div(
  ({ theme: { fontSizes } }) => ({
    fontSize: fontSizes[1],
    fontWeight: '400',
    textTransform: 'uppercase',
    padding: '5px 0',
  })
);

export const FdrAutoSuggestLink = styled(BsLink)({
  fontSize: '16px',
  display: 'block',
  padding: '5px 0',
  ':hover': {
    cursor: 'pointer',
  },
});

export const FdrNonProductLink = styled(FdrAutoSuggestLink)<{
  active: boolean;
}>(({ active, theme: { colors } }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...(active && { backgroundColor: colors.paperGrey }),
}));

export const FdrProductHeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const FdrProductHeader = styled(FdrSearchAutoSuggestTitle)(
  ({ theme: { space } }) => ({
    flex: '50%',
    textAlign: 'left',
    marginBottom: space[2],
  })
);

export const FdrProductShowAllLinkContainer = styled(FdrProductHeader)(
  ({ theme: { colors, fontSizes } }) => ({
    fontSize: fontSizes[1],
    textTransform: 'initial',
    color: colors.primary,
  })
);

export const FdrProductLink = styled(FdrAutoSuggestLink)<{ active: boolean }>(
  ({ active, theme: { colors } }) => ({
    display: 'flex',
    ...(active && { backgroundColor: colors.white }),
  })
);

export const FdrProductImageContainer = styled.div({
  flexBasis: '80px',
  flexShrink: 0,
  flexGrow: 0,
});

export const FdrProductImage = styled(FdrImage)({
  width: '100%',
  ':hover': {
    cursor: 'pointer',
  },
});

export const FdrProductContent = styled.div({
  padding: '5px 10px',
  fontWeight: 700,
});

export const FdrSearchAutoSuggestHighlight = styled.span({
  fontWeight: 700,
});
