import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import { lCfg, pCfg } from 'components/util/criterias/base.dmc';

export const fdrMarketingMobileImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`375w_mobile`, pCfg(375, 263, FdrImageResizeMode.FillDown)],
  [`414w_mobile`, pCfg(414, 290, FdrImageResizeMode.FillDown)],
  [`768w_mobile`, pCfg(768, 538, FdrImageResizeMode.FillDown)],
  [`912w_mobile`, pCfg(912, 347, FdrImageResizeMode.FillDown)],

  // mobile landscape

  [`740w_mobile`, lCfg(740, 518, FdrImageResizeMode.FillDown)],
  [`915w_mobile`, lCfg(915, 348, FdrImageResizeMode.FillDown)],
  [`1024w_mobile`, lCfg(1024, 390, FdrImageResizeMode.FillDown)],
  [`1368w_mobile`, lCfg(1368, 520, FdrImageResizeMode.FillDown)],
];

const fdrMarketingDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1920w_desktop`, lCfg(1920, 724, FdrImageResizeMode.FillDown)],
  [`2560w_desktop`, lCfg(2560, 967, FdrImageResizeMode.FillDown)],
  [`3440w_desktop`, lCfg(3440, 1052, FdrImageResizeMode.FillDown)],
  [`3840w_desktop`, lCfg(3840, 1052, FdrImageResizeMode.FillDown)],
];

export const fdrMarketingImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrMarketingMobileImagesSpecs,
  ...fdrMarketingDesktopImagesSpecs,
]);

export const fdrMarketingRecommendation = query2FdrCriteria(
  fdrMarketingImagesSpecs
);
