import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from 'react';

type ExactSearchType = boolean;
type ExactSearchTypeSetter = (isExactSearch: boolean) => void;
type ExactSearchContextType = [ExactSearchType, ExactSearchTypeSetter] | null;

const ExactSearchContext = React.createContext<ExactSearchContextType>(null);

export function FdrExactSearchContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  const exactSearchContextState = useState<boolean>(true);

  return (
    <ExactSearchContext.Provider value={exactSearchContextState}>
      {children}
    </ExactSearchContext.Provider>
  );
}

export function useFdrExactSearchContext(): ExactSearchContextType {
  return useContext(ExactSearchContext);
}
