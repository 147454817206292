import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { FdrOpeningStatusWrapper } from './fdr-schedule.styles';

interface IFdrScheduleOpeningStatusProps {
  opened: boolean;
  showStatusIcon?: boolean;
  className?: string;
}

export function FdrScheduleOpeningStatus({
  opened,
  showStatusIcon,
  className,
}: IFdrScheduleOpeningStatusProps): ReactElement {
  const [t] = useTranslation('agency');

  return (
    <FdrOpeningStatusWrapper
      data-id="fdr-help-overlay-opening-status"
      opened={opened}
      showStatusIcon={showStatusIcon}
      className={className}
    >
      {t(opened ? 'agency:openingHours.opened' : 'agency:openingHours.closed')}
    </FdrOpeningStatusWrapper>
  );
}
