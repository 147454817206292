import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrThemeGeoBreadcrumbsQueryVariables = Types.Exact<{
  geoId: Types.Scalars['String'];
  themeId: Types.Scalars['String'];
}>;

export type FdrThemeGeoBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrThemeGeoPage?: {
    __typename?: 'FdrThemeGeoPageResponse';
    page?: {
      __typename?: 'FdrThemeGeoPage';
      breadcrumbTitle: string;
      theme: {
        __typename?: 'FdrTheme';
        name: string;
        webMeta: {
          __typename?: 'FdrThemeWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      };
    } | null;
  } | null;
  fdrHomePage?: {
    __typename?: 'FdrHomePage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
  fdrThemeOverviewPage?: {
    __typename?: 'FdrThemeOverviewPage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export const FdrThemeGeoBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrThemeGeoBreadcrumbs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'geoId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'themeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrThemeGeoPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'geoId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'geoId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'themeId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'themeId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'breadcrumbTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'webMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'descriptionWebMeta',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'link' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fdrLink',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrThemeOverviewPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrThemeGeoBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrThemeGeoBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrThemeGeoBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrThemeGeoBreadcrumbsQuery({
 *   variables: {
 *      geoId: // value for 'geoId'
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useFdrThemeGeoBreadcrumbsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrThemeGeoBreadcrumbsQuery,
    FdrThemeGeoBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrThemeGeoBreadcrumbsQuery,
    FdrThemeGeoBreadcrumbsQueryVariables
  >(FdrThemeGeoBreadcrumbsDocument, options);
}
export function useFdrThemeGeoBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrThemeGeoBreadcrumbsQuery,
    FdrThemeGeoBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrThemeGeoBreadcrumbsQuery,
    FdrThemeGeoBreadcrumbsQueryVariables
  >(FdrThemeGeoBreadcrumbsDocument, options);
}
export type FdrThemeGeoBreadcrumbsQueryHookResult = ReturnType<
  typeof useFdrThemeGeoBreadcrumbsQuery
>;
export type FdrThemeGeoBreadcrumbsLazyQueryHookResult = ReturnType<
  typeof useFdrThemeGeoBreadcrumbsLazyQuery
>;
export type FdrThemeGeoBreadcrumbsQueryResult = Apollo.QueryResult<
  FdrThemeGeoBreadcrumbsQuery,
  FdrThemeGeoBreadcrumbsQueryVariables
>;
