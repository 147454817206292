import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import {
  FdrAtSearchItemFragmentDoc,
  FdrHotelSearchItemFragmentDoc,
  FdrRoundTripSearchItemFragmentDoc,
  FdrContinentSearchItemFragmentDoc,
  FdrCountryGroupSearchItemFragmentDoc,
  FdrCountrySearchItemFragmentDoc,
  FdrRegionSearchItemFragmentDoc,
  FdrResortSearchItemFragmentDoc,
  FdrThemeSearchItemFragmentDoc,
  FdrBlogArticleSearchItemFragmentDoc,
  FdrCatalogueSearchItemFragmentDoc,
  FdrAgencyOverviewSearchItemFragmentDoc,
  FdrNewsletterSearchItemFragmentDoc,
  FdrStaticPageSearchItemFragmentDoc,
} from 'schemas/fragment/search/fdr-search-items.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrTextSearchQueryVariables = Types.Exact<{
  searchInput: Types.Scalars['String'];
  searchTypes: Array<Types.FdrSearchType> | Types.FdrSearchType;
  searchMode: Types.FdrSearchMode;
  pagination: Types.FdrPaginationInput;
}>;

export type FdrTextSearchQuery = {
  __typename?: 'Query';
  fdrTextSearch: {
    __typename?: 'FdrTextSearchResponse';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    itemList?: Array<
      | {
          __typename?: 'FdrAdventureTravelSearchItem';
          debugInfo?: string | null;
          adventureTravel: {
            __typename?: 'FdrAdventureTravel';
            name: string;
            isInBookmarks: boolean;
            teaser: string;
            leadText?: string | null;
            id: string;
            images?: Array<{
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }> | null;
            googleStaticMapImage?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
              bgColor?: string | null;
              fgColor?: string | null;
            }> | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            productInfoB2B?: {
              __typename?: 'ProductInfoB2B';
              route?: string | null;
              duration: string;
              priceFrom?: string | null;
              typeFlags?: Array<string> | null;
            } | null;
            route?: Array<{
              __typename?: 'FdrAdventureTravelRoutePoint';
              locationName: string;
              coordinates: {
                __typename?: 'FdrCoordinates';
                latitude: number;
                longitude: number;
              };
            }> | null;
            featureGroups?: Array<{
              __typename?: 'FdrProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'FdrProductFeature';
                id: string;
                name: string;
                enabled: boolean;
              }>;
            }> | null;
          };
        }
      | {
          __typename?: 'FdrAgencyOverviewPageSearchItem';
          debugInfo?: string | null;
          agencyOverviewPage: {
            __typename?: 'FdrAgencyOverviewPage';
            text?: string | null;
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | { __typename?: 'FdrAirportSearchItem' }
      | {
          __typename?: 'FdrBlogArticleSearchItem';
          debugInfo?: string | null;
          blogArticle: {
            __typename?: 'FdrBlogArticle';
            text?: string | null;
            id: string;
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrCatalogPageSearchItem';
          debugInfo?: string | null;
          catalogPage: {
            __typename?: 'FdrCatalogPage';
            text?: string | null;
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrContinentSearchItem';
          debugInfo?: string | null;
          continent: {
            __typename?: 'FdrContinent';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountryGroupSearchItem';
          debugInfo?: string | null;
          countryGroup: {
            __typename?: 'FdrCountryGroup';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountrySearchItem';
          debugInfo?: string | null;
          country: {
            __typename?: 'FdrCountry';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrCruiseSearchItem' }
      | { __typename?: 'FdrDestinationSearchItem' }
      | {
          __typename?: 'FdrHotelSearchItem';
          debugInfo?: string | null;
          hotel: {
            __typename?: 'FdrHotel';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
              bgColor?: string | null;
              fgColor?: string | null;
            }> | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            resort: {
              __typename?: 'FdrResort';
              publicId: string;
              name: string;
              destination: {
                __typename?: 'FdrDestination';
                publicId: string;
                name: string;
                country: {
                  __typename?: 'FdrCountry';
                  publicId: string;
                  name: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                };
                region?: {
                  __typename?: 'FdrRegion';
                  publicId: string;
                  name: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                } | null;
              };
            };
          };
        }
      | {
          __typename?: 'FdrNewsletterSubscriptionPageSearchItem';
          debugInfo?: string | null;
          newsletterSubscriptionPage: {
            __typename?: 'FdrNewsletterSubscriptionPage';
            text?: string | null;
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrRegionSearchItem';
          debugInfo?: string | null;
          region: {
            __typename?: 'FdrRegion';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrResortSearchItem';
          debugInfo?: string | null;
          resort: {
            __typename?: 'FdrResort';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrRoundTripSearchItem';
          debugInfo?: string | null;
          roundTrip: {
            __typename?: 'FdrRoundTrip';
            name: string;
            leadText?: string | null;
            isInBookmarks: boolean;
            id: string;
            mapImages?: Array<{
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }> | null;
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
              bgColor?: string | null;
              fgColor?: string | null;
            }> | null;
            productInfoB2B?: {
              __typename?: 'ProductInfoB2B';
              route?: string | null;
              duration: string;
              priceFrom?: string | null;
              typeFlags?: Array<string> | null;
            } | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrStaticPageSearchItem';
          debugInfo?: string | null;
          staticPage: {
            __typename?: 'FdrStaticPage';
            text?: string | null;
            name?: string | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          };
        }
      | {
          __typename?: 'FdrThemeSearchItem';
          debugInfo?: string | null;
          theme: {
            __typename?: 'FdrTheme';
            name: string;
            isInBookmarks: boolean;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrThemeWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
    > | null;
  };
};

export type FdrSearchItemFdrAdventureTravelSearchItemFragment = {
  __typename?: 'FdrAdventureTravelSearchItem';
  debugInfo?: string | null;
  adventureTravel: {
    __typename?: 'FdrAdventureTravel';
    name: string;
    isInBookmarks: boolean;
    teaser: string;
    leadText?: string | null;
    id: string;
    images?: Array<{
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    }> | null;
    googleStaticMapImage?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
    productInfoB2B?: {
      __typename?: 'ProductInfoB2B';
      route?: string | null;
      duration: string;
      priceFrom?: string | null;
      typeFlags?: Array<string> | null;
    } | null;
    route?: Array<{
      __typename?: 'FdrAdventureTravelRoutePoint';
      locationName: string;
      coordinates: {
        __typename?: 'FdrCoordinates';
        latitude: number;
        longitude: number;
      };
    }> | null;
    featureGroups?: Array<{
      __typename?: 'FdrProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'FdrProductFeature';
        id: string;
        name: string;
        enabled: boolean;
      }>;
    }> | null;
  };
};

export type FdrSearchItemFdrAgencyOverviewPageSearchItemFragment = {
  __typename?: 'FdrAgencyOverviewPageSearchItem';
  debugInfo?: string | null;
  agencyOverviewPage: {
    __typename?: 'FdrAgencyOverviewPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrSearchItemFdrAirportSearchItemFragment = {
  __typename?: 'FdrAirportSearchItem';
};

export type FdrSearchItemFdrBlogArticleSearchItemFragment = {
  __typename?: 'FdrBlogArticleSearchItem';
  debugInfo?: string | null;
  blogArticle: {
    __typename?: 'FdrBlogArticle';
    text?: string | null;
    id: string;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrSearchItemFdrCatalogPageSearchItemFragment = {
  __typename?: 'FdrCatalogPageSearchItem';
  debugInfo?: string | null;
  catalogPage: {
    __typename?: 'FdrCatalogPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrSearchItemFdrContinentSearchItemFragment = {
  __typename?: 'FdrContinentSearchItem';
  debugInfo?: string | null;
  continent: {
    __typename?: 'FdrContinent';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrCountryGroupSearchItemFragment = {
  __typename?: 'FdrCountryGroupSearchItem';
  debugInfo?: string | null;
  countryGroup: {
    __typename?: 'FdrCountryGroup';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrCountrySearchItemFragment = {
  __typename?: 'FdrCountrySearchItem';
  debugInfo?: string | null;
  country: {
    __typename?: 'FdrCountry';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrCruiseSearchItemFragment = {
  __typename?: 'FdrCruiseSearchItem';
};

export type FdrSearchItemFdrDestinationSearchItemFragment = {
  __typename?: 'FdrDestinationSearchItem';
};

export type FdrSearchItemFdrHotelSearchItemFragment = {
  __typename?: 'FdrHotelSearchItem';
  debugInfo?: string | null;
  hotel: {
    __typename?: 'FdrHotel';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
    resort: {
      __typename?: 'FdrResort';
      publicId: string;
      name: string;
      destination: {
        __typename?: 'FdrDestination';
        publicId: string;
        name: string;
        country: {
          __typename?: 'FdrCountry';
          publicId: string;
          name: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        };
        region?: {
          __typename?: 'FdrRegion';
          publicId: string;
          name: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
        } | null;
      };
    };
  };
};

export type FdrSearchItemFdrNewsletterSubscriptionPageSearchItemFragment = {
  __typename?: 'FdrNewsletterSubscriptionPageSearchItem';
  debugInfo?: string | null;
  newsletterSubscriptionPage: {
    __typename?: 'FdrNewsletterSubscriptionPage';
    text?: string | null;
    name?: string | null;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  };
};

export type FdrSearchItemFdrRegionSearchItemFragment = {
  __typename?: 'FdrRegionSearchItem';
  debugInfo?: string | null;
  region: {
    __typename?: 'FdrRegion';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrResortSearchItemFragment = {
  __typename?: 'FdrResortSearchItem';
  debugInfo?: string | null;
  resort: {
    __typename?: 'FdrResort';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrRoundTripSearchItemFragment = {
  __typename?: 'FdrRoundTripSearchItem';
  debugInfo?: string | null;
  roundTrip: {
    __typename?: 'FdrRoundTrip';
    name: string;
    leadText?: string | null;
    isInBookmarks: boolean;
    id: string;
    mapImages?: Array<{
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    }> | null;
    disrupters?: Array<{
      __typename?: 'FdrDisrupter';
      text: string;
      bgColor?: string | null;
      fgColor?: string | null;
    }> | null;
    productInfoB2B?: {
      __typename?: 'ProductInfoB2B';
      route?: string | null;
      duration: string;
      priceFrom?: string | null;
      typeFlags?: Array<string> | null;
    } | null;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFdrStaticPageSearchItemFragment = {
  __typename?: 'FdrStaticPageSearchItem';
  debugInfo?: string | null;
  staticPage: {
    __typename?: 'FdrStaticPage';
    text?: string | null;
    name?: string | null;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
  };
};

export type FdrSearchItemFdrThemeSearchItemFragment = {
  __typename?: 'FdrThemeSearchItem';
  debugInfo?: string | null;
  theme: {
    __typename?: 'FdrTheme';
    name: string;
    isInBookmarks: boolean;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    webMeta: {
      __typename?: 'FdrThemeWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrSearchItemFragment =
  | FdrSearchItemFdrAdventureTravelSearchItemFragment
  | FdrSearchItemFdrAgencyOverviewPageSearchItemFragment
  | FdrSearchItemFdrAirportSearchItemFragment
  | FdrSearchItemFdrBlogArticleSearchItemFragment
  | FdrSearchItemFdrCatalogPageSearchItemFragment
  | FdrSearchItemFdrContinentSearchItemFragment
  | FdrSearchItemFdrCountryGroupSearchItemFragment
  | FdrSearchItemFdrCountrySearchItemFragment
  | FdrSearchItemFdrCruiseSearchItemFragment
  | FdrSearchItemFdrDestinationSearchItemFragment
  | FdrSearchItemFdrHotelSearchItemFragment
  | FdrSearchItemFdrNewsletterSubscriptionPageSearchItemFragment
  | FdrSearchItemFdrRegionSearchItemFragment
  | FdrSearchItemFdrResortSearchItemFragment
  | FdrSearchItemFdrRoundTripSearchItemFragment
  | FdrSearchItemFdrStaticPageSearchItemFragment
  | FdrSearchItemFdrThemeSearchItemFragment;

export const FdrSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrATSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrHotelSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrRoundTripSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrContinentSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrCountryGroupSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrCountrySearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrRegionSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrResortSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrThemeSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrBlogArticleSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrCatalogueSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAgencyOverviewSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrNewsletterSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrStaticPageSearchItem' },
          },
        ],
      },
    },
    ...FdrAtSearchItemFragmentDoc.definitions,
    ...FdrHotelSearchItemFragmentDoc.definitions,
    ...FdrRoundTripSearchItemFragmentDoc.definitions,
    ...FdrContinentSearchItemFragmentDoc.definitions,
    ...FdrCountryGroupSearchItemFragmentDoc.definitions,
    ...FdrCountrySearchItemFragmentDoc.definitions,
    ...FdrRegionSearchItemFragmentDoc.definitions,
    ...FdrResortSearchItemFragmentDoc.definitions,
    ...FdrThemeSearchItemFragmentDoc.definitions,
    ...FdrBlogArticleSearchItemFragmentDoc.definitions,
    ...FdrCatalogueSearchItemFragmentDoc.definitions,
    ...FdrAgencyOverviewSearchItemFragmentDoc.definitions,
    ...FdrNewsletterSearchItemFragmentDoc.definitions,
    ...FdrStaticPageSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrTextSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrTextSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTypes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrSearchType' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchMode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrSearchMode' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrTextSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchInput' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchTypes' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchMode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrTextSearchQuery__
 *
 * To run a query within a React component, call `useFdrTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrTextSearchQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      searchTypes: // value for 'searchTypes'
 *      searchMode: // value for 'searchMode'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrTextSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrTextSearchQuery,
    FdrTextSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrTextSearchQuery,
    FdrTextSearchQueryVariables
  >(FdrTextSearchDocument, options);
}
export function useFdrTextSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrTextSearchQuery,
    FdrTextSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrTextSearchQuery,
    FdrTextSearchQueryVariables
  >(FdrTextSearchDocument, options);
}
export type FdrTextSearchQueryHookResult = ReturnType<
  typeof useFdrTextSearchQuery
>;
export type FdrTextSearchLazyQueryHookResult = ReturnType<
  typeof useFdrTextSearchLazyQuery
>;
export type FdrTextSearchQueryResult = Apollo.QueryResult<
  FdrTextSearchQuery,
  FdrTextSearchQueryVariables
>;
