import { ReactElement } from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

const ExternalOverlayModal = styled(WhiteBoxModal)(
  ({ theme }) => theme.modals.externalOverlay
);

interface IExternalOverlayProps {
  showOverlay: boolean;
  closeOverlay(): void;
  href?: string;
  loading: boolean;
}

export default function ExternalOverlay({
  showOverlay,
  closeOverlay,
  href,
  loading,
}: IExternalOverlayProps): ReactElement {
  return (
    <ExternalOverlayModal
      show={showOverlay}
      onClose={closeOverlay}
      name="externalOverlay"
    >
      {!loading && href ? <iframe src={href} title={href} /> : null}
      {loading ? `Loading ....` : null}
    </ExternalOverlayModal>
  );
}
