import throttle from 'lodash/throttle';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { optionsWithPassive } from '@hotelplan/libs.polyfills';
import { isBrowser } from '@hotelplan/libs.utils';
import {
  FdrScrollButton,
  FdrScrollToTopButtonWrapper,
} from './fdr-scroll-to-top.styles';

function handleScrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function FdrScrollToTop(): ReactElement {
  const [scrollToTopButton, setShowScrollToTopButton] = useState(false);
  const scrollToTopButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const onScroll = throttle(() => {
      setShowScrollToTopButton(window.scrollY > 20);
    }, 150);

    window.addEventListener('scroll', onScroll, optionsWithPassive);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <FdrScrollToTopButtonWrapper
      show={scrollToTopButton}
      ref={scrollToTopButtonRef}
    >
      <FdrScrollButton onClick={handleScrollToTop} />
    </FdrScrollToTopButtonWrapper>
  );
}
