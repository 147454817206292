import React from 'react';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { useElementEnteredViewport } from '@hotelplan/core.serving.bs-lazy-render';

const VideoMediaWrap = styled.div({
  height: '100%',
  minHeight: '20vw',
});

const MusicMediaWrap = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const ExternalMediaLink = styled(BsLink)({ width: '100%', height: '100%' });

const PdfMediaLink = styled(ExternalMediaLink)({
  width: '100%',
  height: '100%',
});

export type TMediaItemProps = {
  lazy?: boolean;
  isGallery?: boolean;
  className?: string;
  typename?: string;
  resourceLink?: string | null;
};

export const FdrMarketingMediaSwitch: React.FC<TMediaItemProps> = props => {
  const { className, isGallery, children, resourceLink } = props;

  let item: React.ReactNode = null;

  const { refEl, entered } = useElementEnteredViewport<HTMLDivElement>();

  switch (props.typename) {
    case 'FdrImage': {
      item = children;
      break;
    }
    case 'FdrVideo':
      item = (
        <VideoMediaWrap ref={refEl} className="video" data-id="slide-video">
          {entered && (
            <ReactPlayer
              playing={!isGallery}
              controls={isGallery}
              muted={!isGallery}
              url={resourceLink}
              width="100%"
              height="100%"
            />
          )}
        </VideoMediaWrap>
      );
      break;
    case 'FdrAudio':
      item = (
        <MusicMediaWrap ref={refEl} className="music" data-id="music-media">
          {children}
          {entered && (
            <ReactPlayer
              playing={!isGallery}
              controls={isGallery}
              url={resourceLink}
              width="100%"
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
              }}
            />
          )}
        </MusicMediaWrap>
      );
      break;
    case 'FdrExternalMedia':
      item = (
        <ExternalMediaLink
          className="external"
          href={resourceLink}
          data-id="external-media"
          target="_blank"
        >
          {children}
        </ExternalMediaLink>
      );
      break;
    case 'FdrPdf':
      item = (
        <PdfMediaLink
          className="pdf"
          href={resourceLink}
          data-id="pdf-media"
          target="_blank"
        >
          {children}
        </PdfMediaLink>
      );
      break;
    default:
      break;
  }

  return <div className={className}>{item}</div>;
};
