import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrCookieDisclaimerQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrCookieDisclaimerQuery = {
  __typename?: 'Query';
  fdrCookieDisclaimer?: {
    __typename?: 'FdrCookieDisclaimer';
    mainText: string;
  } | null;
};

export const FdrCookieDisclaimerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrCookieDisclaimer' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrCookieDisclaimer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrCookieDisclaimerQuery__
 *
 * To run a query within a React component, call `useFdrCookieDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrCookieDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrCookieDisclaimerQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrCookieDisclaimerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrCookieDisclaimerQuery,
    FdrCookieDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrCookieDisclaimerQuery,
    FdrCookieDisclaimerQueryVariables
  >(FdrCookieDisclaimerDocument, options);
}
export function useFdrCookieDisclaimerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrCookieDisclaimerQuery,
    FdrCookieDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrCookieDisclaimerQuery,
    FdrCookieDisclaimerQueryVariables
  >(FdrCookieDisclaimerDocument, options);
}
export type FdrCookieDisclaimerQueryHookResult = ReturnType<
  typeof useFdrCookieDisclaimerQuery
>;
export type FdrCookieDisclaimerLazyQueryHookResult = ReturnType<
  typeof useFdrCookieDisclaimerLazyQuery
>;
export type FdrCookieDisclaimerQueryResult = Apollo.QueryResult<
  FdrCookieDisclaimerQuery,
  FdrCookieDisclaimerQueryVariables
>;
