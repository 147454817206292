import { useTranslation } from 'next-i18next';
import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { ShareLink } from 'components/domain/share-overlay/share-overlay.styles';
import {
  EShareType,
  TShareLink,
} from 'components/domain/share-overlay/useShareLinks';

type TProps = {
  item: TShareLink;
  trackFn: () => void;
  platform: string;
};

const ShareButton: React.FC<TProps> = ({ item, trackFn, platform }: TProps) => {
  const [t] = useTranslation('common');

  const attrs =
    platform === EShareType.DOWNLOAD && item?.name
      ? { download: `${encodeURIComponent(item.name)}.pdf` }
      : {};

  return (
    <button onClick={trackFn}>
      <ShareLink
        href={item.apiLink}
        onClick={item.func}
        target="__blank"
        {...attrs}
        aria-label={t('common:share.overlay.shareLabel', {
          platform,
        })}
      >
        <Icon name={item.icon} size="md" />
      </ShareLink>
    </button>
  );
};

export default ShareButton;
