import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import { lCfg, pCfg } from 'components/util/criterias/base.dmc';

export const fdrHeroMobileImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`375w_mobile`, pCfg(375, 740, FdrImageResizeMode.FillDown)],
  [`414w_mobile`, pCfg(414, 915, FdrImageResizeMode.FillDown)],
  [`768w_mobile`, pCfg(768, 1024, FdrImageResizeMode.FillDown)],
  [`912w_mobile`, pCfg(912, 1368, FdrImageResizeMode.FillDown)],

  // mobile landscape
  [`740w_mobile`, lCfg(740, 375, FdrImageResizeMode.FillDown)],
  [`915w_mobile`, lCfg(915, 414, FdrImageResizeMode.FillDown)],
  [`1024w_mobile`, lCfg(1024, 768, FdrImageResizeMode.FillDown)],
  [`1368w_mobile`, lCfg(1368, 912, FdrImageResizeMode.FillDown)],
];

const fdrHeroDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1920w_desktop`, lCfg(1920, 800, FdrImageResizeMode.FillDown)],
  [`2560w_desktop`, lCfg(2560, 800, FdrImageResizeMode.FillDown)],
  [`3440w_desktop`, lCfg(3440, 800, FdrImageResizeMode.FillDown)],
  [`3840w_desktop`, lCfg(3840, 800, FdrImageResizeMode.FillDown)],
];

const fdrHomePageHeroDesktopUpdateSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1920w_desktop`, lCfg(1920, 1080, FdrImageResizeMode.FillDown)],
  [`2560w_desktop`, lCfg(2560, 1440, FdrImageResizeMode.FillDown)],
  [`3440w_desktop`, lCfg(3440, 1440, FdrImageResizeMode.FillDown)],
  [`3840w_desktop`, lCfg(3840, 2160, FdrImageResizeMode.FillDown)],
];

const fdrHomePageHeroUpdate: TFdrMediaMetaMap = new Map(
  fdrHomePageHeroDesktopUpdateSpecs
);

export const fdrHeroImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrHeroMobileImagesSpecs,
  ...fdrHeroDesktopImagesSpecs,
]);

export const fdrHomeHeroImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrHeroMobileImagesSpecs,
  ...fdrHomePageHeroDesktopUpdateSpecs,
]);

export const fdrHeroCriteria = query2FdrCriteria(fdrHeroImagesSpecs);

export const fdrHomeHeroCriteria = query2FdrCriteria(
  fdrHeroImagesSpecs,
  fdrHomePageHeroUpdate
);
