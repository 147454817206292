import throttle from 'lodash/throttle';
import { useEffect, MutableRefObject } from 'react';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { useSelfUpdatingRef } from '@hotelplan/libs.hooks-react';

export function useScrollPosition(
  wrapperRef: MutableRefObject<HTMLDivElement>,
  firstItemRef: MutableRefObject<HTMLDivElement>,
  onChangePage: (page: number) => void,
  cardsGap: number,
  perPage: number,
  page: number
): void {
  const { w: width } = useElementSize(wrapperRef);
  const currentPage = useSelfUpdatingRef(page);

  useEffect(
    function watchScrollPositionEffect() {
      const wrapperEl = wrapperRef.current;
      const firstItemEl = firstItemRef.current;

      if (!wrapperEl || !firstItemEl || !onChangePage) return;

      const onScroll = throttle(() => {
        const itemWidth = firstItemEl.offsetWidth;

        if (itemWidth === 0) {
          console.error(`Item width is 0, aborting scroll logic`);
          return;
        }

        const rightSide = wrapperEl.scrollLeft + width;
        const cardToShow = Math.floor(rightSide / (itemWidth + cardsGap) + 1);
        const pageToShow = Math.floor(cardToShow / perPage);

        if (currentPage.current !== pageToShow) {
          onChangePage(pageToShow);
        }
      }, 300);

      // Adding timeout to ensure the elements are fully rendered
      const timeoutId = setTimeout(() => {
        wrapperEl.addEventListener('scroll', onScroll, { passive: true });
      }, 0);

      return function positionEventCleanup() {
        clearTimeout(timeoutId);
        wrapperEl.removeEventListener('scroll', onScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wrapperRef.current, firstItemRef.current, width, perPage, currentPage]
  );
}
