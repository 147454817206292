import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { useBookmarkModalPopupContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-modal-popup-context';
import { useCreateBookmarkGroup } from 'components/domain/fdr-bookmark/hooks/useCreateBookmarkGroup';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupContentRow,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './fdr-bookmark-modal-popup.styles';
import FdrBookmarkPopupNewGroupForm, {
  IBookmarkPopupNewGroupFormState,
} from './fdr-bookmark-popup-new-group-form';

const BookmarkGroupCreateModalPopup: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const [t] = useTranslation(['common']);
  const { createBookmarkGroup } = useCreateBookmarkGroup();

  const onSubmit = useCallback((values: IBookmarkPopupNewGroupFormState) => {
    createBookmarkGroup(values.name).then(response => {
      if (response?.data?.fdrCreateBookmarkGroup?.success) {
        bookmarkModalContext.close();
      }
    });
  }, []);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.group.create.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            bookmarkModalContext.close();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupTextRow>
          {t('bookmark.group.create.text')}:
        </BookmarkPopupTextRow>
        <BookmarkPopupContentRow>
          <FdrBookmarkPopupNewGroupForm
            onSubmit={onSubmit}
            onCancel={() => {
              bookmarkModalContext.close();
            }}
          />
        </BookmarkPopupContentRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};
export default BookmarkGroupCreateModalPopup;
