import { ReactElement, useMemo } from 'react';
import { BoosterFrequency, FdrBooster } from '@hotelplan/supergraph-api';
import { FdrBoostersWrapper } from './fdr-boosters-section.styles';

interface IFdrBoostersSectionProps {
  boosters: Array<FdrBooster>;
}

export function FdrBoostersSection({
  boosters,
}: IFdrBoostersSectionProps): ReactElement {
  const frequencyBoosters: Array<FdrBooster> = useMemo(() => {
    return boosters
      ?.slice(0)
      .concat(
        boosters
          .filter(
            b =>
              b.frequency === BoosterFrequency.Twice ||
              b.frequency === BoosterFrequency.Infinite
          )
          .map((b, i) => ({ ...b, id: `${b.id}_copy-${i}` }))
      );
  }, [boosters]);

  if (frequencyBoosters?.length === 0) return null;

  return <FdrBoostersWrapper boosters={frequencyBoosters} timesToRepeat={1} />;
}
