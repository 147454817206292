import React, { ReactElement } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrHelpOverlayBox } from 'components/candidate/fdr-help-overlay';
import {
  useFdrGetClientGclidValue,
  useFdrGetLinkWithJotform,
} from 'components/domain/fdr-jotform';
import { FdrAgencyFragment } from 'schemas/fragment/agency/fdr-agency.generated';
import { FdrHelpOverlayBoxFragment } from 'schemas/query/help-overlay/fdr-help-overlay.generated';
import { FlaglineText, ThirdHeading } from 'theme/headings';
import {
  FdrAgencyAddress,
  FdrBoxLink,
  FdrHelpOverlayContent,
  FdrInfoCardWrapper,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayGeneralBoxProps {
  box: FdrHelpOverlayBoxFragment;
  boxesCount: number;
  isAgency: boolean;
  agency?: FdrAgencyFragment;
  onClick?(): void;
}

export function FdrHelpOverlayGeneralBox({
  box,
  boxesCount,
  onClick,
  isAgency,
  agency,
}: IFdrHelpOverlayGeneralBoxProps): ReactElement {
  const { mobile } = useDeviceType();
  const gclid = useFdrGetClientGclidValue('', true);
  const linkWithJotform = useFdrGetLinkWithJotform(box.targetUrl, gclid);

  if (!box) return null;

  const boxLink = isAgency ? agency?.webMeta.link : linkWithJotform;

  return (
    <FdrHelpOverlayBox boxesCount={boxesCount} link={boxLink} onClick={onClick}>
      <FdrInfoCardWrapper infoCardImage={box.icon}>
        <div className="help-overlay-content-wrap">
          <FdrHelpOverlayContent>
            {!mobile && (
              <FlaglineText className="image-tagline">
                {isAgency ? agency?.name : box.icon.alt}
              </FlaglineText>
            )}
            <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
            {!mobile && (
              <p
                className="image-text"
                dangerouslySetInnerHTML={{ __html: box.mainText }}
              />
            )}
            {!mobile && isAgency && (
              <FdrAgencyAddress>
                {agency?.address?.street ? (
                  <span className="address">{agency.address.street}</span>
                ) : null}
                {agency?.address?.zip ? (
                  <span className="address">{`${agency.address.zip} ${agency.address.city}`}</span>
                ) : null}
              </FdrAgencyAddress>
            )}
          </FdrHelpOverlayContent>
        </div>
        <FdrBoxLink icon={{ name: 'chevron-long-right' }}>
          <span className="link-text">{box.mainTitle}</span>
        </FdrBoxLink>
      </FdrInfoCardWrapper>
    </FdrHelpOverlayBox>
  );
}
