import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrInfoNotificationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrInfoNotificationQuery = {
  __typename?: 'Query';
  fdrNotifications: {
    __typename?: 'FdrNotificationsResponse';
    maintenance?: Array<{
      __typename?: 'FdrMaintenanceNotification';
      id: string;
      mainText: string;
      mainTitle: string;
      showIfClosed?: Types.FdrMaintenanceNotificationShowMode | null;
      notificationType: Types.FdrMaintenanceNotificationType;
      backgroundColor: string;
      closable?: boolean | null;
    }> | null;
  };
};

export const FdrInfoNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrInfoNotification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maintenance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'showIfClosed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrInfoNotificationQuery__
 *
 * To run a query within a React component, call `useFdrInfoNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrInfoNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrInfoNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrInfoNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >(FdrInfoNotificationDocument, options);
}
export function useFdrInfoNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >(FdrInfoNotificationDocument, options);
}
export type FdrInfoNotificationQueryHookResult = ReturnType<
  typeof useFdrInfoNotificationQuery
>;
export type FdrInfoNotificationLazyQueryHookResult = ReturnType<
  typeof useFdrInfoNotificationLazyQuery
>;
export type FdrInfoNotificationQueryResult = Apollo.QueryResult<
  FdrInfoNotificationQuery,
  FdrInfoNotificationQueryVariables
>;
