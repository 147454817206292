import styled from 'styled-components';

export const SectionWrapper = styled.div(
  ({ theme }) => theme.card.cardsSection
);

export const CardsSectionContent = styled.div({
  position: 'relative',
  overflow: 'visible',
  '.copyright': {
    maxWidth: '75%',
    paddingLeft: '10px',
    textAlign: 'left',
    left: 0,
  },
});

export const SectionHeader = styled.div<{ isSRL: boolean }>(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({ theme }) => theme.card.cardsSectionHeader,
  ({ theme: { space, media }, isSRL }) => ({
    marginBottom: isSRL ? space[6] : null,
    [media.mobile]: {
      display: isSRL ? 'flex' : 'none',
    },
  })
);

export const SectionTitle = styled.h2<{ isSRL: boolean }>(
  ({ theme: { text } }) => text.groupHeading,
  ({ isSRL }) => ({
    fontSize: isSRL ? '15px' : null,
    fontWeight: isSRL ? '400' : null,
  })
);

export const SectionMainTitle = styled.h2(
  ({ theme: { media, space, fontSizes } }) => ({
    fontSize: '46px',
    fontWeight: '800',
    lineHeight: '60px',
    textAlign: 'center',
    margin: '0 auto',
    marginBottom: space[6],
    [media.mobile]: {
      fontSize: fontSizes[7],
      lineHeight: '42px',
    },
  })
);

export const SectionTotalCount = styled.div<{ isSRL: boolean }>(
  ({ theme }) => theme.card.cardSectionTotalCount,
  ({ isSRL }) => ({
    fontSize: isSRL ? '15px' : null,
    fontWeight: isSRL ? '400' : null,
  })
);

export const PaginationControlLoader = styled.img.attrs({
  src: '/images/spinner-button.svg',
})<{ side?: 'left' | 'right'; isSRL: boolean }>(
  ({ theme: { space } }) => ({
    display: 'block',
    width: '30px',
    marginLeft: space[1],
    marginTop: '2px',
    filter:
      'invert(73%) sepia(27%) saturate(4%) hue-rotate(323deg) brightness(92%) contrast(90%)',
  }),
  ({ side, isSRL }) =>
    side === 'right'
      ? {
          marginLeft: 'auto',
          width: '40px',
          height: isSRL ? '40px' : '28px',
        }
      : null
);

export const SwiperWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'hidden',
  height: `fit-content`,
  maxHeight: `-webkit-fill-available`,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
});

export const SliderWrapper = styled.section({
  position: 'relative',
  overflow: 'hidden',
  paddingTop: '40px',
  marginTop: '-40px',
});

export const SliderItems = styled.div({
  position: 'relative',
  display: 'flex',
  transition: 'left 500ms',
});
