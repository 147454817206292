import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { TBookmarkInput } from 'components/domain/fdr-bookmark/fdr-bookmark-types';
import { useFdrCreateBookmarkGroupMutation } from 'schemas/query/bookmark/fdr-create-bookmark-group.generated';
import { useAddBookmarkToBookmarkGroup } from './useAddBookmarkToBookmarkGroup';

function useAddBookmarkItemToNewGroup(): {
  addBookmarkItemToNewGroup(
    pageType: FdrBookmarkObjectType,
    objectId: string,
    groupName: string
  ): Promise<TBookmarkInput | undefined>;
} {
  const [createBookmarkMutation] = useFdrCreateBookmarkGroupMutation();
  const { addBookmarkToBookmarkGroup } = useAddBookmarkToBookmarkGroup();

  async function addBookmarkItemToNewGroup(
    pageType: FdrBookmarkObjectType,
    objectId: string,
    groupName: string
  ) {
    const { data: createBookmarkData } = await createBookmarkMutation({
      variables: {
        input: {
          bookmarkGroupName: groupName,
        },
      },
    });

    const groupId =
      createBookmarkData?.fdrCreateBookmarkGroup?.entity?.id || '';

    if (!groupId.length) {
      return undefined;
    }

    const { data: addBookmarkData } = await addBookmarkToBookmarkGroup(
      pageType,
      objectId,
      groupId
    );

    return addBookmarkData?.fdrAddBookmark?.success
      ? { objectId, pageType, groupId }
      : undefined;
  }

  return { addBookmarkItemToNewGroup };
}

export default useAddBookmarkItemToNewGroup;
