import React, { ReactElement } from 'react';
import { mapOpeningHoursToSchedules } from '@hotelplan/fdr.lib.misc.schedule';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrOpeningHours } from '@hotelplan/supergraph-api';
import { FdrHelpOverlayBox } from 'components/candidate/fdr-help-overlay';
import { FdrSchedule } from 'components/candidate/fdr-schedule';
import { FdrHelpOverlayBoxPhoneFragment } from 'schemas/query/help-overlay/fdr-help-overlay.generated';
import { FlaglineText, ThirdHeading } from 'theme/headings';
import {
  FdrBoxLink,
  FdrHelpOverlayContent,
  FdrInfoCardWrapper,
  FdrPhoneLinkWrapper,
  PhoneLink,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayPhoneBoxProps {
  box: FdrHelpOverlayBoxPhoneFragment;
  boxesCount: number;
  onClick?(): void;
  openingHours?: FdrOpeningHours;
  phoneNumber?: string;
  boxTitle?: string;
  timezone?: string;
}

export function FdrHelpOverlayPhoneBox({
  box,
  boxesCount,
  onClick,
  boxTitle,
  phoneNumber,
  openingHours,
  timezone,
}: IFdrHelpOverlayPhoneBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  if (!box) return null;

  return (
    <FdrHelpOverlayBox boxesCount={boxesCount} onClick={onClick}>
      <FdrInfoCardWrapper infoCardImage={box.icon}>
        <div className="help-overlay-content-wrap">
          <FdrHelpOverlayContent>
            {!mobile && (
              <FlaglineText className="image-tagline">{boxTitle}</FlaglineText>
            )}
            <ThirdHeading className="image-title">{box.mainTitle}</ThirdHeading>
            <FdrPhoneLinkWrapper>
              <FdrBoxLink icon={{ name: 'chevron-long-right' }}>
                <span className="link-text">{phoneNumber}</span>
              </FdrBoxLink>
              {/* TODO: added as tmp solution, as NextLink cannot work with tel link */}
              <PhoneLink href={`tel:${phoneNumber}`} />
            </FdrPhoneLinkWrapper>
          </FdrHelpOverlayContent>
        </div>
        <FdrSchedule
          showStatusIcon
          className="schedule"
          timezone={timezone}
          openingHours={openingHours}
          mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
        />
      </FdrInfoCardWrapper>
    </FdrHelpOverlayBox>
  );
}
