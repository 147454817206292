import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrRenameBookmarkGroupMutationVariables = Types.Exact<{
  input: Types.FdrRenameBookmarkGroupInput;
}>;

export type FdrRenameBookmarkGroupMutation = {
  __typename?: 'Mutation';
  fdrRenameBookmarkGroup: {
    __typename?: 'FdrRenameBookmarkGroupResponse';
    success: boolean;
    entity?: {
      __typename?: 'FdrBookmarkGroup';
      id: string;
      name: string;
    } | null;
  };
};

export const FdrRenameBookmarkGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrRenameBookmarkGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRenameBookmarkGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrRenameBookmarkGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrRenameBookmarkGroupMutationFn = Apollo.MutationFunction<
  FdrRenameBookmarkGroupMutation,
  FdrRenameBookmarkGroupMutationVariables
>;

/**
 * __useFdrRenameBookmarkGroupMutation__
 *
 * To run a mutation, you first call `useFdrRenameBookmarkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrRenameBookmarkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrRenameBookmarkGroupMutation, { data, loading, error }] = useFdrRenameBookmarkGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrRenameBookmarkGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrRenameBookmarkGroupMutation,
    FdrRenameBookmarkGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrRenameBookmarkGroupMutation,
    FdrRenameBookmarkGroupMutationVariables
  >(FdrRenameBookmarkGroupDocument, options);
}
export type FdrRenameBookmarkGroupMutationHookResult = ReturnType<
  typeof useFdrRenameBookmarkGroupMutation
>;
export type FdrRenameBookmarkGroupMutationResult =
  Apollo.MutationResult<FdrRenameBookmarkGroupMutation>;
export type FdrRenameBookmarkGroupMutationOptions = Apollo.BaseMutationOptions<
  FdrRenameBookmarkGroupMutation,
  FdrRenameBookmarkGroupMutationVariables
>;
