/* eslint-disable @typescript-eslint/no-unused-vars,i18next/no-literal-string */
import { FieldPolicy, TypePolicies, TypePolicy } from '@apollo/client';
import { KeyArgsReducer } from './apolloTypePolicies.types';
import {
  fdrAgenciesAutocompleteMerge,
  fdrBlogArticlesMerge,
  fdrLastSearchedMerge,
  fdrLastViewedMerge,
  fdrMergeGeoSiblings,
} from './apolloTypePolicies.utils';

const possibleTypeNames = [
  `FaqComponent`,
  `FdrAdventureTravel`,
  `FdrAdventureTravelSearchItem`,
  `FdrAgency`,
  `FdrAgencyOverviewPage`,
  `FdrAgenciesOverviewResponse`,
  `FdrAllBlogArticlesResponse`,
  `FdrBenefitsComponent`,
  `FdrBlogArticle`,
  `FdrBlogArticlesOverviewPage`,
  `FdrBookmark`,
  `FdrBookmarkGroup`,
  `FdrBookmarksPage`,
  `FdrB2BLoginPage`,
  `FdrCatalogPage`,
  `FdrContinent`,
  `FdrContactModule`,
  `FdrCountry`,
  `FdrCountrySiblings`,
  `FdrGeo`,
  `FdrGeoOverviewPage`,
  `FdrGeoRecommender`,
  `FdrHomePage`,
  `FdrHotel`,
  `FdrMarkdownText`,
  `FdrMarketingRecommender`,
  `FdrNotFoundPage`,
  `FdrOrganization`,
  `FdrProductBookmark`,
  `FdrProductB2bInformationGroup`,
  `FdrProductFeature`,
  `FdrProductFeatureGroup`,
  `FdrProductRecommender`,
  `FdrProductSearchResultListPage`,
  `FdrRawText`,
  `FdrRegion`,
  `FdrRegionSiblings`,
  `FdrResizedImage`,
  `FdrRoundTrip`,
  `FdrSearchAgenciesResponse`,
  `FdrSearchHistoryResponse`,
  `FdrStaticPage`,
  `FdrStaticPageLanguageAlternative`,
  `FdrTextSearchHistory`,
  `FdrTextSearchResponse`,
  `FdrTheme`,
  `FdrThemeGeoPage`,
  `FdrThemeGeoPageResponse`,
  `FdrThemeGeoRecommender`,
  `FdrThemeOverviewPage`,
  `FdrThemeRecommender`,
  `FdrUspBoxesComponent`,
  `FdrVisitHistory`,
  `Query`,
] as const;

type TypeNames = typeof possibleTypeNames[number];

// add default behaviour to all types
const possibleTypePolicies: Record<TypeNames, TypePolicy> =
  possibleTypeNames.reduce(
    (policies, policyType) => ({
      ...policies,
      [policyType]: { keyFields: false, merge: true },
    }),
    {} as any
  );

const addAllArgsReducer: KeyArgsReducer = (res, args) => {
  return [...res, ...Object.keys(args)].filter((v, i, a) => a.indexOf(v) === i);
};

const excludeContextArgReducer: KeyArgsReducer = res => {
  return res.filter(key => key !== 'context' && key !== 'requestContext');
};

const buildFieldPolicy = (reducers: KeyArgsReducer[]): FieldPolicy => ({
  keyArgs: (args, ctx) => {
    const k = reducers.reduce((keys, reducer) => {
      return reducer(keys, args, ctx);
    }, []);

    return k;
  },
});

const defaultFieldPolicy = buildFieldPolicy([
  addAllArgsReducer,
  excludeContextArgReducer,
]);

function assign<K extends TypeNames>(typeName: K, policy: TypePolicy) {
  Object.assign(
    (possibleTypePolicies[typeName] = possibleTypePolicies[typeName] || {}),
    policy
  );
}

assign('Query', {
  fields: {
    fdrAllBlogArticles: {
      keyArgs: false,
      merge: fdrBlogArticlesMerge,
    },
    fdrBookmarks: defaultFieldPolicy,
    fdrSearchAgencies: {
      keyArgs: ['input', ['suggestion']],
      merge: fdrAgenciesAutocompleteMerge,
    },
    fdrThemeGeoPage: defaultFieldPolicy,
    fdrSearchHistory: {
      keyArgs: false,
      merge: fdrLastSearchedMerge,
    },
    fdrVisitHistory: {
      keyArgs: false,
      merge: fdrLastViewedMerge,
    },
  },
});

assign('FdrGeo', {
  fields: {
    siblings: {
      keyArgs: false,
      merge: fdrMergeGeoSiblings,
    },
  },
});

assign('FdrAgency', { merge: false });
assign('FdrResizedImage', { merge: false, keyFields: false });

export const apolloTypePolicies: TypePolicies = { ...possibleTypePolicies };
