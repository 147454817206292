import { MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { FdrLink } from '@hotelplan/supergraph-api';
import {
  FdrHelpOverlayBoxWrap,
  FdrLinkWrapper,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayBoxProps {
  boxesCount: number;
  link?: FdrLink;
  onClick?(e: MouseEvent): void;
}

export function FdrHelpOverlayBox({
  boxesCount,
  link,
  onClick,
  children,
}: PropsWithChildren<IFdrHelpOverlayBoxProps>): ReactElement {
  return (
    <FdrHelpOverlayBoxWrap boxesCount={boxesCount} onClick={onClick}>
      <FdrLinkWrapper link={link}>{children}</FdrLinkWrapper>
    </FdrHelpOverlayBoxWrap>
  );
}
