import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrBreadcrumbsSectionWrapper = styled.div(
  ({ theme: { media } }) => {
    return {
      width: '100%',
      maxWidth: '1480px',
      margin: '0 auto',
      zIndex: '99',
      [media.mobile]: {
        boxShadow: '0px 5px 10px -5px rgba(0, 0, 0, 0.1)',
      },
    };
  }
);

export const FdrBreadcrumbsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexGrow: '1',
    width: '100%',
    maxWidth: '1440px',
    borderRadius: '0 0 5px 5px',
    padding: '10px 16px 2px 16px',
    backgroundColor: 'paperGrey',
    '.geo-breadcrumb': {
      fontSize: '12px',
    },
    '.breadcrumb-list': {
      rowGap: '4px',
    },
    '.breadcrumb-label': {
      border: '2px solid rgba(0,0,0,0.05)',
      borderRadius: '5px',
      padding: '4px 8px 4px 8px',
      marginBottom: 2,
    },
  })
);
