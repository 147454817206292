import { TFunction, useTranslation } from 'next-i18next';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  getCurrentOpeningHours,
  isDateShiftIncluded,
  mapOpeningHoursToSchedules,
} from '@hotelplan/fdr.lib.misc.schedule';
import { FdrOpeningHours } from '@hotelplan/supergraph-api';
import { FdrScheduleOpeningStatus } from './fdr-schedule-opening-status';
import { FdrScheduleWrapper } from './fdr-schedule.styles';

const UPDATING_OPEN_STATUS_INTERVAL = 5 * 1000;

interface IFdrScheduleProps {
  openingHours: FdrOpeningHours;
  timezone: string;
  showStatusIcon?: boolean;
  className?: string;
  mapCustomOpeningHoursToSchedules?: (
    t: TFunction,
    openingHours: FdrOpeningHours
  ) => string[];
}

export function FdrSchedule({
  openingHours,
  showStatusIcon,
  timezone,
  mapCustomOpeningHoursToSchedules,
  className,
}: IFdrScheduleProps): ReactElement {
  const [t] = useTranslation('agency');

  const [opened, setOpened] = useState(() => {
    const currentDate = new Date();
    return openingHours
      ? isDateShiftIncluded(
          getCurrentOpeningHours(openingHours, currentDate).shifts,
          timezone,
          currentDate
        )
      : false;
  });

  const updateOpenedState = useCallback(() => {
    const currentDate = new Date();
    const currentHours = getCurrentOpeningHours(openingHours, currentDate);
    setOpened(isDateShiftIncluded(currentHours.shifts, timezone, currentDate));
  }, [openingHours, timezone]);

  useEffect(
    function updateOpenedStateEffect() {
      const intervalID = setInterval(
        updateOpenedState,
        UPDATING_OPEN_STATUS_INTERVAL
      );

      return function cleanupInterval() {
        clearInterval(intervalID);
      };
    },
    [updateOpenedState]
  );

  const mapFunction = mapCustomOpeningHoursToSchedules
    ? mapCustomOpeningHoursToSchedules
    : mapOpeningHoursToSchedules;

  if (!openingHours) return null;

  return (
    <FdrScheduleWrapper opened={opened} className={className}>
      <FdrScheduleOpeningStatus
        opened={opened}
        showStatusIcon={showStatusIcon}
      />
      <div>{t('openingHours.title')} </div>
      <div data-id="fdr-help-overlay-opening-hours">
        {mapFunction(t, openingHours).map((item, index) => {
          return (
            <div key={index} data-id="fdr-help-overlay-opening-hours-day">
              {item}
            </div>
          );
        })}
      </div>
    </FdrScheduleWrapper>
  );
}
