import React, { useState } from 'react';
import styled from 'styled-components';
import { MapPin } from '@hotelplan/components.common.map-pin';
import { FdrAdventureTravelRoutePoint } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { pinContent } from 'components/domain/fdr-map';
import InfoLabel from 'components/domain/map-constructor/InfoLabel';

type TProps = {
  points: FdrAdventureTravelRoutePoint[];
  showAllLabels?: boolean;
};

const ProductPinRouteIcon = styled.div<{ visible: boolean }>(({ visible }) =>
  sx2CssThemeFn({
    display: visible ? 'block' : 'none',
    width: '19px',
    height: '19px',
    position: 'relative',
    textAlign: 'center',
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: '900',
    background: '#E4251B',
  })
);

const MapPinWrapper = styled(MapPin)(
  sx2CssThemeFn(pinContent, {
    zIndex: 'auto',
    '.map-pin-wrapper:after': {
      display: 'none',
    },
  })
);

const FdrGeoProductRoutePins: React.FC<TProps> = ({
  points,
  showAllLabels = false,
}: TProps) => {
  const [hoveredPin, setHoveredPin] = useState(undefined);

  return (
    <>
      {points.map((item, index) => {
        return (
          <MapPinWrapper
            coordinates={item.coordinates}
            object={item}
            key={index}
          >
            <ProductPinRouteIcon
              visible={true}
              onMouseOver={() => setHoveredPin(index)}
              onMouseOut={() => setHoveredPin(undefined)}
            />

            <InfoLabel visible={showAllLabels || index === hoveredPin}>
              {item.locationName}
            </InfoLabel>
          </MapPinWrapper>
        );
      })}
    </>
  );
};

export default FdrGeoProductRoutePins;
