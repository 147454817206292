import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrImage as FdrImageType, FdrLink } from '@hotelplan/supergraph-api';

interface IMapCardInfoProps {
  image: FdrImageType;
  link?: FdrLink;
  iconDescription: string;
  title?: string;
  iconName: string;
}

export const FdrMapCardInfo = ({
  image,
  title,
  iconName,
  link,
  iconDescription,
}: IMapCardInfoProps): ReactElement => {
  return (
    <>
      <div className="infoWindow-image">
        {image && <FdrImage image={image} />}
      </div>
      <div style={{ display: 'flex' }}>
        <div className="infoWindow-name">
          <div className="infoWindow-text-wrapper">
            <span className="infoWindow-text">{title || `-`}</span>
            {link && (
              <span className="arrow-icon">
                <BsIcon name="chevron-long-right" />
              </span>
            )}
          </div>
        </div>
        {link && (
          <div className="open-icon">
            <BsIcon name={iconName} />
            <span>{iconDescription}</span>
          </div>
        )}
      </div>
    </>
  );
};
