import { useContext } from 'react';
import { isProductPin } from 'components/domain/fdr-geo/trips-list/fdr-geo-trips-list.utils';
import { FdrGeoTripsContext } from './fdr-geo-trips.context';

export const useActiveProduct = () => {
  const { productPins, activeProduct, activeTrip, clusterMarkers } =
    useContext(FdrGeoTripsContext);

  const product = productPins.get(activeProduct);
  const clusterProduct = clusterMarkers
    ?.filter(isProductPin)
    ?.find(m => m.tripId === activeTrip);

  return product || clusterProduct;
};
