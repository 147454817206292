import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';

const BookmarkButton = styled.button({
  zIndex: 1,
});

interface IBookmarkIconPros {
  onClick(): void;
  active: boolean;
}

const FdrBookmarkIcon: React.FC<IBookmarkIconPros> = ({ active, onClick }) => {
  const [t] = useTranslation('bookmark');
  const icon = active ? (
    <>
      <BsIcon name="wishlist-active" className="wishlist-icon active" />
      <span className="button-text">{t('bookmark:removeFromBookmark')}</span>
    </>
  ) : (
    <>
      <BsIcon name="wishlist" className="wishlist-icon" />
      <span className="button-text">{t('bookmark:addToBookmark')}</span>
    </>
  );

  return (
    <BookmarkButton
      className={'wishlist-icon-button'}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      {icon}
    </BookmarkButton>
  );
};

export default FdrBookmarkIcon;
