import { NextRouter } from 'next/router';
import { FdrImage, FdrLink } from '@hotelplan/supergraph-api';
import { TFdrRouteMapProduct } from 'components/domain/fdr-product-route-map/fdr-product-route-map.types';
import { FdrAtSearchItemFragment } from 'schemas/fragment/search/fdr-search-items.generated';
import { FdrTextSearchAgenciesQuery } from 'schemas/query/search/fdr-text-search-agencies.generated';
import { FdrTextSearchQuery } from 'schemas/query/search/fdr-text-search.generated';
import { FDR_SEARCH_SECTION_KEYS } from './fdr-search.constants';
import { TNormalizedSearchItem } from './fdr-search.types';

export function removeSectionQuery(router: NextRouter): void {
  if (typeof location === 'undefined') return;

  const searchParams = new URLSearchParams(location.search);
  searchParams.delete(`section`);
  const query = decodeURIComponent(searchParams.toString());

  router.replace({ pathname: location.pathname, query });
}

export function mapSearchItemToNormalizedSearchItem(
  searchItem:
    | FdrTextSearchQuery['fdrTextSearch']['itemList'][number]
    | FdrTextSearchAgenciesQuery['fdrSearchAgencies']['agencies'][number]
    | undefined,
  isB2B = false
): TNormalizedSearchItem {
  function getSearchItem({
    name,
    webMeta,
    hero,
  }: {
    name?: string;
    webMeta?: { descriptionWebMeta?: { link: FdrLink }; link?: FdrLink };
    hero?: FdrImage;
  }): Pick<TNormalizedSearchItem, 'name' | 'link' | 'image'> {
    function hasDescriptionWebMeta(
      meta: typeof webMeta
    ): meta is { descriptionWebMeta: { link: FdrLink } } {
      return 'descriptionWebMeta' in meta;
    }

    return {
      name,
      link: hasDescriptionWebMeta(webMeta)
        ? webMeta.descriptionWebMeta.link
        : webMeta.link,
      image: hero,
    };
  }

  switch (searchItem.__typename) {
    case 'FdrAdventureTravelSearchItem': {
      const b2cTypeFlags =
        searchItem.adventureTravel?.featureGroups
          ?.flatMap(group => group.features)
          ?.filter(feature => feature.enabled)
          ?.map(({ name }) => name) || [];

      return {
        id: searchItem.adventureTravel.id,
        bookmarked: searchItem.adventureTravel.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.adventureTravel),
        image: isB2B
          ? searchItem.adventureTravel?.googleStaticMapImage
          : searchItem.adventureTravel?.images?.[0],
        productInfoB2B: searchItem.adventureTravel?.productInfoB2B,
        leadText: !isB2B ? searchItem.adventureTravel?.leadText || '' : '',
        b2cTypeFlags,
      };
    }
    case 'FdrRoundTripSearchItem': {
      return {
        id: searchItem.roundTrip.id,
        bookmarked: searchItem.roundTrip.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.roundTrip),
        image: searchItem.roundTrip?.mapImages?.[0],
        productInfoB2B: searchItem.roundTrip?.productInfoB2B,
      };
    }
    case 'FdrHotelSearchItem': {
      const { resort } = searchItem.hotel;
      return {
        id: searchItem.hotel.id,
        bookmarked: searchItem.hotel.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        leadText: searchItem.hotel?.leadText || '',
        breadcrumbs: Array.from(
          new Set([
            resort.name,
            resort.destination.name,
            resort.destination.region?.name,
            resort.destination.country.name,
          ])
        ),
        ...getSearchItem(searchItem.hotel),
      };
    }
    case 'FdrContinentSearchItem': {
      return {
        id: searchItem.continent.id,
        bookmarked: searchItem.continent.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.continent),
      };
    }
    case 'FdrCountryGroupSearchItem': {
      return {
        id: searchItem.countryGroup.id,
        bookmarked: searchItem.countryGroup.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.countryGroup),
      };
    }
    case 'FdrCountrySearchItem': {
      return {
        id: searchItem.country.id,
        bookmarked: searchItem.country.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.country),
      };
    }
    case 'FdrRegionSearchItem': {
      return {
        id: searchItem.region.id,
        bookmarked: searchItem.region.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.region),
      };
    }
    case 'FdrThemeSearchItem': {
      return {
        id: searchItem.theme.id,
        bookmarked: searchItem.theme.isInBookmarks,
        debugInfo: searchItem.debugInfo,
        ...getSearchItem(searchItem.theme),
      };
    }
    case 'FdrBlogArticleSearchItem': {
      return {
        debugInfo: searchItem.debugInfo,
        leadText: searchItem.blogArticle?.text || '',
        ...getSearchItem(searchItem.blogArticle),
      };
    }
    case 'FdrStaticPageSearchItem': {
      return {
        debugInfo: searchItem.debugInfo,
        leadText: searchItem.staticPage.text || '',
        ...getSearchItem(searchItem.staticPage),
      };
    }
    case 'FdrCatalogPageSearchItem': {
      return {
        debugInfo: searchItem.debugInfo,
        leadText: searchItem.catalogPage.text || '',
        ...getSearchItem(searchItem.catalogPage),
      };
    }
    case 'FdrAgencyOverviewPageSearchItem': {
      return {
        debugInfo: searchItem.debugInfo,
        leadText: searchItem.agencyOverviewPage.text || '',
        ...getSearchItem(searchItem.agencyOverviewPage),
      };
    }
    case 'FdrAgency': {
      return {
        debugInfo: '',
        ...getSearchItem(searchItem),
        address: searchItem.address,
        openStatus: searchItem.openStatus,
      };
    }
  }
}

export function mapSectionKeyToTitle(
  sectionKey: typeof FDR_SEARCH_SECTION_KEYS[number]
): string {
  return {
    adventuretravel: `search.adventuretravel.section.title`,
    geo: `search.geo.section.title`,
    themes: `search.theme.section.title`,
    blogArticles: `search.blog.section.title`,
    extraHotels: `search.extrahotel.section.title`,
    regularHotels: `search.regularhotel.section.title`,
    roundtrip: `search.roundtrip.section.title`,
    agencies: `search.agencies.section.title`,
    other: `search.other.section.title`,
  }[sectionKey];
}

export function mapATSearchItemToRouteMapProduct({
  adventureTravel,
}: FdrAtSearchItemFragment): TFdrRouteMapProduct {
  return {
    id: adventureTravel.id,
    route: adventureTravel.route?.map(r => r.locationName) ?? [],
    routePoints: adventureTravel.route ?? [],
    image: adventureTravel.images?.[0],
    link: adventureTravel.webMeta.descriptionWebMeta.link,
    name: adventureTravel.name,
    productInfoB2b: adventureTravel.productInfoB2B,
    bookmarked: adventureTravel.isInBookmarks,
    teaser: adventureTravel.teaser,
  };
}
