import styled from 'styled-components';

export interface IBackgroundWrapperProps {
  bgColor?: string;
  image?: string;
  fontColor?: string;
}

export const FdrBackgroundWrapper = styled.div<IBackgroundWrapperProps>(
  ({ bgColor, image, fontColor }) => {
    return {
      backgroundColor: image ? null : bgColor,
      backgroundImage: image ? `url(${image})` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      color: fontColor,
    };
  }
);
