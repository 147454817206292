import { ReactElement } from 'react';
import {
  FdrBreadcrumbs,
  TFdrBreadcrumb,
} from 'components/candidate/fdr-breadcrumbs';
import {
  FdrBreadcrumbsSectionWrapper,
  FdrBreadcrumbsWrapper,
} from './fdr-breadcrumbs-section.styles';

interface IFdrBreadcrumbsSectionProps {
  breadcrumbs: Array<TFdrBreadcrumb>;
}

export function FdrBreadcrumbsSection({
  breadcrumbs,
}: IFdrBreadcrumbsSectionProps): ReactElement {
  if (!breadcrumbs?.length) return null;

  return (
    <FdrBreadcrumbsSectionWrapper>
      <FdrBreadcrumbsWrapper>
        <FdrBreadcrumbs
          className="geo-breadcrumb"
          breadcrumbs={breadcrumbs}
          delimiter={`›`}
        />
      </FdrBreadcrumbsWrapper>
    </FdrBreadcrumbsSectionWrapper>
  );
}
