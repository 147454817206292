import React from 'react';
import {
  TProductPin,
  TRelatedPin,
} from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';

type TLayoutContext = {
  routePoints?: Map<string, TRelatedPin>;
  activeRoutePoint?: string;
  activeProduct?: string;
  clusterMarkers?: Array<TProductPin | TRelatedPin>;
  setActiveRoutePoint?: React.Dispatch<React.SetStateAction<string>>;
  setActiveProduct?: React.Dispatch<React.SetStateAction<string>>;
  setClusterMarkers?: React.Dispatch<
    React.SetStateAction<Array<TProductPin | TRelatedPin>>
  >;
  isFullScreen?: boolean;
  activeTrip?: string;
  setActiveTrip?: React.Dispatch<React.SetStateAction<string>>;
  productPins?: Map<string, TProductPin>;
};

export const FdrGeoTripsContext = React.createContext<TLayoutContext>({});
