import React, { ReactElement, useEffect, useRef } from 'react';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { FdrAgencyOpenStatus } from '@hotelplan/supergraph-api';
import FdrBookmarkBlock from 'components/domain/fdr-bookmark/fdr-bookmark-block';
import { FdrDisrupters } from 'components/domain/fdr-disrupter';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { FdrSearchItemDescription } from './fdr-search-item-description';
import { FDR_SEARCH_SECTION_KEYS } from './fdr-search.constants';
import {
  FdrSearchItemWrapper,
  FdrTeaserImageCardWrapper,
  FdrTeaserLink,
} from './fdr-search.styles';
import { TNormalizedSearchItem } from './fdr-search.types';

interface IFdrSearchItemProps {
  searchItem: TNormalizedSearchItem;
  sectionKey: typeof FDR_SEARCH_SECTION_KEYS[number];
  lazy?: boolean;
  className?: string;
  getImageHeight?: (height: number) => void;
}

export function FdrSearchItem({
  searchItem,
  sectionKey,
  lazy,
  className,
  getImageHeight,
}: IFdrSearchItemProps): ReactElement {
  const imageRef = useRef<HTMLDivElement>(null);

  const { h } = useElementSize(imageRef);

  useEffect(() => {
    getImageHeight && getImageHeight(h);
  }, [h, getImageHeight]);

  if (!searchItem) return null;

  const {
    id,
    bookmarked,
    image,
    debugInfo,
    disrupters,
    link,
    name,
    leadText,
    productInfoB2B,
    breadcrumbs,
    openStatus,
    address,
    b2cTypeFlags,
  } = searchItem || {};

  const showBookmark = [
    'adventuretravel',
    'roundtrip',
    'extraHotels',
    'regularHotels',
    'geo',
    'themes',
  ].includes(sectionKey);

  return (
    <FdrSearchItemWrapper>
      <FdrTeaserLink className={className} link={link}>
        <div ref={imageRef}>
          <FdrTeaserImageCardWrapper
            img={<FdrImg image={image} />}
            cfg={{
              lazy,
            }}
            className={sectionKey === 'geo' ? 'is-geo' : ''}
          >
            {!!disrupters && disrupters.length ? (
              <FdrDisrupters
                disrupters={disrupters}
                className="disrupter-wrapper"
              />
            ) : null}
            {debugInfo ? (
              <div
                dangerouslySetInnerHTML={{ __html: debugInfo }}
                className="debug-info"
              />
            ) : null}
          </FdrTeaserImageCardWrapper>
        </div>
        <FdrSearchItemDescription
          sectionKey={sectionKey}
          name={name}
          highlightedText={name}
          productInfoB2B={productInfoB2B}
          imageHeight={h}
          leadText={leadText}
          breadcrumbs={breadcrumbs}
          opened={openStatus && openStatus !== FdrAgencyOpenStatus.Close}
          addressDetails={address}
          b2cTypeFlags={b2cTypeFlags}
        />
      </FdrTeaserLink>
      {showBookmark ? (
        <FdrBookmarkBlock
          objectId={id}
          active={bookmarked}
          trackingComponent={BookmarkComponentType.SRL}
        />
      ) : null}
    </FdrSearchItemWrapper>
  );
}
