import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useViewportSize } from '@hotelplan/libs.hooks-dom';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import {
  CARDS_PER_PAGE_THEME,
  CARDS_PER_PAGE_THEME_SMALLDESKTOP,
} from 'components/domain/customizable-slider/CustomizableSlider.constants';
import { AdaptiveCardsSlider } from 'components/domain/customizable-slider/ThemeCardsSection';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';

interface ISliderSectionProps {
  count?: number;
  title?: string;
  titleColor?: string;
  itemsPerPage?: number;
  className?: string;
  customContent?: React.ReactNode;
}

export function FdrThemeRecommendationSlider({
  title,
  titleColor,
  count,
  className,
  children,
  customContent,
}: PropsWithChildren<ISliderSectionProps>): React.ReactElement {
  const { mobile } = useDeviceType();
  const [page, setPage] = useState(0);
  const { width } = useViewportSize();
  const isSliderPagination = !mobile;
  const { t } = useTranslation();

  const smallerDesktop = width <= 1249 && width >= 1024;
  const perPage = smallerDesktop
    ? CARDS_PER_PAGE_THEME_SMALLDESKTOP
    : CARDS_PER_PAGE_THEME;
  const totalPages = Math.ceil(count / perPage);

  return (
    <CardsSection
      className={className}
      title={title}
      page={page}
      totalPages={totalPages}
      sectionTitle={
        isSliderPagination &&
        totalPages > 1 &&
        t('theme.recommender.section.title')
      }
      totalCount={isSliderPagination && totalPages > 1 && count}
      onPrevPage={() => setPage(current => (current > 0 ? current - 1 : 0))}
      onNextPage={() =>
        setPage(current => (current < totalPages - 1 ? current + 1 : 0))
      }
      headerStyle={{ color: titleColor }}
      isSliderPagination={isSliderPagination}
      trackingSource={PaginationArrowClickSource.THEME}
    >
      {customContent ? <>{customContent}</> : null}
      <AdaptiveCardsSlider
        page={page}
        onChangePage={next =>
          setPage(current =>
            next > 0 || next < totalPages - 1 ? next : current
          )
        }
        perPage={perPage}
        total={count}
        isSliderPagination={isSliderPagination}
      >
        {children}
      </AdaptiveCardsSlider>
    </CardsSection>
  );
}
