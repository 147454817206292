import React, { useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';

export const useFullScreenControl = (
  mapContainerRef: React.MutableRefObject<HTMLDivElement>
): {
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const { mobile } = useDeviceType();

  const fullScreenStyles = {
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    zIndex: '100',
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useIsomorphicLayoutEffect(() => {
    if (mapContainerRef.current) {
      document.body.style.overflow = isFullScreen ? 'hidden' : '';

      Object.entries(fullScreenStyles).forEach(([prop, value]) => {
        mapContainerRef.current.style[prop] = isFullScreen ? value : '';
      });
    }
  }, [isFullScreen, mobile]);

  return { isFullScreen, setIsFullScreen };
};
