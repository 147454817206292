import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  getFdrAgencyBreadcrumbs,
  getFdrAgencyOverviewBreadcrumbs,
} from './get.agency';
import {
  getFdrBlogArticleBreadcrumbs,
  getFdrBlogArticleOverviewBreadcrumbs,
} from './get.blog';
import { getFdrBookmarkBreadcrumbs } from './get.bookmark';
import { getFdrCatalogsBreadcrumbs } from './get.catalogs';
import {
  getFdrEmailRevokeBreadcrumbs,
  getFdrEmailRevokeConfirmationBreadcrumbs,
} from './get.email';
import { getFdrGeoBreadcrumbs, getFdrGeoOverviewBreadcrumbs } from './get.geo';
import {
  getFdrNewsletterConfirmationBreadcrumbs,
  getFdrNewsletterFinalizationBreadcrumbs,
  getFdrNewsletterSubscriptionBreadcrumbs,
  getFdrNewsletterUnsubscriptionBreadcrumbs,
  getFdrNewsletterUnsubscriptionConfirmationBreadcrumbs,
} from './get.newsletter';
import { getFdrPdpBreadcrumbs } from './get.pdp';
import { getFdrSearchBreadcrumbs } from './get.search';
import { getFdrStaticBreadcrumbs } from './get.static';
import {
  getFdrThemeBreadcrumbs,
  getFdrThemeGeoBreadcrumbs,
  getFdrThemeOverviewBreadcrumbs,
} from './get.theme';

const requestsMap = new Map<
  EPageType,
  (
    queryCtx: TQueryCtx,
    lang?: string,
    pageId?: string | { themeId: string; geoId: string }
  ) => Promise<Array<TFdrBreadcrumb>>
>([
  [EPageType.GeoOverview, getFdrGeoOverviewBreadcrumbs],
  [EPageType.ThemeOverview, getFdrThemeOverviewBreadcrumbs],
  [EPageType.AgencyOverview, getFdrAgencyOverviewBreadcrumbs],
  [EPageType.BlogOverview, getFdrBlogArticleOverviewBreadcrumbs],
  [EPageType.BlogArticle, getFdrBlogArticleBreadcrumbs],
  [EPageType.GeoObject, getFdrGeoBreadcrumbs],
  [EPageType.Pdp, getFdrPdpBreadcrumbs],
  [EPageType.ThemeObject, getFdrThemeBreadcrumbs],
  [EPageType.CombinedThemeGeo, getFdrThemeGeoBreadcrumbs],
  [EPageType.AgencyObject, getFdrAgencyBreadcrumbs],
  [EPageType.Static, getFdrStaticBreadcrumbs],
  [EPageType.Srl, getFdrSearchBreadcrumbs],
  [EPageType.Bookmark, getFdrBookmarkBreadcrumbs],
  [EPageType.NewsletterSubscription, getFdrNewsletterSubscriptionBreadcrumbs],
  [EPageType.NewsletterConfirmation, getFdrNewsletterConfirmationBreadcrumbs],
  [EPageType.NewsletterFinalization, getFdrNewsletterFinalizationBreadcrumbs],
  [
    EPageType.NewsletterUnsubscription,
    getFdrNewsletterUnsubscriptionBreadcrumbs,
  ],
  [
    EPageType.NewsletterUnsubscriptionFinalization,
    getFdrNewsletterUnsubscriptionConfirmationBreadcrumbs,
  ],
  [EPageType.EmailAdvertisingRevocation, getFdrEmailRevokeBreadcrumbs],
  [
    EPageType.EmailAdvertisingRevocationConfirmation,
    getFdrEmailRevokeConfirmationBreadcrumbs,
  ],
  [EPageType.CatalogOverview, getFdrCatalogsBreadcrumbs],
]);

interface IPageOptions {
  lang?: string;
  pageId?: string | { themeId: string; geoId: string };
}

export function getFdrBreadcrumbs(
  queryCtx: TQueryCtx,
  pageType: EPageType,
  { lang, pageId }: IPageOptions = {}
): Promise<Array<TFdrBreadcrumb>> {
  return requestsMap.get(pageType)(queryCtx, lang, pageId);
}
