import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
// import { expandChat } from '@hotelplan/components.common.chat';
import { languageDefault } from '@hotelplan/fdr.lib.i18n.lang-config';
import Chat from 'components/domain/chat-container/ChatContainer';
import { useLayoutContext } from 'components/domain/layout/Layout.context';
import { useGetFdrHelpOverlayQuery } from 'schemas/query/help-overlay/fdr-help-overlay.generated';
import { FdrChatBox } from './fdr-chat-box';
import { FdrHelpOverlayGeneralBox } from './fdr-general-box';
import {
  FdrHelpOverlayBackground,
  FdrHelpOverlayMobileWrapper,
  FdrHelpOverlayWrapper,
} from './fdr-help-overlay.styles';
import { FdrHelpOverlayPhoneBox } from './fdr-phone-box';
import { useFdrHelpOverlayContactInfo } from './useFdrHelpOverlayContactInfo';

interface IFdrHelpOverlayProps {
  dropDownRef?: React.MutableRefObject<HTMLDivElement>;
  onClose?(): void;
}

export function FdrHelpOverlay({
  dropDownRef,
  onClose,
}: IFdrHelpOverlayProps): ReactElement {
  const [, { language }] = useTranslation();
  const { headerHeight } = useLayoutContext();
  const { isAgencyBoxVisible, agencyData, contactInfo } =
    useFdrHelpOverlayContactInfo();

  const { data: fdrHelpOverlayData } = useGetFdrHelpOverlayQuery({
    ssr: false,
  });

  if (!fdrHelpOverlayData?.fdrHelpOverlay.boxes) return null;

  const fdrBoxes = fdrHelpOverlayData.fdrHelpOverlay.boxes;

  return (
    <FdrHelpOverlayBackground
      data-id="fdr-help-overlay"
      ref={dropDownRef}
      headerHeight={headerHeight}
    >
      <FdrHelpOverlayWrapper>
        {fdrBoxes.map((fdrBox, index) => {
          switch (fdrBox.__typename) {
            case 'FdrHelpOverlayBox': {
              return (
                <FdrHelpOverlayGeneralBox
                  key={fdrBox.id}
                  box={fdrBox}
                  boxesCount={fdrBoxes.length}
                  isAgency={isAgencyBoxVisible && index === 0}
                  agency={agencyData}
                  onClick={onClose}
                />
              );
            }

            case 'FdrHelpOverlayBoxPhone': {
              return (
                <FdrHelpOverlayPhoneBox
                  key={fdrBox.id}
                  box={fdrBox}
                  boxesCount={fdrBoxes.length}
                  onClick={onClose}
                  boxTitle={agencyData?.name || contactInfo?.phoneTitle}
                  phoneNumber={agencyData?.phone || contactInfo?.phoneNumber}
                  timezone={agencyData?.timezone || contactInfo?.timezone}
                  openingHours={
                    agencyData?.openingHours || contactInfo?.openingHours
                  }
                />
              );
            }

            case 'FdrHelpOverlayBoxChat': {
              if (language !== languageDefault) return null;

              return (
                <React.Fragment key={fdrBox.id}>
                  <FdrChatBox
                    box={fdrBox}
                    boxesCount={fdrBoxes.length}
                    onClick={e => {
                      e.preventDefault();
                      // expandChat();
                    }}
                  />
                  {/* TODO: Create FdrChat after fdr account finished on BE */}
                  <Chat />
                </React.Fragment>
              );
            }
          }
        })}
      </FdrHelpOverlayWrapper>
    </FdrHelpOverlayBackground>
  );
}

interface IFdrHelpOverlayMobileProps {
  visible: boolean;
  onClose(): void;
}

export function FdrHelpOverlayMobile({
  visible,
  onClose,
}: IFdrHelpOverlayMobileProps): ReactElement {
  return (
    <FdrHelpOverlayMobileWrapper
      fullScreen
      name="mobile-help"
      show={visible}
      onClose={onClose}
      title=""
    >
      <FdrHelpOverlay onClose={onClose} />
    </FdrHelpOverlayMobileWrapper>
  );
}
