import React from 'react';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { FeatureList } from 'config/featureList';
import FdrMultipleBookmarkGroupBlock from './fdr-bookmark-multiple-group-block';
import FdrSingularBookmarkGroupBlock from './fdr-bookmark-singular-group-block';
import { mapObjectIdToBookmarkObjectType } from './fdr-bookmark.utils';

interface IBookmarkBlockProps {
  objectId: string;
  active?: boolean;
  trackingComponent?: BookmarkComponentType;
}

const FdrBookmarkBlock: React.FC<IBookmarkBlockProps> = ({
  objectId,
  active,
  trackingComponent,
}) => {
  const { isEnabled } = useFeatureToggle();
  const multipleBookmarksEnabled = isEnabled(FeatureList.MULTIPLE_BOOKMARKS);
  const bookmarkObjectType = mapObjectIdToBookmarkObjectType(objectId);

  return (
    <>
      {!multipleBookmarksEnabled && (
        <FdrSingularBookmarkGroupBlock
          pageType={bookmarkObjectType}
          objectId={objectId}
          active={active}
          trackingComponent={trackingComponent}
        />
      )}
      {multipleBookmarksEnabled && (
        <FdrMultipleBookmarkGroupBlock
          pageType={bookmarkObjectType}
          objectId={objectId}
          active={active}
          trackingComponent={trackingComponent}
        />
      )}
    </>
  );
};

export default FdrBookmarkBlock;
