import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { isAndroid, isIOS } from '@hotelplan/libs.utils';

type TProps = { icon?: string };

const ShareIcon: React.FC<TProps> = ({ icon }: TProps) => {
  const { desktop } = useDeviceType();

  return (
    <Icon
      className="share-icon"
      name={
        icon ||
        (isIOS || (desktop && !isAndroid) ? 'share-ios' : 'share-android')
      }
      size="md"
    />
  );
};

export default ShareIcon;
