import React, { HTMLAttributes } from 'react';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import type { FdrImage } from '@hotelplan/supergraph-api';
import { FdrTeaserCard } from 'components/domain/fdr-teaser-card';
import type { TFdrTeaserCfg } from './fdr-teaser-card.types';

type TFdrImageTeaserProps = React.PropsWithChildren<
  {
    image?: Partial<FdrImage>;
    cfg?: TFdrTeaserCfg;
  } & HTMLAttributes<HTMLDivElement>
>;

export const FdrImageTeaser: React.FC<TFdrImageTeaserProps> = props => {
  const { image, cfg = {}, children, ...divProps } = props;

  return (
    <FdrTeaserCard
      img={<FdrImg image={image} lazy={cfg.lazy} />}
      cfg={cfg}
      {...divProps}
    >
      {children}
    </FdrTeaserCard>
  );
};
