import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { TRoundtripItem } from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import FdrGeoTripsListPoint from './fdr-geo-trips-list-point';
import { Info, ListSubtitle } from './fdr-geo-trips-list.styles';
import {
  isRelatedPin,
  useFindTrips,
  useShowTitle,
} from './fdr-geo-trips-list.utils';
import { TPinType } from './fdr-geo-trips.types';

const FdrGeoTripsListRelatedTiles: React.FC = () => {
  const [t, { language }] = useTranslation('geo');
  const { channelType } = useAuthentication();
  const {
    activeRoutePoint,
    routePoints,
    clusterMarkers,
    isFullScreen,
    setActiveTrip,
    setActiveProduct,
    setActiveRoutePoint,
  } = useContext(FdrGeoTripsContext);

  const isB2b = channelType === AuthChannelType.B2B;
  const selectedRoutePoint = routePoints.get(activeRoutePoint);
  const routePointstrips: TRoundtripItem[] = selectedRoutePoint?.roundtrips;

  const roundtripItems: TRoundtripItem[] = clusterMarkers
    ?.filter(isRelatedPin)
    .flatMap(({ roundtrips }) => roundtrips);

  const trips: TRoundtripItem[] = useFindTrips(
    routePointstrips,
    roundtripItems
  );
  const showTitle = useShowTitle(trips);

  return (
    <>
      {showTitle && (
        <>
          <ListSubtitle>
            {t('map.tripList.roundtrips')}
            <span className="number">{trips.length}</span>
          </ListSubtitle>
          {language === 'de' && (
            <Info>
              <Icon name={'info-small-icon'} />
              {t('map.tripList.item.roundtrips.description')}
            </Info>
          )}
        </>
      )}
      {isB2b &&
        trips.map(trip => {
          return (
            <FdrGeoTripsListPoint
              pinType={TPinType.RELATED_ROUTES}
              showButtonByDefault={false}
              key={trip.tripId}
              isFullScreen={isFullScreen}
              trip={trip}
              onClick={() => {
                setActiveTrip(trip.tripId);
                setActiveRoutePoint(trip.relatedId);
                setActiveProduct(null);
              }}
            />
          );
        })}
    </>
  );
};

export default FdrGeoTripsListRelatedTiles;
