import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const FdrExactSearchSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const FdrExactSearchOptions = styled.div(
  ({ theme: { colors, space } }) => ({
    display: 'flex',
    background: colors.lightGreySecond,
    border: `1px solid ${colors.greenLight}`,
    borderRadius: '8px',
    padding: '5px',
    marginLeft: space[4],
    gap: '4px',
  })
);

export const FdrOptionButton = styled(BsButtonWithIcon)<{
  bg?: string;
  isCloseIcon?: boolean;
}>(({ theme: { space, colors, icons }, bg, isCloseIcon }) => ({
  background: colors[bg] || 'transparent',
  borderRadius: '5px',
  padding: space[2],
  marginRight: isCloseIcon ? space[2] : null,
  '.icon': icons.md,
  '&:hover,&:focus,&:active': {
    backgroundColor: colors[bg] || 'transparent',
    borderColor: 'none',
    svg: {
      fill: 'inherit',
    },
  },
}));
