import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBreadcrumbFragmentDoc } from 'schemas/fragment/breadcrumb/fdr-breadcrumb.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBlogArticleBreadcrumbsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type FdrBlogArticleBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrBlogArticle?: {
    __typename?: 'FdrBlogArticle';
    breadcrumbs?: Array<{
      __typename?: 'FdrPageBreadcrumb';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
  } | null;
};

export const FdrBlogArticleBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBlogArticleBreadcrumbs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrBlogArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrBreadcrumb' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBreadcrumbFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBlogArticleBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrBlogArticleBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBlogArticleBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBlogArticleBreadcrumbsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFdrBlogArticleBreadcrumbsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrBlogArticleBreadcrumbsQuery,
    FdrBlogArticleBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBlogArticleBreadcrumbsQuery,
    FdrBlogArticleBreadcrumbsQueryVariables
  >(FdrBlogArticleBreadcrumbsDocument, options);
}
export function useFdrBlogArticleBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBlogArticleBreadcrumbsQuery,
    FdrBlogArticleBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBlogArticleBreadcrumbsQuery,
    FdrBlogArticleBreadcrumbsQueryVariables
  >(FdrBlogArticleBreadcrumbsDocument, options);
}
export type FdrBlogArticleBreadcrumbsQueryHookResult = ReturnType<
  typeof useFdrBlogArticleBreadcrumbsQuery
>;
export type FdrBlogArticleBreadcrumbsLazyQueryHookResult = ReturnType<
  typeof useFdrBlogArticleBreadcrumbsLazyQuery
>;
export type FdrBlogArticleBreadcrumbsQueryResult = Apollo.QueryResult<
  FdrBlogArticleBreadcrumbsQuery,
  FdrBlogArticleBreadcrumbsQueryVariables
>;
