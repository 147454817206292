import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrSearchOverlayQueryVariables = Types.Exact<{
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FdrSearchOverlayQuery = {
  __typename?: 'Query';
  fdrSearchOverlay?: {
    __typename?: 'FdrSearchOverlay';
    input?: string | null;
    mostSearched?: Array<string> | null;
    searchTrends?: Array<string> | null;
  } | null;
};

export const FdrSearchOverlayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrSearchOverlay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrSearchOverlay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchQuery' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchQuery' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'input' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mostSearched' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchTrends' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrSearchOverlayQuery__
 *
 * To run a query within a React component, call `useFdrSearchOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSearchOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSearchOverlayQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useFdrSearchOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrSearchOverlayQuery,
    FdrSearchOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrSearchOverlayQuery,
    FdrSearchOverlayQueryVariables
  >(FdrSearchOverlayDocument, options);
}
export function useFdrSearchOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrSearchOverlayQuery,
    FdrSearchOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrSearchOverlayQuery,
    FdrSearchOverlayQueryVariables
  >(FdrSearchOverlayDocument, options);
}
export type FdrSearchOverlayQueryHookResult = ReturnType<
  typeof useFdrSearchOverlayQuery
>;
export type FdrSearchOverlayLazyQueryHookResult = ReturnType<
  typeof useFdrSearchOverlayLazyQuery
>;
export type FdrSearchOverlayQueryResult = Apollo.QueryResult<
  FdrSearchOverlayQuery,
  FdrSearchOverlayQueryVariables
>;
