// import getConfig from 'next/config';
// import dynamic from 'next/dynamic';
import React /*, { useMemo } */ from 'react';
// import { DeviceType, PageLanguage } from '@hotelplan/graphql.types';
// import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
// import { useGetPersonalProfileQuery } from 'graphql/account/GetPersonalProfile.generated';

// const Chat = dynamic(
//   () => import('@hotelplan/components.common.chat').then(module => module.Chat),
//   { ssr: false }
// );

const ChatContainer: React.FC = () => {
  // const { publicRuntimeConfig } = getConfig();
  // const context = useRequestContext<DeviceType, PageLanguage>();
  // const { data: account } = useGetPersonalProfileQuery({
  //   variables: { context },
  // });

  // const personalChatData = useMemo(
  //   () => ({
  //     firstName: account?.account?.personalData.firstName,
  //     lastName: account?.account?.personalData.lastName,
  //     email: account?.account?.personalData.email,
  //     phone: account?.account?.personalData.phoneDetails?.phone,
  //   }),
  //   [account]
  // );

  return (
    <div></div>
    // <Chat
    //   id={publicRuntimeConfig.chatId}
    //   personalData={personalChatData}
    //   systemConfig={{
    //     hb_primary_color: '#E4251B',
    //     hb_custom_style: {
    //       general: {
    //         corners: `hard`,
    //         secondaryColor: '#E4251B',
    //       },
    //     },
    //   }}
    // />
  );
};

export default ChatContainer;
