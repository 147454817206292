import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsCopyright } from '@hotelplan/core.basis.bs-copyright';
import {
  FdrResponsiveImg,
  TFdrResponsiveImageProps,
} from '@hotelplan/fdr.regular.fusion.fdr-responsive-img';

const FdrResponsiveImgWithCopyright: React.FC<TFdrResponsiveImageProps> =
  props => {
    const [t] = useTranslation(['common']);
    const copyright = props?.image?.copyright;

    return (
      <FdrResponsiveImg {...props}>
        {copyright && (
          <BsCopyright>
            {t('copyright.photo')} {copyright}
          </BsCopyright>
        )}
      </FdrResponsiveImg>
    );
  };

export default FdrResponsiveImgWithCopyright;
