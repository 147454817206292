import { uniqWith } from 'lodash/fp';
import { useContext } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  isEqualByTripId,
  TProductPin,
  TRelatedPin,
  TRoundtripItem,
} from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import { TPinType } from './fdr-geo-trips.types';

export const useShowTitle = <T extends TProductPin | TRoundtripItem>(
  items: T[]
) => {
  const { mobile } = useDeviceType();
  const { activeTrip } = useContext(FdrGeoTripsContext);

  const hasActiveTrip = activeTrip !== null && activeTrip !== undefined;

  const noList = mobile && hasActiveTrip;

  return items && items.length > 0 && !noList;
};

export const useFindTrips = <T extends TProductPin | TRoundtripItem>(
  base: T[] = [],
  cluster: T[] = []
): T[] => {
  const { activeTrip } = useContext(FdrGeoTripsContext);
  const { mobile } = useDeviceType();

  return uniqWith(
    isEqualByTripId,
    [...base, ...cluster].filter(Boolean).filter(r => {
      return !(!!activeTrip && mobile && activeTrip !== r.tripId);
    })
  );
};

export const isProductPin = (
  item: TProductPin | TRelatedPin
): item is TProductPin => {
  return item.type === TPinType.PRODUCTS;
};

export const isRelatedPin = (
  item: TProductPin | TRelatedPin
): item is TRelatedPin => {
  return item.type === TPinType.RELATED_ROUTES;
};
