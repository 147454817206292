import React, { FC } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import FdrBookmarkBlock from 'components/domain/fdr-bookmark/fdr-bookmark-block';
import { bookmarkablePageTypes } from 'components/domain/fdr-bookmark/fdr-bookmark-types';
import FdrShareSiteTool from 'components/domain/fdr-site-tool/fdr-share-site-tool';
import { HeroToolsWrap } from 'components/domain/sitetools/SiteTools.common';
import { useShareUrl } from 'components/domain/sitetools/use-share-url';
import { BookmarkComponentType } from 'components/domain/tracking/bookmarkTracking';
import { mapImageCriteriaField } from 'components/util/criterias/mapImageCriteriaField';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';

type TFdrHeroMediaGalleryMediaItem = Extract<
  FdrPageComponentsFragment['components'][number],
  { __typename: 'FdrHeroMediaGallery' }
>['media'][number];

type TFdrHeroMediaGalleryImage = Extract<
  TFdrHeroMediaGalleryMediaItem,
  { __typename: 'FdrImage' }
>;

type TFdrHeroMediaGalleryVideo = Extract<
  TFdrHeroMediaGalleryMediaItem,
  { __typename: 'FdrVideo' }
>;

interface IHeroShareToolProps {
  component?: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrHeroMediaGallery' }
  >;
  bookmarked?: boolean;
}

const FdrHeroShareTool: FC<IHeroShareToolProps> = ({
  component,
  bookmarked,
}) => {
  const pageType = usePageType<EPageType>();
  const { id } = useObjectId();

  const shareUrl = useShareUrl();

  const image =
    component.media?.find((item): item is TFdrHeroMediaGalleryImage => {
      return item.__typename === 'FdrImage';
    }) ??
    component.media?.find((item): item is TFdrHeroMediaGalleryVideo => {
      return item.__typename === 'FdrVideo';
    })?.preview;

  return (
    <HeroToolsWrap>
      <FdrShareSiteTool
        pageTitle={component.title}
        image={mapImageCriteriaField(image)}
        url={shareUrl}
      />
      {bookmarkablePageTypes.includes(pageType) && (
        <div className={'site-tools-button-wrapper-bookmark'}>
          <FdrBookmarkBlock
            objectId={id}
            active={bookmarked}
            trackingComponent={BookmarkComponentType.CONTENT}
          />
        </div>
      )}
    </HeroToolsWrap>
  );
};

export default FdrHeroShareTool;
