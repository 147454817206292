export enum NavigationIds {
  TRIPS = 'navigation-trips',
  PRODUCTS = 'navigation-travels',
  DESTINATIONS = 'navigation-destinations',
  HOTELS = 'navigation-hotels',
  HIGHLIGHTS = 'navigation-highlights',
  MEDIA = 'navigation-product-gallery',
  BENEFITS = 'navigation-benefits',
  THEMES = 'navigation-themes',
  ARTICLES = 'navigation-articles',
  LONG_TEXT = 'navigation-long-text',
  BEST_TRAVEL_PERIOD = 'navigation-best-travel-period',
}
