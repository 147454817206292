import { useTranslation } from 'next-i18next';
import React, { ReactElement, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  AdaptiveCardsSlider,
  CardsSection,
} from 'components/domain/customizable-slider/CardsSection';
import {
  mapProductFeatureGroupsToProductFeatures,
  TFdrProductRecommenderProduct,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrProductRecommendation } from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommendation';
import { RecommendationListWithSlidesNavigationContainer } from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommendation-common';
import {
  IFdrRecommenderProduct,
  IFdrRecommenderProductComponent,
} from 'components/domain/fdr-page-components/recommenders/product/fdr-product-recommender.types';
import {
  isFdrAdventureTravelProduct,
  isFdrHotelProduct,
} from 'components/domain/fdr-product/fdr-product.utils';
import { searchCardPlaceholder } from 'components/domain/fdr-search/fdr-search-section.skeleton';
import {
  CARDS_GAP,
  DEFAULT_IMAGE_HEIGHT,
  DEFAULTS_RESULT_PER_PAGE,
} from 'components/domain/fdr-search/fdr-search.constants';
import { RecommendationListContainer } from 'components/domain/recommendations/RecommendationSliderSection';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import {
  FdrPaginatedProductRecommenderFragment,
  useFdrProductComponentsLazyQuery,
} from 'schemas/query/product/fdr-paginated-product-recommender.generated';

interface IFdrProductRecommenderContainerProps
  extends IFdrRecommenderProductComponent {
  sectionTitle: string;
  id: string;
  testId?: string;
  customContent?: React.ReactNode;
}

export function FdrProductRecommenderContainer({
  id,
  testId,
  sectionTitle,
  name,
  items,
  fontColor,
  backgroundColor,
  total,
  customContent,
}: IFdrProductRecommenderContainerProps): ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation();

  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [itemList, setItemList] =
    useState<Array<IFdrRecommenderProduct>>(items);

  const [fetchProductComponents, { loading }] =
    useFdrProductComponentsLazyQuery();

  async function fetchItems(nextPage: number) {
    if (nextPage * DEFAULTS_RESULT_PER_PAGE >= itemList.length) {
      await fetchProductComponents({
        variables: {
          input: { ids: [id] },
          pagination: {
            page: nextPage,
            perPage: DEFAULTS_RESULT_PER_PAGE,
          },
        },
        onCompleted(result) {
          const recommender = result.fdrComponents.components.find(
            comp => comp.__typename === 'FdrProductRecommender'
          ) as FdrPaginatedProductRecommenderFragment;

          setItemList(stored => {
            const updatedList = [...stored];

            recommender.productPage.products.forEach(
              (product: TFdrProductRecommenderProduct, index) => {
                updatedList[
                  recommender.productPage.pagination.page *
                    recommender.productPage.pagination.perPage +
                    index
                ] = {
                  ...product,
                  link: product.webMeta.descriptionWebMeta.link,
                  image: product.images[0],
                  features: isFdrHotelProduct(product)
                    ? []
                    : mapProductFeatureGroupsToProductFeatures(
                        product.featureGroups
                      ),
                  route: isFdrAdventureTravelProduct(product)
                    ? product.route?.map(r => r.locationName)
                    : [],
                };
              }
            );

            return updatedList;
          });
        },
      });
    }
  }

  async function onNextPage() {
    setCurrentPage(prev => prev + 1);
    await fetchItems(currentPage + 1);
  }

  function onPrevPage() {
    setCurrentPage(prev => prev - 1);
  }

  async function onChangePage(nextPage: number) {
    setCurrentPage(nextPage);
    await fetchItems(nextPage);
  }

  function handleImageHeight(height: number) {
    if (!height) return;
    setImageHeight(height);
  }

  const ContainerVariant = !mobile
    ? RecommendationListWithSlidesNavigationContainer
    : RecommendationListContainer;

  return (
    <div data-id={testId} id={id} style={{ color: fontColor, backgroundColor }}>
      <ContainerVariant>
        <CardsSection
          title={name}
          totalCount={total}
          page={currentPage}
          totalPages={Math.ceil(total / DEFAULTS_RESULT_PER_PAGE)}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          paginationLoading={loading}
          isSliderPagination={!mobile}
          imageHeight={imageHeight}
          trackingSource={PaginationArrowClickSource.SRL}
          headerStyle={{ color: fontColor }}
          sectionTitle={
            sectionTitle ? sectionTitle : t('product.recommender.section.title')
          }
        >
          {customContent ? <>{customContent}</> : null}
          <AdaptiveCardsSlider
            hideDelimiter
            page={currentPage}
            total={total || itemList.length}
            perPage={DEFAULTS_RESULT_PER_PAGE}
            onChangePage={onChangePage}
            placeholder={searchCardPlaceholder}
            cardsGap={CARDS_GAP}
          >
            {itemList.map((item, index) => {
              return (
                <FdrProductRecommendation
                  key={index}
                  {...item}
                  lazy={index + 1 > 3}
                  getImageHeight={
                    index === currentPage * DEFAULTS_RESULT_PER_PAGE
                      ? handleImageHeight
                      : undefined
                  }
                />
              );
            })}
          </AdaptiveCardsSlider>
        </CardsSection>
      </ContainerVariant>
    </div>
  );
}
