import React from 'react';
import styled from 'styled-components';
import { TMediaMetaMap } from '@hotelplan/core.basis.bs-picture';
import { BsMedias } from '@hotelplan/core.serving.bs-medias';
import {
  IMediaGalleryItem,
  TRenderMediaItem,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import ResponsiveImgWithCopyright from 'components/domain/copyright/responsive-img-with-copyright';

const MediaGalleryItem = styled(BsMedias)({
  height: '100%',
  '.image': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

type TProps<T extends IMediaGalleryItem> = {
  onClick: (item: T, e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  preview: boolean;
  lazy?: boolean;
  item: T;
  previewSpec: TMediaMetaMap<FdrImageResizeMode>;
  fullSpec: TMediaMetaMap<FdrImageResizeMode>;
};

const DefaultMediaItemRender = <T extends IMediaGalleryItem>({
  item,
  lazy,
  onClick,
  preview,
  previewSpec,
  fullSpec,
}: TProps<T>): React.ReactElement<T> => {
  const image = preview ? item.preview || item.image : item.image;
  const specs = preview ? previewSpec : fullSpec;
  const imageProps = {
    className: 'image',
    lazy,
    onClick(e) {
      onClick(item, e);
    },
  };

  return (
    <MediaGalleryItem lazy={lazy} className="general-item" isGallery {...item}>
      <ResponsiveImgWithCopyright
        image={image}
        specs={specs}
        imageProps={imageProps}
      />
    </MediaGalleryItem>
  );
};

export function renderMediaItemPartial(
  previewSpec: TMediaMetaMap<FdrImageResizeMode>,
  fullSpec: TMediaMetaMap<FdrImageResizeMode>
): TRenderMediaItem {
  return function DefaultRender(item, i, onSlideClick, preview) {
    return (
      <DefaultMediaItemRender
        key={i}
        item={item}
        lazy={i > 0}
        preview={preview}
        onClick={onSlideClick}
        fullSpec={fullSpec}
        previewSpec={previewSpec}
      />
    );
  };
}
