import { useEffect, useRef } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { getObjectIdPageProp } from '@hotelplan/libs.context.object-id';
import { getPageTypeProp } from '@hotelplan/libs.context.page-type';
import { getRoutePageProp } from '@hotelplan/libs.router-config';
import { FdrVisitHistoryObjectType } from '@hotelplan/supergraph-api';
import { useFdrAddVisitHistoryMutation } from 'schemas/mutation/search/fdr-add-visit-history.generated';
import { FdrLastViewedDocument } from 'schemas/query/search/fdr-last-viewed.generated';

function mapPageTypeToFdrVisitHistoryObjectType(
  pageType: EPageType
): FdrVisitHistoryObjectType | null {
  switch (pageType) {
    case EPageType.Pdp:
      return FdrVisitHistoryObjectType.Product;
    case EPageType.GeoObject:
      return FdrVisitHistoryObjectType.Geo;
    case EPageType.Static:
      return FdrVisitHistoryObjectType.Static;
    case EPageType.ThemeObject:
    case EPageType.CombinedThemeGeo:
      return FdrVisitHistoryObjectType.Theme;
    case EPageType.AgencyObject:
      return FdrVisitHistoryObjectType.Agency;
    default:
      return null;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFdrAddViewedHistory(pageProps: any): void {
  const { id: payloadId } = getObjectIdPageProp(pageProps);
  const pageType = getPageTypeProp<EPageType>(pageProps);
  const { pathname } = getRoutePageProp(pageProps);

  let id: string = payloadId;

  if (pageType === EPageType.Static) {
    id = pathname;
  }

  const prevCalledKey = useRef('');

  const objectType = mapPageTypeToFdrVisitHistoryObjectType(pageType);

  const [addHistory] = useFdrAddVisitHistoryMutation({
    refetchQueries: [FdrLastViewedDocument],
    variables: {
      id,
      objectType,
    },
  });

  useEffect(
    function () {
      if (objectType) {
        // Prepare call key to identify the specific call
        const callKey = `${pageType}:${id}`;
        if (!id || prevCalledKey.current === callKey) return;

        // Record called key to prevent double invoking
        prevCalledKey.current = callKey;
        addHistory();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectType, pageType, id]
  );
}
