import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import {
  FdrAtSearchItemFragmentDoc,
  FdrHotelSearchItemFragmentDoc,
  FdrRoundTripSearchItemFragmentDoc,
  FdrContinentSearchItemFragmentDoc,
  FdrCountryGroupSearchItemFragmentDoc,
  FdrCountrySearchItemFragmentDoc,
  FdrRegionSearchItemFragmentDoc,
  FdrResortSearchItemFragmentDoc,
  FdrThemeSearchItemFragmentDoc,
  FdrBlogArticleSearchItemFragmentDoc,
  FdrCatalogueSearchItemFragmentDoc,
  FdrAgencyOverviewSearchItemFragmentDoc,
  FdrNewsletterSearchItemFragmentDoc,
  FdrStaticPageSearchItemFragmentDoc,
} from 'schemas/fragment/search/fdr-search-items.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAdventureTravelSearchQueryVariables = Types.Exact<{
  searchInput: Types.Scalars['String'];
  searchMode: Types.FdrSearchMode;
}>;

export type FdrAdventureTravelSearchQuery = {
  __typename?: 'Query';
  fdrTextSearch: {
    __typename?: 'FdrTextSearchResponse';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    itemList?: Array<
      | {
          __typename?: 'FdrAdventureTravelSearchItem';
          debugInfo?: string | null;
          adventureTravel: {
            __typename?: 'FdrAdventureTravel';
            name: string;
            isInBookmarks: boolean;
            teaser: string;
            leadText?: string | null;
            id: string;
            images?: Array<{
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }> | null;
            googleStaticMapImage?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
              bgColor?: string | null;
              fgColor?: string | null;
            }> | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            productInfoB2B?: {
              __typename?: 'ProductInfoB2B';
              route?: string | null;
              duration: string;
              priceFrom?: string | null;
              typeFlags?: Array<string> | null;
            } | null;
            route?: Array<{
              __typename?: 'FdrAdventureTravelRoutePoint';
              locationName: string;
              coordinates: {
                __typename?: 'FdrCoordinates';
                latitude: number;
                longitude: number;
              };
            }> | null;
            featureGroups?: Array<{
              __typename?: 'FdrProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'FdrProductFeature';
                id: string;
                name: string;
                enabled: boolean;
              }>;
            }> | null;
          };
        }
      | { __typename?: 'FdrAgencyOverviewPageSearchItem' }
      | { __typename?: 'FdrAirportSearchItem' }
      | { __typename?: 'FdrBlogArticleSearchItem' }
      | { __typename?: 'FdrCatalogPageSearchItem' }
      | { __typename?: 'FdrContinentSearchItem' }
      | { __typename?: 'FdrCountryGroupSearchItem' }
      | { __typename?: 'FdrCountrySearchItem' }
      | { __typename?: 'FdrCruiseSearchItem' }
      | { __typename?: 'FdrDestinationSearchItem' }
      | { __typename?: 'FdrHotelSearchItem' }
      | { __typename?: 'FdrNewsletterSubscriptionPageSearchItem' }
      | { __typename?: 'FdrRegionSearchItem' }
      | { __typename?: 'FdrResortSearchItem' }
      | { __typename?: 'FdrRoundTripSearchItem' }
      | { __typename?: 'FdrStaticPageSearchItem' }
      | { __typename?: 'FdrThemeSearchItem' }
    > | null;
  };
};

export const FdrAdventureTravelSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrAdventureTravelSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchMode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrSearchMode' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrTextSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchMode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchInput' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'ADVENTURETRAVEL' },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '1000' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrATSearchItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrAtSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrAdventureTravelSearchQuery__
 *
 * To run a query within a React component, call `useFdrAdventureTravelSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrAdventureTravelSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrAdventureTravelSearchQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      searchMode: // value for 'searchMode'
 *   },
 * });
 */
export function useFdrAdventureTravelSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrAdventureTravelSearchQuery,
    FdrAdventureTravelSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrAdventureTravelSearchQuery,
    FdrAdventureTravelSearchQueryVariables
  >(FdrAdventureTravelSearchDocument, options);
}
export function useFdrAdventureTravelSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrAdventureTravelSearchQuery,
    FdrAdventureTravelSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrAdventureTravelSearchQuery,
    FdrAdventureTravelSearchQueryVariables
  >(FdrAdventureTravelSearchDocument, options);
}
export type FdrAdventureTravelSearchQueryHookResult = ReturnType<
  typeof useFdrAdventureTravelSearchQuery
>;
export type FdrAdventureTravelSearchLazyQueryHookResult = ReturnType<
  typeof useFdrAdventureTravelSearchLazyQuery
>;
export type FdrAdventureTravelSearchQueryResult = Apollo.QueryResult<
  FdrAdventureTravelSearchQuery,
  FdrAdventureTravelSearchQueryVariables
>;
