import React, { useCallback, useEffect, useReducer } from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { useBookmarkContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import { selectBookmarkItem } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context.utils';
import {
  TAddBookmarkModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/fdr-bookmark/context/fdr-bookmark-modal-popup-context';
import {
  BookmarkBlockActionType,
  bookmarkBlockInitialState,
  bookmarkBlockReducer,
  BookmarkBlockView,
} from 'components/domain/fdr-bookmark/fdr-bookmark.utils';
import BookmarkPopupAddToGroup from './fdr-bookmark-popup-add-to-group';
import FdrBookmarkPopupNewGroup from './fdr-bookmark-popup-new-group';

const FdrBookmarkAddModalPopup: React.FC = () => {
  const [bookmarkBlockState, dispatch] = useReducer(
    bookmarkBlockReducer,
    bookmarkBlockInitialState
  );
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const modal = bookmarkModalContext.modal as TAddBookmarkModalPopup;
  const objectId = modal?.payload?.objectId;
  const pageType = modal?.payload?.pageType;
  const bookmarkContext = useBookmarkContext();

  const onBackButtonClick = useCallback(() => {
    dispatch({
      type: BookmarkBlockActionType.SET_VIEW,
      payload: {
        view: BookmarkBlockView.ADD_TO_BOOKMARK_GROUP,
      },
    });
  }, []);

  useEffect(() => {
    let view = BookmarkBlockView.NEW_BOOKMARK_GROUP;

    if (bookmarkContext.bookmarkGroups.length) {
      view = BookmarkBlockView.ADD_TO_BOOKMARK_GROUP;
    }

    dispatch({
      type: BookmarkBlockActionType.SET_VIEW,
      payload: {
        view,
      },
    });
  }, [bookmarkContext.bookmarkGroups, bookmarkModalContext.isOpen]);

  function onSubmit(
    type: FdrBookmarkObjectType,
    id: string,
    groupName: string
  ) {
    bookmarkContext.addBookmarkToNewGroup({
      pageType: type,
      objectId: id,
      groupName,
    });
  }

  const onBookmarkGroupItemClick = useCallback(
    (groupId: string) => {
      if (objectId && pageType) {
        if (selectBookmarkItem(bookmarkContext, objectId, pageType, groupId)) {
          bookmarkContext.removeBookmark({ groupId, pageType, objectId });
        } else {
          bookmarkContext.addBookmark({ groupId, pageType, objectId });
        }
      }
    },
    [bookmarkContext.bookmarkGroups, objectId, pageType]
  );

  return (
    <>
      {bookmarkContext.bookmarkGroups && (
        <>
          {[
            BookmarkBlockView.NEW_BOOKMARK_GROUP,
            BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
          ].includes(bookmarkBlockState.view) && (
            <FdrBookmarkPopupNewGroup
              onSubmit={(values, helpers) => {
                onSubmit(
                  modal.payload.pageType,
                  modal.payload.objectId,
                  values.name
                );

                helpers.resetForm();
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
                bookmarkModalContext.close();
              }}
              onCloseButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
                bookmarkModalContext.close();
              }}
              onBackButtonClick={
                bookmarkBlockState.view ===
                BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK
                  ? onBackButtonClick
                  : undefined
              }
            />
          )}
          {bookmarkBlockState.view ===
            BookmarkBlockView.ADD_TO_BOOKMARK_GROUP && (
            <BookmarkPopupAddToGroup
              currentObject={
                objectId && pageType
                  ? {
                      id: objectId,
                      pageType: pageType,
                    }
                  : undefined
              }
              onCloseButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
                bookmarkModalContext.close();
              }}
              onNewBookmarkGroupButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.SET_VIEW,
                  payload: {
                    view: BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
                  },
                });
              }}
              onBookmarkGroupItemClick={onBookmarkGroupItemClick}
            />
          )}
        </>
      )}
    </>
  );
};

export default withLazyHydrate(FdrBookmarkAddModalPopup, {
  whenIdle: true,
  noWrapper: true,
});
