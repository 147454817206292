import { useTranslation } from 'next-i18next';
import React, { useContext, useEffect, useRef } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  TProductPin,
  TRoundtripItem,
} from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import FdrGeoTripsListBusinessInfo from './fdr-geo-trips-list-business-info';
import {
  Description,
  Ellipse,
  ImageWrapper,
  Tile,
  TileText,
  TripButton,
} from './fdr-geo-trips-list.styles';
import { TPinType } from './fdr-geo-trips.types';

type TProps = {
  trip: TRoundtripItem | TProductPin;
  isFullScreen: boolean;
  onClick(): void;
  showButtonByDefault?: boolean;
  pinType: TPinType;
};

const FdrGeoTripsListPoint: React.FC<TProps> = ({
  trip,
  isFullScreen,
  onClick,
  showButtonByDefault = false,
  pinType,
}: TProps) => {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const [t] = useTranslation('geo');
  const { activeTrip } = useContext(FdrGeoTripsContext);
  const tileRef = useRef<HTMLDivElement>(null);

  const isB2b = channelType === AuthChannelType.B2B;

  const scrollToActiveCard = () => {
    tileRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const isActive = activeTrip === trip.tripId;
  const roundtripTitleClass =
    trip.type === TPinType.PRODUCTS ? 'new-roundtrip' : 'old-roundtrip';

  useEffect(() => {
    if (isActive) scrollToActiveCard();
  }, [isActive]);

  const isActiveInMobile = mobile && isActive;

  const productInfoB2B =
    isB2b && trip.productInfoB2B && (isActiveInMobile || !mobile)
      ? trip.productInfoB2B
      : undefined;

  return (
    <Tile
      ref={tileRef}
      isFullHeight={isFullScreen && mobile && !!activeTrip}
      onClick={onClick}
      className={`${showButtonByDefault ? 'inverse' : ''} ${
        isActive ? 'active' : ''
      } ${roundtripTitleClass}`}
    >
      {!isActiveInMobile && (
        <ImageWrapper>
          {trip.image && <FdrImage image={trip.image} />}
        </ImageWrapper>
      )}
      <TileText>
        <Description>{trip.name}</Description>
        {productInfoB2B && (
          <FdrGeoTripsListBusinessInfo productInfoB2B={productInfoB2B} />
        )}
        {(isActive || showButtonByDefault) && (
          <TripButton href={trip.link.url} target="_blank">
            <Ellipse>
              {pinType === TPinType.RELATED_ROUTES
                ? t(`map.tripList.item.roundtrips.show`)
                : t(`map.tripList.item.product.show`)}
            </Ellipse>
          </TripButton>
        )}
      </TileText>
    </Tile>
  );
};

export default FdrGeoTripsListPoint;
