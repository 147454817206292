import { ReactElement } from 'react';
import { FdrMaintenanceNotifications } from '@hotelplan/fdr.regular.fusion.fdr-maintenance-notifications';
import { useFdrInfoNotificationQuery } from 'schemas/query/maintenance-notification/fdr-maintenance-notification.generated';
import { FdrMaintenanceNotificationWrapper } from './fdr-maintenance-notification.styles';

export function FdrMaintenanceNotification(): ReactElement {
  const result = useFdrInfoNotificationQuery();

  return (
    <FdrMaintenanceNotificationWrapper>
      <FdrMaintenanceNotifications
        notifications={result?.data?.fdrNotifications.maintenance || []}
      />
    </FdrMaintenanceNotificationWrapper>
  );
}
