import React, { ReactElement, useEffect } from 'react';
import { FdrLink } from '@hotelplan/supergraph-api';
import { externalLinkSubject } from 'components/domain/external-overlay';
import { useFdrGetLinkWithJotform } from 'components/domain/fdr-jotform';
import { colors } from 'theme';
import {
  FdrRequestButtonLink,
  FdrStaticRequestButtonBg,
  FdrStaticRequestButtonWrapper,
} from './fdr-request-button.styles';

interface IFdrStaticRequestButtonProps extends IFdrRequestButtonProps {
  className?: string;
}

export function FdrStaticRequestButton(
  props: IFdrStaticRequestButtonProps
): ReactElement {
  const linkWithJotform = useFdrGetLinkWithJotform(props.link);

  return (
    <FdrStaticRequestButtonBg bg={props.backgroundColor}>
      <FdrStaticRequestButtonWrapper
        className={`static-request-button-wrapper ${props.className}`}
      >
        <FdrRequestButton
          {...props}
          onClick={props.onClick}
          link={linkWithJotform}
        />
      </FdrStaticRequestButtonWrapper>
    </FdrStaticRequestButtonBg>
  );
}

interface IFdrRequestButtonProps {
  caption: string;
  link: FdrLink;
  backgroundColor?: string;
  onClick?(): void;
}

export function FdrRequestButton({
  link,
  caption,
  backgroundColor,
  onClick,
}: IFdrRequestButtonProps): ReactElement {
  const hasRedBackground =
    backgroundColor?.toLowerCase() === colors.redBurgundy?.toLowerCase();

  useEffect(
    function initRequestLinkLoad() {
      if (link) {
        externalLinkSubject.next(link.url);
      }
    },
    [link]
  );

  return (
    <FdrRequestButtonLink
      link={link}
      onClick={onClick}
      className={`request-button ${hasRedBackground ? 'red-bg' : ''}`}
    >
      <span
        className="request-button-caption"
        dangerouslySetInnerHTML={{ __html: caption }}
      />
    </FdrRequestButtonLink>
  );
}
