import dynamic from 'next/dynamic';
import React from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { DeviceType, useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  BookmarkModalPopupType,
  useBookmarkModalPopupContext,
} from 'components/domain/fdr-bookmark/context/fdr-bookmark-modal-popup-context';
import BookmarkAddModalPopup from './fdr-bookmark-add-modal-popup';
import BookmarkGroupCreateModalPopup from './fdr-bookmark-create-group-modal-popup';
import BookmarkGroupRemoveModal from './fdr-bookmark-delete-group-modal';
import BookmarkGroupEditModal from './fdr-bookmark-edit-group-modal';
import FdrBookmarkEditModal from './fdr-bookmark-edit-modal';
import BookmarkGroupShareErrorModalPopup from './fdr-bookmark-group-share-error-modal-popup';
import BookmarkGroupShareModalPopup from './fdr-bookmark-group-share-modal-popup';

const BookmarkModalContent: React.FC<{
  type: BookmarkModalPopupType | undefined;
}> = ({ type }) => {
  return (
    <>
      {type === BookmarkModalPopupType.ADD_BOOKMARK && (
        <BookmarkAddModalPopup />
      )}
      {type === BookmarkModalPopupType.EDIT_BOOKMARK_GROUP && (
        <BookmarkGroupEditModal />
      )}
      {type === BookmarkModalPopupType.REMOVE_BOOKMARK_GROUP && (
        <BookmarkGroupRemoveModal />
      )}
      {type === BookmarkModalPopupType.EDIT_BOOKMARK && (
        <FdrBookmarkEditModal />
      )}
      {type === BookmarkModalPopupType.CREATE_BOOKMARK_GROUP && (
        <BookmarkGroupCreateModalPopup />
      )}
      {type === BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_REPLACE && (
        <BookmarkGroupShareModalPopup />
      )}
      {type === BookmarkModalPopupType.SHARE_BOOKMARK_GROUP_ERROR && (
        <BookmarkGroupShareErrorModalPopup />
      )}
    </>
  );
};

const BookmarkModalPopupDesktop = dynamic(
  () => import('./fdr-bookmark-modal-popup-desktop-wrapper')
);
const BookmarkModalPopupMobile = dynamic(
  () => import('./fdr-bookmark-modal-popup-mobile-wrapper')
);

const FdrBookmarkModalPopup: React.FC = () => {
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const { mobile, serverType } = useDeviceType();
  const content = (
    <BookmarkModalContent type={bookmarkModalContext?.modal?.type} />
  );

  const isNotDesktop = serverType !== DeviceType.Desktop;
  const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;

  return (
    <>
      {!isMobileView && (
        <BookmarkModalPopupDesktop open={bookmarkModalContext.isOpen}>
          {content}
        </BookmarkModalPopupDesktop>
      )}
      {isMobileView && (
        <BookmarkModalPopupMobile open={bookmarkModalContext.isOpen}>
          {content}
        </BookmarkModalPopupMobile>
      )}
    </>
  );
};

export default withLazyHydrate(FdrBookmarkModalPopup, {
  whenIdle: true,
});
