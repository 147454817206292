import { IImageProps, IResizedUrlData } from '@hotelplan/core.basis.bs-img';
import { IMediaGalleryItem } from '@hotelplan/core.serving.bs-rsv-gallery';
import {
  FdrCoordinates,
  FdrMedia,
  FdrResizedImage,
} from '@hotelplan/supergraph-api';
import { mapImageCriteriaField } from 'components/util/criterias/mapImageCriteriaField';

export function mapFdrResizedImagesToResizedUrlData(
  fdrResized: Array<FdrResizedImage>
): Array<IResizedUrlData> {
  return fdrResized.map(item => ({
    id: item.id,
    url: item.link.url,
  }));
}

export function mapFdrMediaToImageMediaItems(
  mediaItems: Array<FdrMedia>
): Array<IMediaGalleryItem & { coordinates?: FdrCoordinates }> {
  if (!mediaItems) return [];

  return mediaItems.map(fdrImage => {
    if (fdrImage.__typename !== 'FdrImage') return null;

    const imageProps: IImageProps = {
      ...fdrImage,
      src: fdrImage.rawUrl,
      resized: mapFdrResizedImagesToResizedUrlData(
        mapImageCriteriaField(fdrImage).resized
      ),
    };

    return {
      __typename: `ImageMediaItem`,
      image: imageProps,
      resource: fdrImage.rawUrl,
      preview: imageProps,
      title: fdrImage.alt,
      coordinates: fdrImage.coordinates,
    };
  });
}
