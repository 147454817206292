import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBreadcrumbFragmentDoc } from 'schemas/fragment/breadcrumb/fdr-breadcrumb.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBookmarkBreadcrumbsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrBookmarkBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrBookmarksPage?: {
    __typename?: 'FdrBookmarksPage';
    pageBreadcrumbs?: Array<{
      __typename?: 'FdrPageBreadcrumb';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
  } | null;
};

export const FdrBookmarkBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBookmarkBreadcrumbs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrBookmarksPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageBreadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrBreadcrumb' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBreadcrumbFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBookmarkBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrBookmarkBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBookmarkBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBookmarkBreadcrumbsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrBookmarkBreadcrumbsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrBookmarkBreadcrumbsQuery,
    FdrBookmarkBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBookmarkBreadcrumbsQuery,
    FdrBookmarkBreadcrumbsQueryVariables
  >(FdrBookmarkBreadcrumbsDocument, options);
}
export function useFdrBookmarkBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBookmarkBreadcrumbsQuery,
    FdrBookmarkBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBookmarkBreadcrumbsQuery,
    FdrBookmarkBreadcrumbsQueryVariables
  >(FdrBookmarkBreadcrumbsDocument, options);
}
export type FdrBookmarkBreadcrumbsQueryHookResult = ReturnType<
  typeof useFdrBookmarkBreadcrumbsQuery
>;
export type FdrBookmarkBreadcrumbsLazyQueryHookResult = ReturnType<
  typeof useFdrBookmarkBreadcrumbsLazyQuery
>;
export type FdrBookmarkBreadcrumbsQueryResult = Apollo.QueryResult<
  FdrBookmarkBreadcrumbsQuery,
  FdrBookmarkBreadcrumbsQueryVariables
>;
