import { NetworkStatus, QueryResult } from '@apollo/client';
import { useCallback } from 'react';
import { useFetchingPagination } from '@hotelplan/libs.hooks';
import { calculateNextPage } from '@hotelplan/libs.utils';
import { FdrLink, FdrPagination } from '@hotelplan/supergraph-api';

export interface IHistoryItem {
  text: string;
  link: FdrLink;
}

export interface IHistoryResponse {
  pagination: FdrPagination;
  historyItems: Array<IHistoryItem>;
}

export interface IHistoryItems {
  items: Array<IHistoryItem>;
  page: number;
  hasMore: boolean;
  nextPage(): void | Promise<void>;
  loading: boolean;
}

interface IHistoryOptions<TData, TVariables = any> {
  useHistoryQuery(): QueryResult<TData, TVariables>;
  getHistoryResponse(data?: TData): IHistoryResponse | undefined;
}

export function useFdrLastSearchedViewed<TData = any, TVariables = any>({
  useHistoryQuery,
  getHistoryResponse,
}: IHistoryOptions<TData, TVariables>): IHistoryItems {
  const { data, fetchMore, loading, networkStatus } = useHistoryQuery();

  const {
    historyItems = [],
    pagination: responsePage = {
      page: 0,
      perPage: 0,
      total: 0,
    },
  } = getHistoryResponse(data) || {};

  const { page: pageNumber, perPage, total } = responsePage;
  const nextPageNumber = calculateNextPage(pageNumber, perPage, total);

  const onFetchMore = useCallback(
    async function () {
      await fetchMore({
        variables: {
          page: nextPageNumber,
        },
      });
    },
    [fetchMore, nextPageNumber]
  );

  const { items, page, hasMore, nextPage } = useFetchingPagination(
    historyItems,
    {
      page: pageNumber,
      perPage: perPage,
      total: total,
      fetchMore: onFetchMore,
    }
  );

  return {
    items: networkStatus === NetworkStatus.refetch ? [] : items,
    page,
    hasMore,
    nextPage,
    loading,
  };
}
