import { useTranslation } from 'next-i18next';
import React, { useCallback, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import {
  BookmarkComponentType,
  trackAddBookmark,
  trackRemoveBookmark,
} from 'components/domain/tracking/bookmarkTracking';
import { useBookmarkContext } from './context/fdr-bookmark-context';
import { selectBookmarkItem } from './context/fdr-bookmark-context.utils';
import {
  BookmarkModalPopupType,
  useBookmarkModalPopupContext,
} from './context/fdr-bookmark-modal-popup-context';
import FdrBookmarkIcon from './fdr-bookmark-icon';
import {
  BookmarkBlockActionType,
  bookmarkBlockInitialState,
  bookmarkBlockReducer,
  BookmarkBlockView,
} from './fdr-bookmark.utils';
import useBookmarked from './hooks/useBookmarked';
import BookmarkPopupAddToGroup from './modal/fdr-bookmark-popup-add-to-group';
import FdrBookmarkPopupNewGroup from './modal/fdr-bookmark-popup-new-group';

const BookmarkPopupContainer = styled.div(({ theme: { fontSizes } }) => ({
  '.bookmark-popup': {
    width: '400px',
    position: 'absolute',
    fontSize: fontSizes[1],
    fontWeight: 'normal',
    textShadow: 'none',
  },
}));

interface IMultipleBookmarkBlockProps {
  pageType: FdrBookmarkObjectType;
  objectId: string;
  active?: boolean;
  trackingComponent?: BookmarkComponentType;
}

const FdrMultipleBookmarkGroupBlock: React.FC<IMultipleBookmarkBlockProps> = ({
  pageType,
  objectId,
  active,
  trackingComponent,
}) => {
  const [bookmarkBlockState, dispatch] = useReducer(
    bookmarkBlockReducer,
    bookmarkBlockInitialState
  );
  const { mobile } = useDeviceType();
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const bookmarkContext = useBookmarkContext();
  const { bookmarked } = useBookmarked(objectId, pageType, active);
  const [, { language }] = useTranslation();

  function onSubmit(
    type: FdrBookmarkObjectType,
    id: string,
    groupName: string
  ) {
    bookmarkContext.addBookmarkToNewGroup({
      pageType: type,
      objectId: id,
      groupName,
    });
  }

  const onBookmarkGroupItemClick = useCallback(
    async (groupId: string) => {
      if (selectBookmarkItem(bookmarkContext, objectId, pageType, groupId)) {
        bookmarkContext.removeBookmark({ groupId, pageType, objectId });
      } else {
        bookmarkContext.addBookmark({ groupId, pageType, objectId });
      }
    },
    [bookmarkContext.bookmarkGroups, pageType, objectId]
  );

  const onBookmarkIconClick = useCallback(() => {
    if (trackingComponent) {
      if (bookmarked) {
        trackRemoveBookmark(trackingComponent, language);
      } else {
        trackAddBookmark(trackingComponent, language);
      }
    }

    if (mobile) {
      bookmarkModalContext.open({
        type: BookmarkModalPopupType.ADD_BOOKMARK,
        payload: { pageType, objectId },
      });
      return;
    }

    let view = BookmarkBlockView.NEW_BOOKMARK_GROUP;

    if (bookmarkContext.bookmarkGroups.length) {
      view = BookmarkBlockView.ADD_TO_BOOKMARK_GROUP;
    }

    dispatch({
      type: BookmarkBlockActionType.SET_VIEW,
      payload: {
        view,
      },
    });
  }, [
    bookmarked,
    bookmarkContext.bookmarkGroups,
    mobile,
    pageType,
    objectId,
    bookmarkModalContext,
    language,
    trackingComponent,
  ]);

  const onBackButtonClick = useCallback(() => {
    dispatch({
      type: BookmarkBlockActionType.SET_VIEW,
      payload: {
        view: BookmarkBlockView.ADD_TO_BOOKMARK_GROUP,
      },
    });
  }, []);

  useOnClickOutside<HTMLDivElement>(popupContainerRef, () => {
    if (
      popupContainerRef.current &&
      !popupContainerRef.current.contains(event.target as Node)
    ) {
      dispatch({
        type: BookmarkBlockActionType.RESET,
      });
    }
  });

  if (bookmarkContext.loading) {
    return null;
  }

  return (
    <>
      {bookmarkBlockState.view === BookmarkBlockView.DEFAULT && (
        <FdrBookmarkIcon onClick={onBookmarkIconClick} active={bookmarked} />
      )}
      {bookmarkBlockState.view !== BookmarkBlockView.DEFAULT && (
        <BookmarkPopupContainer ref={popupContainerRef}>
          {[
            BookmarkBlockView.NEW_BOOKMARK_GROUP,
            BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
          ].includes(bookmarkBlockState.view) && (
            <FdrBookmarkPopupNewGroup
              onSubmit={(values, helpers) => {
                onSubmit(pageType, objectId, values.name);

                helpers.resetForm();
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
              }}
              onCloseButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
              }}
              onBackButtonClick={
                bookmarkBlockState.view ===
                BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK
                  ? onBackButtonClick
                  : undefined
              }
            />
          )}
          {bookmarkBlockState.view ===
            BookmarkBlockView.ADD_TO_BOOKMARK_GROUP && (
            <BookmarkPopupAddToGroup
              currentObject={{
                id: objectId,
                pageType,
              }}
              onCloseButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.RESET,
                });
              }}
              onNewBookmarkGroupButtonClick={() => {
                dispatch({
                  type: BookmarkBlockActionType.SET_VIEW,
                  payload: {
                    view: BookmarkBlockView.NEW_BOOKMARK_GROUP_BACK,
                  },
                });
              }}
              onBookmarkGroupItemClick={onBookmarkGroupItemClick}
            />
          )}
        </BookmarkPopupContainer>
      )}
    </>
  );
};

export default FdrMultipleBookmarkGroupBlock;
