import { useGoogleMap } from '@react-google-maps/api';
import { useEffect, useRef } from 'react';
import { FdrCoordinates } from '@hotelplan/supergraph-api';
import { fitBounds } from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';

export const MAP_MAX_ZOOM = 7;

export const useMapCoordinates = <T extends { coordinates?: FdrCoordinates }>(
  items: T[],
  force = false
) => {
  const map = useGoogleMap();
  const firstLoad = useRef(true);

  useEffect(() => {
    if (!map || !items || items.length === 0 || (!force && !firstLoad.current))
      return;

    requestAnimationFrame(() => {
      fitBounds(
        map,
        items.map(({ coordinates }) => coordinates),
        MAP_MAX_ZOOM
      );

      firstLoad.current = false;
    });
  }, [map, items]);
};
