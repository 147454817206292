import React, { ReactElement } from 'react';
import {
  FdrFooterHeading,
  FdrROCContent,
  FdrROCWrapper,
} from './fdr-footer.styles';

interface IFdrReasonsOfConfidenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  text?: string;
}

export function FdrReasonsOfConfidence({
  title,
  text,
  className,
  ...divProps
}: IFdrReasonsOfConfidenceProps): ReactElement {
  return (
    <FdrROCWrapper
      data-id="fdr-reasons-of-confidence"
      className={className}
      {...divProps}
    >
      {title ? (
        <FdrFooterHeading data-id="fdr-roc-title">{title}</FdrFooterHeading>
      ) : null}
      {text ? (
        <FdrROCContent
          data-id="fdr-roc-content"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : null}
    </FdrROCWrapper>
  );
}
