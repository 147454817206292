import { v4 as uuidv4 } from 'uuid';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import {
  BookmarkContextActionType,
  IBookmarkContextState,
  IBookmarkGroupState,
  IBookmarkItemState,
  TBookmarkContextAction,
  TBookmarkInput,
} from 'components/domain/fdr-bookmark/fdr-bookmark-types';

export function getBookmarkItemsToUpdate(
  prevState: IBookmarkGroupState[],
  curState: IBookmarkGroupState[]
): { toAdd: TBookmarkInput[]; toDelete: TBookmarkInput[] } {
  const prevStateMap = getBookmarkStateMap(prevState);
  const curStateMap = getBookmarkStateMap(curState);

  return {
    toAdd: Array.from(curStateMap.values())
      .filter(item => !prevStateMap.has(item.id))
      .map(({ objectId, groupId, pageType }) => ({
        objectId,
        groupId,
        pageType,
      })),
    toDelete: Array.from(prevStateMap.values())
      .filter(item => !curStateMap.has(item.id))
      .map(({ objectId, groupId, pageType }) => ({
        objectId,
        groupId,
        pageType,
      })),
  };
}

interface IBookmarkMapItem {
  id: string;
  objectId: string;
  groupId: string;
  pageType: FdrBookmarkObjectType;
}

function getBookmarkStateMap(
  groups: IBookmarkGroupState[]
): Map<string, IBookmarkMapItem> {
  const map = new Map<string, IBookmarkMapItem>();

  groups.forEach(group => {
    group.items.forEach(item => {
      const id = [group.id, item.id, item.pageType].join('.');

      map.set(id, {
        id,
        objectId: item.id,
        pageType: item.pageType,
        groupId: group.id,
      });
    });
  });

  return map;
}

export function bookmarkContextReducer(
  prevState: IBookmarkContextState,
  action: TBookmarkContextAction
): IBookmarkContextState {
  switch (action.type) {
    case BookmarkContextActionType.INITIALIZE:
      return {
        ...prevState,
        initialized: true,
      };

    case BookmarkContextActionType.SET_BOOKMARK_CONTEXT_LOADING:
      return {
        ...prevState,
        loading: action.payload,
      };

    case BookmarkContextActionType.SET_BOOKMARK_GROUPS:
      return {
        ...prevState,
        loading: true,
        bookmarkGroups: action.payload.map(g => ({
          uuid: uuidv4(),
          id: g.id,
          loading: true,
          name: g.name,
          items: [],
        })),
      };

    case BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS_LOADING:
      return {
        ...prevState,
        bookmarkGroups: prevState.bookmarkGroups.map(g => {
          return g.id == action.payload.id
            ? { ...g, loading: action.payload.loading }
            : g;
        }),
      };

    case BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS:
      return {
        ...prevState,
        bookmarkGroups: prevState.bookmarkGroups.map(g => {
          return g.id == action.payload.id
            ? { ...g, loading: false, items: action.payload.items }
            : g;
        }),
      };

    case BookmarkContextActionType.ADD_BOOKMARK_ITEM:
      return {
        ...prevState,
        bookmarkGroups: prevState.bookmarkGroups.map(g => {
          return g.id == action.payload.groupId &&
            !g.loading &&
            !g.items.find(
              i =>
                i.id == action.payload?.objectId &&
                i.pageType == action.payload.pageType
            )
            ? {
                ...g,
                items: g.items.concat([
                  {
                    id: action.payload.objectId,
                    pageType: action.payload.pageType,
                  },
                ]),
              }
            : g;
        }),
      };

    case BookmarkContextActionType.REMOVE_BOOKMARK_ITEM:
      return {
        ...prevState,
        bookmarkGroups: prevState.bookmarkGroups.map(g => {
          return g.id == action.payload.groupId &&
            !g.loading &&
            g.items.find(
              i =>
                i.id == action.payload.objectId &&
                i.pageType == action.payload.pageType
            )
            ? {
                ...g,
                items: g.items.filter(
                  i =>
                    i.id != action.payload.objectId ||
                    i.pageType != action.payload.pageType
                ),
              }
            : g;
        }),
      };

    case BookmarkContextActionType.SET_BOOKMARK_COUNT:
      return {
        ...prevState,
        count: action.payload,
      };

    default:
      return prevState;
  }
}

export function selectBookmarkItem(
  bookmarkContext: IBookmarkContextState,
  objectId: string,
  pageType: FdrBookmarkObjectType,
  groupId?: string
): IBookmarkItemState | undefined {
  let bookmarkedItem: IBookmarkItemState | undefined;

  if (groupId) {
    const bookmarkItemList = bookmarkContext.bookmarkGroups
      .filter(g => g.id == groupId)
      .map(g => g.items.find(i => i.id == objectId && i.pageType == pageType));

    bookmarkedItem = bookmarkItemList?.[0];
  } else {
    bookmarkedItem = bookmarkContext.bookmarkGroups
      .flatMap(group => group.items)
      .find(item => item.pageType == pageType && item.id == objectId);
  }

  return bookmarkedItem;
}
