import { ReactElement } from 'react';
import { useLogoRoute } from '@hotelplan/fdr.hook.use-logo-route';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrImage, FdrMenu, FdrRequestButton } from '@hotelplan/supergraph-api';
import { FdrHeaderRequestButton } from 'components/domain/fdr-request-button';
import { FdrHeaderSiteTools } from './fdr-header-sitetools';
import {
  FdrDesktopMenuWrapper,
  FdrHeaderLayoutInner,
  FdrHeaderLayoutWrapper,
  FdrHeaderMenuBar,
} from './fdr-header.styles';

interface IFdrHeaderInnerProps {
  logo: FdrImage;
  navLinks: Array<FdrMenu>;
  requestButton?: FdrRequestButton;
  isWithBreadcrumbs: boolean;
}

export function FdrHeaderInner({
  logo,
  navLinks,
  requestButton,
  isWithBreadcrumbs,
}: IFdrHeaderInnerProps): ReactElement {
  const { mobile } = useDeviceType();
  const logoRoute = useLogoRoute();

  return (
    <FdrHeaderLayoutWrapper>
      <FdrHeaderLayoutInner
        logo={logo}
        logoRouterProps={logoRoute}
        isWithBreadcrumbs={isWithBreadcrumbs}
      >
        {!mobile ? (
          <div className="desktop">
            <FdrHeaderMenuBar>
              <FdrDesktopMenuWrapper
                isSubMenuBlocked
                menus={navLinks}
                className="desktop-menu"
              />
            </FdrHeaderMenuBar>
            <FdrHeaderSiteTools />
            <FdrHeaderRequestButton requestButton={requestButton} />
          </div>
        ) : null}
      </FdrHeaderLayoutInner>
    </FdrHeaderLayoutWrapper>
  );
}
