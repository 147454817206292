import {
  query2FdrCriteria,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const continentSpecs: TFdrMediaMetaMap = new Map([
  [
    `375w_mobile`,
    {
      s: { width: 343, height: 250 },
      q: { maxW: 375 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `514w_mobile`,
    {
      s: { width: 482, height: 290 },
      q: { maxW: 514, minW: 375.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `600w_mobile`,
    {
      s: { width: 568, height: 310 },
      q: { maxW: 600, minW: 514.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `895w_desktop`,
    {
      s: { width: 863, height: 320 },
      q: { maxW: 1100, minW: 600.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `1100w_desktop`,
    {
      s: { width: 1076, height: 330 },
      q: { maxW: 1440, minW: 1100.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
  [
    `1440w_desktop`,
    {
      s: { width: 1408, height: 350 },
      q: { minW: 1440.1 },
      mode: FdrImageResizeMode.Auto,
    },
  ],
]);

export const continentCriteria = query2FdrCriteria(continentSpecs);
