import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
export type FdrBreadcrumbFragment = {
  __typename?: 'FdrPageBreadcrumb';
  caption: string;
  link: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  };
};

export const FdrBreadcrumbFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrBreadcrumb' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrPageBreadcrumb' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
