import { useTranslation } from 'next-i18next';
import React, { ReactElement, useEffect } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { PaginationControlLoader } from 'components/domain/customizable-slider/CardsSection.styles';
import { LoadMoreButtonWithWrapper } from 'components/domain/recommendations/LoadMoreButton';
import { FdrSearchItem } from './fdr-search-item';
import { FdrSearchRouteMap } from './fdr-search-route-map';
import {
  geoSearchCardPlaceholder,
  searchCardPlaceholder,
} from './fdr-search-section.skeleton';
import {
  DEFAULTS_RESULT_PER_PAGE,
  FDR_SEARCH_SECTION_KEYS,
} from './fdr-search.constants';
import {
  FdrAllItemsButton,
  FdrAllItemsSection,
  FdrAllItemsSectionHeader,
  FdrAllItemsSkeletonWrapper,
} from './fdr-search.styles';
import {
  mapSearchItemToNormalizedSearchItem,
  mapSectionKeyToTitle,
} from './fdr-search.utils';
import { useFdrAllItemsSearch } from './useFdrAllItemsSearch';

interface IFdrSearchAllResultsPageProps {
  sectionQuery: typeof FDR_SEARCH_SECTION_KEYS[number];
  searchInput: string;
  closeSection?(): void;
  setActiveSectionTitle(title: string): void;
}

export function FdrSearchAllResultsPage({
  sectionQuery,
  searchInput,
  closeSection,
  setActiveSectionTitle,
}: IFdrSearchAllResultsPageProps): ReactElement {
  const [t] = useTranslation('search');
  const { channelType } = useAuthentication();

  const sectionTitle = t(mapSectionKeyToTitle(sectionQuery));
  const isB2B = channelType === AuthChannelType.B2B;

  const { itemList, loading, hasMore, total, fetchMore } = useFdrAllItemsSearch(
    searchInput,
    sectionQuery
  );

  useEffect(function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (sectionTitle) setActiveSectionTitle(sectionTitle);
  }, [sectionTitle]);

  return (
    <>
      <FdrAllItemsButton back onClick={closeSection}>
        {t('all.results.back.button')}
      </FdrAllItemsButton>
      <FdrAllItemsSectionHeader>
        <span className="title">
          {sectionTitle}
          {total && sectionTitle ? ':' : null}
        </span>
        {loading ? (
          <PaginationControlLoader isSRL />
        ) : (
          <span className="total-count">{total}</span>
        )}
        {sectionQuery === 'adventuretravel' && !loading ? (
          <FdrSearchRouteMap searchInput={searchInput} isLoading={loading} />
        ) : null}
      </FdrAllItemsSectionHeader>
      <FdrAllItemsSection>
        {itemList.map((item, index) => {
          return item ? (
            <FdrSearchItem
              key={index}
              sectionKey={sectionQuery}
              className={`srl-${sectionQuery}-${index + 1}`}
              searchItem={mapSearchItemToNormalizedSearchItem(item, isB2B)}
            />
          ) : null;
        })}
      </FdrAllItemsSection>
      {loading ? (
        <FdrAllItemsSkeletonWrapper
          page={0}
          total={3}
          perPage={DEFAULTS_RESULT_PER_PAGE}
          placeholder={
            sectionQuery === 'geo'
              ? geoSearchCardPlaceholder
              : searchCardPlaceholder
          }
        />
      ) : null}
      {hasMore ? (
        <LoadMoreButtonWithWrapper onClick={fetchMore}>
          {t('allResult.load.more')}
        </LoadMoreButtonWithWrapper>
      ) : null}
    </>
  );
}
