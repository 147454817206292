import { Polyline } from '@react-google-maps/api';
import React from 'react';
import { FdrAdventureTravelRoutePoint } from '@hotelplan/supergraph-api';
import { getPath } from 'components/domain/fdr-gallery-with-map';

const getOptions = (double, active, hovered, only) => {
  const base = double
    ? { zIndex: active ? 12 : 2, strokeOpacity: 1 }
    : {
        strokeWeight: 6,
        zIndex: active ? 11 : 1,
        strokeOpacity: active ? 1 : 0,
      };

  if (double && active) return { ...base, strokeColor: '#ffffff' };
  if (active) return { ...base, strokeColor: '#E4251B' };
  if (hovered) return { ...base, strokeColor: '#E4251B' };
  if (only) return { ...base, strokeColor: '#E4251B' };

  return { ...base, strokeColor: '#7A2739', zIndex: 1 };
};

type TBasePolylineProps = {
  points: Array<FdrAdventureTravelRoutePoint>;
  onClick?(e: google.maps.MapMouseEvent): void;
  onMouseOver?(e: google.maps.MapMouseEvent): void;
  onMouseOut?(e: google.maps.MapMouseEvent): void;
  active?: boolean;
  hovered?: boolean;
  only?: boolean;
};

export const BasePolyline: React.FC<TBasePolylineProps> = ({
  points,
  active = false,
  hovered = false,
  only = false,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const options = getOptions(false, active, hovered, only);
  const doubleOptions = getOptions(true, active, hovered, only);

  const path = getPath(points);

  return (
    <>
      <Polyline
        path={path}
        options={doubleOptions}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
      <Polyline
        path={path}
        options={options}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </>
  );
};
