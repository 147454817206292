import styled from 'styled-components';

export const FdrScheduleWrapper = styled.div<{ opened: boolean }>(
  ({ theme }) => theme.helpOverlay.schedule,
  ({ opened, theme: { colors } }) => ({
    '> p': {
      color: opened ? colors.green : colors.primary,
    },
  })
);

export const FdrOpeningStatusWrapper = styled.p<{
  opened: boolean;
  showStatusIcon: boolean;
}>(({ opened, showStatusIcon, theme: { colors, space } }) => ({
  position: 'relative',
  '&:before': showStatusIcon
    ? {
        content: "''",
        position: 'absolute',
        top: space[2],
        left: '-24px',
        width: '12px',
        height: '12px',
        backgroundColor: opened ? colors.success : colors.primary,
        borderRadius: '50%',
      }
    : {},
}));
