import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrProductBreadcrumbsDocument,
  FdrProductBreadcrumbsQuery,
  FdrProductBreadcrumbsQueryVariables,
} from 'schemas/query/product/fdr-product-breadcrumbs.generated';
import { createBreadcrumb, getHomeBreadcrumb } from './utils.common';

export async function getFdrPdpBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrProductBreadcrumbsQuery,
    FdrProductBreadcrumbsQueryVariables
  >({
    query: FdrProductBreadcrumbsDocument,
    variables: { id: pageId },
  });

  const homeBreadcrumb = getHomeBreadcrumb(data, lang);

  switch (data?.fdrProduct?.__typename) {
    case 'FdrAdventureTravel': {
      const { country, region } = data?.fdrProduct.destination || {};

      return [
        ...homeBreadcrumb, // home
        // continent
        createBreadcrumb(
          country.continent.webMeta.descriptionWebMeta.link,
          country.continent.name
        ),
        createBreadcrumb(country.webMeta.descriptionWebMeta.link, country.name), // country
        // region
        region &&
          createBreadcrumb(region.webMeta.descriptionWebMeta.link, region.name),
        // crossing countries if exist
        ...(data?.fdrProduct.crossingCountries?.map(crossingCountry => {
          return createBreadcrumb(
            crossingCountry.webMeta.descriptionWebMeta.link,
            crossingCountry.name
          );
        }) || []),
        createBreadcrumb(null, data?.fdrProduct.name, true), // current product
      ];
    }
    case 'FdrHotel': {
      const { country, region } = data?.fdrProduct.resort.destination || {};

      return [
        ...homeBreadcrumb, // home
        createBreadcrumb(country.webMeta.descriptionWebMeta.link, country.name), // country
        region &&
          createBreadcrumb(region.webMeta.descriptionWebMeta.link, region.name), // region
        createBreadcrumb(null, data?.fdrProduct.resort.name, true), // resort (inactive)
        createBreadcrumb(null, data?.fdrProduct.name, true), // current product
      ];
    }
    case 'FdrRoundTrip': {
      return [
        ...homeBreadcrumb, // home
        createBreadcrumb(null, data?.fdrProduct.name, true), // current product
      ];
    }
    default: {
      return homeBreadcrumb;
    }
  }
}
