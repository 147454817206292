import React, { ReactElement } from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const HEIGHT_DESKTOP = 610;
const HEIGHT_MOBILE = 350;

const WIDTH_MOBILE = 400;
const WIDTH_DESKTOP = 710;

export function FdrGalleryWithMapSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  const itemHeight = mobile ? HEIGHT_MOBILE : HEIGHT_DESKTOP;
  const itemWidth = mobile ? WIDTH_MOBILE : WIDTH_DESKTOP;

  const gap = 20;

  return (
    <PlaceholderLoader
      uid="fdr-gallery-with-map-skeleton"
      width={mobile ? '100%' : itemWidth * 2 + gap}
      height={mobile ? itemHeight * 2 + gap : itemHeight}
      style={{
        width: mobile ? '100%' : itemWidth * 2 + gap,
        height: mobile ? itemHeight * 2 + gap : itemHeight,
      }}
    >
      <rect x={0} width={itemWidth} height={itemHeight} rx={10} />
      <rect
        x={mobile ? 0 : itemWidth + gap}
        y={mobile ? itemHeight + gap : 0}
        width={itemWidth}
        height={itemHeight}
        rx={10}
      />
    </PlaceholderLoader>
  );
}
