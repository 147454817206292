import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const BenefitItemContainer = styled.div<{ mobile: boolean }>(
  ({ theme: { colors } }) =>
    sx2CssThemeFn(({ mobile }) => ({
      display: 'flex',
      overflowX: 'auto',
      columnGap: mobile ? '0px' : '20px',
      rowGap: mobile ? '40px' : '0px',
      flexDirection: mobile ? 'column' : 'row',

      '.with-link': { '&:hover': { borderColor: colors.primary } },
      '&::-webkit-scrollbar': { display: 'none' },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    }))
);

const BenefitItem = styled.div<{ mobile: boolean }>(
  sx2CssThemeFn(({ mobile }) => ({
    backgroundColor: 'white',
    padding: mobile ? '20px 30px 20px 30px' : '22px 20px 20px 20px',
    border: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,

    width: mobile ? '100%' : '25%',
    minHeight: mobile ? '86px' : '191px',
    fontSize: '18px',
    lineHeight: 1.44,

    '&:not(:last-child)::after': {
      content: "''",
      backgroundImage:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB9SURBVHgBjZDBDYAgDEWpgbsjdAES2UA3cRRHcCPZgAMMwAjeOeA/YGIIir20fX1pk5L3ftZaW/ESzrlRKcU55/GPRyGEjD6mlBZjTKwkhnSg5IK63lAYSynX+mph/EBd714oiCjWItjZYJ8e4YcW2eI/m2gE5jvSVNqudwHwYzZEEcfCzwAAAABJRU5ErkJggg==")',
      display: 'block',
      position: 'absolute',
      height: '6px',
      top: mobile ? 'calc(100% + 17.5px)' : '50%',
      left: mobile ? 'calc(50% - 20px)' : '100%',
      transform: mobile ? `rotate(90deg)` : `rotate(0deg)`,
      width: mobile ? '40px' : '20px',
    },
  }))
);

const BenefitItemNumber = styled.div<{ mobile: boolean }>(({ mobile }) =>
  sx2CssThemeFn({
    fontSize: mobile ? '18px' : '24px',
    color: 'redBurgundy',
    fontWeight: '700',
  })
);

const BenefitItemText = styled.div({
  textAlign: 'left',
  marginLeft: '20px',
});

interface IBenefitItemsProps {
  items: { text?: string }[];
}

const FdrBenefitItems: React.FC<IBenefitItemsProps> = props => {
  const { items } = props;
  const { mobile } = useDeviceType();

  return (
    <BenefitItemContainer mobile={mobile}>
      {items.map((item, i) => {
        return (
          <BenefitItem mobile={mobile} key={i}>
            <BenefitItemNumber mobile={mobile}>{i + 1}</BenefitItemNumber>
            <BenefitItemText dangerouslySetInnerHTML={{ __html: item.text }} />
          </BenefitItem>
        );
      })}
    </BenefitItemContainer>
  );
};

export default FdrBenefitItems;
