import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrLink } from '@hotelplan/supergraph-api';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { FDR_BREADCRUMBS_CAPTIONS } from './utils.constants';

export function mapFdrBreadcrumbs(
  breadcrumbs: Array<TFdrBreadcrumb>
): Array<TFdrBreadcrumb> {
  return (
    breadcrumbs?.map((breadcrumb, index) => ({
      ...breadcrumb,
      disabled: index === breadcrumbs.length - 1, // disable last breadcrumb
    })) || []
  );
}

export function createBreadcrumb(
  link: FdrLink | null,
  caption: string,
  disabled = false
): TFdrBreadcrumb {
  return { link, caption, disabled };
}

export function getHardcodedCaption(pageType: EPageType, lang: string): string {
  return FDR_BREADCRUMBS_CAPTIONS.get(pageType)[lang];
}

export function getHomeBreadcrumb(
  data: { fdrHomePage?: { webMeta: { link: FdrLink } } },
  lang: string
): Array<TFdrBreadcrumb> {
  return [
    createBreadcrumb(
      data?.fdrHomePage?.webMeta.link,
      getHardcodedCaption(EPageType.Home, lang)
    ),
  ];
}
