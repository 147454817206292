import React, { Dispatch, useEffect } from 'react';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { fdrBookmarkPageDefaultPagination } from 'components/page/bookmark/bookmark.vars';
import {
  FdrBookmarkContextListQuery,
  useFdrBookmarkContextListQuery,
} from 'schemas/query/bookmark/fdr-bookmark-context-list.generated';
import {
  BookmarkContextActionType,
  TBookmarkContextAction,
} from './fdr-bookmark-types';
import { getBookmarkedObjectItemFromFdrBookmark } from './fdr-bookmark.utils';

const mapBookmarksToItems = (
  bookmarks:
    | FdrBookmarkContextListQuery['atGroup']['bookmarks']
    | FdrBookmarkContextListQuery['rtGroup']['bookmarks']
    | FdrBookmarkContextListQuery['hotelGroup']['bookmarks']
    | FdrBookmarkContextListQuery['geoGroup']['bookmarks']
    | FdrBookmarkContextListQuery['themeGroup']['bookmarks'],
  pageType: FdrBookmarkObjectType
) =>
  bookmarks
    ?.map(gr => ({
      id: getBookmarkedObjectItemFromFdrBookmark(gr)?.id,
      pageType,
    }))
    ?.filter(gr => gr.id) || [];

interface IBookmarkGroupQueryItem {
  groupId: string;
  dispatch: Dispatch<TBookmarkContextAction>;
}

const BookmarkGroupItemsQueryItem: React.FC<IBookmarkGroupQueryItem> = ({
  groupId,
  dispatch,
}) => {
  const { data, loading, error } = useFdrBookmarkContextListQuery({
    fetchPolicy: 'network-only',
    variables: {
      groupId,
      pagination: fdrBookmarkPageDefaultPagination,
    },
  });

  const { atGroup, rtGroup, hotelGroup, geoGroup, themeGroup } = data || {};

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS,
        payload: {
          id: groupId,
          items: [
            ...mapBookmarksToItems(
              atGroup?.bookmarks,
              FdrBookmarkObjectType.Product
            ),
            ...mapBookmarksToItems(
              rtGroup?.bookmarks,
              FdrBookmarkObjectType.Product
            ),
            ...mapBookmarksToItems(
              hotelGroup?.bookmarks,
              FdrBookmarkObjectType.Product
            ),
            ...mapBookmarksToItems(
              geoGroup?.bookmarks,
              FdrBookmarkObjectType.Geo
            ),
            ...mapBookmarksToItems(
              themeGroup?.bookmarks,
              FdrBookmarkObjectType.Theme
            ),
          ],
        },
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (loading) {
      dispatch({
        type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS_LOADING,
        payload: {
          id: groupId,
          loading: true,
        },
      });
    }
  }, [loading]);

  return null;
};
export default BookmarkGroupItemsQueryItem;
