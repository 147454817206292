import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { useBookmarkContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import { IBookmarkGroupState } from 'components/domain/fdr-bookmark/fdr-bookmark-types';
import useBookmarkGroupData from 'components/domain/fdr-bookmark/hooks/useBookmarkGroupData';
import {
  BookmarkPopup,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
} from './fdr-bookmark-modal-popup.styles';

const Link = styled(BsLink)(({ theme }) => theme.link.uiPrimary);
const BookmarkGroupItemWrapper = styled.a.attrs({ href: '#' })(
  ({ theme: { colors } }) => ({
    display: 'flex !important',
    alignItems: 'stretch',
    marginTop: '12px',
    '&:hover > div': {
      backgroundColor: colors.paperGrey,
    },
    '&:first-child': {
      marginTop: '24px',
    },
  })
);
const BookmarkGroupItemImageWrapper = styled.div({
  width: '72px',
  height: '60px',
  position: 'relative',
  flexShrink: 0,
  img: {
    objectFit: 'cover',
  },
});
const BookmarkGroupItemImage = styled(FdrImage)({
  display: 'block',
  width: '100%',
  height: '100%',
});
const BookmarkGroupItemContent = styled.div({
  padding: '5px 10px',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});
const BookmarkGroupItemCheckboxWrapper = styled.div({
  display: 'flex',
  paddingRight: '10px',
  justifyContent: 'center',
  alignItems: 'center',
});
const CheckboxIcon = styled(BsButtonWithIcon).attrs({
  variant: 'iconBtn',
  icon: { name: 'checkmark-white' },
})<{ checked: boolean }>(({ theme: { space, colors, icons }, checked }) => ({
  background: checked
    ? `${colors.redWeb} !important`
    : 'transparent !important',
  borderRadius: '5px',
  padding: space[2],
  border: '1px solid',
  borderColor: checked
    ? 'transparent !important'
    : `${colors.borderColor} !important`,
  '.icon': {
    ...icons.md,
    ...(!checked && {
      visibility: 'hidden',
    }),
  },
}));

interface IBookmarkGroupItemProps {
  item: IBookmarkGroupState;

  onClick(item: IBookmarkGroupState): void;

  currentObject?: { id: string; pageType: FdrBookmarkObjectType };
}

const BookmarkGroupItem: React.FC<IBookmarkGroupItemProps> = ({
  item,
  onClick,
  currentObject,
}) => {
  const { bookmarkGroups } = useBookmarkContext();
  const { image } = useBookmarkGroupData(item.id);

  const isSelected = useMemo(() => {
    const group = bookmarkGroups.find(g => g.id == item.id);

    return !!(
      currentObject &&
      group &&
      group.items.find(
        i => i.id == currentObject.id && i.pageType == currentObject.pageType
      )
    );
  }, [currentObject, bookmarkGroups, item]);

  return (
    <BookmarkGroupItemWrapper
      onClick={event => {
        event.preventDefault();
        onClick(item);
      }}
    >
      {image && (
        <BookmarkGroupItemImageWrapper>
          <BookmarkGroupItemImage image={image} />
        </BookmarkGroupItemImageWrapper>
      )}
      <BookmarkGroupItemContent>{item.name}</BookmarkGroupItemContent>
      {currentObject && (
        <BookmarkGroupItemCheckboxWrapper>
          <CheckboxIcon checked={isSelected} />
        </BookmarkGroupItemCheckboxWrapper>
      )}
    </BookmarkGroupItemWrapper>
  );
};

const BookmarkAddNewGroupLinkWrapper = styled.div({
  display: 'flex',
  a: {
    display: 'flex !important',
    width: 'auto !important',
  },
});

interface IBookmarkPopupAddToWishlistProps {
  currentObject?: { id: string; pageType: FdrBookmarkObjectType };

  onCloseButtonClick(): void;

  onNewBookmarkGroupButtonClick(): void;

  onBookmarkGroupItemClick(groupId: string): void;
}

const BookmarkPopupAddToGroup: React.FC<IBookmarkPopupAddToWishlistProps> = ({
  currentObject,
  onCloseButtonClick,
  onNewBookmarkGroupButtonClick,
  onBookmarkGroupItemClick,
}) => {
  const [t] = useTranslation(['common']);
  const bookmarkContext = useBookmarkContext();

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.addToGroup.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            onCloseButtonClick();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkAddNewGroupLinkWrapper>
          <Link
            onClick={event => {
              event.preventDefault();
              onNewBookmarkGroupButtonClick();
            }}
            icon={{ name: 'plus-borderless' }}
          >
            <span className="link-text">
              {t('bookmark.group.create.title')}
            </span>
          </Link>
        </BookmarkAddNewGroupLinkWrapper>
        <div>
          {bookmarkContext.bookmarkGroups.map(group => {
            return (
              <BookmarkGroupItem
                key={group.id}
                item={group}
                onClick={() => {
                  onBookmarkGroupItemClick(group.id);
                }}
                currentObject={currentObject}
              />
            );
          })}
        </div>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};
export default BookmarkPopupAddToGroup;
