import { createGlobalStyle } from 'styled-components';
import { FONT_FAMILY, colors, fontSizes } from './base';

export const GlobalStyle = createGlobalStyle({
  '*,*::before,*::after': {
    boxSizing: 'border-box',
  },
  'body,h1,h2,h3,h4,h5,p,li,figure,figcaption,blockquote,dl,dd': {
    margin: 0,
  },
  'ul,ol': {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  body: {
    fontFamily: FONT_FAMILY,
    fontSize: fontSizes[1],
    color: colors.fontColor,
    lineHeight: '1.5',
    minHeight: '100vh',
    letterSpacing: '0.5px',
    textRendering: 'optimizeSpeed',
  },
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    body: {
      overflowX: 'hidden',
    },
  },
  'h1,h2,h3,h4,h5,h6': {
    lineHeight: '1.25',
    fontFamily: 'inherit',
    fontWeight: 900,
  },
  img: {
    maxWidth: '100%',
    display: 'inline-block',
  },
  a: {
    display: 'inline-block',
    color: 'inherit',
    textDecoration: 'none',
    '-webkit-tap-highlight-color': 'transparent',
  },
  'a:not([class])': {
    textDecorationSkipInk: 'auto',
  },
  ul: {
    listStyle: 'none',
    padding: '0',
  },
  'input,button,textarea,select': {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'normal',
    outline: 'none',
  },
  button: {
    padding: '0',
    color: 'inherit',
    background: 'none',
    border: 'none',
    lineHeight: 'inherit',
    appearance: 'none',
    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  'input,textarea': {
    appearance: 'none',
    border: 'none',
  },
  'input::placeholder,textarea::placeholder': {
    color: 'inherit',
  },
  fieldset: {
    border: '0',
    padding: '0',
    margin: '0',
  },
  address: {
    fontStyle: 'inherit',
  },
  '@media (prefers-reduced-motion: reduce)': {
    '*': {
      'animation-duration': '0.01ms !important',
      'animation-iteration-count': '1 !important',
      'transition-duration': '0.01ms !important',
      'scroll-behavior': 'auto !important',
    },
  },
  'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
    '-webkit-appearance': 'none',
  },
});
