import { useMemo } from 'react';
import { FdrImage, FrdBookmarkType } from '@hotelplan/supergraph-api';
import {
  TGroupBookmarks,
  TGroupData,
} from 'components/domain/fdr-bookmark/fdr-bookmark-types';
import { getBookmarkImageFromFdrBookmark } from 'components/domain/fdr-bookmark/fdr-bookmark.utils';
import { fdrBookmarkPageSingleItemPagination } from 'components/page/bookmark/bookmark.vars';
import { useFdrBookmarkGroupDataQuery } from 'schemas/query/bookmark/fdr-bookmark-group-data.generated';

const useBookmarkGroupData = (
  bookmarkGroupId: string
): { image: FdrImage; shareUrl: string } => {
  let shareUrl: string;
  const documentLocationOrigin =
    typeof document !== 'undefined' ? document.location.origin : '';

  const variables = {
    groupId: bookmarkGroupId,
    pagination: fdrBookmarkPageSingleItemPagination,
  };

  const { data, client } = useFdrBookmarkGroupDataQuery({
    ssr: false,
    variables,
    skip: !bookmarkGroupId,
    onCompleted: response => {
      const image = getFirstImageFromBookmarkGroup(response);

      if (!image) {
        [
          FrdBookmarkType.AdventureTravel,
          FrdBookmarkType.Hotel,
          FrdBookmarkType.RoundTrip,
          FrdBookmarkType.Geo,
          FrdBookmarkType.Theme,
        ].forEach(bookmarkType => {
          client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'fdrBookmarks',
            args: {
              input: {
                bookmarkGroupId,
                bookmarkType,
                pagination: fdrBookmarkPageSingleItemPagination,
              },
            },
          });
        });
      }
    },
  });

  const groupImage: FdrImage | undefined = useMemo(() => {
    return getFirstImageFromBookmarkGroup(data);
  }, [data]);

  const groupShareUrl = useMemo(() => {
    function getShareUrl(res: TGroupData): string | undefined {
      return res?.shareUrl;
    }

    return (
      getShareUrl(data?.atBookmarks) ||
      getShareUrl(data?.rtBookmarks) ||
      getShareUrl(data?.hotelBookmarks) ||
      getShareUrl(data?.geoBookmarks) ||
      getShareUrl(data?.themeBookmarks)
    );
  }, [data]);

  if (groupShareUrl) {
    shareUrl = `${documentLocationOrigin}${groupShareUrl}`;
  }

  return {
    shareUrl,
    image: groupImage,
  };
};

function getFirstImageFromBookmarks(
  bookmarks: TGroupBookmarks
): FdrImage | undefined {
  return bookmarks
    ?.map(item => getBookmarkImageFromFdrBookmark(item))
    .find(i => i);
}

const getFirstImageFromBookmarkGroup = (
  data: ReturnType<typeof useFdrBookmarkGroupDataQuery>['data']
) => {
  return (
    getFirstImageFromBookmarks(data?.atBookmarks?.bookmarks) ||
    getFirstImageFromBookmarks(data?.rtBookmarks?.bookmarks) ||
    getFirstImageFromBookmarks(data?.hotelBookmarks?.bookmarks) ||
    getFirstImageFromBookmarks(data?.geoBookmarks?.bookmarks) ||
    getFirstImageFromBookmarks(data?.themeBookmarks?.bookmarks)
  );
};

export default useBookmarkGroupData;
