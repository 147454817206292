import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrSrlLinkQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
}>;

export type FdrSrlLinkQuery = {
  __typename?: 'Query';
  fdrProductSearchResultListPage?: {
    __typename?: 'FdrProductSearchResultListPage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export const FdrSrlLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrSrlLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchString' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProductSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'searchOverlayCriteria',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'keyword' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchString',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrSrlLinkQuery__
 *
 * To run a query within a React component, call `useFdrSrlLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSrlLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSrlLinkQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useFdrSrlLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrSrlLinkQuery,
    FdrSrlLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FdrSrlLinkQuery, FdrSrlLinkQueryVariables>(
    FdrSrlLinkDocument,
    options
  );
}
export function useFdrSrlLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrSrlLinkQuery,
    FdrSrlLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrSrlLinkQuery,
    FdrSrlLinkQueryVariables
  >(FdrSrlLinkDocument, options);
}
export type FdrSrlLinkQueryHookResult = ReturnType<typeof useFdrSrlLinkQuery>;
export type FdrSrlLinkLazyQueryHookResult = ReturnType<
  typeof useFdrSrlLinkLazyQuery
>;
export type FdrSrlLinkQueryResult = Apollo.QueryResult<
  FdrSrlLinkQuery,
  FdrSrlLinkQueryVariables
>;
