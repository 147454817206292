import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrThemeBreadcrumbsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type FdrThemeBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrTheme?: { __typename?: 'FdrTheme'; name: string } | null;
  fdrHomePage?: {
    __typename?: 'FdrHomePage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
  fdrThemeOverviewPage?: {
    __typename?: 'FdrThemeOverviewPage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export const FdrThemeBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrThemeBreadcrumbs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrTheme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrThemeOverviewPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrThemeBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrThemeBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrThemeBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrThemeBreadcrumbsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFdrThemeBreadcrumbsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrThemeBreadcrumbsQuery,
    FdrThemeBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrThemeBreadcrumbsQuery,
    FdrThemeBreadcrumbsQueryVariables
  >(FdrThemeBreadcrumbsDocument, options);
}
export function useFdrThemeBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrThemeBreadcrumbsQuery,
    FdrThemeBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrThemeBreadcrumbsQuery,
    FdrThemeBreadcrumbsQueryVariables
  >(FdrThemeBreadcrumbsDocument, options);
}
export type FdrThemeBreadcrumbsQueryHookResult = ReturnType<
  typeof useFdrThemeBreadcrumbsQuery
>;
export type FdrThemeBreadcrumbsLazyQueryHookResult = ReturnType<
  typeof useFdrThemeBreadcrumbsLazyQuery
>;
export type FdrThemeBreadcrumbsQueryResult = Apollo.QueryResult<
  FdrThemeBreadcrumbsQuery,
  FdrThemeBreadcrumbsQueryVariables
>;
