import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrHeaderLayout } from '@hotelplan/fdr.regular.fusion.fdr-header-layout';
import { FdrDesktopNav } from '@hotelplan/fdr.regular.fusion.fdr-desktop-nav';

export const FdrHeaderStickyWrapper = styled.div<{
  infoHeight: number;
}>(({ theme: { media }, infoHeight }) =>
  sx2CssThemeFn({
    position: 'absolute',
    maxWidth: '1480px',
    px: '20px',
    width: '100%',
    zIndex: 100,
    left: 0,
    right: 0,
    margin: '0 auto',
    [media.mobile]: {
      position: 'absolute',
      top: 0,
      p: 0,
    },
    '&.sticky': {
      position: 'fixed',
      top: infoHeight ? `-${infoHeight}px` : 0,
    },
  })
);

export const FdrHeaderBox = styled.div(
  sx2CssThemeFn({
    boxShadow: [null, '0px 0px 40px -15px rgba(0, 0, 0, 0.35)'],
    borderRadius: '0 0 5px 5px',
  })
);

export const FdrHeaderLayoutWrapper = styled.div(
  sx2CssThemeFn({
    height: '100%',
  })
);

export const FdrHeaderLayoutInner = styled(FdrHeaderLayout)<{
  isWithBreadcrumbs: boolean;
}>(({ isWithBreadcrumbs }) =>
  sx2CssThemeFn({
    top: 0,
    width: '100%',
    borderBottom: 'none',
    zIndex: '100',
    header: {
      position: 'relative',
      left: '0',
      right: '0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      maxWidth: '1440px',
      background: ['transparent', 'white'],
      borderRadius: !isWithBreadcrumbs && '0px 0px 5px 5px',
      p: ['12px 16px 12px 16px', '12px 12px 12px 20px'],
      '.desktop': { display: ['none', 'flex'] },
      '> a': {
        mt: '-12px',
        mb: '0',
        flexGrow: 1,
      },
      img: {
        width: ['50%', '175px'],
        height: 'auto',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    '.static-request-button-wrapper': {
      ml: 1,
      '.request-button': {
        borderRadius: '5px',
        padding: '9px 26px 11px',
        '.request-button-caption': {
          fontWeight: '400',
          letterSpacing: '0.5px',
          lineHeight: [null, '22px'],
        },
      },
    },
    '.desktop-menu': { p: 0 },
    'button[data-id="markup-control"]': { display: 'none' },
  })
);

export const FdrHeaderMenuBar = styled.div({
  padding: '0 24px',
  gap: 4,
});

export const FdrDesktopMenuWrapper = styled(FdrDesktopNav)(
  sx2CssThemeFn({
    '.sub-link-chevron': { display: 'none' },
    '.menu-link-item': {
      px: 3,
      py: 2,
      height: [null, 'auto'],
      border: 'none',
      letterSpacing: '0.5px',
      '> a': {
        minHeight: [null, 'auto'],
      },
    },
  })
);
