import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBreadcrumbFragmentDoc } from 'schemas/fragment/breadcrumb/fdr-breadcrumb.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBlogArticleOverviewBreadcrumbsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrBlogArticleOverviewBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrBlogArticlesOverviewPage?: {
    __typename?: 'FdrBlogArticlesOverviewPage';
    breadcrumbs?: Array<{
      __typename?: 'FdrPageBreadcrumb';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
  } | null;
};

export const FdrBlogArticleOverviewBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBlogArticleOverviewBreadcrumbs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrBlogArticlesOverviewPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrBreadcrumb' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBreadcrumbFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBlogArticleOverviewBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrBlogArticleOverviewBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBlogArticleOverviewBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBlogArticleOverviewBreadcrumbsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrBlogArticleOverviewBreadcrumbsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrBlogArticleOverviewBreadcrumbsQuery,
    FdrBlogArticleOverviewBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBlogArticleOverviewBreadcrumbsQuery,
    FdrBlogArticleOverviewBreadcrumbsQueryVariables
  >(FdrBlogArticleOverviewBreadcrumbsDocument, options);
}
export function useFdrBlogArticleOverviewBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBlogArticleOverviewBreadcrumbsQuery,
    FdrBlogArticleOverviewBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBlogArticleOverviewBreadcrumbsQuery,
    FdrBlogArticleOverviewBreadcrumbsQueryVariables
  >(FdrBlogArticleOverviewBreadcrumbsDocument, options);
}
export type FdrBlogArticleOverviewBreadcrumbsQueryHookResult = ReturnType<
  typeof useFdrBlogArticleOverviewBreadcrumbsQuery
>;
export type FdrBlogArticleOverviewBreadcrumbsLazyQueryHookResult = ReturnType<
  typeof useFdrBlogArticleOverviewBreadcrumbsLazyQuery
>;
export type FdrBlogArticleOverviewBreadcrumbsQueryResult = Apollo.QueryResult<
  FdrBlogArticleOverviewBreadcrumbsQuery,
  FdrBlogArticleOverviewBreadcrumbsQueryVariables
>;
