import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrBookmarkObjectType } from '@hotelplan/supergraph-api';
import { FdrBookmarkGroupDataQuery } from 'schemas/query/bookmark/fdr-bookmark-group-data.generated';
import { FdrBookmarkListQuery } from 'schemas/query/bookmark/fdr-bookmark-list.generated';

export type TTypedBookmarkEntryObject = {
  __typename?: string;
};

export type TFdrBookmarkedGeo<T extends TTypedBookmarkEntryObject> = Extract<
  T,
  { __typename: 'FdrBookmarkedGeo' }
>;

export type TFdrBookmarkedProduct<T extends TTypedBookmarkEntryObject> =
  Extract<T, { __typename: 'FdrBookmarkedProduct' }>;

export type TFdrBookmarkedTheme<T extends TTypedBookmarkEntryObject> = Extract<
  T,
  { __typename: 'FdrBookmarkedTheme' }
>;

export type TFdrBookmarkedEntryObject<T extends TTypedBookmarkEntryObject> =
  | TFdrBookmarkedGeo<T>
  | TFdrBookmarkedProduct<T>
  | TFdrBookmarkedTheme<T>;

export const bookmarkablePageTypes: EPageType[] = [
  EPageType.Pdp,
  EPageType.GeoObject,
  EPageType.ThemeObject,
];

export interface IBookmarkItemState {
  id: string;
  pageType: FdrBookmarkObjectType;
}

export interface IBookmarkGroupState {
  uuid: string;
  id: string;
  loading: boolean;
  items: IBookmarkItemState[];
  name?: string;
}

export interface IBookmarkContextState {
  loading: boolean;
  bookmarkGroups: IBookmarkGroupState[];
  count: number;
  initialized: boolean;
}

export type TBookmarkInput = {
  objectId: string;
  pageType: FdrBookmarkObjectType;
  groupId: string;
};
export type TAddBookmarkInput = Omit<TBookmarkInput, 'groupId'> & {
  groupId?: string;
};
export type TAddBookmarkToNewGroupInput = Omit<TBookmarkInput, 'groupId'> & {
  groupName: string;
};

export interface IBookmarkContext extends IBookmarkContextState {
  addBookmark(bookmark: TAddBookmarkInput): void;

  addBookmarkToNewGroup(
    bookmark: TAddBookmarkToNewGroupInput
  ): Promise<TBookmarkInput | undefined>;

  removeBookmark(bookmark: TBookmarkInput): void;
}

export enum BookmarkContextActionType {
  'ADD_BOOKMARK_ITEM',
  'REMOVE_BOOKMARK_ITEM',
  'SET_BOOKMARK_GROUPS',
  'SET_BOOKMARK_CONTEXT_LOADING',
  'SET_BOOKMARK_GROUPS_ITEMS',
  'SET_BOOKMARK_GROUPS_ITEMS_LOADING',
  'SET_BOOKMARK_COUNT',
  'INITIALIZE',
}

export type TBookmarkContextAction =
  | {
      type: BookmarkContextActionType.ADD_BOOKMARK_ITEM;
      payload: TBookmarkInput;
    }
  | {
      type: BookmarkContextActionType.REMOVE_BOOKMARK_ITEM;
      payload: TBookmarkInput;
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS;
      payload: {
        id: string;
        name: string;
      }[];
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_CONTEXT_LOADING;
      payload: boolean;
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS;
      payload: {
        id: string;
        items: { id: string; pageType: FdrBookmarkObjectType }[];
      };
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_GROUPS_ITEMS_LOADING;
      payload: { id: string; loading: boolean };
    }
  | {
      type: BookmarkContextActionType.SET_BOOKMARK_COUNT;
      payload: number;
    }
  | { type: BookmarkContextActionType.INITIALIZE };

export type TBookmarkItem =
  | FdrBookmarkListQuery['atBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['rtBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['hotelBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['geoBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['themeBookmarks']['bookmarks'][number];

export type TProductBookmark =
  | FdrBookmarkListQuery['atBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['rtBookmarks']['bookmarks'][number]
  | FdrBookmarkListQuery['hotelBookmarks']['bookmarks'][number];

export type TGroupData =
  | FdrBookmarkGroupDataQuery['atBookmarks']
  | FdrBookmarkGroupDataQuery['rtBookmarks']
  | FdrBookmarkGroupDataQuery['hotelBookmarks']
  | FdrBookmarkGroupDataQuery['themeBookmarks']
  | FdrBookmarkGroupDataQuery['geoBookmarks'];

export type TGroupBookmarks =
  | FdrBookmarkGroupDataQuery['atBookmarks']['bookmarks']
  | FdrBookmarkGroupDataQuery['rtBookmarks']['bookmarks']
  | FdrBookmarkGroupDataQuery['hotelBookmarks']['bookmarks']
  | FdrBookmarkGroupDataQuery['themeBookmarks']['bookmarks']
  | FdrBookmarkGroupDataQuery['geoBookmarks']['bookmarks'];
