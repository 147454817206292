import styled from 'styled-components';
import { MapPin } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ProductPinType } from 'components/domain/fdr-adventure-travel-routes';
import { pinContent } from 'components/domain/fdr-map';

export const FdrMapPinWrapper = styled(MapPin)<{
  productPinType?: ProductPinType;
  index: number;
}>(({ productPinType, index }) => {
  switch (productPinType) {
    default:
    case ProductPinType.PinIcon:
      return sx2CssThemeFn(pinContent);

    case ProductPinType.RouteIcon:
      return sx2CssThemeFn(pinContent, {
        zIndex: index === 0 ? 1 : 'auto',
        '.map-pin-wrapper:after': {
          display: 'none',
        },
      });
  }
});

export const FdrProductPinRouteIcon = styled.div<{ visible: boolean }>(
  ({ visible }) =>
    sx2CssThemeFn({
      display: visible ? 'block' : 'none',
      width: '19px',
      height: '19px',
      position: 'relative',
      textAlign: 'center',
      borderRadius: '50%',
      fontSize: '12px',
      fontWeight: '900',
      background: '#E4251B',
      color: 'white',
    })
);

export const FdrProductPinRouteInfoWindow = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 12px',
    border: '1px solid #707070',
    marginTop: '-29px',
    borderRadius: '3px',
    background: '#fff',
    color: '#000',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '10px',
  })
);
