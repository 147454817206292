import { useGoogleMap } from '@react-google-maps/api';
import { isEqual } from 'lodash';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import {
  mapCoordinatesToLatLng,
  MapPinView,
} from '@hotelplan/components.common.map-pin';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  FdrAdventureTravelRoutePoint,
  FdrImage,
} from '@hotelplan/supergraph-api';
import {
  FdrRouteContext,
  ProductPinType,
} from 'components/domain/fdr-adventure-travel-routes';
import useHover from 'components/util/hover/useHover';
import {
  FdrMapPinWrapper,
  FdrProductPinRouteIcon,
  FdrProductPinRouteInfoWindow,
} from './fdr-product-pins.styles';
import { indexToChar } from './fdr-product-pins.utils';

interface IFdrProductPinsProps {
  selectedItem: FdrImage | FdrAdventureTravelRoutePoint;
  mapSelectGroupHandler?(
    nextItem: FdrImage | FdrAdventureTravelRoutePoint
  ): void;
}

export function FdrProductPins({
  selectedItem,
  mapSelectGroupHandler,
}: IFdrProductPinsProps): ReactElement {
  const context = useContext(FdrRouteContext);

  const list = context.isRouteMode
    ? context.products.map(i => i.routePoints)
    : context.products.map(i => i.images);

  return (
    <>
      {list.map(
        (
          items: Array<FdrImage> | Array<FdrAdventureTravelRoutePoint>,
          key: number
        ) => {
          const isVisible =
            list.length === 1 ||
            context.active === key ||
            context.hovered === key ||
            (context.hasCurrent &&
              !context.hovered &&
              !context.active &&
              key === 0);

          return (
            <FdrProductMapPins
              key={key}
              items={items}
              visible={isVisible}
              selectItem={mapSelectGroupHandler}
              selectedItem={selectedItem}
              showDetails={context.isRouteMode && context.hovered === key}
            />
          );
        }
      )}
    </>
  );
}

interface IFdrProductMapPinsProps {
  visible: boolean;
  showDetails?: boolean;
  items: Array<FdrImage> | Array<FdrAdventureTravelRoutePoint>;
  selectedItem?: FdrImage | FdrAdventureTravelRoutePoint;
  selectItem?(group: FdrImage | FdrAdventureTravelRoutePoint): void;
}

function FdrProductMapPins({
  items,
  selectedItem,
  selectItem,
  visible,
  showDetails,
}: IFdrProductMapPinsProps): ReactElement {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map || !items || !items.length) return;

    if (items.length === 1) {
      const item = items[0];
      const defaultZoom = map.getZoom();

      if (item.coordinates) {
        map.setCenter(mapCoordinatesToLatLng(item.coordinates));
        map.setZoom(defaultZoom);

        return;
      }
    }

    const bounds = new google.maps.LatLngBounds();

    items.forEach((item: FdrImage | FdrAdventureTravelRoutePoint) => {
      if (item.coordinates) {
        bounds.extend(mapCoordinatesToLatLng(item.coordinates));
      }
    });

    requestAnimationFrame(() => {
      map.fitBounds(bounds, 30);
    });
  }, [map, items]);

  function onPinClick(item: FdrImage | FdrAdventureTravelRoutePoint) {
    selectItem && selectItem(item);
  }

  return (
    <>
      {items.map(
        (item: FdrImage | FdrAdventureTravelRoutePoint, index: number) => {
          return (
            <FdrProductPin
              key={`product-pin-${index}`}
              index={index}
              item={item}
              visible={visible}
              selected={!!selectedItem && isEqual(selectedItem, item)}
              onClick={onPinClick}
              showDetails={showDetails}
            />
          );
        }
      )}
    </>
  );
}

interface IFdrProductPinProps {
  selected: boolean;
  visible: boolean;
  item: FdrImage | FdrAdventureTravelRoutePoint;
  showDetails?: boolean;
  onClick(group: FdrImage | FdrAdventureTravelRoutePoint): void;
  index: number;
}

function FdrProductPin({
  selected,
  item,
  onClick,
  index,
  showDetails,
  visible,
}: IFdrProductPinProps): ReactElement {
  const map = useGoogleMap();
  const [showDetailedInfo, show, hide] = useToggleState(false);

  const { pinType } = useContext(FdrRouteContext);
  const ref = useRef<MapPinView>(null);
  const pinRef = useRef<HTMLDivElement>(null);

  useHover(pinRef, show, hide);

  useEffect(() => {
    if (!ref.current) return;

    google.maps.event.trigger(ref.current, selected ? 'select' : 'deselect');
  }, [selected]);

  useEffect(() => {
    if (!map || !selected) return;

    requestAnimationFrame(() => {
      if (item.coordinates) {
        map.panTo(mapCoordinatesToLatLng(item.coordinates));
      }
    });
  }, [map, selected]);

  if (!item.coordinates) return null;

  return (
    <FdrMapPinWrapper
      viewRef={ref}
      coordinates={item.coordinates}
      object={item}
      onClick={onClick}
      initialSelected={selected}
      productPinType={pinType}
      index={index}
    >
      {pinType === ProductPinType.PinIcon && (
        <BsIcon
          name={selected ? 'map-pin-active' : 'map-pin-inactive'}
          size="xlg"
        />
      )}
      {pinType === ProductPinType.RouteIcon && (
        <>
          <FdrProductPinRouteIcon ref={pinRef} visible={visible}>
            {indexToChar(index)}
          </FdrProductPinRouteIcon>
          <div style={{ position: 'relative' }}>
            {(showDetails || showDetailedInfo) && (
              <FdrProductPinRouteInfoWindow>
                {(item as FdrAdventureTravelRoutePoint).locationName}
              </FdrProductPinRouteInfoWindow>
            )}
          </div>
        </>
      )}
    </FdrMapPinWrapper>
  );
}
