import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { DescriptionGeoLinkFragmentDoc } from 'schemas/query/geo/fdr-geo-breadcrumbs.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
export type FdrGeoRecommenderItemFdrContinentFragment = {
  __typename?: 'FdrContinent';
  id: string;
  title: string;
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFdrCountryFragment = {
  __typename?: 'FdrCountry';
  id: string;
  title: string;
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFdrCountryGroupFragment = {
  __typename?: 'FdrCountryGroup';
  id: string;
  title: string;
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFdrDestinationFragment = {
  __typename?: 'FdrDestination';
  id: string;
  title: string;
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFdrRegionFragment = {
  __typename?: 'FdrRegion';
  id: string;
  title: string;
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFdrResortFragment = {
  __typename?: 'FdrResort';
  id: string;
  title: string;
  image?: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export type FdrGeoRecommenderItemFragment =
  | FdrGeoRecommenderItemFdrContinentFragment
  | FdrGeoRecommenderItemFdrCountryFragment
  | FdrGeoRecommenderItemFdrCountryGroupFragment
  | FdrGeoRecommenderItemFdrDestinationFragment
  | FdrGeoRecommenderItemFdrRegionFragment
  | FdrGeoRecommenderItemFdrResortFragment;

export const FdrGeoRecommenderItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrGeoRecommenderItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'title' },
            name: { kind: 'Name', value: 'name' },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCountryGroup' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRegion' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrContinent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCountry' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrDestination' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'image' },
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '364' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '315' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '468' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '388' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
