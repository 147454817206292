import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrMaintenanceNotificationWrapper = styled.div(
  ({ theme: { text } }) =>
    sx2CssThemeFn({
      width: '100%',
      maxWidth: '770px',
      mx: 'auto',
      '.maintenance-notification': {
        position: 'relative',
        marginTop: 4,
        borderRadius: '0px',
        bottom: 0,
        '.warning-notification-title': {
          marginBottom: '0px',
          mr: 3,
          ...text.groupHeading,
        },
      },
    })
);
