import React, { useContext, useRef, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import Scrollbar from 'react-scrollbars-custom';
import styled from 'styled-components';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import { colors as clrs } from 'theme';
import FdrGeoTripsListActionPoints from './fdr-geo-trips-list-action-points';
import FdrGeoTripsListProductTiles from './fdr-geo-trips-list-product-tiles';
import FdrGeoTripsListRelatedTiles from './fdr-geo-trips-list-related-tiles';
import FdrGeoTripsListReturnButton from './fdr-geo-trips-list-return-button';

const Trips = styled.div<{ b2c: boolean }>(
  sx2CssThemeFn(({ theme: { colors } }) => ({
    display: 'flex',
    gap: '15px',
    flexDirection: 'column',
    background: colors.paperGrey,
    gridRowStart: 1,
    gridRowEnd: ['1', `span 2`],
    overflow: 'hidden',
  }))
);

const FullScreenTrips = styled(Trips)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    zIndex: 9999,
    position: 'fixed',
    left: ['8px', '100px'],
    top: ['60px', '100px'],
    padding: ['10px 10px 0', '20px'],
    width: '374px',
    borderRadius: '5px',
  })
);

const ScrollableList = styled.div<{
  isFullScreen: boolean;
  isFullHeight: boolean;
}>(({ isFullScreen, isFullHeight }) => {
  const marginBottomMobile = isFullScreen ? '10px' : '20px';

  return sx2CssThemeFn({
    maxHeight: [
      isFullHeight ? 'auto' : '220px',
      isFullScreen ? '512px' : '100%',
    ],
    position: 'relative',
    marginBottom: [marginBottomMobile, 0],
    '.copyright': {
      display: ['none', 'block'],
      left: '10px',
    },
  });
});

const trackYProps = { style: { width: '5px', background: 'white' } };
const thumbYProps = {
  style: { width: '5px', background: clrs.lightGrey },
};

const FdrGeoTripsList: React.FC = () => {
  const [height, setHeight] = useState(0);
  const scrollContainer = useRef<HTMLDivElement>();
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const {
    isFullScreen,
    activeTrip,
    setActiveTrip,
    activeRoutePoint,
    clusterMarkers,
    activeProduct,
  } = useContext(FdrGeoTripsContext);

  useIsomorphicLayoutEffect(() => {
    if (!scrollContainer.current) return;
    setHeight(scrollContainer.current.scrollHeight);
  });

  const hideList =
    !activeRoutePoint && !clusterMarkers && !activeProduct && isFullScreen;
  const isB2b = channelType === AuthChannelType.B2B;
  const TripsVariant = isFullScreen ? FullScreenTrips : Trips;
  const showReturnButton = mobile && !!activeTrip;

  return (
    <>
      {!hideList && (
        <TripsVariant b2c={!isB2b} className="trips-list-wrapper">
          <FdrGeoTripsListActionPoints />
          {showReturnButton && (
            <FdrGeoTripsListReturnButton onClick={() => setActiveTrip(null)} />
          )}
          <ScrollableList
            style={{ height: `${height}px` }}
            isFullScreen={isFullScreen}
            isFullHeight={isFullScreen && showReturnButton}
          >
            <Scrollbar
              minimalThumbYSize={300}
              trackYProps={trackYProps}
              thumbYProps={thumbYProps}
              noScroll={isFullScreen && showReturnButton}
            >
              <div ref={scrollContainer}>
                <FdrGeoTripsListProductTiles />
                <FdrGeoTripsListRelatedTiles />
              </div>
            </Scrollbar>
          </ScrollableList>
        </TripsVariant>
      )}
    </>
  );
};

export default FdrGeoTripsList;
