import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import {
  BookmarkPopup,
  BookmarkPopupButtonRow,
  BookmarkPopupClose,
  BookmarkPopupContent,
  BookmarkPopupHeader,
  BookmarkPopupHeaderText,
  BookmarkPopupTextRow,
} from './fdr-bookmark-modal-popup.styles';

interface IBookmarkEditRemovePopupPops {
  onRemoveBookmark(): void;
  onCloseButtonClick(): void;
  onBackButtonClick(): void;
}

const FdrBookmarkEditRemovePopup: React.FC<IBookmarkEditRemovePopupPops> = ({
  onRemoveBookmark,
  onCloseButtonClick,
  onBackButtonClick,
}) => {
  const [t] = useTranslation(['common']);

  return (
    <BookmarkPopup>
      <BookmarkPopupHeader>
        <BookmarkPopupHeaderText>
          {t('bookmark.remove.title')}
        </BookmarkPopupHeaderText>
        <BookmarkPopupClose
          type="reset"
          variant="iconBtn"
          icon={{
            name: 'close-icon',
            size: 'lg',
          }}
          onClick={event => {
            event.preventDefault();
            onCloseButtonClick();
          }}
        >
          {t('common:close')}
        </BookmarkPopupClose>
      </BookmarkPopupHeader>
      <BookmarkPopupContent>
        <BookmarkPopupTextRow>{t('bookmark.remove.text')}</BookmarkPopupTextRow>
        <BookmarkPopupButtonRow>
          <BsButton variant="tagBtn" onClick={onBackButtonClick}>
            {t('bookmark.group.remove.cancel')}
          </BsButton>
          <BsButton className="form-submit-button" onClick={onRemoveBookmark}>
            {t('bookmark.group.remove.apply')}
          </BsButton>
        </BookmarkPopupButtonRow>
      </BookmarkPopupContent>
    </BookmarkPopup>
  );
};

export default FdrBookmarkEditRemovePopup;
