import styled from 'styled-components';
import { Container } from 'components/domain/container';

export const TextBlockContainer = styled(Container)(
  ({ theme }) => theme.text.richText,
  ({ theme: { media, space } }) => ({
    paddingTop: space[6],
    paddingBottom: space[6],
    overflowX: 'auto',
    [media.tablet]: {
      paddingTop: space[9],
      paddingBottom: space[9],
    },
  })
);
