import { useCallback, useEffect, useState } from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { FdrTextSearchAgenciesQuery } from 'schemas/query/search/fdr-text-search-agencies.generated';
import { FdrTextSearchQuery } from 'schemas/query/search/fdr-text-search.generated';
import { FDR_SEARCH_SECTION_KEYS } from './fdr-search.constants';
import { useFdrTextSearch } from './useFdrTextSearch';

const RESULTS_PER_PAGE = 30;
const initialState: ILocalState = {
  itemList: [],
  pagination: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  loading: true,
};

interface ILocalState {
  itemList: Array<
    | FdrTextSearchQuery['fdrTextSearch']['itemList'][number]
    | FdrTextSearchAgenciesQuery['fdrSearchAgencies']['agencies'][number]
    | undefined
  >;
  pagination: FdrPagination;
  loading?: boolean;
  hasMore?: boolean;
}

interface IFdrAllItemsSearch extends Omit<ILocalState, 'pagination'> {
  fetchMore(): void;
  total?: number;
}

export function useFdrAllItemsSearch(
  searchInput: string,
  sectionQuery: typeof FDR_SEARCH_SECTION_KEYS[number]
): IFdrAllItemsSearch {
  const [localData, setLocalData] = useState<ILocalState>(initialState);

  const {
    itemList,
    pagination: initialPagination,
    fetchMore,
    loading: initialLoading,
  } = useFdrTextSearch(searchInput, sectionQuery, {
    perPage: RESULTS_PER_PAGE,
    fetchPolicy: 'network-only',
    isAllItems: true,
  });

  const isAgenciesSection = sectionQuery === 'agencies';

  useEffect(() => {
    if (!initialLoading) {
      setLocalData(prev => ({
        ...prev,
        itemList,
        pagination: initialPagination,
        loading: false,
        hasMore:
          initialPagination?.page <
          Math.ceil(initialPagination?.total / initialPagination?.perPage) - 1,
      }));
    }
  }, [initialLoading]);

  const onFetchMore = useCallback(
    async function () {
      setLocalData(prev => ({
        ...prev,
        loading: true,
      }));

      // For some reason fetchMore doesn't trigger useFdrTextSearch onComplete callback
      // So localState used to handle that
      const fetchMoreResponse = await fetchMore(
        localData.pagination.page + 1,
        RESULTS_PER_PAGE
      );

      setLocalData(prev => {
        const newItems: Array<
          | FdrTextSearchQuery['fdrTextSearch']['itemList'][number]
          | FdrTextSearchAgenciesQuery['fdrSearchAgencies']['agencies'][number]
          | undefined
        > = isAgenciesSection
          ? (fetchMoreResponse?.data as FdrTextSearchAgenciesQuery)
              ?.fdrSearchAgencies.agencies || []
          : (fetchMoreResponse?.data as FdrTextSearchQuery)?.fdrTextSearch
              ?.itemList || [];

        const nextPagination = isAgenciesSection
          ? (fetchMoreResponse?.data as FdrTextSearchAgenciesQuery)
              ?.fdrSearchAgencies.pagination
          : (fetchMoreResponse?.data as FdrTextSearchQuery)?.fdrTextSearch
              ?.pagination;

        return {
          ...prev,
          itemList: [...prev.itemList, ...newItems],
          pagination: nextPagination,
          loading: false,
          hasMore:
            nextPagination.page <
            Math.ceil(nextPagination.total / nextPagination.perPage) - 1,
        };
      });
    },
    [isAgenciesSection, localData, fetchMore]
  );

  return {
    itemList: localData.itemList,
    hasMore: localData.hasMore,
    loading: localData.loading || !localData.itemList.length,
    fetchMore: onFetchMore,
    total: localData.pagination?.total,
  };
}
