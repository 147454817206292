import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBreadcrumbFragmentDoc } from 'schemas/fragment/breadcrumb/fdr-breadcrumb.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables =
  Types.Exact<{ [key: string]: never }>;

export type FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery = {
  __typename?: 'Query';
  fdrNewsletter?: {
    __typename?: 'FdrNewsletterPages';
    unsubscriptionConfirmation?: {
      __typename?: 'FdrNewsletterUnsubscriptionConfirmationPage';
      breadcrumbs?: Array<{
        __typename?: 'FdrPageBreadcrumb';
        caption: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }> | null;
    } | null;
  } | null;
};

export const FdrNewsletterUnsubscriptionConfirmationBreadcrumbsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'FdrNewsletterUnsubscriptionConfirmationBreadcrumbs',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrNewsletter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unsubscriptionConfirmation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'breadcrumbs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrBreadcrumb' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBreadcrumbFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery__
 *
 * To run a query within a React component, call `useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >(FdrNewsletterUnsubscriptionConfirmationBreadcrumbsDocument, options);
}
export function useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >(FdrNewsletterUnsubscriptionConfirmationBreadcrumbsDocument, options);
}
export type FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryHookResult =
  ReturnType<typeof useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery>;
export type FdrNewsletterUnsubscriptionConfirmationBreadcrumbsLazyQueryHookResult =
  ReturnType<
    typeof useFdrNewsletterUnsubscriptionConfirmationBreadcrumbsLazyQuery
  >;
export type FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryResult =
  Apollo.QueryResult<
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQuery,
    FdrNewsletterUnsubscriptionConfirmationBreadcrumbsQueryVariables
  >;
