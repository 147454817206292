import React, { FC, useMemo } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import {
  EMediaGalleryPlacement,
  FdrMediaAutoplayGallery,
  TMediaItem,
} from '@hotelplan/fdr.regular.fusion.fdr-media-autoplay-gallery';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { FdrAnyHeroContent } from 'components/domain/fdr-hero-section/fdr-any-hero-content';
import { FdrHeroGeoContent } from 'components/domain/fdr-hero-section/fdr-hero-geo-content';
import { FdrHeroHomeContent } from 'components/domain/fdr-hero-section/fdr-hero-home-content';
import { TFdrHeroMediaGalleryComponent } from 'components/domain/fdr-hero-section/fdr-hero-section-types';
import HeroOgTags from 'components/domain/fdr-layout/fdr-hero-og-tags';
import { renderResponsiveImg } from 'components/domain/fdr-page-components/fdr-page-components.utils';
import {
  fdrHeroImagesSpecs,
  fdrHomeHeroImagesSpecs,
} from 'components/util/criterias/hero.dmc';
import {
  mapImageCriteriaField,
  mapMediaItemsImageCriteriaField,
} from 'components/util/criterias/mapImageCriteriaField';
import { FdrHeroMediaContent } from './fdr-media-gallery.styles';

interface IFdrHeroPageComponentProps {
  component?: TFdrHeroMediaGalleryComponent;
  bookmarked?: boolean;
}

const switchHeroContent = (
  pageType: EPageType,
  component,
  bookmarked
): React.ReactNode => {
  switch (pageType) {
    case EPageType.Home: {
      return <FdrHeroHomeContent component={component} />;
    }
    case EPageType.GeoOverview: {
      return (
        <FdrHeroGeoContent component={component} bookmarked={bookmarked} />
      );
    }
    default: {
      return (
        <FdrAnyHeroContent component={component} bookmarked={bookmarked} />
      );
    }
  }
};

const FdrHeroMediaGalleryPageComponent: FC<IFdrHeroPageComponentProps> = ({
  component,
  bookmarked,
}) => {
  const pageType = usePageType<EPageType>();

  const isHome = pageType === EPageType.Home;

  const renderHeroContent = useMemo(
    () => switchHeroContent(pageType, component, bookmarked),
    [pageType, component, bookmarked]
  );

  const firstItem = component.media[0];
  const type = firstItem.__typename;
  const image = type === 'FdrVideo' ? firstItem.preview : firstItem;

  const imageSpecs = isHome ? fdrHomeHeroImagesSpecs : fdrHeroImagesSpecs;

  const mappedMedia = mapMediaItemsImageCriteriaField(
    component?.media as Array<TMediaItem>
  );

  return (
    <>
      <FdrMediaAutoplayGallery
        ratio={0}
        placement={EMediaGalleryPlacement.Hero}
        mediaItems={mappedMedia}
        className={isHome ? 'home-hero-section' : undefined}
        imageElement={renderResponsiveImg(`image-media`, imageSpecs)}
        previewImage={renderResponsiveImg(`preview-media`, imageSpecs)}
        loop
        muted
        fullScreen={isHome}
      />
      <FdrHeroMediaContent className={isHome ? 'is-home' : 'not-home'}>
        {image.__typename === 'FdrImage' ? (
          <HeroOgTags image={mapImageCriteriaField(image)} />
        ) : null}
        {renderHeroContent}
      </FdrHeroMediaContent>
    </>
  );
};

export default FdrHeroMediaGalleryPageComponent;
