import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const ProductRouteMapModalDesktop = styled(WhiteBoxModal)<{
  windowHeight: string;
  animateModal: boolean;
}>(
  sx2CssThemeFn({
    '.modal-wrapper': {
      maxHeight: '100vh',
    },
    '.modal-header': {
      px: 4,
      py: '10px',
      borderColor: 'lightGrey',
      '.modal-title': {
        fontSize: '22px',
        fontWeight: 800,
      },
      '.icon': {
        width: '24px',
        height: '24px',
      },
    },
    '.modal-body': {
      position: 'relative',
    },
  })
);

export const ProductRouteMapModalMobile = styled(WhiteBoxModal)<{
  windowHeight: string;
  animateModal: boolean;
}>(({ windowHeight, animateModal }) =>
  sx2CssThemeFn({
    '.modal-wrapper': {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      borderRadius: 0,
      maxHeight: windowHeight ? windowHeight : 'auto',
      transition: '0.5s ease',
      transform: `translateY(${animateModal ? 0 : windowHeight || 0})`,
    },
    '.modal-header': {
      position: 'relative',
      zIndex: 10,
      px: 4,
      py: '10px',
      borderColor: 'lightGrey',
      '.modal-title': {
        fontSize: '22px',
        fontWeight: 800,
      },
      '.icon': {
        width: '24px',
        height: '24px',
      },
    },
    '.modal-body': {
      height: '100%',
      position: 'relative',
    },
    '.trips-list-wrapper': {
      position: 'absolute',
      left: '8px',
      top: 3,
    },
    '.shadow-overlay': {
      transition: '0.5s ease',
      bg: animateModal ? 'modalShadow' : 'rgba(0, 0, 0, 0.0)',
    },
  })
);
