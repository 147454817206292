import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrAgencyFragment } from 'schemas/fragment/agency/fdr-agency.generated';
import { FdrContactDataFragment } from 'schemas/fragment/regular/fdrContactData.generated';
import { useGetFdrAgenciesQuery } from 'schemas/query/agencies/fdr-agencies.generated';
import { useFdrHeaderQuery } from 'schemas/query/header/fdr-header-contact-information.generated';

interface IFdrHelpOverlayContactInfo {
  isAgencyBoxVisible: boolean;
  agencyData: FdrAgencyFragment | null;
  contactInfo: FdrContactDataFragment | null;
}

export function useFdrHelpOverlayContactInfo(): IFdrHelpOverlayContactInfo {
  const { channelType } = useAuthentication();
  const { agency } = useRequestContextHeaders();

  const { data: fdrAgenciesData } = useGetFdrAgenciesQuery({
    skip: !agency,
    variables: {
      ids: agency,
    },
  });

  const agencyData = fdrAgenciesData?.fdrAgencies.agencies?.[0];

  const { data: fdrContactInfoData } = useFdrHeaderQuery({
    skip: !!agency && !!agencyData,
  });

  const isB2C = channelType !== AuthChannelType.B2B;

  return {
    isAgencyBoxVisible: isB2C && !!agency && !!agencyData,
    agencyData: agencyData || null,
    contactInfo: fdrContactInfoData?.fdrHeader.contactInformation || null,
  };
}
