import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

type TProps = React.PropsWithChildren<{
  visible: boolean;
  style?: CSSObject;
}>;

const InfoWindowContainer = styled.div(
  sx2CssThemeFn({
    position: 'relative',
  })
);

const InfoWindow = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 12px',
    border: '1px solid #707070',
    marginTop: '-29px',
    borderRadius: '3px',
    background: '#fff',
    color: '#000',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '10px',
  })
);

const InfoLabel: React.FC<TProps> = ({
  visible,
  children,
  style = {},
}: TProps) => {
  return (
    <InfoWindowContainer style={style}>
      {visible && <InfoWindow>{children}</InfoWindow>}
    </InfoWindowContainer>
  );
};

export default InfoLabel;
