import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { FdrBookmarkGroup } from '@hotelplan/supergraph-api';
import { useBookmarkContext } from 'components/domain/fdr-bookmark/context/fdr-bookmark-context';
import {
  TEditBookmarkModalPopup,
  useBookmarkModalPopupContext,
} from 'components/domain/fdr-bookmark/context/fdr-bookmark-modal-popup-context';
import {
  trackEditBookmarkCopy,
  trackEditBookmarkDelete,
  trackEditBookmarkMove,
} from 'components/domain/tracking/bookmarkTracking';
import { fdrBookmarkPageDefaultPagination } from 'components/page/bookmark/bookmark.vars';
import { useFdrBookmarkGroupListQuery } from 'schemas/query/bookmark/fdr-bookmark-group-list.generated';
import { useFdrCopyBookmarkMutation } from 'schemas/query/bookmark/fdr-copy-bookmark.generated';
import { useFdrMoveBookmarkMutation } from 'schemas/query/bookmark/fdr-move-bookmark.generated';
import FdrBookmarkEditDefaultPopup from './fdr-bookmark-edit-default-popup';
import {
  BookmarkEditModalActionType,
  bookmarkEditModalInitialState,
  bookmarkEditModalReducer,
  BookmarkEditModalView,
} from './fdr-bookmark-edit-modal.utils';
import FdrBookmarkEditRemovePopup from './fdr-bookmark-edit-remove-popup';
import FdrBookmarkEditTextPopup from './fdr-bookmark-edit-text-popup';

const FdrBookmarkEditModal: React.FC = () => {
  const [t, { language }] = useTranslation(['common']);
  const bookmarkModalContext = useBookmarkModalPopupContext();
  const modal = bookmarkModalContext.modal as TEditBookmarkModalPopup;
  const [bookmarkEditModalState, dispatch] = useReducer(
    bookmarkEditModalReducer,
    bookmarkEditModalInitialState
  );
  const {
    data: bookmarkGroupsData,
    loading: bookmarkGroupsLoading,
    refetch: refetchBookmarkGroups,
  } = useFdrBookmarkGroupListQuery({
    variables: {
      input: {
        pagination: fdrBookmarkPageDefaultPagination,
      },
    },
  });
  const [moveBookmark, { loading: moveBookmarkLoading }] =
    useFdrMoveBookmarkMutation();
  const [copyBookmark, { loading: copyBookmarkLoading }] =
    useFdrCopyBookmarkMutation();
  const [loading, setLoading] = useState(false);
  const bookmarkContext = useBookmarkContext();

  useEffect(() => {
    setLoading(moveBookmarkLoading || copyBookmarkLoading || loading);
  }, [moveBookmarkLoading, copyBookmarkLoading, bookmarkContext.loading]);

  const onMoveBookmark = useCallback(
    (bookmarkGroup: FdrBookmarkGroup) => {
      trackEditBookmarkMove(language);

      if (!loading) {
        moveBookmark({
          variables: {
            input: {
              bookmarkId: modal.payload.bookmarkId,
              bookmarkGroupIdFrom: modal.payload.groupId,
              bookmarkGroupIdTo: bookmarkGroup.id,
            },
          },
        }).then(response => {
          if (response?.data?.fdrMoveBookmark?.success) {
            dispatch({
              type: BookmarkEditModalActionType.SET_VIEW,
              payload: {
                view: BookmarkEditModalView.MOVE,
                bookmarkGroup,
              },
            });
            refetchBookmarkGroups();
          }
        });
      }
    },
    [modal.payload, loading]
  );

  const onCopyBookmark = useCallback(
    (bookmarkGroup: FdrBookmarkGroup) => {
      if (!loading) {
        trackEditBookmarkCopy(language);

        copyBookmark({
          variables: {
            input: {
              bookmarkId: modal.payload.bookmarkId,
              bookmarkGroupIdFrom: modal.payload.groupId,
              bookmarkGroupIdTo: bookmarkGroup.id,
            },
          },
        }).then(response => {
          if (response?.data?.fdrCopyBookmark.success) {
            dispatch({
              type: BookmarkEditModalActionType.SET_VIEW,
              payload: {
                view: BookmarkEditModalView.COPY,
                bookmarkGroup,
              },
            });
            refetchBookmarkGroups();
          }
        });
      }
    },
    [modal.payload, loading]
  );

  const onRemoveBookmark = useCallback(() => {
    if (!loading) {
      bookmarkContext.removeBookmark({
        objectId: modal.payload.objectId,
        pageType: modal.payload.pageType,
        groupId: modal.payload.groupId,
      });
      dispatch({
        type: BookmarkEditModalActionType.RESET,
      });
      bookmarkModalContext.close();
    }
  }, [modal.payload, loading]);

  const onCloseButtonClick = useCallback(() => {
    dispatch({ type: BookmarkEditModalActionType.RESET });
    bookmarkModalContext.close();
  }, []);

  const onRemoveButtonClick = useCallback(() => {
    trackEditBookmarkDelete(language);

    dispatch({
      type: BookmarkEditModalActionType.SET_VIEW,
      payload: {
        view: BookmarkEditModalView.REMOVE,
      },
    });
  }, []);

  const onBackButtonClick = useCallback(() => {
    dispatch({ type: BookmarkEditModalActionType.RESET });
  }, []);

  if (bookmarkGroupsLoading) {
    return null;
  }

  return (
    <>
      {bookmarkEditModalState.view === BookmarkEditModalView.DEFAULT && (
        <FdrBookmarkEditDefaultPopup
          bookmarkGroups={(
            bookmarkGroupsData?.fdrAllBookmarkGroups?.entries || []
          ).filter(i => {
            return i.id !== modal.payload.groupId;
          })}
          onMoveBookmark={onMoveBookmark}
          onCopyBookmark={onCopyBookmark}
          onRemoveButtonClick={onRemoveButtonClick}
          onCloseButtonClick={onCloseButtonClick}
        />
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.REMOVE && (
        <FdrBookmarkEditRemovePopup
          onRemoveBookmark={onRemoveBookmark}
          onCloseButtonClick={onCloseButtonClick}
          onBackButtonClick={onBackButtonClick}
        />
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.MOVE && (
        <FdrBookmarkEditTextPopup onCloseButtonClick={onCloseButtonClick}>
          {t('bookmark.move.text', {
            name: bookmarkEditModalState.bookmarkGroup.name,
          })}
        </FdrBookmarkEditTextPopup>
      )}
      {bookmarkEditModalState.view === BookmarkEditModalView.COPY && (
        <FdrBookmarkEditTextPopup onCloseButtonClick={onCloseButtonClick}>
          {t('bookmark.copy.text', {
            name: bookmarkEditModalState.bookmarkGroup.name,
          })}
        </FdrBookmarkEditTextPopup>
      )}
    </>
  );
};
export default FdrBookmarkEditModal;
