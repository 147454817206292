import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrLastSearchedQueryVariables = Types.Exact<{
  page: Types.Scalars['Int'];
}>;

export type FdrLastSearchedQuery = {
  __typename?: 'Query';
  fdrSearchHistory: {
    __typename?: 'FdrSearchHistoryResponse';
    entries?: Array<
      | { __typename?: 'FdrFlightSearchHistory' }
      | { __typename?: 'FdrProductSearchHistory' }
      | {
          __typename?: 'FdrTextSearchHistory';
          id: string;
          webMeta?: {
            __typename?: 'FdrSearchResultWebMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
          textSearchCriteria?: {
            __typename?: 'FdrHistoryTextSearchCriteria';
            text: string;
          } | null;
        }
    > | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export const FdrLastSearchedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrLastSearched' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrSearchHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'types' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'TEXT_SEARCH' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrTextSearchHistory' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'webMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'textSearchCriteria',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrLastSearchedQuery__
 *
 * To run a query within a React component, call `useFdrLastSearchedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrLastSearchedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrLastSearchedQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFdrLastSearchedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrLastSearchedQuery,
    FdrLastSearchedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrLastSearchedQuery,
    FdrLastSearchedQueryVariables
  >(FdrLastSearchedDocument, options);
}
export function useFdrLastSearchedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrLastSearchedQuery,
    FdrLastSearchedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrLastSearchedQuery,
    FdrLastSearchedQueryVariables
  >(FdrLastSearchedDocument, options);
}
export type FdrLastSearchedQueryHookResult = ReturnType<
  typeof useFdrLastSearchedQuery
>;
export type FdrLastSearchedLazyQueryHookResult = ReturnType<
  typeof useFdrLastSearchedLazyQuery
>;
export type FdrLastSearchedQueryResult = Apollo.QueryResult<
  FdrLastSearchedQuery,
  FdrLastSearchedQueryVariables
>;
