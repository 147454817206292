import React, { useState } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { SlidersNavigationContainer } from 'components/domain/container';
import {
  RecommendationListContainer,
  RecommendationSliderSection,
} from 'components/domain/recommendations/RecommendationSliderSection';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import { IFdrRecommenderProductComponent } from './fdr-product-recommender.types';

const DEFAULT_IMAGE_HEIGHT = 387;

interface ISectionProps {
  testId?: string;
  component: IFdrRecommenderProductComponent;
  id?: string;
  customContent?: React.ReactNode;
  sectionHeaderTitle?: string;
}

export const RecommendationListWithSlidesNavigationContainer = styled(
  SlidersNavigationContainer
)({
  paddingTop: '80px',
  paddingBottom: '80px',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  scrollMarginTop: '100px',
  '.count': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

const FdrProductRecommendationCommon: React.FC<
  React.PropsWithChildren<ISectionProps>
> = ({
  component,
  testId,
  children,
  id,
  sectionHeaderTitle,
  customContent,
}) => {
  const { mobile } = useDeviceType();
  const count = component?.items.length;
  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);
  const isSliderPagination = !mobile;

  if (!count) return null;
  const { backgroundColor, fontColor, name } = component;

  const RecommendationListContainerVariant = isSliderPagination
    ? RecommendationListWithSlidesNavigationContainer
    : RecommendationListContainer;

  return (
    <div style={{ color: fontColor, backgroundColor }} data-id={testId} id={id}>
      <RecommendationListContainerVariant>
        <RecommendationSliderSection
          title={name}
          count={count}
          titleColor={fontColor}
          customContent={customContent}
          imageHeight={imageHeight}
          isSliderPagination={isSliderPagination}
          trackingSource={PaginationArrowClickSource.PRODUCTS}
          sectionHeaderTitle={sectionHeaderTitle}
        >
          {typeof children === 'function' ? children(setImageHeight) : children}
        </RecommendationSliderSection>
      </RecommendationListContainerVariant>
    </div>
  );
};

export default FdrProductRecommendationCommon;
