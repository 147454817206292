import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import {
  FdrAutocompleteAtSearchItemFragmentDoc,
  FdrAutocompleteHotelSearchItemFragmentDoc,
  FdrAutocompleteRoundTripSearchItemFragmentDoc,
  FdrAutocompleteContinentSearchItemFragmentDoc,
  FdrAutocompleteCountryGroupSearchItemFragmentDoc,
  FdrAutocompleteCountrySearchItemFragmentDoc,
  FdrAutocompleteRegionSearchItemFragmentDoc,
  FdrAutocompleteThemeSearchItemFragmentDoc,
  FdrAutocompleteBlogArticleSearchItemFragmentDoc,
  FdrAutocompleteCatalogueSearchItemFragmentDoc,
  FdrAutocompleteAgencyOverviewSearchItemFragmentDoc,
  FdrAutocompleteNewsletterSearchItemFragmentDoc,
  FdrAutocompleteStaticPageSearchItemFragmentDoc,
} from 'schemas/fragment/search/fdr-autocomplete-search-items.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrTextSearchAutocompleteQueryVariables = Types.Exact<{
  userInput: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  searchType: Array<Types.FdrSearchType> | Types.FdrSearchType;
}>;

export type FdrTextSearchAutocompleteQuery = {
  __typename?: 'Query';
  fdrTextAutocomplete: {
    __typename?: 'FdrTextAutocompleteResponse';
    itemList?: Array<
      | {
          __typename?: 'FdrAdventureTravelSearchItem';
          debugInfo?: string | null;
          adventureTravel: {
            __typename?: 'FdrAdventureTravel';
            name: string;
            id: string;
            images?: Array<{
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }> | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrAgencyOverviewPageSearchItem';
          debugInfo?: string | null;
          agencyOverviewPage: {
            __typename?: 'FdrAgencyOverviewPage';
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | { __typename?: 'FdrAirportSearchItem' }
      | {
          __typename?: 'FdrBlogArticleSearchItem';
          debugInfo?: string | null;
          blogArticle: {
            __typename?: 'FdrBlogArticle';
            id: string;
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrCatalogPageSearchItem';
          debugInfo?: string | null;
          catalogPage: {
            __typename?: 'FdrCatalogPage';
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrContinentSearchItem';
          debugInfo?: string | null;
          continent: {
            __typename?: 'FdrContinent';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountryGroupSearchItem';
          debugInfo?: string | null;
          countryGroup: {
            __typename?: 'FdrCountryGroup';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountrySearchItem';
          debugInfo?: string | null;
          country: {
            __typename?: 'FdrCountry';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrCruiseSearchItem' }
      | { __typename?: 'FdrDestinationSearchItem' }
      | {
          __typename?: 'FdrHotelSearchItem';
          debugInfo?: string | null;
          hotel: {
            __typename?: 'FdrHotel';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrNewsletterSubscriptionPageSearchItem';
          debugInfo?: string | null;
          newsletterSubscriptionPage: {
            __typename?: 'FdrNewsletterSubscriptionPage';
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrRegionSearchItem';
          debugInfo?: string | null;
          region: {
            __typename?: 'FdrRegion';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrResortSearchItem' }
      | {
          __typename?: 'FdrRoundTripSearchItem';
          debugInfo?: string | null;
          roundTrip: {
            __typename?: 'FdrRoundTrip';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrStaticPageSearchItem';
          debugInfo?: string | null;
          staticPage: {
            __typename?: 'FdrStaticPage';
            name?: string | null;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          };
        }
      | {
          __typename?: 'FdrThemeSearchItem';
          debugInfo?: string | null;
          theme: {
            __typename?: 'FdrTheme';
            name: string;
            id: string;
            hero?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrThemeWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
    > | null;
  };
};

export const FdrTextSearchAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrTextSearchAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrSearchType' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrTextAutocomplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userInput' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchType' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteATSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteHotelSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteRoundTripSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteContinentSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCountryGroupSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCountrySearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteRegionSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteThemeSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteBlogArticleSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCatalogueSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteAgencyOverviewSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteNewsletterSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteStaticPageSearchItem',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAutocompleteAtSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteHotelSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRoundTripSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteContinentSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountryGroupSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountrySearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRegionSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteThemeSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteBlogArticleSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCatalogueSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteAgencyOverviewSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteNewsletterSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteStaticPageSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrTextSearchAutocompleteQuery__
 *
 * To run a query within a React component, call `useFdrTextSearchAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrTextSearchAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrTextSearchAutocompleteQuery({
 *   variables: {
 *      userInput: // value for 'userInput'
 *      page: // value for 'page'
 *      searchType: // value for 'searchType'
 *   },
 * });
 */
export function useFdrTextSearchAutocompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >(FdrTextSearchAutocompleteDocument, options);
}
export function useFdrTextSearchAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >(FdrTextSearchAutocompleteDocument, options);
}
export type FdrTextSearchAutocompleteQueryHookResult = ReturnType<
  typeof useFdrTextSearchAutocompleteQuery
>;
export type FdrTextSearchAutocompleteLazyQueryHookResult = ReturnType<
  typeof useFdrTextSearchAutocompleteLazyQuery
>;
export type FdrTextSearchAutocompleteQueryResult = Apollo.QueryResult<
  FdrTextSearchAutocompleteQuery,
  FdrTextSearchAutocompleteQueryVariables
>;
