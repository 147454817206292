import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrImage } from '@hotelplan/supergraph-api';
import {
  FdrInfoCardContentWrapper,
  FdrInfoCardImage,
} from './fdr-help-overlay.styles';

interface IFdrInfoCardProps {
  className?: string;
  infoCardImage?: FdrImage;
}

export function FdrInfoCard({
  infoCardImage,
  className,
  children,
}: PropsWithChildren<IFdrInfoCardProps>): ReactElement {
  return (
    <FdrInfoCardContentWrapper className={className}>
      {infoCardImage ? (
        <div className="info-card-icon">
          <FdrInfoCardImage lazy={false} image={infoCardImage} />
        </div>
      ) : null}
      {children}
    </FdrInfoCardContentWrapper>
  );
}
