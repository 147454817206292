import { Fragment, ReactElement } from 'react';
import {
  FdrBreadcrumbLabel,
  FdrBreadcrumbLink,
  FdrBreadcrumbsList,
} from './fdr-breadcrumbs.styles';
import { TFdrBreadcrumb } from './fdr-breadcrumbs.types';

interface IFdrBreadcrumbsProps {
  breadcrumbs: Array<TFdrBreadcrumb>;
  delimiter?: string;
  className?: string;
}

export function FdrBreadcrumbs({
  breadcrumbs,
  delimiter = '/',
  className,
}: IFdrBreadcrumbsProps): ReactElement {
  return (
    <FdrBreadcrumbsList
      data-id="fdr-breadcrumbs-list"
      className={`breadcrumb-list ${className || ''}`}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        if (!breadcrumb) return null;

        return (
          <Fragment key={index}>
            {breadcrumb.disabled || !breadcrumb.link ? (
              <FdrBreadcrumbLabel
                data-id="fdr-breadcrumb-label"
                className="breadcrumb-label"
                disabled={breadcrumb.disabled}
                onClick={breadcrumb.onClick}
              >
                {breadcrumb.caption}
              </FdrBreadcrumbLabel>
            ) : (
              <FdrBreadcrumbLink
                data-id="fdr-breadcrumb-link"
                className="breadcrumb-link"
                delimiter={delimiter}
                link={breadcrumb.link}
                onClick={breadcrumb.onClick}
              >
                {breadcrumb.caption}
              </FdrBreadcrumbLink>
            )}
          </Fragment>
        );
      })}
    </FdrBreadcrumbsList>
  );
}
