import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { FdrScalableImage } from 'components/domain/fdr-scalable-image';
import type { TFdrTeaserCfg } from './fdr-teaser-card.types';

const TeaserCardWrapper = styled.div(
  {
    position: 'relative',
    '.media-card-content': {
      position: 'absolute',
      textAlign: 'left',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column-reverse',
      '.card-subtitle': { display: 'inline' },
      '.chevron-icon': { transform: 'rotate(180deg)' },
    },
  },
  ({ theme }) => theme.card.teaserCard || {}
);

type TFdrTeaserCardProps = React.PropsWithChildren<
  {
    img?: React.ReactNode;
    cfg?: TFdrTeaserCfg;
  } & HTMLAttributes<HTMLDivElement>
>;

export function FdrTeaserCard(props: TFdrTeaserCardProps): React.ReactElement {
  const { img, cfg = {}, children, ...divProps } = props;

  const titleElement = cfg.title ? (
    <>
      <span className="card-image-title">{cfg.title}</span>
      <Icon name="chevron-long-left" className="chevron-icon" />
    </>
  ) : null;

  return (
    <TeaserCardWrapper {...divProps}>
      <FdrScalableImage
        img={img}
        className="teaser-media"
        cfg={{ ...cfg, title: titleElement }}
      >
        {children}
      </FdrScalableImage>
    </TeaserCardWrapper>
  );
}
