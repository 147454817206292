import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { DescriptionGeoLinkFragmentDoc } from 'schemas/query/geo/fdr-geo-breadcrumbs.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrLastViewedQueryVariables = Types.Exact<{
  page: Types.Scalars['Int'];
}>;

export type FdrLastViewedQuery = {
  __typename?: 'Query';
  fdrVisitHistory: {
    __typename?: 'FdrVisitHistoryResponse';
    entries?: Array<{
      __typename?: 'FdrVisitHistory';
      id: string;
      object:
        | {
            __typename?: 'FdrHistoryVisitedAgency';
            agency?: {
              __typename?: 'FdrAgency';
              id: string;
              name: string;
              webMeta: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
            } | null;
          }
        | {
            __typename?: 'FdrHistoryVisitedGeo';
            geo?:
              | {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrRegion';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrResort';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | null;
          }
        | {
            __typename?: 'FdrHistoryVisitedProduct';
            product?:
              | {
                  __typename?: 'FdrAdventureTravel';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | { __typename?: 'FdrCruise'; name: string; id: string }
              | {
                  __typename?: 'FdrHotel';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | {
                  __typename?: 'FdrRoundTrip';
                  name: string;
                  id: string;
                  webMeta: {
                    __typename?: 'FdrProductWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                }
              | null;
          }
        | {
            __typename?: 'FdrHistoryVisitedStaticPage';
            staticPage?: {
              __typename?: 'FdrStaticPage';
              name: string;
              webMeta: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
            } | null;
          }
        | {
            __typename?: 'FdrHistoryVisitedTheme';
            theme?: {
              __typename?: 'FdrTheme';
              name: string;
              id: string;
              webMeta: {
                __typename?: 'FdrThemeWebMeta';
                descriptionWebMeta?: {
                  __typename?: 'FdrMeta';
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            } | null;
          };
    }> | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export type FdrHistoryViewedProductFragment = {
  __typename?: 'FdrHistoryVisitedProduct';
  product?:
    | {
        __typename?: 'FdrAdventureTravel';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | { __typename?: 'FdrCruise'; name: string; id: string }
    | {
        __typename?: 'FdrHotel';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrRoundTrip';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | null;
};

export type FdrHistoryViewedGeoFragment = {
  __typename?: 'FdrHistoryVisitedGeo';
  geo?:
    | {
        __typename?: 'FdrContinent';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrCountry';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrCountryGroup';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrDestination';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrRegion';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrResort';
        name: string;
        id: string;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | null;
};

export type FdrHistoryVisitedThemeFragment = {
  __typename?: 'FdrHistoryVisitedTheme';
  theme?: {
    __typename?: 'FdrTheme';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrThemeWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  } | null;
};

export type FdrHistoryVisitedStaticFragment = {
  __typename?: 'FdrHistoryVisitedStaticPage';
  staticPage?: {
    __typename?: 'FdrStaticPage';
    name: string;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export type FdrHistoryVisitedAgencyFragment = {
  __typename?: 'FdrHistoryVisitedAgency';
  agency?: {
    __typename?: 'FdrAgency';
    id: string;
    name: string;
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export type FdrHistoryGeoLinkFdrContinentFragment = {
  __typename?: 'FdrContinent';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFdrCountryFragment = {
  __typename?: 'FdrCountry';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFdrCountryGroupFragment = {
  __typename?: 'FdrCountryGroup';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFdrDestinationFragment = {
  __typename?: 'FdrDestination';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFdrRegionFragment = {
  __typename?: 'FdrRegion';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFdrResortFragment = {
  __typename?: 'FdrResort';
  webMeta: {
    __typename?: 'FdrGeoWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryGeoLinkFragment =
  | FdrHistoryGeoLinkFdrContinentFragment
  | FdrHistoryGeoLinkFdrCountryFragment
  | FdrHistoryGeoLinkFdrCountryGroupFragment
  | FdrHistoryGeoLinkFdrDestinationFragment
  | FdrHistoryGeoLinkFdrRegionFragment
  | FdrHistoryGeoLinkFdrResortFragment;

export type FdrHistoryProductLinkFdrAdventureTravelFragment = {
  __typename?: 'FdrAdventureTravel';
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryProductLinkFdrCruiseFragment = {
  __typename?: 'FdrCruise';
};

export type FdrHistoryProductLinkFdrHotelFragment = {
  __typename?: 'FdrHotel';
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryProductLinkFdrRoundTripFragment = {
  __typename?: 'FdrRoundTrip';
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    descriptionWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrHistoryProductLinkFragment =
  | FdrHistoryProductLinkFdrAdventureTravelFragment
  | FdrHistoryProductLinkFdrCruiseFragment
  | FdrHistoryProductLinkFdrHotelFragment
  | FdrHistoryProductLinkFdrRoundTripFragment;

export type FdrDescriptionLinkFragment = {
  __typename?: 'FdrProductWebMeta';
  descriptionWebMeta?: {
    __typename?: 'FdrMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
};

export const FdrDescriptionLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrDescriptionLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductWebMeta' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryProductLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryProductLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrHotel' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAdventureTravel' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRoundTrip' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDescriptionLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrDescriptionLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryViewedProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryViewedProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHistoryProductLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHistoryProductLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryGeoLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryGeoLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrResort' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRegion' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCountryGroup' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrDestination' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCountry' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrContinent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryViewedGeoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryViewedGeo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedGeo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHistoryGeoLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHistoryGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryVisitedThemeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryVisitedTheme' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedTheme' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionWebMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryVisitedStaticFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryVisitedStatic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedStaticPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staticPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'name' },
                  name: { kind: 'Name', value: 'slug' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryVisitedAgencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryVisitedAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedAgency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrLastViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrLastViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrVisitHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'object' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrHistoryViewedProduct',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrHistoryViewedGeo',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrHistoryVisitedTheme',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrHistoryVisitedStatic',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrHistoryVisitedAgency',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHistoryViewedProductFragmentDoc.definitions,
    ...FdrHistoryViewedGeoFragmentDoc.definitions,
    ...FdrHistoryVisitedThemeFragmentDoc.definitions,
    ...FdrHistoryVisitedStaticFragmentDoc.definitions,
    ...FdrHistoryVisitedAgencyFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrLastViewedQuery__
 *
 * To run a query within a React component, call `useFdrLastViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrLastViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrLastViewedQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFdrLastViewedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrLastViewedQuery,
    FdrLastViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrLastViewedQuery,
    FdrLastViewedQueryVariables
  >(FdrLastViewedDocument, options);
}
export function useFdrLastViewedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrLastViewedQuery,
    FdrLastViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrLastViewedQuery,
    FdrLastViewedQueryVariables
  >(FdrLastViewedDocument, options);
}
export type FdrLastViewedQueryHookResult = ReturnType<
  typeof useFdrLastViewedQuery
>;
export type FdrLastViewedLazyQueryHookResult = ReturnType<
  typeof useFdrLastViewedLazyQuery
>;
export type FdrLastViewedQueryResult = Apollo.QueryResult<
  FdrLastViewedQuery,
  FdrLastViewedQueryVariables
>;
