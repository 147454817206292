import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrProductInfoB2bFragment = {
  __typename?: 'ProductInfoB2B';
  route?: string | null;
  duration: string;
  priceFrom?: string | null;
  typeFlags?: Array<string> | null;
};

export const FdrProductInfoB2bFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductInfoB2b' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductInfoB2B' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'route' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeFlags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
