import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { FdrLink } from '@hotelplan/supergraph-api';
import { FdrMapCardInfo } from 'components/domain/fdr-map';
import { TResultProductWithRoute } from './fdr-routes.types';

type TLinkBoxProps = {
  link?: FdrLink;
};

function LinkBox({
  children,
  link,
}: PropsWithChildren<TLinkBoxProps>): ReactElement {
  if (link && link?.url)
    return (
      <BsLink href={link.url} target="_blank" className="infoWindow-link-box">
        {children}
      </BsLink>
    );

  return <div>{children}</div>;
}

interface IFdrCustomInfoWindowProps {
  selected: TResultProductWithRoute;
}

export const FdrCustomInfoWindow: React.FC<IFdrCustomInfoWindowProps> = ({
  selected,
}) => {
  const [t] = useTranslation('common');
  const image = selected.images[0] || selected?.image;

  return (
    <LinkBox link={selected.link}>
      <FdrMapCardInfo
        link={selected.link}
        image={image}
        title={selected.name}
        iconName="route-adventuretravel-icon"
        iconDescription={t('continueTrip')}
      />
    </LinkBox>
  );
};
