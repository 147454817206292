import Cookies from 'js-cookie';
import { B2B_MARKUP_KEY } from '@hotelplan/fdr.lib.context.request-util';
import { getTomorrowsDate } from '@hotelplan/libs.utils';

export function setB2BMarkupCookie(markup: number): void {
  if (typeof window !== 'undefined') {
    Cookies.set(B2B_MARKUP_KEY, markup.toString(), {
      path: '/',
      expires: getTomorrowsDate(),
    });
  }
}
