import { FdrImage } from '@hotelplan/supergraph-api';
import { FdrMarketingItemFragment } from 'schemas/fragment/regular/fdrMarketingRecommender.generated';

export function createFullScreenFdrImage(
  media: FdrMarketingItemFragment['media']
): Partial<FdrImage> {
  if (media.__typename === 'FdrImage') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { resizedPreview, resizedImage, ...img } = media;
    return { ...img, resized: resizedImage };
  }

  return media.image;
}
export function getResourceLink(
  media: FdrMarketingItemFragment['media']
): string {
  if (media.__typename === 'FdrImage') {
    return null;
  }

  return media.resourceLink.url;
}
