import findIndex from 'lodash/findIndex';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  BsRsvGallery,
  IGalleryApi,
  OverlaySlideInfo,
  SlideInfo,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import {
  FdrCustomMapControl,
  FdrMapControlPosition,
  FdrMapProvider,
} from '@hotelplan/fdr.regular.fusion.fdr-map';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import {
  FdrAdventureTravelRoutePoint,
  FdrCoordinates,
  FdrImage,
} from '@hotelplan/supergraph-api';
import {
  FdrRoutePolylines,
  FdrRoutes,
  ProductPinType,
} from 'components/domain/fdr-adventure-travel-routes';
import {
  createFullScreenControl,
  productFdrMapOptions,
  useFullScreenControl,
} from 'components/domain/fdr-map';
import { mapFdrMediaToImageMediaItems } from 'components/domain/fdr-pdp-media-gallery';
import { FdrProductPins } from 'components/domain/fdr-product-pins';
import { trackMediaGalleryFullScreenClick } from 'components/domain/tracking/mediaGalleryTracking';
import {
  MapClickEventTargetType,
  trackMapClick,
} from 'components/domain/tracking/pdpTracking';
import { ClickEventActionType } from 'components/domain/tracking/tracking.types';
import { renderGalleryWithMap } from 'components/util/criterias';
import { FlaglineText } from 'theme/headings';
import { FdrGalleryWithMapSkeleton } from './fdr-gallery-with-map.skeleton';
import {
  FdrGalleryMapContent,
  FdrGalleryMapWrapper,
  FdrGalleryWithMapContent,
  FdrGalleryWithMapWrapper,
  FdrMediaGalleryWrapper,
} from './fdr-gallery-with-map.styles';
import { createDefaultMapPinControl } from './fdr-gallery-with-map.utils';

const PDP_GALLERY_HASH = '#gallery';
const PRODUCT_GALLERY_MAP_ZOOM = 12;

type TRoute = {
  locationName: string;
  coordinates: FdrCoordinates;
};

interface IFdrGalleryWithMapProps {
  name: string;
  routes: Array<TRoute>;
  images: Array<FdrImage>;
  loading?: boolean;
  defaultPinType?: ProductPinType;
}

export function FdrGalleryWithMap({
  name,
  routes,
  images,
  loading,
  defaultPinType,
}: IFdrGalleryWithMapProps): ReactElement {
  const [t] = useTranslation('common');
  const pageType = usePageType<EPageType>();

  const galleryApi = useRef<IGalleryApi>(null);
  const mapRef = useRef<google.maps.Map>(null);
  const mapWrapperRef = useRef<HTMLDivElement>(null);

  const { isFullScreen, setIsFullScreen } = useFullScreenControl(mapWrapperRef);
  const mapSize = useElementSize(mapWrapperRef, { enable: true });

  const [defaultZoom, setDefaultZoom] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<
    FdrImage | FdrAdventureTravelRoutePoint
  >(images?.[0]);
  const [pinType, setPinType] = useState(
    defaultPinType ?? ProductPinType.PinIcon
  );

  useEffect(() => {
    if (!isFullScreen || !isPDP) return;
    trackMapClick(
      ClickEventActionType.ENLARGE,
      pinType === ProductPinType.PinIcon
        ? MapClickEventTargetType.FULL_SCREEN_MAP_IMAGE_VIEW
        : MapClickEventTargetType.FULL_SCREEN_MAP_ROUTING_VIEW
    );
  }, [isFullScreen]);

  useIsomorphicLayoutEffect(() => {
    if (!loading) {
      setDefaultZoom(mapRef.current?.getZoom());
    }
  }, [loading, isFullScreen]);

  const mediaItems = mapFdrMediaToImageMediaItems(images);
  const isPDP = pageType === EPageType.Pdp;
  const isRouteMode = pinType === ProductPinType.RouteIcon;
  const overlayStatus =
    typeof window !== 'undefined' && window.location.hash === PDP_GALLERY_HASH;

  function mapSelectGroupHandler(
    nextItem: FdrImage | FdrAdventureTravelRoutePoint
  ) {
    setSelectedItem(nextItem);

    if (!isRouteMode) {
      galleryApi.current?.setSlide(
        findIndex(mediaItems, function (item) {
          return item.coordinates === nextItem.coordinates;
        })
      );
    }
  }

  function handleGallerySetItem<T extends { coordinates?: FdrCoordinates }>(
    nextItem: T
  ) {
    if (!isRouteMode) {
      setSelectedItem(images.find(i => i.coordinates === nextItem.coordinates));
    }
  }

  return (
    <FdrGalleryWithMapWrapper>
      <FlaglineText className="gallery-with-map-title">
        {t('galleryWithMap.title')}
      </FlaglineText>
      <FdrGalleryWithMapContent>
        {loading && !images?.length ? (
          <FdrGalleryWithMapSkeleton />
        ) : (
          <>
            <FdrGalleryMapWrapper ref={mapWrapperRef}>
              <FdrGalleryMapContent>
                <FdrMapProvider
                  mapRef={mapRef}
                  zoom={PRODUCT_GALLERY_MAP_ZOOM}
                  options={productFdrMapOptions}
                  styles={{ width: `${mapSize.w}px`, height: `${mapSize.h}px` }}
                >
                  <FdrRoutes
                    showNearBy={false}
                    pinType={pinType}
                    currentProduct={{
                      name,
                      images,
                      routePoints: routes,
                    }}
                  >
                    <FdrProductPins
                      selectedItem={selectedItem}
                      mapSelectGroupHandler={mapSelectGroupHandler}
                    />
                    <FdrRoutePolylines defaultZoom={defaultZoom} />
                  </FdrRoutes>
                  {routes?.length && !isFullScreen && (
                    <FdrCustomMapControl
                      onCreateControl={() => {
                        return createDefaultMapPinControl({
                          pinType,
                          onChange: setPinType,
                          captionGallery: t(
                            'galleryWithMap.iconCaption.gallery'
                          ),
                          captionRouting: t(
                            'galleryWithMap.iconCaption.routing'
                          ),
                          isPdp: isPDP,
                        });
                      }}
                      position={FdrMapControlPosition.TOP_LEFT}
                    />
                  )}
                  <FdrCustomMapControl
                    onCreateControl={createFullScreenControl({
                      onChange: setIsFullScreen,
                    })}
                    position={FdrMapControlPosition.RIGHT_TOP}
                  />
                </FdrMapProvider>
              </FdrGalleryMapContent>
            </FdrGalleryMapWrapper>
            <FdrMediaGalleryWrapper>
              <BsRsvGallery
                renderSlide={renderGalleryWithMap}
                galleryApi={galleryApi}
                items={mediaItems}
                setCurrentItem={handleGallerySetItem}
                onChangeSlide={item => handleGallerySetItem(item)}
                initialOverlayStatus={overlayStatus}
                className="highlighted-media-gallery"
                overlaySlideInfo={(
                  item,
                  slideIndex,
                  count,
                  isHideNavigation
                ) => (
                  <OverlaySlideInfo
                    item={item}
                    slide={slideIndex + 1}
                    count={count}
                    copyrightLabel={t('copyright.photo')}
                    isHideNavigation={isHideNavigation}
                  />
                )}
                slideInfo={(item, slideIndex, count, isHideNavigation) => (
                  <SlideInfo
                    item={item}
                    slide={slideIndex + 1}
                    count={count}
                    copyrightLabel={t('copyright.photo')}
                    isHideNavigation={isHideNavigation}
                  />
                )}
                afterOverlayOpen={isPDP && trackMediaGalleryFullScreenClick}
              />
            </FdrMediaGalleryWrapper>
          </>
        )}
      </FdrGalleryWithMapContent>
    </FdrGalleryWithMapWrapper>
  );
}
