import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FONT_FAMILY } from 'theme';

export const FdrMobileMenuNavWrapper = styled.div(
  sx2CssThemeFn({
    bg: 'lightGreySecond',
    width: '100%',
    position: 'absolute',
    bottom: '50px',
    left: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '8px 8px 0 0',
  })
);

export const FdrMobileMenuContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    pt: 2,
    pb: 3,
    px: 2,
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flex: '1',
    color: 'black',
    '.image-text': {
      mb: 2,
      fontWeight: 'normal',
      fontSize: 2,
      textAlign: 'start',
    },
  })
);

export const FdrMobileMenuItemWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    height: '42px',
    fontSize: 1,
    fontWeight: '500',
    textAlign: 'left',
    bg: 'paperGrey',
    borderRadius: '5px',
    marginBottom: '4px',
    '&.stand-alone': { marginTop: '12px' },
    '.bottom-menu-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      img: {
        width: '50px',
        height: '50px',
        marginTop: '-5px',
      },
    },
    '.icon-label': {
      fontSize: '16px',
      color: 'black',
      fontWeight: '500',
      lineHeight: '22px',
    },
    'a, .logoutSiteTool': {
      width: '100%',
      height: '42px',
      padding: '10px 12px',
    },
    '.wishlistIconCounter': {
      display: 'flex',
      '.bs-button-with-icon': {
        width: '100%',
        maxHeight: '20px',
        mr: '16px',
      },
      '.counter': {
        right: 'auto',
        left: '12px',
      },
      '.mobile-tooltip': {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        color: 'black',
        fontFamily: FONT_FAMILY,
        fontWeight: 'normal',
      },
    },
  })
);
