import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { openFdrSearchOverlay } from 'components/domain/fdr-search-overlay';
import { TFdrHeroMediaGalleryComponent } from './fdr-hero-section-types';
import {
  FdrHeroTextBlockHome,
  FdrSearchInput,
} from './fdr-hero-section.styles';

interface IFdrHeroHomeContentProps {
  component: TFdrHeroMediaGalleryComponent;
}

export const FdrHeroHomeContent: React.FC<IFdrHeroHomeContentProps> = ({
  component,
}) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('search');

  const { title, titleColor } = component;

  return (
    <>
      <FdrHeroTextBlockHome title={title} text="" color={titleColor} />
      <FdrSearchInput
        hideLabel
        placeholder={t('search:searchInput.placeholder')}
        icon={{
          name: `search`,
          size: mobile ? 'sm' : 'md',
        }}
        onClick={openFdrSearchOverlay}
      />
    </>
  );
};
