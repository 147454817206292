import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsCopyright } from '@hotelplan/core.basis.bs-copyright';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrImage as FdrImageAPI } from '@hotelplan/supergraph-api';

interface IFdrImgWithCopyrightProps {
  image: FdrImageAPI;
  className?: string;
  noSet?: boolean;
}

const FdrImgWithCopyright: React.FC<IFdrImgWithCopyrightProps> = ({
  image,
  className,
  noSet = false,
}) => {
  const [t] = useTranslation(['common']);

  return (
    <FdrImage image={image} className={className} noSet={noSet}>
      {image.copyright && (
        <BsCopyright>
          {t('copyright.photo')} {image.copyright}
        </BsCopyright>
      )}
    </FdrImage>
  );
};

export default FdrImgWithCopyright;
