import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { FdrInfoCard } from 'components/candidate/fdr-help-overlay';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

export const FdrHelpOverlayBackground = styled.div<{
  headerHeight: number;
}>(({ theme: { colors, shadows, media }, headerHeight }) => ({
  backgroundColor: colors.redVivid,
  width: '100%',
  display: 'flex',
  [media.desktop]: {
    maxHeight: '90vh',
    overflow: 'auto',
  },
  [media.mobile]: {
    paddingBottom: '10vh',
  },
  [media.tablet]: {
    position: 'absolute',
    left: '0px',
    top: `calc(16px + ${headerHeight}px)`,
    boxShadow: shadows.cardShadow,
    zIndex: 11,
  },
}));

export const FdrHelpOverlayWrapper = styled(Container)(
  sx2CssThemeFn({
    display: 'flex',
    pt: [3, 9],
    pb: [0, 9],
    px: [3, 4],
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'stretch'],
    justifyContent: 'center',
    width: '100%',
    flex: ['1', 'auto'],
    color: 'black',
    overflowY: [`scroll`, 'auto'],
    '-webkit-overflow-scrolling': `touch`,

    '.image-text': {
      mb: [2, 3],
      fontWeight: 'normal',
      fontSize: 2,
      textAlign: 'center',
    },
  })
);

export const FdrHelpOverlayContent = styled.div(
  sx2CssThemeFn({
    textAlign: 'center',
    minHeight: [null, '210px'],
    '.image-title': {
      mb: 4,
    },
    '.image-tagline': {
      fontSize: '15px',
      mb: [2, 5],
    },
    '.cut-image-text': cutTextByLinesCount(5),
  })
);

export const FdrBoxLink = styled(BsLink)(({ theme }) => theme.link.uiPrimary);

export const PhoneLink = styled(BsLink)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const FdrPhoneLinkWrapper = styled.div(
  sx2CssThemeFn({
    a: {
      paddingTop: 0,
      marginBottom: 4,
      position: 'relative',
    },
  })
);

export const FdrInfoCardWrapper = styled(FdrInfoCard)(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'roseFine',
    borderRadius: '0px',
    border: 'none',
    px: [3, '20px'],
    py: [4, '20px'],
    mb: [3, 0],
    '.info-card-icon img': {
      width: '48px',
      height: '48px',
      display: 'block',
      margin: 'auto',
    },
    '.info-card-icon': {
      textAlign: 'center',
      display: 'block',
      mb: [4, 6],
    },
    button: {
      mt: [0, 3],
      mb: [4, '60px'],
      px: 3,
      fontSize: 3,
    },
    '.help-overlay-content-wrap': {
      borderBottom: '1px solid',
      borderColor: '#B1B1B1',
      minHeight: [null, '340px'],
      textAlign: 'center',
      '.image-tagline': {
        mb: [2, 4],
      },
    },
    '.schedule': {
      paddingLeft: 5,
      fontSize: 2,
      p: {
        fontSize: 2,
        fontWeight: 'normal',
        color: '#000',
        mb: 2,
      },
    },
    a: {
      paddingTop: 4,
      justifyContent: ['center', 'flex-start'],
    },
  })
);

export const FdrAgencyAddress = styled(BsLink)(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '.address': {
      fontSize: 2,
    },
  })
);

export const FdrHelpOverlayMobileWrapper = styled(WhiteBoxModal)(
  sx2CssThemeFn({
    '.modal-header': {
      backgroundColor: 'redVivid',
      borderBottom: 'none',
      color: 'white',
      '.icon': {
        width: '30px',
        height: '30px',
      },
    },
    '.modal-wrapper': {
      maxHeight: '100%',
    },
  })
);
