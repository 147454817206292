import React, { createContext, FC, useContext } from 'react';

const GclIdContext = createContext<string>(null);

export const useFdrGclIdValue = (): string => {
  return useContext(GclIdContext);
};

export const FdrGclIdProvider: FC<{ gclidValue: string }> = ({
  children,
  gclidValue,
}) => {
  return (
    <GclIdContext.Provider value={gclidValue}>{children}</GclIdContext.Provider>
  );
};
