import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrBookmarkBreadcrumbsDocument,
  FdrBookmarkBreadcrumbsQuery,
  FdrBookmarkBreadcrumbsQueryVariables,
} from 'schemas/query/bookmark/fdr-bookmark-breadcrumbs.generated';
import { mapFdrBreadcrumbs } from './utils.common';

export async function getFdrBookmarkBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrBookmarkBreadcrumbsQuery,
    FdrBookmarkBreadcrumbsQueryVariables
  >({
    query: FdrBookmarkBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrBookmarksPage.pageBreadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}
