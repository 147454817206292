import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrInfoNotificationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrInfoNotificationQuery = {
  __typename?: 'Query';
  fdrNotifications: {
    __typename?: 'FdrNotificationsResponse';
    information?: Array<{
      __typename?: 'FdrInfoNotification';
      mainText: string;
      icon?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
    }> | null;
  };
};

export const FdrInfoNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrInfoNotification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'information' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resized' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: 'main',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '100',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '100',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'FIT',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrResized' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrInfoNotificationQuery__
 *
 * To run a query within a React component, call `useFdrInfoNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrInfoNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrInfoNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrInfoNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >(FdrInfoNotificationDocument, options);
}
export function useFdrInfoNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrInfoNotificationQuery,
    FdrInfoNotificationQueryVariables
  >(FdrInfoNotificationDocument, options);
}
export type FdrInfoNotificationQueryHookResult = ReturnType<
  typeof useFdrInfoNotificationQuery
>;
export type FdrInfoNotificationLazyQueryHookResult = ReturnType<
  typeof useFdrInfoNotificationLazyQuery
>;
export type FdrInfoNotificationQueryResult = Apollo.QueryResult<
  FdrInfoNotificationQuery,
  FdrInfoNotificationQueryVariables
>;
