import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrBlogArticleOverviewBreadcrumbsDocument,
  FdrBlogArticleOverviewBreadcrumbsQuery,
  FdrBlogArticleOverviewBreadcrumbsQueryVariables,
} from 'schemas/query/blog-article-overview/fdr-blog-article-overview-breadcrumbs.generated';
import {
  FdrBlogArticleBreadcrumbsDocument,
  FdrBlogArticleBreadcrumbsQuery,
  FdrBlogArticleBreadcrumbsQueryVariables,
} from 'schemas/query/blog-article/fdr-blog-article-breadcrumbs.generated';
import { mapFdrBreadcrumbs } from './utils.common';

export async function getFdrBlogArticleOverviewBreadcrumbs(
  queryCtx: TQueryCtx
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrBlogArticleOverviewBreadcrumbsQuery,
    FdrBlogArticleOverviewBreadcrumbsQueryVariables
  >({
    query: FdrBlogArticleOverviewBreadcrumbsDocument,
  });

  const breadcrumbs = data?.fdrBlogArticlesOverviewPage?.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}

export async function getFdrBlogArticleBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrBlogArticleBreadcrumbsQuery,
    FdrBlogArticleBreadcrumbsQueryVariables
  >({
    query: FdrBlogArticleBreadcrumbsDocument,
    variables: { id: pageId },
  });

  const breadcrumbs = data?.fdrBlogArticle?.breadcrumbs || [];
  return mapFdrBreadcrumbs(breadcrumbs);
}
