import { useRouter } from 'next/router';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';

export const useShareUrl = () => {
  const { asPath } = useRouter();
  const {
    user: { agency, channel },
  } = useAuthContext();

  const documentLocationOrigin =
    typeof document !== 'undefined' ? document.location.origin : '';

  const agencyUrlQuery =
    agency && channel === AuthChannelType.B2B ? `?agency=${agency}` : '';

  return `${documentLocationOrigin}${asPath}${agencyUrlQuery}`;
};
