import { track } from '@hotelplan/libs.tracking-events';

export const trackSRLSearchFieldClick = (): void => {
  track({
    event: `🚀 click.openSearch`,
    _clear: true,
  });
};

export const trackProductRouteMapIconClick = (language: string): void => {
  track({
    event: `open_map`,
    action: `open`,
    label: `map`,
    category: undefined,
    component: 'map-icon',
    position: undefined,
    language,
    _clear: true,
  });
};
