import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAddVisitHistoryMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  objectType: Types.FdrVisitHistoryObjectType;
}>;

export type FdrAddVisitHistoryMutation = {
  __typename?: 'Mutation';
  fdrAddVisitHistory: {
    __typename?: 'FdrAddVisitHistoryResponse';
    message?: string | null;
    success: boolean;
  };
};

export const FdrAddVisitHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrAddVisitHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrVisitHistoryObjectType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAddVisitHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'objectId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'objectType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'objectType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrAddVisitHistoryMutationFn = Apollo.MutationFunction<
  FdrAddVisitHistoryMutation,
  FdrAddVisitHistoryMutationVariables
>;

/**
 * __useFdrAddVisitHistoryMutation__
 *
 * To run a mutation, you first call `useFdrAddVisitHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrAddVisitHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrAddVisitHistoryMutation, { data, loading, error }] = useFdrAddVisitHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objectType: // value for 'objectType'
 *   },
 * });
 */
export function useFdrAddVisitHistoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrAddVisitHistoryMutation,
    FdrAddVisitHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrAddVisitHistoryMutation,
    FdrAddVisitHistoryMutationVariables
  >(FdrAddVisitHistoryDocument, options);
}
export type FdrAddVisitHistoryMutationHookResult = ReturnType<
  typeof useFdrAddVisitHistoryMutation
>;
export type FdrAddVisitHistoryMutationResult =
  Apollo.MutationResult<FdrAddVisitHistoryMutation>;
export type FdrAddVisitHistoryMutationOptions = Apollo.BaseMutationOptions<
  FdrAddVisitHistoryMutation,
  FdrAddVisitHistoryMutationVariables
>;
