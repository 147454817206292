import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FdrUspBox } from '@hotelplan/fdr.regular.fusion.fdr-usp-boxes';
import type { FdrUspBox as FdrUspBoxType } from '@hotelplan/supergraph-api';
import { FdrUspBoxesWrapper } from './fdr-usp-boxes.styles';

const MAX_BOXES_COUNT = 4;

interface IFdrUspBoxesProps {
  title?: string;
  boxes: Array<FdrUspBoxType>;
  backgroundColor?: string;
  color?: string;
}

const FdrUspContainerWrapper = styled.div<{
  fontColor?: string;
  bgColor?: string;
}>(({ fontColor, bgColor, theme: { space } }) => ({
  backgroundColor: bgColor,
  color: fontColor,
  padding: `${space[8]} ${space[3]}`,
}));

export function FdrUspBoxes({
  title,
  boxes,
  backgroundColor,
  color,
}: IFdrUspBoxesProps): ReactElement {
  const cols = boxes.length > MAX_BOXES_COUNT ? MAX_BOXES_COUNT : boxes.length;

  return (
    <FdrUspContainerWrapper fontColor={color} bgColor={backgroundColor}>
      <FdrUspBoxesWrapper count={cols}>
        {title ? <h2>{title}</h2> : null}
        {boxes?.map((box, i) => {
          return <FdrUspBox key={`usps-${i}`} {...box} />;
        })}
      </FdrUspBoxesWrapper>
    </FdrUspContainerWrapper>
  );
}
