import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAgencyFragmentDoc } from 'schemas/fragment/agency/fdr-agency.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type GetFdrAgenciesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type GetFdrAgenciesQuery = {
  __typename?: 'Query';
  fdrAgencies: {
    __typename?: 'FdrAgenciesResponse';
    agencies?: Array<{
      __typename?: 'FdrAgency';
      id: string;
      name: string;
      phone: string;
      email: string;
      timezone: string;
      brand: Types.FdrAgencyBrand;
      openStatus: Types.FdrAgencyOpenStatus;
      review?: {
        __typename?: 'FdrAgencyRating';
        totalReviewCount?: number | null;
        averageRating?: number | null;
      } | null;
      address?: {
        __typename?: 'FdrAgencyAddress';
        zip?: string | null;
        city?: string | null;
        region?: string | null;
        street?: string | null;
        country?: string | null;
      } | null;
      openingHours?: {
        __typename?: 'FdrOpeningHours';
        monday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        tuesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        wednesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        thursday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        friday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        saturday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        sunday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
      } | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        latitude: number;
        longitude: number;
      } | null;
      webMeta: {
        __typename?: 'FdrMeta';
        title: string;
        description: string;
        index: boolean;
        noFollow: boolean;
        sitemap: boolean;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
    } | null> | null;
  };
};

export const GetFdrAgenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFdrAgencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAgencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrAgency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAgencyFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetFdrAgenciesQuery__
 *
 * To run a query within a React component, call `useGetFdrAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFdrAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFdrAgenciesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetFdrAgenciesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFdrAgenciesQuery,
    GetFdrAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFdrAgenciesQuery,
    GetFdrAgenciesQueryVariables
  >(GetFdrAgenciesDocument, options);
}
export function useGetFdrAgenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFdrAgenciesQuery,
    GetFdrAgenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFdrAgenciesQuery,
    GetFdrAgenciesQueryVariables
  >(GetFdrAgenciesDocument, options);
}
export type GetFdrAgenciesQueryHookResult = ReturnType<
  typeof useGetFdrAgenciesQuery
>;
export type GetFdrAgenciesLazyQueryHookResult = ReturnType<
  typeof useGetFdrAgenciesLazyQuery
>;
export type GetFdrAgenciesQueryResult = Apollo.QueryResult<
  GetFdrAgenciesQuery,
  GetFdrAgenciesQueryVariables
>;
