import { useFdrRenameBookmarkGroupMutation } from 'schemas/query/bookmark/fdr-rename-bookmark-group.generated';

export function useRenameBookmarkGroup() {
  const [mutation, { data, loading }] = useFdrRenameBookmarkGroupMutation({
    // eslint-disable-next-line i18next/no-literal-string
    refetchQueries: ['FdrBookmarkGroupList'],
  });

  async function renameBookmarkGroup(groupId: string, groupName: string) {
    return mutation({
      variables: {
        input: {
          id: groupId,
          bookmarkGroupName: groupName,
        },
      },
    });
  }

  return {
    renameBookmarkGroup,
    data,
    loading,
  };
}
