import { IImageProps } from '@hotelplan/core.basis.bs-img';
import { mapFdrResizedImagesToResizedUrlData } from 'components/domain/fdr-pdp-media-gallery/fdr-pdp-media-gallery.utils';
import { FdrMediaGalleryFragment } from 'schemas/fragment/media-gallery/fdr-media-gallery.generated';

export const isAllImages = (
  media: FdrMediaGalleryFragment['media']
): boolean => {
  if (!media) return false;

  return media?.every(({ __typename }) => __typename === 'FdrImage');
};

export const mapGalleryMediaToImageMediaItems = (
  media: FdrMediaGalleryFragment['media']
) => {
  return (
    media?.map(mediaItem => {
      if (mediaItem?.__typename != 'FdrImage') return null;

      const fdrImage = mediaItem;

      return {
        __typename: `ImageMediaItem`,
        image: createImageProps(mediaItem, 'mediaImageResized'),
        resource: fdrImage.rawUrl,
        preview: createImageProps(mediaItem, 'mediaImagePreviewResized'),
        title: fdrImage.alt,
        coordinates: fdrImage.coordinates,
      };
    }) || []
  );
};

const createImageProps = (
  mediaItem: Extract<
    FdrMediaGalleryFragment['media'][number],
    { __typename: 'FdrImage' }
  >,
  resizedField: 'mediaImageResized' | 'mediaImagePreviewResized'
): IImageProps => ({
  ...mediaItem,
  src: mediaItem.rawUrl,
  resized: mapFdrResizedImagesToResizedUrlData(mediaItem[resizedField]),
});
