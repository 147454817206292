import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { TProductPin } from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';
import FdrGeoTripsListPoint from './fdr-geo-trips-list-point';
import { ListSubtitle } from './fdr-geo-trips-list.styles';
import {
  isProductPin,
  useFindTrips,
  useShowTitle,
} from './fdr-geo-trips-list.utils';
import { TPinType } from './fdr-geo-trips.types';

const FdrGeoTripsListProductTiles: React.FC = () => {
  const [t] = useTranslation('geo');
  const {
    activeProduct,
    productPins,
    isFullScreen,
    setActiveTrip,
    clusterMarkers,
    setActiveRoutePoint,
  } = useContext(FdrGeoTripsContext);

  const product = productPins.get(activeProduct);
  const clusterProducts = clusterMarkers?.filter(isProductPin);

  const products: TProductPin[] = useFindTrips([product], clusterProducts);
  const showTitle = useShowTitle(products);

  return (
    <>
      {showTitle && (
        <ListSubtitle>
          {t('map.tripList.adventureTrips')}
          <span className="number">{products.length}</span>
        </ListSubtitle>
      )}
      {products.map((trip: TProductPin) => {
        return (
          <FdrGeoTripsListPoint
            pinType={TPinType.PRODUCTS}
            key={trip.tripId}
            isFullScreen={isFullScreen}
            trip={trip}
            onClick={() => {
              setActiveTrip(trip.tripId);
              setActiveRoutePoint(null);
            }}
          />
        );
      })}
    </>
  );
};

export default FdrGeoTripsListProductTiles;
