import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const IPAD_MINI_SCREEN = `@media screen and (min-width: 768px) and (max-width: 1024px)`;

export const FdrCookiesDisclaimerWrapper = styled.div(
  ({ theme, theme: { media } }) =>
    sx2CssThemeFn({
      '.cookies-disclaimer-text': {
        pl: [4, 6],
        fontSize: 0,
        color: '#C69E9E',
        lineHeight: 1.2,
        letterSpacing: '1px',
        a: {
          textDecoration: 'underline',
          color: 'inherit',
        },
        [media.tablet]: {
          pr: 0,
        },
      },
      '.cookies-disclaimer-button': {
        ...theme.buttons.cookieAccept,
        ml: 4,
        mb: ['18px', 0],
        [IPAD_MINI_SCREEN]: {
          mr: 4,
          ml: 0,
        },
        [media.mobile]: {
          width: 'auto',
          mr: 4,
          flexGrow: 1,
        },
      },
    })
);
