import styled from 'styled-components';

export const FdrDisruptersWrapper = styled.div(
  ({ theme: { media, space } }) => ({
    width: '100%',
    maxWidth: '1480px!important',
    left: 0,
    right: 0,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    margin: `0 auto ${space[2]} auto`,
    [media.tablet]: {
      maxWidth: '1480px',
    },
  })
);

export const FdrDisrupterBody = styled.div<{
  color: string;
  backgroundColor: string;
}>(({ color, backgroundColor }) => ({
  minHeight: '45px',
  padding: '5px 15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color,
  backgroundColor,
  fontSize: '12px',
  fontWeight: 900,
}));
