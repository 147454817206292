import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrLink } from '@hotelplan/supergraph-api';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  GetFdrAgencyBreadcrumbsDocument,
  GetFdrAgencyBreadcrumbsQuery,
  GetFdrAgencyBreadcrumbsQueryVariables,
} from 'schemas/query/agencies/fdr-agency-breadcrumbs.generated';
import {
  FdrHomePageUrlDocument,
  FdrHomePageUrlQuery,
  FdrHomePageUrlQueryVariables,
} from 'schemas/query/home/fdr-home-page-url.generated';
import {
  createBreadcrumb,
  getHardcodedCaption,
  getHomeBreadcrumb,
} from './utils.common';

function getCommonAgencyBreadcrumbs(
  data: {
    fdrHomePage?: { webMeta: { link: FdrLink } };
    fdrAgencyOverviewPage?: { webMeta: { link: FdrLink } };
  },
  lang: string
): Array<TFdrBreadcrumb> {
  return [
    ...getHomeBreadcrumb(data, lang),
    createBreadcrumb(
      data?.fdrAgencyOverviewPage?.webMeta.link || null,
      getHardcodedCaption(EPageType.AgencyOverview, lang)
    ),
  ];
}

export async function getFdrAgencyOverviewBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrHomePageUrlQuery,
    FdrHomePageUrlQueryVariables
  >({
    query: FdrHomePageUrlDocument,
  });

  const breadcrumbs = getCommonAgencyBreadcrumbs(data, lang);
  breadcrumbs[1].disabled = true;

  return breadcrumbs;
}

export async function getFdrAgencyBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    GetFdrAgencyBreadcrumbsQuery,
    GetFdrAgencyBreadcrumbsQueryVariables
  >({
    query: GetFdrAgencyBreadcrumbsDocument,
    variables: { id: pageId },
  });

  const commonBreadcrumbs = getCommonAgencyBreadcrumbs(data, lang);

  return [
    ...commonBreadcrumbs,
    createBreadcrumb(null, data?.fdrAgencies.agencies[0]?.name, true),
  ];
}
