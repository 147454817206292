import { track } from '@hotelplan/libs.tracking-events';
import { ClickEventActionType, TrackingEventType } from './tracking.types';

export enum MapClickEventTargetType {
  MAP_PDP_IMAGE_VIEW = 'map-pdp-image-view',
  MAP_PDP_ROUTING_VIEW = 'map-pdp-routing-view',
  FULL_SCREEN_MAP_IMAGE_VIEW = 'full-screen-map-image-view',
  FULL_SCREEN_MAP_ROUTING_VIEW = 'full-screen-map-routing-view',
  ROUTES_NEARBY = 'routes-nearby',
}

export const trackMapClick = (
  eventAction: ClickEventActionType,
  eventTarget: MapClickEventTargetType
): void => {
  track({
    event: TrackingEventType.CLICK,
    eventMetadata: {
      action: eventAction,
      target: eventTarget,
    },
    _clear: true,
  });
};

export enum WishlistEventType {
  ADD_TO_WISHLIST = '🚀 click.addToWishlist',
  REMOVE_FROM_WISHLIST = '🚀 click.removeFromWishlist',
}

export enum PdpWishlistComponentType {
  PRODUCT_RECOMMENDER = 'product-recommender',
  PDP_ICONS = 'pdp-icons',
}

export const trackPdpWishlistIconClick = (
  event: WishlistEventType,
  component: PdpWishlistComponentType
): void => {
  track({
    event,
    eventMetadata: {
      source: `pdp`,
      target: `wishlist`,
      component,
    },
    _clear: true,
  });
};

export const trackPdpShareSiteToolOpenClick = (): void => {
  track({
    event: `🚀 click.openLinkSharing`,
    eventMetadata: {
      source: `pdp`,
      target: `link`,
      component: 'pdp-icons',
    },
    _clear: true,
  });
};

export const trackPdpShareSiteToolClick = (target: string): void => {
  track({
    event: `🚀 click.shareLink`,
    eventMetadata: {
      source: `pdp`,
      target,
      component: 'pdp-icons',
    },
    _clear: true,
  });
};
