import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrDeleteBookmarksMutationVariables = Types.Exact<{
  input: Types.FdrDeleteBookmarksInput;
}>;

export type FdrDeleteBookmarksMutation = {
  __typename?: 'Mutation';
  fdrDeleteBookmarks: {
    __typename?: 'FdrDeleteBookmarksResponse';
    success: boolean;
  };
};

export const FdrDeleteBookmarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrDeleteBookmarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrDeleteBookmarksInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrDeleteBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FdrDeleteBookmarksMutationFn = Apollo.MutationFunction<
  FdrDeleteBookmarksMutation,
  FdrDeleteBookmarksMutationVariables
>;

/**
 * __useFdrDeleteBookmarksMutation__
 *
 * To run a mutation, you first call `useFdrDeleteBookmarksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrDeleteBookmarksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrDeleteBookmarksMutation, { data, loading, error }] = useFdrDeleteBookmarksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrDeleteBookmarksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrDeleteBookmarksMutation,
    FdrDeleteBookmarksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrDeleteBookmarksMutation,
    FdrDeleteBookmarksMutationVariables
  >(FdrDeleteBookmarksDocument, options);
}
export type FdrDeleteBookmarksMutationHookResult = ReturnType<
  typeof useFdrDeleteBookmarksMutation
>;
export type FdrDeleteBookmarksMutationResult =
  Apollo.MutationResult<FdrDeleteBookmarksMutation>;
export type FdrDeleteBookmarksMutationOptions = Apollo.BaseMutationOptions<
  FdrDeleteBookmarksMutation,
  FdrDeleteBookmarksMutationVariables
>;
