import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const FDR_PRODUCT_GALLERY_ASPECT_RATIO = '86%';

export const FdrGalleryWithMapWrapper = styled(Container)(({ theme }) =>
  sx2CssThemeFn({
    marginTop: ['50px', '80px'],
    marginBottom: ['25px', '65px'],
    ...theme.map.controls,
  })
);

export const FdrGalleryWithMapContent = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: [`column-reverse`, 'row'],
    gap: '20px',
    '.map-loader-wrapper': {
      left: 0,
    },
  })
);

export const FdrGalleryMapWrapper = styled.div(
  sx2CssThemeFn({
    width: ['100%', '50%'],
    height: '100%',
  })
);

export const FdrGalleryMapContent = styled.div({
  position: 'relative',
  width: '100%',
  ':after': {
    content: "''",
    display: 'block',
    width: '100%',
    paddingBottom: FDR_PRODUCT_GALLERY_ASPECT_RATIO,
  },
});

export const FdrMediaGalleryWrapper = styled.div(
  sx2CssThemeFn({
    width: ['100%', '50%'],
    '.highlighted-media-gallery': {
      '&:after': {
        paddingBottom: FDR_PRODUCT_GALLERY_ASPECT_RATIO,
      },
    },
    '.slider-control-centerleft': {
      left: ['0px!important', '5px!important'],
    },
    '.slider-control-centerright': {
      right: ['0px!important', '5px!important'],
    },
    '.slide-info': {
      pl: [3, 4],
      pr: '55px',
    },
    '.open-preview': {
      mr: [3, 4],
      mb: 3,
      '.icon': {
        fill: 'transparent',
        width: '32px',
        height: '32px',
      },
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
);
