import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { FdrGeoTripsContext } from 'components/domain/fdr-geo/trips/fdr-geo-trips.context';

const Steps = styled.div({
  rowGap: '20px',
  display: 'flex',
  flexDirection: 'column',
});

const Step = styled.div(({ theme: { colors } }) => ({
  color: colors.darkGrey,
  fontSize: '20px',
  fontWeight: '450',
  '&.active': {
    color: 'black',
  },
}));

const FdrGeoTripsListActionPoints: React.FC = () => {
  const [t] = useTranslation('geo');
  const { activeRoutePoint, clusterMarkers, activeProduct } =
    useContext(FdrGeoTripsContext);

  const isSomethingSelected =
    activeRoutePoint || clusterMarkers || activeProduct;

  const steps = [{ n: 1, active: isSomethingSelected }];

  return (
    <>
      <Steps>
        {steps.map((step, i) => {
          return (
            <Step key={i} className={step.active && 'active'}>
              {t(`map.tripList.tip.step${step.n}`)}
            </Step>
          );
        })}
      </Steps>
    </>
  );
};

export default FdrGeoTripsListActionPoints;
