import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FdrSearchMode } from '@hotelplan/supergraph-api';
import { useFdrExactSearchContext } from 'components/domain/fdr-exact-search';
import { FdrProductRouteMap } from 'components/domain/fdr-product-route-map';
import { trackProductRouteMapIconClick } from 'components/domain/tracking/srlTracking';
import { useFdrAdventureTravelSearchQuery } from 'schemas/query/search/fdr-adventure-travel-search.generated';
import { mapATSearchItemToRouteMapProduct } from './fdr-search.utils';

interface IFdrSearchRouteMapProps {
  searchInput: string;
  isLoading: boolean;
}

export function FdrSearchRouteMap({
  searchInput,
  isLoading,
}: IFdrSearchRouteMapProps): ReactElement {
  const [, { language }] = useTranslation();
  const [exactSearch] = useFdrExactSearchContext();

  const searchMode = exactSearch
    ? FdrSearchMode.Phrase
    : FdrSearchMode.PartialWord;

  const { data } = useFdrAdventureTravelSearchQuery({
    skip: isLoading || !searchInput,
    variables: {
      searchMode,
      searchInput,
    },
  });

  return (
    <FdrProductRouteMap
      id={searchInput}
      products={data?.fdrTextSearch?.itemList.map(
        mapATSearchItemToRouteMapProduct
      )}
      onMapOpenClick={() => {
        trackProductRouteMapIconClick(language);
      }}
    />
  );
}
