import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  FDR_SEARCH_VALUE_KEY,
  openFdrSearchOverlay,
} from 'components/domain/fdr-search-overlay';
import { trackSRLSearchFieldClick } from 'components/domain/tracking/srlTracking';
import {
  FdrEmptySearchButton,
  FdrSearchFieldWrapper,
  FdrSearchInput,
  FdrSearchInputWrap,
  FdrSRLSearchButton,
} from './fdr-search.styles';

interface IFdrSearchFieldProps {
  searchInput: string;
}

export function FdrSearchField({
  searchInput,
}: IFdrSearchFieldProps): ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation(['common', 'search']);

  function handleSearchOverlayOpen(): void {
    openFdrSearchOverlay();
    trackSRLSearchFieldClick();
  }

  function handleOpenWithSetStorage(): void {
    handleSearchOverlayOpen();
    sessionStorage.setItem(FDR_SEARCH_VALUE_KEY, searchInput);
  }

  return (
    <FdrSearchFieldWrapper>
      <FdrSearchInputWrap>
        <FdrSearchInput
          readOnly
          hideLabel
          type="search"
          value={searchInput}
          onClick={handleOpenWithSetStorage}
          placeholder={t('search:searchInput.placeholder')}
          icon={{
            name: `search`,
            size: mobile ? 'sm' : 'md',
          }}
        />
        <FdrEmptySearchButton
          variant="iconBtn"
          onClick={handleSearchOverlayOpen}
          icon={{
            name: 'close-icon',
            size: mobile ? 'sm' : 'md',
          }}
        >
          {t('search:searchInput.submit')}
        </FdrEmptySearchButton>
      </FdrSearchInputWrap>
      <FdrSRLSearchButton
        onClick={handleOpenWithSetStorage}
        icon={{
          name: 'chevron-long-right',
          size: 'lg',
        }}
      >
        <span className="search-submit-button-text">
          {t('search:searchInput.submit')}
        </span>
      </FdrSRLSearchButton>
    </FdrSearchFieldWrapper>
  );
}
