import { Polyline } from '@react-google-maps/api';
import React, { useContext } from 'react';
import { FdrAdventureTravelRoutePoint } from '@hotelplan/supergraph-api';
import { getPath } from 'components/domain/fdr-gallery-with-map';
import { FdrRouteContext } from './fdr-routes.context';

const getOptions = (list, active, hovered, key, double, hasCurrent) => {
  const base = double
    ? { zIndex: active ? 12 : 2, strokeOpacity: 1 }
    : {
        strokeWeight: 6,
        zIndex: active ? 11 : 1,
        strokeOpacity: active === key ? 1 : 0,
      };

  if (list.length === 1) return { ...base, strokeColor: '#E4251B' };
  if (double && active === key) return { ...base, strokeColor: '#ffffff' };
  if (active === key) return { ...base, strokeColor: '#E4251B' };
  if (hovered === key) return { ...base, strokeColor: '#E4251B' };
  if (hasCurrent && Number(key) === 0) {
    return { ...base, strokeColor: '#E4251B', zIndex: 10 };
  }

  return { ...base, strokeColor: '#7A2739', zIndex: 1 };
};

type TProps = {
  keyCode: number;
  setLatLng(latLng: google.maps.LatLng | null): void;
  items: Array<FdrAdventureTravelRoutePoint>;
  list: Array<Array<FdrAdventureTravelRoutePoint>>;
};

export const FdrDoublePolyline: React.FC<TProps> = ({
  keyCode: key,
  items,
  list,
  setLatLng,
}) => {
  const { hover, click, hovered, active, showNearBy, hasCurrent } =
    useContext(FdrRouteContext);

  const handleClick = event => {
    if (showNearBy) {
      setLatLng(event.latLng);
      click(key);
    }
  };

  const handleOver = () => {
    hover(key);
  };

  const handleOut = () => {
    hover(undefined);
  };

  const options = getOptions(list, active, hovered, key, false, hasCurrent);
  const doubleOptions = getOptions(
    list,
    active,
    hovered,
    key,
    true,
    hasCurrent
  );
  const path = getPath(items);

  return (
    <React.Fragment key={key}>
      <Polyline
        path={path}
        options={doubleOptions}
        onClick={handleClick}
        onMouseOver={handleOver}
        onMouseOut={handleOut}
      />
      <Polyline
        key={key}
        path={path}
        options={options}
        onClick={handleClick}
        onMouseOver={handleOver}
        onMouseOut={handleOut}
      />
    </React.Fragment>
  );
};
