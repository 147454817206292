import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from './fdrLink.generated';
export type FdrMenuFragment = {
  __typename?: 'FdrMenu';
  caption: string;
  link: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  };
  menuItems?: Array<{
    __typename?: 'FdrMenuItem';
    caption: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }> | null;
};

export const FdrMenuFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMenu' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrMenu' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menuItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
