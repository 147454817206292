export enum ClickEventActionType {
  SWITCH = 'switch',
  ENLARGE = 'enlarge',
  SELECT = 'select',
  GO_TO = 'go-to',
}

export enum TrackingEventType {
  CLICK = '🚀 click',
}
