import {
  FdrAdventureTravelRoutePoint,
  FdrImage,
  FdrLink,
  ProductInfoB2B,
} from '@hotelplan/supergraph-api';

export interface IAdventureTravelRouteProduct {
  __typename: string;
  name: string;
  image: FdrImage;
  routePoints: Array<FdrAdventureTravelRoutePoint>;
  link: FdrLink;
  productInfoB2B?: ProductInfoB2B;
}

export enum ProductPinType {
  PinIcon = 'PIN_ICON',
  RouteIcon = 'ROUTE_ICON',
}

export type TInputProductWithRoute = {
  name: string;
  images: Array<FdrImage>;
  routePoints?: Array<FdrAdventureTravelRoutePoint>;
  link?: FdrLink;
};

export type TResultProductWithRoute = {
  name: string;
  images: Array<FdrImage>;
  routePoints?: Array<FdrAdventureTravelRoutePoint>;
  link?: FdrLink;
  image?: FdrImage;
};
