import { track } from '@hotelplan/libs.tracking-events';
import { TrackingEventType } from './tracking.types';

export enum ClickEventSourceType {
  DESCRIPTION = 'description',
  USP = 'usp',
  MENU = 'menu',
}

export const trackRequestFormClick = (
  eventSource: ClickEventSourceType
): void => {
  track({
    event: TrackingEventType.CLICK,
    eventMetadata: {
      source: eventSource,
      action: `open-request-form`,
    },
    _clear: true,
  });
};
