import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import React from 'react';
import { FdrSearchResetButton } from 'components/domain/fdr-search-overlay';

export const BookmarkModalPopupWrapper = styled.div<{ open: boolean }>(
  ({ open }) => ({
    width: open ? '100vw' : 0,
    height: open ? '100vh' : 0,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 120,
    overflow: 'hidden',
  })
);

export const BookmarkModalPopupOverlay = styled.div({
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
});

export const BookmarkModalPopupContent = styled.div<{ open: boolean }>(
  ({ open }) => ({
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    transition: 'transform 0.5s ease',
    transform: open ? 'translateY(0%)' : 'translateY(100%)',
  })
);

export const BookmarkPopup = styled.div.attrs({
  className: 'bookmark-popup',
})(({ theme: { colors } }) =>
  sx2CssThemeFn({
    width: ['auto', '500px'],
    right: ['initial', 0],
    top: ['initial', 0],
    backgroundColor: 'white',
    padding: ['12px 20px 24px', '10px 20px 20px'],
    paddingBottom: `calc(20px + env(safe-area-inset-bottom))`,
    zIndex: 5,
    cursor: 'default',
    color: colors.black,
    boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.30)',
  })
);

export const BookmarkPopupHeader = styled.div.attrs({
  className: 'bookmark-popup-header',
})(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    paddingBottom: ['12px', '10px'],
    paddingLeft: '20px',
    paddingRight: '20px',
    marginLeft: '-20px',
    marginRight: '-20px',
    borderBottom: `1px solid ${colors.lightGrey}`,
    alignItems: 'center',
  })
);

export const BookmarkPopupClose = styled(FdrSearchResetButton)(
  sx2CssThemeFn({
    position: 'static',
    transform: 'none',
    flex: '0 1 auto',
    svg: {
      color: 'black',
    },
  })
);

export enum BookmarkPopupContentView {
  DEFAULT,
  COMPACT,
}

export const BookmarkPopupContent = styled.div.attrs({
  className: 'bookmark-popup-content',
})<{ view?: BookmarkPopupContentView }>(
  ({ theme: { space, fontSizes }, view }) =>
    sx2CssThemeFn({
      maxHeight: '252px',
      overflowY: 'auto',
      ...((view === BookmarkPopupContentView.DEFAULT || !view) && {
        paddingTop: space[4],
        '.bookmark-popup-content-row': {
          marginTop: space[4],
        },
        '.bookmark-popup-text-row': {
          fontSize: ['18px', '20px'],
        },
      }),
      ...(view === BookmarkPopupContentView.COMPACT && {
        paddingTop: space[3],
        '.bookmark-popup-content-row': {
          marginTop: space[3],
        },
        '.bookmark-popup-text-row': {
          fontSize: fontSizes[1],
          lineHeight: 1.375,
        },
      }),
    })
);

export const BookmarkPopupHeaderText = styled.div(
  sx2CssThemeFn({
    fontSize: '22px',
    fontWeight: 900,
    flex: '1 1 auto',
  })
);

export const BookmarkPopupTextInputContainer = styled.div({
  width: '100%',
  position: 'relative',
  '.input-icon': {
    display: 'none',
  },
  '.base-input-wrapper input': {
    paddingRight: '48px',
  },
});

export const BookmarkPopupFormSubmitButton = styled(BsButton)({
  width: '100%',
});

export const BookmarkPopupContentRow = styled.div.attrs({
  className: 'bookmark-popup-content-row',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:first-child': {
    marginTop: 0,
  },
}));

export const BookmarkPopupTextRow = styled(BookmarkPopupContentRow).attrs<{
  children?: React.ReactNode;
}>({
  className: `bookmark-popup-text-row`,
})<{ error?: boolean }>(({ theme: { space, radii, colors }, error }) => ({
  flexDirection: 'column',
  gap: space[2],
  ...(error && {
    padding: '20px',
    borderRadius: radii.message,
    background: colors.roseFine,
  }),
}));

export const BookmarkPopupButtonRow = styled(BookmarkPopupContentRow)(
  ({ theme: { space } }) => ({
    justifyContent: 'space-between',
    button: {
      'flex-basis': `calc(50% - ${space[3]})`,
    },
  })
);
