import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

export const FdrB2BAuthPanelContent = styled.div(
  ({ theme }) => theme.auth.panelContent
);

export const FdrB2BLoginFormOverlay = styled(WhiteBoxModal)(
  ({ theme }) => theme.modals.content,
  ({ theme }) => ({
    '.modal-wrapper': theme.auth.formModalWrapperStyles,
  })
);

export const FdrLogoutIconHolder = styled.div(
  ({ theme }) => theme.auth.logoutIconHolder
);
