import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { FdrSearchMode, FdrSearchType } from '@hotelplan/supergraph-api';
import {
  FdrTextSearchQuery,
  useFdrTextSearchQuery,
} from 'schemas/query/search/fdr-text-search.generated';

export function useGeneralSearch(
  isAgenciesSection: boolean,
  searchInput: string,
  fetchPolicy: WatchQueryFetchPolicy,
  perPageCount: number,
  searchMode: FdrSearchMode,
  searchTypes: FdrSearchType | FdrSearchType[],
  storedPage: number
): {
  data: FdrTextSearchQuery;
  loading: boolean;
  fetchMore: (
    nextPage: number,
    fetchingCount: number
  ) => Promise<ApolloQueryResult<FdrTextSearchQuery>>;
} {
  const prevMode = useRef<FdrSearchMode>();
  const prevSearchInput = useRef<string>();

  const [localData, setLocalData] = useState<FdrTextSearchQuery>();

  const variables = {
    searchMode,
    searchInput,
    searchTypes,
    pagination: { page: storedPage, perPage: perPageCount },
  };

  const { fetchMore: fdrTextSearchFetchMore, loading } = useFdrTextSearchQuery({
    variables,
    skip: isAgenciesSection || !searchTypes || !searchInput,
    notifyOnNetworkStatusChange: true,
    fetchPolicy:
      searchMode && prevMode.current !== searchMode
        ? `cache-and-network`
        : fetchPolicy || `cache-first`,
    onCompleted(responseData) {
      setLocalData(stored => {
        if (!responseData || !responseData?.fdrTextSearch) return stored;
        const search = responseData.fdrTextSearch;

        if (
          !stored ||
          prevMode.current !== searchMode ||
          prevSearchInput.current !== searchInput
        ) {
          prevMode.current = searchMode;
          prevSearchInput.current = searchInput;

          const newList = [
            ...new Array(search.pagination.page * search.pagination.perPage),
            ...search.itemList,
          ];

          return {
            ...responseData,
            fdrTextSearch: { ...search, itemList: newList },
          };
        }

        const updatedList = [...stored?.fdrTextSearch?.itemList];
        updatedList.splice(
          search.pagination.page * search.pagination.perPage,
          search.itemList.length,
          ...search.itemList
        );

        return {
          ...responseData,
          fdrTextSearch: { ...search, itemList: updatedList },
        };
      });
    },
  });

  const fetchMore = useCallback(
    (nextPage: number, fetchingCount: number) => {
      return fdrTextSearchFetchMore({
        variables: {
          ...variables,
          pagination: {
            page: nextPage,
            perPage: fetchingCount,
          },
        },
      });
    },
    [searchMode, searchTypes, searchInput]
  );

  return {
    data: localData,
    loading,
    fetchMore,
  };
}
