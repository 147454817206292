import { useTranslation } from 'next-i18next';
import React from 'react';
import { ProductInfoB2B } from '@hotelplan/supergraph-api';
import { removeExtraSpaces } from 'components/domain/string/string.utils';
import { Details } from './fdr-geo-trips-list.styles';

type TProps = {
  productInfoB2B: ProductInfoB2B;
};

const FdrGeoTripsListBusinessInfo: React.FC<TProps> = ({
  productInfoB2B,
}: TProps) => {
  const [t] = useTranslation('geo');

  const areTagsVisible = (productInfoB2B.typeFlags || []).length > 0;

  return (
    <Details>
      {removeExtraSpaces(productInfoB2B?.duration) && (
        <>
          {t(`product.b2bInfo.duration`)} {productInfoB2B.duration}
        </>
      )}

      {removeExtraSpaces(productInfoB2B.route) && (
        <>
          <br />
          {productInfoB2B.route}
        </>
      )}
      {removeExtraSpaces(productInfoB2B.priceFrom) && (
        <>
          <br />
          {t(`product.b2bInfo.price`)}
          {productInfoB2B.priceFrom}
        </>
      )}
      {areTagsVisible && (
        <>
          <br />
          {productInfoB2B.typeFlags.join(', ')}
        </>
      )}
    </Details>
  );
};

export default FdrGeoTripsListBusinessInfo;
