import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrLangControlWrapper = styled.div(({ theme: { media } }) => ({
  display: 'flex',
  fontSize: '12px',
  fontWeight: '900',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  position: 'absolute',
  '@media screen and (max-width: 896px) and (orientation: landscape)': {
    position: 'static',
  },
  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    position: 'static',
  },
  top: '28px',
  right: '35px',
  [media.tablet]: {
    position: 'static',
    top: 'auto',
    right: 'auto',
  },
  '.lang-link:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export const FdrLang = styled.div(
  sx2CssThemeFn({
    p: 1,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '2px',
      bottom: '0px',
      left: '0px',
      bg: 'transparent',
    },
    '&.active:after': {
      bg: 'white',
    },
    '&:hover': {
      color: 'white',
      '&:after': {
        bg: 'white',
      },
    },
  })
);
