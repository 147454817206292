import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import {
  BsRsvGallery,
  OverlaySlideInfo,
  SlideInfo,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { FdrMedia } from '@hotelplan/supergraph-api';
import { renderMedia } from 'components/util/criterias';
import { FlaglineText } from 'theme/headings';
import { FdrPdpMediaGalleryContainer } from './fdr-pdp-media-gallery.styles';
import { mapFdrMediaToImageMediaItems } from './fdr-pdp-media-gallery.utils';

interface IFdrPdpMediaGalleryProps {
  mediaItems: Array<FdrMedia>;
  title?: string;
  initialSlide?: number;
  className?: string;
}

export function FdrPdpMediaGallery({
  title,
  initialSlide,
  className,
  mediaItems,
}: IFdrPdpMediaGalleryProps): ReactElement {
  const [t] = useTranslation(['common', 'pdp']);

  const mappedItems = mapFdrMediaToImageMediaItems(mediaItems);

  if (!mappedItems.length) return null;

  return (
    <FdrPdpMediaGalleryContainer className={className}>
      <FlaglineText className="gallery-title">
        {title || t('pdp:mediaGallery.title')}
      </FlaglineText>
      <BsRsvGallery
        renderSlide={renderMedia}
        items={mappedItems}
        initialSlide={initialSlide}
        overlaySlideInfo={(item, slideIndex, count, isHideNavigation) => (
          <OverlaySlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
        slideInfo={(item, slideIndex, count, isHideNavigation) => (
          <SlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
      />
    </FdrPdpMediaGalleryContainer>
  );
}
