import {
  PaginationArrowClickSource,
  PaginationArrowSide,
  trackPaginationArrowClick,
} from 'components/domain/tracking/paginationTracking';

export const handleLeftArrowClick = ({
  onPrevPage,
  trackingSource,
}: {
  onPrevPage?(): void;
  trackingSource?: PaginationArrowClickSource;
}): void => {
  onPrevPage();

  if (!trackingSource) return;
  trackPaginationArrowClick({
    source: trackingSource,
    target: PaginationArrowSide.LEFT,
  });
};

export const handleRightArrowClick = ({
  onNextPage,
  trackingSource,
}: {
  onNextPage?(): void;
  trackingSource?: PaginationArrowClickSource;
}): void => {
  onNextPage();

  if (!trackingSource) return;
  trackPaginationArrowClick({
    source: trackingSource,
    target: PaginationArrowSide.RIGHT,
  });
};
