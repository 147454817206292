import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { PrintButton } from 'components/domain/share-overlay/share-overlay.styles';

type TProps = { icon: string };

const Print: React.FC<TProps> = ({ icon }: TProps) => {
  return (
    <PrintButton onClick={() => window.print()}>
      <Icon name={icon} size="md" />
    </PrintButton>
  );
};

export default Print;
