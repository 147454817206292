import React from 'react';
import { IFdrImageProps } from '@hotelplan/fdr.regular.basis.fdr-image';
import { TFdrMediaMetaMap } from '@hotelplan/fdr.regular.basis.fdr-picture';
import {
  FdrProductFeature,
  FdrProductFeatureGroup,
} from '@hotelplan/supergraph-api';
import FdrResponsiveImgWithCopyright from 'components/domain/fdr-copyright/fdr-responsive-img-with-copyright';
import { TFdrThProduct } from 'components/domain/fdr-product/fdr-product.types';
import { isFdrThProduct } from 'components/domain/fdr-product/fdr-product.utils';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrImageMediaWrapper } from './fdr-page-components.styles';

type TFdrProductRecommenderComponent = Extract<
  FdrPageComponentsFragment['components'][number],
  { __typename: 'FdrProductRecommender' }
>;

export type TFdrProductRecommenderProduct = TFdrThProduct<
  TFdrProductRecommenderComponent['productPage']['products'][number]
>;

export function getFdrProductRecommenderProducts(
  component: TFdrProductRecommenderComponent
): TFdrProductRecommenderProduct[] {
  return component.productPage?.products?.filter(isFdrThProduct) || [];
}

export function mapProductFeatureGroupsToProductFeatures(
  featureGroups: FdrProductFeatureGroup[]
): FdrProductFeature[] {
  return (
    featureGroups
      ?.flatMap(group => group.features)
      .filter(feature => feature.enabled) || []
  );
}

export function renderResponsiveImg(
  className: string,
  specs: TFdrMediaMetaMap
) {
  return function img(props: IFdrImageProps & { key: React.Key }): JSX.Element {
    const { key, ...imageProps } = props;

    return (
      <FdrImageMediaWrapper className="hero-media-wrapper" key={key}>
        <FdrResponsiveImgWithCopyright
          {...imageProps}
          imageProps={{
            className,
            noSet: true,
          }}
          specs={specs}
        />
      </FdrImageMediaWrapper>
    );
  };
}
