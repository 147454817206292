import React from 'react';
import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrImage } from '@hotelplan/supergraph-api';
import {
  getFdrLinkWithJotform,
  useFdrGclid,
} from 'components/domain/fdr-jotform';
import { FdrContactModuleFragment } from 'schemas/fragment/contact-module/fdr-contact-module.generated';
import { FlaglineText, SecondaryHeading } from 'theme/headings';

interface IContactsSectionProps {
  component?: FdrContactModuleFragment;
}

const ContactsBlock = styled.div(
  ({ theme: { media, space, colors, fontSizes } }) => ({
    border: '16px solid',
    borderColor: colors.primary,
    backgroundColor: colors.roseFine,
    padding: `50px ${space[3]}`,
    [media.tablet]: {
      border: '20px solid',
      borderColor: colors.primary,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.section-title': {
      fontSize: '15px',
      lineHeight: '20px',
      marginBottom: space[4],
      textAlign: 'center',
      padding: '0 60px',
    },
    '.section-heading': {
      fontSize: fontSizes[7],
      lineHeight: '42px',
      textAlign: 'center',
      marginBottom: space[6],
      marginLeft: space[2],
      marginRight: space[2],
      [media.tablet]: {
        fontSize: '46px',
        lineHeight: '60px',
        marginBottom: space[6],
      },
    },
  })
);

const ContactsItems = styled.div<{ itemsNumber: number }>(
  ({ theme: { media, text, space }, itemsNumber }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'baseline',
    gap: `${space[6]} 0`,
    '@media screen and (max-width: 767px) and (orientation: portrait)': {
      'a:first-child': {
        flexBasis: itemsNumber === 3 ? '100%' : '50%',
      },
    },
    [media.tablet]: {
      maxWidth: '1170px',
      gap: '55px',
      flex: '1 0 auto',
    },
    a: {
      textAlign: 'center',
      flexBasis: '50%',
      '@media screen and (min-width: 767px)': {
        flex: '0 0 auto',
      },
      [media.tablet]: {
        flex: '0 0 auto',
      },
    },
    'a:hover': {
      color: '#000000',
    },
    '.phone-text': {
      ...text.groupHeading,
      letterSpacing: '0.1px',
    },
  })
);

const ContactItem = styled.div(({ theme: { media, space } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: space[6],
  minWidth: '160px',
  '.text-block-text': {
    fontSize: '15px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  img: {
    width: '48px',
  },
  [media.mobile]: {
    gap: space[4],
  },
}));

const FdrContactSection: React.FC<IContactsSectionProps> = ({ component }) => {
  const { flagline, moduleItems } = component;
  const gclid = useFdrGclid();

  return (
    <ContactsBlock>
      <FlaglineText
        className="section-title"
        dangerouslySetInnerHTML={{ __html: flagline }}
      />
      <SecondaryHeading className="section-heading">
        {component.title}
      </SecondaryHeading>
      <ContactsItems itemsNumber={moduleItems.length}>
        {moduleItems.map((contactItem, index) => {
          const { title, icon } = contactItem;

          switch (contactItem.__typename) {
            case 'FdrContactModuleLink': {
              const linkWithJotform = getFdrLinkWithJotform(
                contactItem.link,
                gclid
              );

              return (
                <FdrRoute
                  link={linkWithJotform}
                  key={`contact-item-${index}`}
                  testId="contact-item"
                >
                  <FdrBaseContact icon={icon} title={title} />
                </FdrRoute>
              );
            }
            case 'FdrContactModulePhone': {
              return (
                <ContactPhoneBox
                  key={`contact-item-${index}`}
                  phone={contactItem.phone}
                >
                  <FdrBaseContact icon={icon} title={title} />
                </ContactPhoneBox>
              );
            }
            default:
              return null;
          }
        })}
      </ContactsItems>
    </ContactsBlock>
  );
};

interface IFdrBaseContactProps {
  icon: Partial<FdrImage>;
  title: string;
}

const FdrBaseContact: React.FC<IFdrBaseContactProps> = ({ icon, title }) => {
  return (
    <ContactItem>
      <FdrImg image={icon} />
      <FlaglineText
        className="text-block-text"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </ContactItem>
  );
};

interface IContactPhoneBoxProps {
  phone?: string;
}

const ContactPhoneBox: React.FC<IContactPhoneBoxProps> = ({
  children,
  phone,
}) => {
  return (
    <BsLink href={`tel:${phone}`} testId="contact-phone-item">
      {children}
      <span className="phone-text">{phone}</span>
    </BsLink>
  );
};

export default FdrContactSection;
